import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  productService from "./productService";

export const productIndex = createAsyncThunk(
    "products/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await productService.productIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const productStore = createAsyncThunk(
    "products/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await productService.productStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const productUpdate = createAsyncThunk(
    "products/update",
    async (args, thunkAPI) => {
        try {
            return await productService.productUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const productUploadPhoto = createAsyncThunk(
    "products/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await productService.productUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const productShow = createAsyncThunk(
    "products/:id/show",
    async (args, thunkAPI) => {
        try {
            return await productService.productShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const productDelete = createAsyncThunk(
    "products/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await productService.productDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    product: {},
    products:  {
        data:[]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
    },
    extraReducers : {
        [productStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [productStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.product = payload.data.product;
            state.loading=false;
            state.isSuccess = true;
        },
        [productStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [productUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [productUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [productUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [productShow.pending]:(state, {payload}) => {
            state.data = {};
            state.product = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [productShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.product = payload.data.product;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [productShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [productDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.product = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [productDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [productDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [productIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [productIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.products = payload.data.products;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [productIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = productSlice.actions

export default productSlice;