import api from "../../support/support_api";

const auctionBiddingList = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/getListManageAuction', params);

    return response.data;
}

const getListMyAuctionBuyer = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/getListMyAuctionBuyer', params);

    return response.data;
}

const auctionBiddingDetail = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/'+params.id+'/getDetailAuctionPlot');

    return response.data;
}
const auctionBiddingDetailBuyer = async (params) => {
    const response  = await  api.axiosGet('/auction-plots/'+params.id+'/getDetailAuctionPlotBuyer');

    return response.data;
}


const auctionBiddingService = {
    auctionBiddingList,
    getListMyAuctionBuyer,
    auctionBiddingDetail,
    auctionBiddingDetailBuyer
}


export default auctionBiddingService;