import React, { useState, useEffect, useContext } from "react";
import AccountLayout from "pages/AccountLayout";
import { gradeIndex } from "../../features/grade/gradeSlice";
import { productShow, productUpdate } from "../../features/product/productSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { farmIndex } from "../../features/farm/farmSlice";
import { categoryIndex } from "../../features/category/categorySlice";
import { useParams } from "react-router-dom";
import { fileUpload } from "features/file/fileSlice";
import { catalogDestroy, catalogStore } from "../../features/catalog/catalogSlice";
import PhotoSlider from "../files/slider/PhotoSlider";
import SnackbarContext from "_helpers/snackbar-context";
import PopupImageSlider from "./PopupImageSlider";
import CustomImageProduct from "./CustomImageProduct";
import { Col, Row, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

/**
 * TODO -  Refactor this code lateron, it has to be readable and easy to understand
 * TODO - Refactor the adding/removing of catalog, make it a single method instead of using 2 methods.
 *
 * @returns {*}
 * @constructor
 */
const ProductEdit = () => {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const [isShowPopupImage, setIsShowPopupImage] = useState(false);
  const [product, setProduct] = useState(null);
  const [scientificName, setScientificName] = useState("");
  const [quantityNumber, setQuantityNumber] = useState(0);
  const [farmPrice, setFarmPrice] = useState(0);
  const [farmPriceUnit, setFarmPriceUnit] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [defaultPopUpImage, setDefaultPopUpImage] = useState(0);
  const [quantityTarget, setQuantityTarget] = useState(0);
  const [quantityNumberUnit, setQuantityNumberUnit] = useState('');
  const [categoryId, setCategoryId] = useState("");
  const [includeDeliveryFee, setIncludeDeliveryFee] = useState(null);
  const [deliveryChargeType, setDeliveryChargeType] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0)
  const [variety, setVariety] = useState("");

  const reduxProduct = useSelector((state) => state.product);
  const reduxCatalog = useSelector((state) => state.catalog);
  const reduxcategory = useSelector((state) => state.category);

  const [gradeList, setGradeList] = useState({
    data: [],
  });
  const [farmList, setFarmList] = useState({
    data: [],
  });

  const [gradeId, setGradeId] = useState("");
  const [farmId, setFarmId] = useState("");
  const [catalogId, setCatalogId] = useState("");
  const snackbarCtx = useContext(SnackbarContext);
  const uploadImg = () => {
    inputElementPhoto.click();
  }
  const save = (event) => {
    event.preventDefault();
    if (parseFloat(quantityNumber) < 0 || parseFloat(quantityTarget) < 0) {
      snackbarCtx.displayMsg("Quantities cannot be less than zero.", "failure");
      return;
    }
    let productId = params.product_id;
    dispatch(
      productUpdate({
        product_id: productId,
        farm_id: farmId,
        farm_ids: [farmId], // preparation for multiple grades
        // grade_id: gradeId, // This will apply the 1 to 1 relationship between the product and grade
        // grade_ids: [gradeId], // This will apply the many to many relationship between the product and grades
        name: name,
        quantity_number: quantityNumber,
        quantity_target: quantityTarget,
        category_id: categoryId,
        scientific_name: scientificName,
        selling_price: sellingPrice,
        delivery_charge: deliveryCharge,
        delivery_charge_type: deliveryChargeType
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
        let product = response.payload.data.product;

        navigate(`/products`);
      }
    });


  };

  const loadFarms = () => {
    dispatch(
      farmIndex({
        page: 1,
        limit: 100,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        // alert(response.payload);
      } else {
        let farms = response.payload.data.farms;

        setFarmList(farms);

        loadProduct();
      }
    });
  };

  const loadCategories = () => {
    dispatch(
      categoryIndex({
        page: 1,
        limit: 100,
      })
    );
  };
  const loadProduct = () => {
    let productId = params.product_id;

    dispatch(
      productShow({
        product_id: productId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        // alert(response.payload);
      } else {
        let product = response.payload.data.product;
        setProduct(product);
        setPhotos(product.photos);

        setScientificName(product.scientific_name);
        setName(product.name);
        setVariety(product.crop.variety_name);
        setFarmId(product.farm_id);
        setQuantityNumber(product.quantity_number);
        setQuantityTarget(product.quantity_target);
        setFarmPrice(product.crop.grades.data[0].price_formatted);
        setFarmPriceUnit(product.crop.grades.data[0].price_unit_symbol)
        setCategoryId(product.category_id);
        setSellingPrice(parseFloat(product.crop.grades.data[0].selling_price ? product.crop.grades.data[0].selling_price : product.crop.grades.data[0].price_formatted))
        setDeliveryCharge(parseFloat(product.delivery_charge))
        setDeliveryChargeType(product.delivery_charge_type.toString())

        if (product?.delivery_charge !== null) {
          setIncludeDeliveryFee(0)
        }

        if (product?.delivery_charge === '0.00') {
          setIncludeDeliveryFee(1)
        }

        // TODO - (Refactor)  we need to implement the waiting state, after setFarmId() finished executing then we need to trigger this code bellow.
        setTimeout(function () {
          setGradeId(product.grade_id);
          setupQuantityUnit(product);
        }, 500);
      }
    });
  };

  const setupQuantityUnit = (product) => {
    const filterGrade = product?.crop?.grades?.data.filter(grade => {
      return grade.id === parseInt(gradeId)
    });
    if (filterGrade?.length > 0) {
      setQuantityNumberUnit(filterGrade[0]?.quantity_unit_capital);
    } else {
      setQuantityNumberUnit(product?.crop?.grades?.data[0]?.quantity_unit_capital);
    }

  }

  const loadGrades = () => {
    dispatch(
      gradeIndex({
        page: 1,
        limit: 100,
        farm_id: farmId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
        let grades = response.payload.data.grades;
        setGradeList(grades);
      }
    });
  };

  const productSaveToCatalog = () => {
    let productId = params.product_id;

    dispatch(
      catalogStore({
        product_id: productId,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        //alert(response.payload.message);

        loadProduct();
      }
    });
  };

  const productRemoveFromCatalog = () => {
    dispatch(
      catalogDestroy({
        catalog_id: reduxProduct.product.catalog.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        // alert(response.payload);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        // alert(response.payload.message);

        loadProduct();
      }
    });
  };

  useEffect(() => {
    loadFarms();
    loadCategories();
  }, []);

  useEffect(() => {
    setupQuantityUnit(reduxProduct.product);
  }, [gradeId]);

  // only run if the farmId was updated
  useEffect(() => {
    if (farmId) {
      setGradeId("");

      loadGrades();
    }
  }, [farmId]);

  const [photos, setPhotos] = useState([]);
  const [inputElementPhoto, setInputElementPhoto] = useState("");

  const uploadPhotos = (event) => {
    let productId = params.product_id;

    let length = event.target.files.length;

    for (let i = 0; i < length; i++) {
      let selectedFile = event.target.files[i];

      const formData = new FormData();

      formData.append("file", selectedFile, selectedFile.name);
      formData.append("store", "multiple");
      formData.append("model", "product");
      formData.append("type", "photo");
      formData.append("model_id", productId);

      dispatch(fileUpload(formData)).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
          //alert(response.payload);
        } else {
          let photos = response.payload.data.product.photos;

          setPhotos(photos);
        }
      });
    }
  };

  return (
    <AccountLayout>
      <div data-testid="product-edit" className="flex flex-col h-full max-h items-start gap-6 p-4 mb-5">
        {/* Title */}
        <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <h1 className="title-content text-center text-xl" style={{ marginTop: -20 }}>Edit Product</h1>
        {/* Widget */}

        <div className="flex flex-col rounded-lg shadow bg-card-white-new w-full">
          <Row>
            {/* image  */}
            <Col md={6}>
              {/* Photos */}
              {/* <section className="w-full place-self-center laptop:self-start flex flex-col space-y-4 col-span-2 "> */}

              <div className="flex flex-col items-center">
                {/* <PhotoSlider photos={photos} reloadData={loadProduct} /> */}
                {/* <PhotoSlider photos={photos.data} reloadData={loadProduct} /> */}
                {(isShowPopupImage && product && photos) && <PopupImageSlider defaultSlideIndex={defaultPopUpImage} defaultPhoto={product?.photo_default} photos={photos} isShowPopupImage={isShowPopupImage} reloadData={loadProduct} setIsShowPopupImage={setIsShowPopupImage} />}
                {product && photos && <CustomImageProduct defaultPhoto={product?.photo_default} photos={photos?.data} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} uploadImg={uploadImg} reloadData={loadProduct} setDefaultPopUpImage={setDefaultPopUpImage} />}
                {/* <button type="button" className="saveButton" onClick={() => {
                      inputElementPhoto.click();
                    }}>
                  <label
                    className="cursor-pointer text-sm"
                    
                  >
                    Upload Photos
                  </label>
                  
                </button> */}
                <input
                  className={"hidden"}
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  onChange={uploadPhotos}
                  ref={(input) => setInputElementPhoto(input)}
                />
              </div>
              {/* </section> */}
            </Col>

            <Col md={6}>
              <form className="w-full max-w flex flex-col space-y-4" onSubmit={save}>
                <section className="flex justify-start items-center">
                  <label className="w-1/3">Product Name:</label>
                  <input type="text" placeholder={name} value={name} onChange={({ target }) => setName(target.value)} className={"input-search w-2/3"} />
                </section>

                {/* <section className="flex justify-start items-center">
                  <label className="w-1/3">Categories: </label>
                  {
                    <select
                      disabled={true}
                      className="w-2/3 input-search  bg-slate-300"
                      value={categoryId || ''}
                      onChange={({ target }) => setCategoryId(target.value)}
                    >
                      <option value="">Select Category</option>
                      {reduxcategory.categories.data.map((category, index) => {
                        return (
                          <option value={category.id} key={index}>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                  }
                </section> */}

                <section className="flex justify-start items-center">
                  <label className="w-1/3">Farm of Origin:</label> <span> {reduxProduct.product.farm && reduxProduct.product.farm.name} </span>
                </section>

                <section className="flex justify-start items-center">
                  <label className="w-1/3">Crop:</label> <span> {reduxProduct.product.crop && reduxProduct.product.crop.name} </span>
                </section>

                <section className="flex justify-start items-center">
                  <label className="w-1/3">Variety: </label>
                  <input
                    className="w-1/3 input-search bg-slate-300"
                    type="number"
                    min="0"
                    placeholder={variety}
                    value={variety} />
                </section>

                <section className="flex justify-start items-center">
                  <label className="w-1/3">Grades: </label>
                  {reduxProduct.product.crop && reduxProduct.product.crop.grades.data.length > 0 ? (
                    <select
                      className="w-1/3 input-search"
                      defaultValue={""}
                      // value={gradeId}
                      onChange={({ target }) => {
                        setGradeId(target.value);

                        reduxProduct.product.crop.grades.data.map((grade, index) => {
                          if (target.value == grade.id) {
                            setQuantityNumber(grade.quantity)
                            setQuantityTarget(grade.quantity)
                          }
                        })
                      }}
                    >
                      {/*<option value="">Select Grade</option>*/}
                      {reduxProduct.product.crop.grades.data.map((grade, index) => {
                        return (
                          <option value={grade.id} key={index}>
                            {grade.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <span>No grades available.</span>
                  )}
                </section>
                <section className="flex justify-between items-center">
                  <label className="w-1/3">Available Quantity:</label>
                  <div className="flex w-2/3 justify-start items-center">
                    <input
                      className="w-1/3 input-search"
                      type="number"
                      min="0"
                      placeholder={quantityNumber}
                      value={quantityNumber}
                      onChange={({ target }) => setQuantityNumber(target.value)}
                    />
                    <label className="w-1/6 text-center">
                      {quantityNumberUnit}
                    </label>
                  </div>
                </section>
                <section className="flex justify-between items-center">
                  <label className="w-1/3">Farm Price:</label>
                  <div className="flex w-2/3 justify-start items-center">
                    <label className="w-1/6 text-center">
                      {farmPriceUnit}
                    </label>
                    <input
                      disabled
                      className="bg-slate-300 w-1/3 input-search"
                      type="number"
                      min="0"
                      placeholder={farmPrice}
                      value={farmPrice}
                      onChange={({ target }) => setFarmPrice(target.value)}
                    />
                  </div>
                </section>
                <section className="flex justify-between items-center">
                  <label className="w-1/3">Selling Price:</label>
                  <div className="flex w-2/3 justify-start items-center">
                    <label className="w-1/6 text-center">
                      RM
                    </label>
                    <input
                      disabled
                      className="bg-slate-300 w-1/3 input-search"
                      type="number"
                      min="0"
                      placeholder={sellingPrice}
                      value={sellingPrice}
                    />
                  </div>
                </section>
                <label>Include Delivery Charges?</label>
                <label className="text-green" style={{ fontSize: "15px" }}>*If you choose to include delivery charges you will be required to pay for delivery fee</label>
                <Row className="mt-2 mb-2">
                  <Col md={3} style={{ width: '3cm' }}>
                    <input
                      type="radio"
                      name="include_delivery_fee"
                      value={1}
                      checked={includeDeliveryFee == 1}
                      onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                    /> Yes
                  </Col>
                  <Col md={3} style={{ width: '3cm' }}>
                    <input
                      type="radio"
                      name="include_delivery_fee"
                      value={0}
                      checked={includeDeliveryFee == 0}
                      onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                    /> No
                  </Col>
                </Row>
                {includeDeliveryFee == 0 && (
                  <>
                    <label>Delivery Charge:</label>
                    <Row className="mt-2 mb-2">
                      <Col md={6} xs={7}>
                        <InputGroup>
                          {(deliveryChargeType != 1) && (
                            <>
                              <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge - 1)}>-</div></InputGroup.Text>
                              <Form.Control
                                type="number"
                                min="0"
                                placeholder=""
                                className="text-center text-green input-group-plot"
                                value={deliveryCharge}
                                onChange={({ target }) => setDeliveryCharge(parseFloat(target.value))}
                                step="any"
                              />
                            </>
                          )}
                          {(deliveryChargeType == 1) && (
                            <>
                              <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                              <Form.Control
                                type="number"
                                min="0"
                                placeholder=""
                                className="input-group-custom form-control"
                                value={deliveryCharge}
                                onChange={({ target }) => setDeliveryCharge(parseFloat(target.value))}
                                step="any"
                              />
                            </>
                          )}
                          {(deliveryChargeType != 1) && (
                            <>
                              <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge + 1)}>+</div></InputGroup.Text>
                            </>
                          )}
                        </InputGroup>
                      </Col>
                      <Col md={6} xs={5}>
                        <select className="input-search text-green" defaultValue={"DEFAULT"} value={deliveryChargeType} onChange={({ target }) => setDeliveryChargeType(target.value)}>
                          <option value="DEFAULT">
                            Select Type..
                          </option>
                          <option value="1">Fix Amount</option>
                          <option value="2">%</option>
                        </select>
                      </Col>
                    </Row>
                  </>
                )}
                <section className="flex justify-between items-center hidden">
                  <label className="w-1/3"> Target Quantity:</label>
                  <div className="flex w-2/3 justify-between items-center">
                    <input
                      className="w-2/3 input-search"
                      type="number"
                      min="0"
                      placeholder={quantityTarget}
                      value={quantityTarget}
                      onChange={({ target }) => setQuantityTarget(target.value)}
                    />
                    <label className="w-1/3 text-center">
                      {quantityNumberUnit}
                    </label>
                  </div>
                </section>
                <section className="flex justify-between wide:justify-evenly items-center gap-2">
                  <button type="submit" className="saveButton w-1/2 wide:w-1/3 rounded-md">
                    {reduxProduct.updating ? "Processing, please wait.." : "Save"}
                  </button>
                  {reduxProduct.product.catalog_exist ? (
                    <button
                      type="submit"
                      className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-md text-sm px-5 py-2.5 text-center w-1/2 wide:w-1/3"
                      onClick={productRemoveFromCatalog}
                      disabled={reduxCatalog.loading}
                    >
                      {reduxCatalog.loading ? "Removing from catalog, please wait..." : "Unpublish"}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="saveButton w-1/2 wide:w-1/3 rounded-md"
                      onClick={productSaveToCatalog}
                      disabled={reduxCatalog.loading}
                    >
                      {reduxCatalog.loading ? "Adding to catalog, please wait..." : "Publish"}
                    </button>
                  )}
                </section>

              </form>
            </Col>
          </Row>
        </div>
      </div>
    </AccountLayout>
  );
};

export default ProductEdit;
