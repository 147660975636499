import api from "../../support/support_api";

const orderStore = async (params) => {
    const response  = await  api.axiosPost('/orders', params);

    return response.data;
}

const orderUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/orders/${params.order_id}`, params);

    return response.data;
}

const orderShow = async (params) => {
    const response  = await api.axiosShow(`/orders/${params.order_id}`, params);

    return response.data;
}

const orderIndex = async (params) => {
    const response  = await api.axiosGet(`/orders`, params);

    return response.data;
}

const orderUpdateByToken = async (params) => {
    const response  = await api.axiosPatch(`/orders/update-by-token`, params);

    return response.data;
}

const orderPlace = async (params) => {
    const response  = await api.axiosPatch(`/orders/place`, params);

    return response.data;
}

const productsCheckQuantity = async (params) => {
    const response  = await api.axiosPost(`/orders/${params.order_id}/products-check-quantity`, params);

    return response.data;
}

const orderReject = async (params) => {
    const response  = await  api.axiosPut('/orders/'+params.id+'/reject');

    return response.data;
}

const orderApprove = async (params) => {
    const response  = await  api.axiosPut('/orders/'+params.id+'/approve');

    return response.data;
}

// const orderReceivableForFarmer = async (params) => {
//     const response  = await api.axiosGet(`/orders/receivable-for-farmer`, params);
//
//     return response.data;
// }
//
// const orderMadeByCustomer = async (params) => {
//     const response  = await api.axiosGet(`/orders/made-by-customer`, params);
//
//     return response.data;
// }

const orderService = {
    orderStore, orderUpdate, orderShow, orderIndex, orderUpdateByToken, orderPlace, productsCheckQuantity, orderReject, orderApprove
    // orderReceivableForFarmer, orderMadeByCustomer
}


export default orderService;