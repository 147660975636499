import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserInfo, authRoleActiveChange } from "../../features/auth/authSlice";
import editUserIcon from "./../../images/EditUserIcon.png";
import profileIcon from "../../images/ProfileIcon.png";
import ProfileRating from "./ProfileRating";

const ProfileInfoAdmin = () => {
  const dispatch = useDispatch();

  const { currentUser, token, currentCompany } = useSelector((state) => state.auth);
  const role = currentUser.role_name.charAt(0).toUpperCase() + currentUser.role_name.slice(1);
  const setRoleActiveChange = (roleName) => {
    dispatch(
      authRoleActiveChange({
        role_name: roleName,
      })
    ).then(function () {
      window.location = "/account/edit";
    });
  };
  useEffect(() => {
    dispatch(getCurrentUserInfo());
  }, []);

  const userPhotoSrc = typeof currentUser.photo === 'object' ? currentUser.photo.src
    : currentUser.photo;

  return (
    <div className="flex items-center gap-x-4">
      <img
        src={userPhotoSrc}
        alt="profile icon"
        className="bg-white border-[.01rem] border-buttonBorder rounded-full w-16 h-16 object-cover aspect-square"
      />
      <div className="flex flex-col gap-2" style={{width: "100%"}}>
        <p className="text-basis font-semibold">{currentUser.first_name}</p>
        <p className="text-basis">Administrator</p>

      </div>
      
    </div>
  );
};

export default ProfileInfoAdmin;
