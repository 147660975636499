import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import SnackbarContext from "_helpers/snackbar-context";
import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { printAwb } from "../../features/order-farmer/orderFarmerSlice";
const OrderFarmer = ({ orderFarmer }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    // const [statusText, seStatusText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let status = "";
    let statusText = "";

    if (orderFarmer.status === 0) {
        status = "primary";
    } else if (orderFarmer.status === 1) {
        status = "warning";
    } else if (orderFarmer.status === -1) { //need confirm quantity from booking
        status = "warning";
    }  else if (orderFarmer.status === 9) { //need confirm quantity from booking
        statusText = 'Order created';
        status = "warning";
    }  else if (orderFarmer.status === 10) { //need confirm quantity from booking
        statusText = 'Farmer start to packaging';
        status = "warning";
    } else if (orderFarmer.status === 5) {
        status = "danger";
    } else {
        status = "success";
    }

    const editHandler = () => {
        navigate(`/order-farmers/${orderFarmer.id}/tracking`);
    };

    const handleClickPrintAwb = (order_farmer_id) => {
        setIsLoading(true);
        dispatch(
            printAwb({
                order_farmer_id: order_farmer_id
            })
        ).then(function (response) {
            setIsLoading(false);
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure");
                // alert(response.payload);
            } else {
                // snackbarCtx.displayMsg(response.payload.message, "success")
                let result = response.payload.data;
                if(result){
                    window.open(result.url, '_blank', 'noreferrer');
                }
            }
        });
    }

    return (
        <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full" style={{ borderBottomWidth: "1px" }}>
            {/* <div className="flex flex-row items-center justify-center pb-3" style={{borderBottomWidth: "1px"}}>
              <div className="basis-1/4 flex items-center justify-center font-medium">Orders</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Price</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
              <div className="basis-1/4 flex items-center justify-center font-medium"></div>
            </div> */}
            <div className=" w-full flex flex-row p-2 gap-4 items-center ">
                <section className="basis-1/4 desktop:flex items-center gap-x-8 ">
                    <label className="lg:hidden text-md font-semibold text-center">Order # {(orderFarmer.order.order_number) ? orderFarmer.order.order_number : orderFarmer.id} </label>
                    <img
                        // src={orderDetail.product.photo ? info.photo : }
                        src={orderFarmer.detail.product ? orderFarmer.detail.product.photo_default : "/assets/images/products/WheatIcon.svg"}
                        alt={orderFarmer.detail.product ? orderFarmer.detail.product.name : "-"}
                        className="  orderLarge:inline min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-full"
                    />
                    <label className="hidden laptop:flex desktop:hidden justify-center">{`${orderFarmer.detail.quantity} ${orderFarmer.detail.grade && orderFarmer.detail.grade.quantity_unit_capital}`}</label>

                    <div className="hidden lg:flex flex-col">
                        <label className="text-2md font-semibold">Order #{(orderFarmer.order.order_number) ? orderFarmer.order.order_number : orderFarmer.id} </label>
                        <label>{`${orderFarmer.detail.quantity} ${orderFarmer.detail.grade && orderFarmer.detail.grade.quantity_unit_capital}`}</label>
                        {/*<label>{`${orderDetail.quantity} ${$orderDeta.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
                    </div>
                </section>
                <section className="basis-1/4 flex gap-4 items-center justify-center productSmall:flex-col">
                    <div className="flex flex-col gap-1 items-center ">
                        <label className="laptop:hidden text-xs font-bold">Quantity:</label>
                        <label className="laptop:hidden">{`${orderFarmer.detail.quantity} ${orderFarmer.detail.grade && orderFarmer.detail.grade.quantity_unit_capital}`}</label>
                        {/* <Badge bg={status} className={(orderFarmer.status == -1) ? 'text-black' : 'lg:hidden'}>{orderFarmer.status_text}</Badge> */}
                        <label className="laptop:hidden text-xs font-bold">Price:</label>
                        <label className=" text-center w-[7rem]">
                            {/*{info.currency}{info.price.toFixed(2)}*/}
                            
                            {orderFarmer?.price_unit} {orderFarmer.amount_total_overall}
                        </label>
                        <label className="laptop:hidden text-xs font-bold">Status:</label>
                        <label className=" laptop:hidden text-center w-[7rem]">
                            <Badge bg={status} className={(orderFarmer.status == -1) ? 'text-black' : ''}>{orderFarmer.status_text || statusText}</Badge>
                            {/* {orderFarmer.status_text} */}
                            {/*{info.status ? info.status : "Loading..."}*/}
                        </label>
                        <button className="laptop:hidden btn-border-green w-[120px] mr-auto ml-auto mb-3" onClick={editHandler}>{(orderFarmer.status == -1) ? 'Confirm Quantity' : 'Details'}</button>
                    </div>
                </section>

                <section className="basis-1/4 flex gap-4 items-center justify-center">

                    <div className="hidden orderSmall:flex gap-3 items-center">

                        <label className="text-center w-[7rem]">
                            <Badge bg={status} className={(orderFarmer.status == -1) ? 'text-black' : ''}>{orderFarmer.status_text || statusText}</Badge>
                            {/* {orderFarmer.status_text} */}
                            {/*{info.status ? info.status : "Loading..."}*/}
                        </label>
                    </div>
                </section>
                {/* <section className="basis-1/4 flex gap-4 items-center justify-end">
                    <button className="hidden laptop:flex btn-border-green w-[120px] items-center justify-center text-center" onClick={editHandler}>{(orderFarmer.status == -1) ? 'Confirm Quantity' : 'Details'}</button>
                </section> */}
                <section className="basis-1/4 flex gap-4 items-center justify-center productSmall:flex-col">
                    <div className="flex flex-col gap-3 items-center ">
                        {/* <label className="lg:hidden text-2md font-semibold">-</label> */}
                        {/* <label className="lg:hidden">-</label> */}
                        <button className="hidden sm:block btn-border-green w-[120px] mr-auto ml-auto mb-3" onClick={editHandler}>{(orderFarmer.status == -1) ? 'Confirm Quantity' : 'Details'}</button>
                        {(orderFarmer.status == 1 && orderFarmer.shipping_id) ?
                            <>
                                <button className="btn-border-green w-[120px] mr-auto ml-auto mb-3" onClick={() => handleClickPrintAwb(orderFarmer.id)}>{(isLoading) ? 'Processing, please wait..' : 'Print AWB'}</button>
                            </>
                            : ""
                        }
                    </div>
                </section>
            </div>
            {/* <div className="inline laptop:hidden wide:hidden flex w-full space-x-2 text-center mb-2">
                <button className="btn-border-green w-[120px] mr-auto ml-auto mb-3" onClick={editHandler}>{(orderFarmer.status == -1) ? 'Confirm Quantity' : 'Details'}</button>
            </div> */}
        </div>
    );
};

export default OrderFarmer;
