import AccountLayout from "pages/AccountLayout";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  authUpdateCompanyPhoto,
} from "../../features/auth/authSlice";
import { companyShow, companyUpdate } from "../../features/company/companySlice";
import { packageIndex } from "../../features/package-subscription/packageSlice";
import { useParams } from "react-router-dom";
import { faTrashCan, faPenToSquare, } from '@fortawesome/free-solid-svg-icons';
import styles from "../account/Account.module.scss";
import clsx from "clsx";

const CompanyDetailEdit = () => {
  const params = useParams();
  const { updating } = useSelector((state) => state.auth);
  const [company, setCompany] = useState(null);
  const [identityID, setIdentityID] = useState(0);
  const [companyName, setCompanyName] = useState(null);
  const [regNumber, setRegNumber] = useState(0);
  const [companyAddress, setCompanyAddress] = useState(null);
  const [subsStartDate, setSubsStartDate] = useState(null);
  const [subsEndDate, setSubsEndDate] = useState("");
  const [contactNumber, setContactNumber] = useState(0);
  const [packageName, setPackageName] = useState("");
  const [serviceChargeType, setServiceChargeType] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [packageID, setPackageID] = useState(0);
  const [companyPackages, setCompanyPackages] = useState([]);
  const [photo, setPhoto] = useState("");
  const [inputElement, setInputElement] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState(""); // State to track the selected account type
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  //modal advance booking
  const [showAdvanceBookingForm, setShowAdvanceBookingForm] = useState(false);
  const popupCloseAdvanceBookingForm = () => setShowAdvanceBookingForm(false);
  const popupShowAdvanceBookingForm = () => setShowAdvanceBookingForm(true);

  const handleAccountTypeChange = (event) => {
    setSelectedAccountType(event.target.value);
  };

  const handleServiceChargeTypeChange = (event) => {
    setServiceChargeType(event.target.value);
  };

  const loadCompany = () => {
    let companyID = params.company_id;

    dispatch(
      companyShow({
        company_id: companyID,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        // alert(response.payload);
      } else {
        // console.log(response.payload.message);

        let company = response.payload.data.company;
        console.log(company?.company_packages.data);
        setCompanyPackages(company?.company_packages.data);
        // console.log(user.photo);
        setIdentityID(company.identity_id)
        setCompany(company);
        setCompanyName(company.name);
        setRegNumber(company.registration_number);
        setCompanyAddress(company.billing_address);
        setContactNumber(company.business_contact_number);
        setSubsStartDate(formatDate(company.packages?.subs_start_date) ?? '');
        setSubsEndDate(formatDate(company.packages?.subs_end_date));
        setPackageName(company?.packages?.package?.package_name ?? 'Select package');
        setSelectedAccountType(company?.role_id?.role_id);
        setPackageID(company?.packages?.package_id ?? 0);
        setServiceChargeType(company?.packages?.service_charge_type ?? 0);
        if (serviceChargeType == 0) {
          setServiceCharge(company.company_package?.service_charge);
        } else {
          setServiceCharge(company.package?.service_charge);
        }
        let photo = company.photo.src || company.photo;
        setPhoto(photo);
      }
    });
  };
  const [packageList, setPackagesList] = useState({
    data: []
  });

  const loadPackage = () => {
    if (packageID) {
      dispatch(
        packageIndex({
          package_id: packageID,
          page: 1,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          // console.log(response.payload.message);
          let packages = response.payload.data.packages;
          setPackagesList(packages);
        }
      });
    } else {
      dispatch(
        packageIndex({
          package_id: packageID,
          page: 1,
        })
      ).then(function (response) {
        // console.log(response.payload.message);
        let packages = response.payload.data.packages;
        setPackagesList(packages);
      });
    }
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const update = (event) => {
    let company_id = params.company_id;
    dispatch(
      // companyPackageStore({
      //   company_id: company_id,
      //   package_id: packageID,
      // }),
      companyUpdate({
        company_id: company_id,
        identity_id: identityID,
        company_name: companyName,
        registration_number: regNumber,
        company_address: companyAddress,
        business_contact_number: contactNumber,
        package_id: packageID,
        account_type: selectedAccountType,
        service_charge_type: serviceChargeType,
        service_charge: serviceCharge,
        subsStartDate: subsStartDate,
        subsEndDate: subsEndDate,
      }),
      // companyPackageStore({
      //   company_id: company_id,
      //   package_id: packageID,
      // })
    ).then(function (response) {
      // console.log("true")
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
        // alert(response.payload);
      } else {
        //alert(response.payload.message);

        snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });

    event.preventDefault();
  };

  const onFileChange = (event) => {
    let selectedFile = event.target.files[0];

    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);
    formData.append("store", "single");
    formData.append("model", "adminCompanyUpdate");
    formData.append("type", "photo");
    formData.append("company_id", company.id);

    dispatch(authUpdateCompanyPhoto(formData)).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
        //alert(response.payload);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        //alert(response.payload.message);
        window.location.reload();
      }
    });
  };


  useEffect(() => {
    loadCompany()
    loadPackage()
  }, []);

  return (
    <AccountLayout>
      <div className="flex flex-col items-start gap-2 p-4">
        <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <h1 className="text-3xl font-regular font font-semibold pb-2" style={{ color: "#395B50" }}>Edit Company</h1>
        <div className="w-full max-w bg-white flex flex-col items-start rounded-lg shadow gap-4 p-4">
          <h1 className="text-xl font-regular font font-semibold" style={{ color: "#395B50" }}>Company Details</h1>
          <form className="row font-regular text-sm" style={{ width: "100%" }} onSubmit={update}>
            <div className="col">
              <div className="self-start flex flex-col gap-4 col-md-10">
                <div className="flex items-start ">
                  <img
                    src={photo}
                    alt="profile"
                    onClick={() => inputElement.click()}
                    className="bg-white w-25 h-25 cursor-pointer object-cover aspect-square border-[.01rem] border-green-400 rounded-md"
                  />
                  <input className={"hidden"} type="file" value="" onChange={onFileChange} ref={(input) => setInputElement(input)} accept="image/png, image/jpeg" />
                </div>
                <div className="flex flex-col space-y-6">
                  <section className="flex flex-col space-y-1">
                    <label>Company ID:</label>
                    <input
                      type="text"
                      className={"input-search"}
                      placeholder={identityID}
                      value={identityID}
                      onChange={({ target }) => setIdentityID(target.value)}
                    // disabled={true}
                    />
                  </section>
                  <section className="flex flex-col space-y-1">
                    <label>Company Name:</label>
                    <input
                      type="text"
                      className={"input-search"}
                      placeholder={companyName}
                      value={companyName}
                      onChange={({ target }) => setCompanyName(target.value)}
                    // disabled={!currentUser.is_company_owner}
                    />
                  </section>
                  <section className="flex flex-col space-y-1">
                    <label>Company Registration Number:</label>
                    <input
                      type="text"
                      className={"input-search"}
                      placeholder={regNumber}
                      value={regNumber}
                      onChange={({ target }) => setRegNumber(target.value)}
                    // disabled={!currentUser.is_company_owner}
                    />
                  </section>
                  <section className="flex flex-col space-y-1">
                    <label>Company Address:</label>
                    <textarea
                      className={"input-search"}
                      placeholder={companyAddress}
                      value={companyAddress}
                      onChange={({ target }) => setCompanyAddress(target.value)}
                    // disabled={true}
                    />
                  </section>
                  <section className="flex flex-col space-y-1">
                    <label>Business Contact No:</label>
                    <input
                      type="text"
                      className={"input-search"}
                      placeholder={contactNumber}
                      value={contactNumber}
                      onChange={({ target }) => setContactNumber(target.value)}
                    // disabled={!currentUser.is_company_owner}
                    />
                  </section>
                  {packageID ? (<>
                    <section className="flex flex-col space-y-1">
                      <label>Package:</label>
                      <select className="input-search" onChange={({ target }) => setPackageID(target.value)}>
                        <option value={packageID}>{packageName}
                        </option>
                        {packageList.data.map((pack) => {
                          return (
                            <option value={pack.id} key={pack.id}>{pack.package_name}</option>
                          );
                        })}
                      </select>
                    </section>
                    <section className="flex flex-col space-y-1">
                      <label>Service Charge Type:</label>
                      <select
                        value={serviceChargeType}
                        className="input-search"
                        onChange={handleServiceChargeTypeChange}
                      >
                        <option value={serviceChargeType} disabled>
                          {"Select Service Type"}
                        </option>
                        <option value="0">Percentage</option>
                        <option value="1">Fixed</option>
                      </select>
                    </section>
                    <section className="flex flex-col space-y-1">
                      <label>Service Charge (%) per Month: </label>
                      <input
                        type="number"
                        className={"input-search"}
                        placeholder={serviceCharge}
                        value={serviceCharge}
                        onChange={({ target }) => setServiceCharge(target.value)}
                        disabled={serviceChargeType === 1}
                      />
                    </section>
                  </>
                  ) : (<></>)}
                  <section className="flex flex-col space-y-1">
                    <label>Account Type:</label>
                    <select
                      value={selectedAccountType}
                      className="input-search"
                      onChange={handleAccountTypeChange}
                    >
                      <option value={company?.role_id?.role_id}>
                        {company?.role_id?.role_name}
                      </option>
                      <option value="2">Farmer</option>
                      <option value="1">Customer</option>
                      <option value="5">Both</option>
                    </select>
                  </section>
                  {packageID ? (<>
                    <section className="flex flex-col space-y-1">
                      <div className="flex flex-col">
                        <div className="flex items-center col-span-3 gap-2">
                          <label>Subscription</label>
                          {/* <label className="ml-36">Remaining days:</label>
                      <label style={{ color: "#E54C4C" }}>30</label> */}
                        </div>
                        <div className="flex col-span-2 gap-4">
                          <div>
                            <label>Start Date:</label>
                            <input
                              className="input-search"
                              type="date"
                              placeholder={subsStartDate}
                              value={subsStartDate}
                              onChange={({ target }) => setSubsStartDate(target.value)}
                            />
                          </div>
                          <div>
                            <label>End Date:</label>
                            <input
                              className="input-search"
                              type="date"
                              placeholder={subsEndDate}
                              value={subsEndDate}
                              onChange={({ target }) => setSubsEndDate(target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </>) : (<>
                  </>)}
                  <div className="row">
                    <div className="col-md-4">
                      <button
                        type="submit"
                        className={"saveButton text-white"}
                      >
                        {updating ? "Processing, please wait.." : "Edit and Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="self-start flex flex-col gap-4 col-md-10">
                <div className={clsx(styles.scroll, "w-full mt-2 shadow rounded-lg")}>
                  <table className="rounded-t-lg" style={{ width: "100%" }}>
                    <tr className="text-center p-4 text-sm rounded-t-lg" style={{ backgroundColor: "#00AF54", color: "white" }}>
                      <th className="lg:w-6/12 sm:w-full md:w-full p-2 rounded-tl-lg">
                        Package Name
                      </th>
                      <th className="lg:w-4/12 sm:w-full md:w-full p-2 rounded-tr-lg">
                        Options
                      </th>
                    </tr>
                    <>
                      {companyPackages.map((company, key) =>
                        <tr className="text-center text-sm bg-white rounded-b-lg" style={{ borderBottomWidth: "1px" }}>
                          <td className="p-2">
                            {company?.package?.package_name}
                          </td>
                          <td className="p-2">
                            <div className="flex justify-center">
                              <FontAwesomeIcon icon={faPenToSquare} className={"ml-6 text-lg cursor-pointer"} onClick={() => setOpenEditPopup(true)} />
                              <FontAwesomeIcon icon={faTrashCan} className=" ml-6 text-lg cursor-pointer" onClick={() => setOpenDeletePopup(true)} />
                            </div>
                          </td>
                        </tr>)}
                      {companyPackages.length === 0 && <span>No package is available.</span>}
                    </>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AccountLayout>
  );
};

export default CompanyDetailEdit;