import SnackbarContext from "_helpers/snackbar-context";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { advanceBookingDetail, downloadInvoice, uploadPaymentReceipt } from "../../../features/advance-booking/advanceBookingSlice";

import Footer from "components/footer/footer";
import { Col, Row, Badge } from "react-bootstrap";
import DepositProgress from "./DepositProgress";
// import { upload } from "@testing-library/user-event/dist/upload";
// const FileDownload = require('js-file-download');

// import { useSearchParams } from "react-router-dom";
// import {Route, Link, Routes, useParams} from 'react-router-dom';

const DepositOffline = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const snackbarCtx = useContext(SnackbarContext);
    const [bookingPlot, setBookingPlot] = useState(null);
    const [paymentMessage, setPaymentMessage] = useState(null);
    const [inputPhotoPaymentReceipt, setInputPhotoPaymentReceipt] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadDetail = () => {
        setIsLoading(true);
        dispatch(
            advanceBookingDetail({
                id: params.booking_id,
            })
        ).then(function (response) {
            setIsLoading(false);
            if (response.error && response.error.message == "Rejected") {
            } else {
                let result = response.payload.data.booking_plot;
                // console.log(result);
                setBookingPlot(result);
            }
        });
    };

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadPaymentReceiptHandler = () => {
        if (selectedFile == null) {
            alert("Please choose payment proof.");
        } else {
            if (window.confirm("Are you sure want to pay deposit this booking?")) {
                setIsLoading(true);
                const formData = new FormData();
                formData.append("file", selectedFile, selectedFile.name);
                formData.append("id", params.booking_id);

                dispatch(uploadPaymentReceipt(formData)).then(function (response) {
                    setIsLoading(false);
                    if (response.error && response.error.message === "Rejected") {
                        alert(response.payload);
                    } else {
                        let result = response.payload.data.booking_plot;
                        setBookingPlot(result);
                        setIsLoading(false);
                        snackbarCtx.displayMsg(response.payload.message, "success");
                        navigate(`/manage-advance-booking/detail/${params.booking_id}`);
                    }
                });
            }
        }
    }

    const backToBooking = () => {
        navigate(`/manage-advance-booking/detail/${bookingPlot.id}`);
    };

    const downloadInvoiceHandler = () => {
        setIsLoading(true);
        dispatch(
            downloadInvoice({
                id: params.booking_id
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
            } else {
                let result = response.payload;
                const fileURL = URL.createObjectURL(result);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                snackbarCtx.displayMsg("Invoice has been generated.", "success");
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        loadDetail();
    }, []);

    return (
        <Row className="container mb-20">
            <Col md={3}></Col>
            <Col md={6}>
                <div className="flex flex-col items-center mt-4">
                    <div style={{ minWidth: "80%" }} >
                        <DepositProgress />
                    </div>
                </div>
                <Row className="mt-3">
                    <Col md={12}>
                        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
                            <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                                <div className="w-full max-w">
                                    <center><label className="text-center mt-1"><b>Please transfer the deposit to this account:</b></label></center>
                                    <Row className="mt-2">
                                        <label className="w-1/3">Bank Name</label>
                                        <Col xs={8}> : {bookingPlot?.plot?.farmer?.bank_detail?.bank_name}</Col>
                                    </Row>
                                    <Row>
                                        <label className="w-1/3">Account Name</label>
                                        <Col xs={8}> : {bookingPlot?.plot?.farmer?.bank_detail?.account_name}</Col>
                                    </Row>
                                    <Row>
                                        <label className="w-1/3">Account Number</label>
                                        <Col xs={8}> : {bookingPlot?.plot?.farmer?.bank_detail?.account_number}</Col>
                                    </Row>
                                    <Row>
                                        <label className="w-1/3">Deposit Amount</label>
                                        <Col xs={8}> : RM {bookingPlot?.deposit_amount}</Col>
                                    </Row>
                                    <Row hidden={(bookingPlot?.status === 5) ? false : true}>
                                        <label className="col-md-4">Status</label>
                                        <Col md={8}>
                                            : <Badge bg="warning" className="text-black">Awaiting Farmer Confirmation</Badge>
                                        </Col>
                                    </Row>
                                    <Row className="flex justify-center">
                                        {/* <Col md={4}></Col> */}
                                        <Col xs={10} md={6} lg={4}>
                                            <button type="button" className="btn-green mt-3 mb-3" onClick={() => {
                                                downloadInvoiceHandler()
                                            }}>
                                                {(isLoading) ? "Processing, please wait.." : "Download Invoice"}
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4 mb-2">
                                        <label className="col-md-12">Already transfer? Please upload payment proof for confirmation.</label>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            {/* <label className="input-group-text" for="inputGroupFile" style={{ marginTop: "1px" }}>{(selectedFile) ? "File Selected" : "Select File"}</label> */}
                                            <input
                                                id="inputGroupFile"
                                                // className={"hidden"}
                                                className="form-control"
                                                type="file"
                                                accept="image/png, image/jpeg, image/jpg"
                                                onChange={handleChangeFile}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <button type="button" className="btn-green laptop:w-5/5 wide:w-5/5" onClick={(event) => {
                                                event.preventDefault();
                                                uploadPaymentReceiptHandler();
                                            }}>
                                                {(isLoading) ? "Processing, please wait.." : "Upload"}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={3}></Col>
            <Footer />
        </Row>
    );
};

export default DepositOffline;
