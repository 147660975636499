import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  orderDetailService from "./orderDetailService";

export const orderDetailIndex = createAsyncThunk(
    "orderDetails/index",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailStore = createAsyncThunk(
    "orderDetails/store",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailUpdate = createAsyncThunk(
    "orderDetails/update",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailUpdateQuantity = createAsyncThunk(
    "orderDetails/update",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailUpdateQuantity(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailShow = createAsyncThunk(
    "orderDetails/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailDeliveryDecline = createAsyncThunk(
    "orderDetails/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailDeliveryDecline(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailDeliveryAccept = createAsyncThunk(
    "orderDetails/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailDeliveryAccept(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailDeliveryStart = createAsyncThunk(
    "orderDetails/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailDeliveryStart(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailDeliveryDone = createAsyncThunk(
    "orderDetails/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailDeliveryDone(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


export const orderDetailDelete = createAsyncThunk(
    "orderDetails/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailReceivableForFarmer = createAsyncThunk(
    "orderDetails/placed-orders",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailReceivableForFarmer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderDetailMadeByCustomer = createAsyncThunk(
    "orderDetails/placed-orders",
    async (args, thunkAPI) => {
        try {
            return await orderDetailService.orderDetailMadeByCustomer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    message:'',
    data: {},
    order_details: {
        data: []
    },
    order_detail: {},
    order: [],
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const orderDetailSlice = createSlice({
    name: 'orderDetail',
    initialState,
    reducers: {
        updateDeliveryFee:(state, action) => {
            state.amount_delivery = parseFloat(action.payload || 0);
        }
    },
    extraReducers : {
        [orderDetailStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [orderDetailStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.order_detail = payload.data.order_detail;
            state.loading=false;
            state.isSuccess = true;
        },
        [orderDetailStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [orderDetailUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderDetailUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderDetailUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderDetailShow.pending]:(state, {payload}) => {
            state.data = {};
            state.order_detail = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderDetailShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.order_detail = payload.data.order_detail;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderDetailShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderDetailIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderDetailIndex.fulfilled]:(state, {payload}) => {
            state.data          = payload;
            state.order_details = payload.data.order_details;
            state.order         = payload.data.order;
            // TODO: move to backend
            state.order.amount_total_overall= payload.data.order.amount_total + state.amount_delivery || payload.data.order.amount_total;
            state.order.amount_total_overall_formatted = state.order.amount_total_overall_formatted?.toLocaleString(undefined, { minimumFractionDigits: 2 });
            state.message       = payload.message;
            state.loading       = false;
            state.updating      = false;
            state.isSuccess     = true;
        },
        [orderDetailIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderDetailDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderDetailDelete.fulfilled]:(state, {payload}) => {
            state.data          = payload;
            // state.order_details = payload.data.order_details;
            // state.order         = payload.data.order;
            state.message       = payload.message;
            state.loading       = false;
            state.updating      = false;
            state.isSuccess     = true;
        },
        [orderDetailDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },


        [orderDetailReceivableForFarmer.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderDetailReceivableForFarmer.fulfilled]:(state, {payload}) => {
            state.data          = payload;
            state.order_details = payload.data.order_details;
            // state.order         = payload.data.order;
            state.message       = payload.message;
            state.loading       = false;
            state.updating      = false;
            state.isSuccess     = true;
        },
        [orderDetailReceivableForFarmer.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export const { updateDeliveryFee } = orderDetailSlice.actions

export default orderDetailSlice;