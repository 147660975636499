import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  auctionBiddingService from "./auctionBiddingService";
// import orderFarmerDetailService from "../orderFarmer-detail/orderFarmerDetailService";

export const auctionBiddingList = createAsyncThunk(
    "auction-plots/getListManageAuction",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await auctionBiddingService.auctionBiddingList(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getListMyAuctionBuyer = createAsyncThunk(
    "auction-plots/getListMyAuctionBuyer",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await auctionBiddingService.getListMyAuctionBuyer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const auctionBiddingDetail = createAsyncThunk(
    "auction-plots/:auction_id/getDetailAuctionPlot",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await auctionBiddingService.auctionBiddingDetail(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const auctionBiddingDetailBuyer = createAsyncThunk(
    "auction-plots/:auction_id/getDetailAuctionPlotBuyer",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await auctionBiddingService.auctionBiddingDetailBuyer(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    booking_plot: {},
    booking_plots: {
        data: []
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const auctionBiddingSlice = createSlice({
    name: 'auctionBidding',
    initialState,
    reducers: {
    },
    extraReducers : {
        [auctionBiddingList.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [auctionBiddingList.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.booking_plots = payload.data.booking_plots;
            state.loading=false;
            state.isSuccess = true;
        },
        [auctionBiddingList.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = orderFarmerSlice.actions

export default auctionBiddingSlice;