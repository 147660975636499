import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  orderService from "./orderService";
import orderDetailService from "../order-detail/orderDetailService";

export const orderIndex = createAsyncThunk(
    "orders/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await orderService.orderIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderStore = createAsyncThunk(
    "orders/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await orderService.orderStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderUpdate = createAsyncThunk(
    "orders/update",
    async (args, thunkAPI) => {
        try {
            return await orderService.orderUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderShow = createAsyncThunk(
    "orders/:id/show",
    async (args, thunkAPI) => {
        try {
            return await orderService.orderShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)
//
export const orderUpdateByToken = createAsyncThunk(
    "orders/place",
    async (args, thunkAPI) => {
        try {
            return await orderService.orderUpdateByToken(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderPlace = createAsyncThunk(
    "orders/place",
    async (args, thunkAPI) => {
        try {
            return await orderService.orderPlace(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const productsCheckQuantity = createAsyncThunk(
    "orders/products-check-quantity",
    async (args, thunkAPI) => {
        try {
            return await orderService.productsCheckQuantity(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderReject = createAsyncThunk(
    "orders/:id/reject",
    async (args, thunkAPI) => {
        try {
            return await orderService.orderReject(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const orderApprove = createAsyncThunk(
    "orders/:id/approve",
    async (args, thunkAPI) => {
        try {
            return await orderService.orderApprove(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)



//
// export const orderReceivableForFarmer = createAsyncThunk(
//     "orders/made-and-receivable",
//     async (args, thunkAPI) => {
//         try {
//             return await orderService.orderReceivableForFarmer(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )
//
// export const orderMadeByCustomer = createAsyncThunk(
//     "orders/made-and-receivable",
//     async (args, thunkAPI) => {
//         try {
//             return await orderService.orderMadeByCustomer(args);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )


const initialState = {
    data: {},
    order: {},
    orders: [],
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
    },
    extraReducers : {
        [orderStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [orderStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.order = payload.data.order;
            state.loading=false;
            state.isSuccess = true;
        },
        [orderStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [orderUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderShow.pending]:(state, {payload}) => {
            state.data = {};
            state.order = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.order = payload.data.order;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.orders = payload.data.orders;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [orderPlace.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [orderPlace.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.orders = payload.data.order;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [orderPlace.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [productsCheckQuantity.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [productsCheckQuantity.fulfilled]:(state, {payload}) => {
            state.data = payload;
            // state.orders = payload.data.order;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [productsCheckQuantity.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [orderApprove.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [orderApprove.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.orders = payload.data.orders;
            state.loading=false;
            state.isSuccess = true;
        },
        [orderApprove.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [orderReject.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [orderReject.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.orders = payload.data.orders;
            state.loading=false;
            state.isSuccess = true;
        },
        [orderReject.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },


        // [orderReceivableForFarmer.pending]:(state, {payload}) => {
        //     state.data = {};
        //     state.loading=true;
        //     state.updating=true;
        //     state.isSuccess = false;
        // },
        // [orderReceivableForFarmer.fulfilled]:(state, {payload}) => {
        //     state.data = payload;
        //     state.orders = payload.data.orders;
        //     state.loading=false;
        //     state.updating=false;
        //     state.isSuccess = true;
        // },
        // [orderReceivableForFarmer.rejected]:(state, {payload}) => {
        //     state.data = payload;
        //     state.loading = false;
        //     state.updating = false;
        //     state.isSuccess = true;
        // },
    }
})

// export const {  } = orderSlice.actions

export default orderSlice;