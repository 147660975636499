import { routesPaths } from "constants/routes.constants";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUserInfo} from "../../features/auth/authSlice";
import { useState, useEffect } from "react";

const PlotWidget = ({ plot }) => {
    const navigate = useNavigate();

    const photo_default = "/assets/images/products/WheatIcon.svg";

    const dispatch = useDispatch();

    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getCurrentUserInfo());
    }, []);

    const clickHandler = () => {
        // if(! isLoggedIn) {
        //     navigate(`${routesPaths.LOGIN}`);
        // } else {
            navigate(`${routesPaths.CATALOG_PLOT_DETAIL}/${plot.id}`);
        // }
    };

  return (
    <div
      data-testid="product-catalog-widget"
      className="cursor-pointer relative w-full h-full rounded-lg shadow-lg border-2"
      onClick={clickHandler}
    >
      <img
        src={plot ? plot.farm ? plot.farm.photo_default : photo_default : photo_default}
        alt="photo of the plot"
        className="object-cover w-full h-full aspect-square rounded-lg"
      />
      <section className="absolute bg-black/50 text-white left-0 bottom-0 rounded-b-lg flex flex-col w-full h-1/4 p-2 box-img-card">
        <label className="cursor-pointer font-in-box">{plot?.farm?.name}</label>
        <label className="cursor-pointer font-in-box">{plot?.name ? plot.name : "Plot Name"}</label>
        <label className="cursor-pointer font-in-box">{plot?.crop_name ? plot.crop_name : "Crop Name"}</label>
        <label className={ (plot) ? (plot.harvest_count_down == 'Harvest date has passed') ? "cursor-pointer font-in-box text-red" : "cursor-pointer font-in-box text-green" : "cursor-pointer font-in-box"}>{plot?.harvest_count_down ? plot.harvest_count_down : "Harvest Count Down"}</label>
        {/* <label className="cursor-pointer font-in-box">{product?.scientific_name && <span>( {product?.scientific_name} )</span> } </label> */}
      </section>
    </div>
  );
};

export default PlotWidget;
