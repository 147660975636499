import { useState, useEffect, useContext } from "react";
import { plotUpdate, plotShow, plotDelete, plotSaveAdvancedBooking, plotCancelAdvancedBooking, plotUpdatePublishStatus, getCompanyPackage, getGradeList, plotSaveAuctionBidding, getAucPlot, plotUpdateViewOnlyPubStatus } from "../../../features/plot/plotSlice";
import { settingDepositGet } from "features/settingDeposit/settingDepositSlice";
import { fileUpload } from "../../../features/file/fileSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountLayout from "pages/AccountLayout";
import { cropIndex } from "../../../features/crop/cropSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Row, Modal, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
import * as myConst from "constants/companyPackage.constants";
import clsx from "clsx";
import styles from "./Plots.module.scss";
import Snackbar from "components/ui/snackbar/Snackbar";
import WarSubsPack from "components/home/WarSubsPack";

const PlotEdit = () => {
  const { currentCompany } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [cropName, setCropName] = useState("");
  const [plantingStartDate, setPlantingStartDate] = useState("");
  const [harvestStartDate, setHarvestStartDate] = useState("");
  const [harvestEndDate, setHarvestEndDate] = useState("");
  const [harvestStartDateFormatted, setHarvestStartDateFormatted] = useState("");
  const [harvestEndDateFormatted, setHarvestEndDateFormatted] = useState("");
  const [harvestExpectedQuantity, setHarvestExpectedQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const dispatch = useDispatch();
  const [cropId, setCropId] = useState(null);
  const [cropStage, setCropStage] = useState("")
  const [isAvbSubs, setIsAvbSubs] = useState(false);
  const [showWarSubs, setShowWarSubs] = useState(false);

  //modal advance booking
  const [showAdvanceBookingForm, setShowAdvanceBookingForm] = useState(false);
  const popupCloseAdvanceBookingForm = () => setShowAdvanceBookingForm(false);
  const popupShowAdvanceBookingForm = () => setShowAdvanceBookingForm(true);

  //modal cancel advance booking
  const [showCancelAdvanceBookingModal, setShowCancelAdvanceBookingModal] = useState(false);
  const popupCloseAdvanceBookingModal = () => setShowCancelAdvanceBookingModal(false);
  const popupShowAdvanceBookingModal = () => setShowCancelAdvanceBookingModal(true);

  //form advance booking
  const [farmPrice, setFarmPrice] = useState("");
  const [farmPriceUnit, setFarmPriceUnit] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [sellingPriceUnit, setSellingPriceUnit] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [availableQuantityUnit, setAvailableQuantityUnit] = useState("");
  const [maxBookingQuantity, setMaxBookingQuantity] = useState(0);
  const [maxBookingQuantityUnit, setMaxBookingQuantityUnit] = useState("");
  const [publishStatus, setPublishStatus] = useState(0);
  const [bookingType, setBookingType] = useState("");
  const [changeFarmPrice, setChangeFarmPrice] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [serviceChargeType, setServiceChargeType] = useState(1);
  const [minDepositAmount, setMinDepositAmount] = useState(0);
  const [depositAmountType, setDepositAmountType] = useState(1);
  const [totalDepositAmount, setTotalDepositAmount] = useState(0);
  const [gradesList, setGradesList] = useState([]);
  const [gradeId, setGradeId] = useState(null);
  const [gradeName, setGradeName] = useState(null);
  const [includeDeliveryFee, setIncludeDeliveryFee] = useState(1);
  const [deliveryChargeAmount, setDeliveryChargeAmount] = useState(0);
  const [isEnabledDeposit, setIsEnabledDeposit] = useState(0);
  // const [deliveryChargeType, setDeliveryChargeType] = useState(null);

  // AUCTION BIDDING

  //modal auction bidding
  const [showAuctionBiddingForm, setShowAuctionBiddingForm] = useState(false);
  const popupCloseAuctionBiddingForm = () => setShowAuctionBiddingForm(false);
  const popupShowAuctionBiddingForm = () => setShowAuctionBiddingForm(true);

  const [auctionPlot, setAuctionPlot] = useState(null);

  //form auction bidding
  const [startDateBid, setStartDateBid] = useState("");
  const [endDateBid, setEndDateBid] = useState("");
  const [farmPriceBid, setFarmPriceBid] = useState("");
  const [farmPriceUnitBid, setFarmPriceUnitBid] = useState("");
  const [sellingPriceBid, setSellingPriceBid] = useState("");
  const [sellingPriceUnitBid, setSellingPriceUnitBid] = useState("");
  const [availableQuantityBid, setAvailableQuantityBid] = useState(0);
  const [availableQuantityUnitBid, setAvailableQuantityUnitBid] = useState("");
  const [changeFarmPriceBid, setChangeFarmPriceBid] = useState(0);
  const [minDepositAmountBid, setMinDepositAmountBid] = useState(0);
  const [depositAmountTypeBid, setDepositAmountTypeBid] = useState(1);
  const [gradeIdBid, setGradeIdBid] = useState(null);
  const [gradeNameBid, setGradeNameBid] = useState(null);
  const [includeDeliveryFeeBid, setIncludeDeliveryFeeBid] = useState(1);
  const [isAllowMinBid, setIsAllowMinBid] = useState(true);
  const [miniBidIncre, setMiniBidIncre] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [deliveryChargeType, setDeliveryChargeType] = useState(1);
  const [totalDepositeAmount, setTotalDepositeAmount] = useState(0);
  const [totalDepositeAmountCuren, setTotalDepositeAmountCuren] = useState(0);


  //modal confirm view only
  const [modalConfirmViewOnly, setModalConfirmViewOnly] = useState(false);
  const popupCloseModalConfirmViewOnly = () => setModalConfirmViewOnly(false);
  const popupShowModalConfirmViewOnly = () => setModalConfirmViewOnly(true);

  //is enable disable min bid inscre

  const [cropList, setCropList] = useState({
    data: [],
  });

  const [photos, setPhotos] = useState([]);
  const snackbarCtx = useContext(SnackbarContext);
  const params = useParams();
  const { updating } = useSelector((state) => state.plot);
  const navigate = useNavigate();
  const [inputElementPhoto, setInputElementPhoto] = useState("");

  const goSubPage = () => {
    navigate('/account/edit');
  };

  const uploadPhotos = (event) => {
    let length = event.target.files.length;

    for (let i = 0; i < length; i++) {
      let selectedFile = event.target.files[i];

      const formData = new FormData();

      formData.append("file", selectedFile, selectedFile.name);
      formData.append("store", "multiple");
      formData.append("model", "plot");
      formData.append("type", "photo");
      formData.append("model_id", params.plot_id);

      dispatch(fileUpload(formData)).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          alert(response.payload);
        } else {
          let photos = response.payload.data.plot.photos.data;

          setPhotos(photos);
        }
      });
    }
  };

  const update = () => {
    let plotId = params.plot_id;
    let farmId = params.farm_id;

    if (parseInt(harvestExpectedQuantity) < 0) {
      snackbarCtx.displayMsg("Quantity cannot be zero.", "failure", true);
      return;
    }
    dispatch(
      plotUpdate({
        plot_id: plotId,
        farm_id: farmId,
        name: name,
        crop_name: cropName,
        planting_start_date: plantingStartDate,
        harvest_start_date: harvestStartDate,
        harvest_end_date: harvestEndDate,
        harvest_expected_quantity: harvestExpectedQuantity,
        unit: unit,
        crop_id: cropId,
        crop_stage: cropStage
        //season: cropStage,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure", true);
      } else {
        navigate(`/farms/${farmId}/edit`);
      }
    });

  };

  const loadSettingDeposit = () => {
    dispatch(
      settingDepositGet()
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let result = response.payload.data.setting;
        if (result) {
          setIsEnabledDeposit(result.is_deposit_payment);
        }
      }
    });
  }

  const loadPlot = () => {
    let plotId = params.plot_id;

    dispatch(
      plotShow({
        plot_id: plotId,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let plot = response.payload.data.plot;
        setName(plot.name);
        setCropName(plot.crop_name);
        setPlantingStartDate(plot.planting_start_date);
        setHarvestStartDate(plot.harvest_start_date);
        setHarvestEndDate(plot.harvest_end_date);
        setHarvestStartDateFormatted(plot.harvest_start_date_formatted);
        setHarvestEndDateFormatted(plot.harvest_end_date_formatted);
        setHarvestExpectedQuantity(plot.harvest_expected_quantity);
        setUnit(plot.unit);
        setPhotos(plot.photos.data);
        setCropId(plot.crop_id);
        setCropStage(plot.crop_stage);
        setBookingType(plot.booking_type);
        setPublishStatus(plot.publish_status);
        // check book type
        if (plot.booking_type == 'advanced') {
          setFarmPrice(plot.farm_price);
          setFarmPriceUnit(plot.farm_unit);
          setSellingPrice(plot.selling_price);
          setSellingPriceUnit(plot.selling_unit);
          setAvailableQuantity(parseInt(plot.available_quantity));
          setAvailableQuantityUnit(plot.available_quantity_unit);
          setMaxBookingQuantity(parseInt(plot.max_booking_quantity));
          setMaxBookingQuantityUnit(plot.max_booking_quantity_unit);
          setMinDepositAmount(plot.min_deposit_amount);
          setDepositAmountType(plot.deposit_type);
          setGradeId(plot.grade_id);
          setGradeName(plot.grade.name);
          setIncludeDeliveryFee(plot.include_delivery_fee);
          setDeliveryChargeAmount(plot.delivery_charge);
          setDeliveryChargeType(plot.delivery_charge_type);
        } else if (plot.booking_type == 'auction') {
          loadAucPlot();
        }

      }
    });    

    if (currentCompany) {
      dispatch(
        getCompanyPackage({
          company_id: currentCompany?.id,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure", true);
        } else {
          let companyPackage = response.payload.data.companyPackage;
          if (companyPackage != null) {
            setIsAvbSubs(true);
            setServiceCharge(companyPackage.service_charge);
            setServiceChargeType(companyPackage.service_charge_type);
          }
        }
      });
    }
  };

  const loadAucPlot = () => {
    dispatch(
      getAucPlot({
        plot_id: params.plot_id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure", true);
      } else {
        if (response.payload.data.auction_plot != null) {
          setAuctionPlot(response.payload.data.auction_plot);
          setFarmPriceBid(response.payload.data.auction_plot.farm_price);
          setFarmPriceUnitBid(response.payload.data.auction_plot.farm_price_unit);
          setSellingPriceBid(response.payload.data.auction_plot.start_bidding_price);
          setSellingPriceUnitBid(response.payload.data.auction_plot.start_bidding_price_unit);
          setAvailableQuantityBid(response.payload.data.auction_plot.available_quantity);
          setAvailableQuantityUnitBid(response.payload.data.auction_plot.available_quantity_unit);
          setMinDepositAmountBid(response.payload.data.auction_plot.deposite_amount);
          setDepositAmountTypeBid(response.payload.data.auction_plot.deposite_amount_type);
          setGradeIdBid(response.payload.data.auction_plot.grade_id);
          // setGradeNameBid(response.payload.data.auction_plot.grade.name);
          setIncludeDeliveryFeeBid(response.payload.data.auction_plot.include_delivery_fee);
          setStartDateBid(response.payload.data.auction_plot.bidding_start_date);
          setEndDateBid(response.payload.data.auction_plot.bidding_end_date);
          if (response.payload.data.auction_plot.is_allow_min_bidd_inc == 1) {
            setIsAllowMinBid(true);
          } else {
            setIsAllowMinBid(false);
          }
          setMiniBidIncre(response.payload.data.auction_plot.mini_bidding_price);
          setDeliveryCharge(parseFloat(response.payload.data.auction_plot.delivery_charge));
          setDeliveryChargeType(response.payload.data.auction_plot.delivery_charge_type);
        } else {
          setAuctionPlot(null);
        }
      }
    });
  }

  const loadCrops = () => {
    let farmId = params.farm_id;

    dispatch(
      cropIndex({
        farm_id: farmId,
        page: 1,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let crops = response.payload.data.crops;

        setCropList(crops);
        // let plot = response.payload.data.plot;

        // navigate(`/farms/${farmId}/plots/${plot.id}/edit`);
      }
    });
  };

  const loadGradeList = () => {
    dispatch(
      getGradeList({
        crop_id: cropId
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let grades = response.payload.data.grades;
        if (grades) {
          setGradesList(grades.data);
        }
      }
    });
  };


  const deletePlot = () => {
    if (window.confirm("Are you sure want to delete this plot?")) {
      dispatch(plotDelete({
        plot_id: params.plot_id,
      })).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          alert(response.payload);
        } else {
          navigate(-1);
        }
      });
    }
  }

  const updatePublishStatusPlot = (data) => {
    let message = (data.publish_status == 1) ? 'Are you sure want to publish plot?' : 'Are you sure want to archive plot?';
    if (window.confirm(message)) {
      dispatch(plotUpdatePublishStatus({
        plot_id: params.plot_id,
        publish_status: data.publish_status
      })).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure", true);
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success", true);
          let plot = response.payload.data.plot;
          setPublishStatus(plot.publish_status);
        }
      });
    }
  }

  const updateViewOnlyPubStatus = (data) => {
    setIsLoading(true);
    dispatch(plotUpdateViewOnlyPubStatus({
      plot_id: params.plot_id,
      action_status: data.action_status,
    })).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure", true);
        setIsLoading(false);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        let plot = response.payload.data.plot;
        setBookingType(plot.booking_type);
        setPublishStatus(plot.publish_status);
        setIsLoading(false);
        popupCloseModalConfirmViewOnly();
      }
    });
  }

  const saveAdvanceBooking = (event) => {
    let plotId = params.plot_id;
    dispatch(
      plotSaveAdvancedBooking({
        id: plotId,
        farm_price: farmPrice,
        farm_unit: farmPriceUnit,
        selling_price: sellingPrice,
        selling_unit: sellingPriceUnit,
        available_quantity: availableQuantity,
        available_quantity_unit: availableQuantityUnit,
        max_booking_quantity: maxBookingQuantity,
        max_booking_quantity_unit: maxBookingQuantityUnit,
        // min_deposit_amount: (isEnabledDeposit == 1) ? minDepositAmount : 0,
        // deposit_type: (isEnabledDeposit == 1) ? depositAmountType : 1,
        min_deposit_amount: minDepositAmount,
        deposit_type: depositAmountType,
        grade_id: (gradeId) ? gradeId : null,
        include_delivery_fee: includeDeliveryFee,
        delivery_charge: deliveryChargeAmount,
        delivery_charge_type: deliveryChargeType
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure", true);
      } else {
        let plot = response.payload.data.plot;
        snackbarCtx.displayMsg(response.payload.message, "success", true);
        setBookingType(plot.booking_type);
        setShowAdvanceBookingForm(false);
        setGradeName(plot.grade.name);
      }
    });

    event.preventDefault();
  };

  const saveAuctionBidding = (event) => {
    dispatch(
      plotSaveAuctionBidding({
        plot_id: params.plot_id,
        farm_price: farmPriceBid,
        farm_price_unit: farmPriceUnitBid,
        start_bidding_price: sellingPriceBid,
        start_bidding_price_unit: sellingPriceUnitBid,
        available_quantity: availableQuantityBid,
        available_quantity_unit: availableQuantityUnitBid,
        deposite_amount: minDepositAmountBid,
        deposite_amount_type: depositAmountTypeBid,
        grade_id: (gradeIdBid) ? gradeIdBid : null,
        include_delivery_fee: includeDeliveryFeeBid,
        bidding_start_date: startDateBid,
        bidding_end_date: endDateBid,
        is_allow_min_bidd_inc: isAllowMinBid,
        mini_bidding_price: miniBidIncre,
        delivery_charge: deliveryCharge,
        delivery_charge_type: deliveryChargeType,
        total_deposite_amount: totalDepositeAmountCuren
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure", true);
      } else {
        let plot = response.payload.data.plot;
        let auction_plot = response.payload.data.auction_plot;

        snackbarCtx.displayMsg(response.payload.message, "success");
        setBookingType(plot.booking_type);
        setShowAuctionBiddingForm(false);
        setAuctionPlot(auction_plot);
      }
    });

    event.preventDefault();
  };

  const cancelAdvanceBooking = (event) => {
    let plotId = params.plot_id;
    dispatch(
      plotCancelAdvancedBooking({
        id: plotId
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure", true);
        setShowCancelAdvanceBookingModal(false);
      } else {
        let plot = response.payload.data.plot;
        snackbarCtx.displayMsg(response.payload.message, "success", true);
        setBookingType(plot.booking_type);
        setFarmPrice(null);
        setFarmPriceUnit(null);
        setSellingPrice(null);
        setSellingPriceUnit(null);
        setAvailableQuantity(0);
        setAvailableQuantityUnit(null);
        setMaxBookingQuantity(0);
        setMaxBookingQuantityUnit(null);
        setMinDepositAmount(null);
        setDepositAmountType(null);
        setPublishStatus(null);
        setGradeId(null);
        setGradeName(null);
        setIncludeDeliveryFee(null);
        setShowCancelAdvanceBookingModal(false);
      }
    });

    event.preventDefault();
  };

  useEffect(() => {
    loadPlot();
    loadCrops();
    loadSettingDeposit();
  }, []);

  useEffect(() => {
    if (cropId != null) {
      loadGradeList();
    }
  }, [cropId]);

  useEffect(() => {
    let tempSellingPrice = null;
    let percentage = null;
    let resultPercentage = null;

    setSellingPrice(parseFloat(farmPrice).toFixed(2));
    //fix amount
    if (serviceChargeType == myConst.constServiceChargeType.FIX_AMOUNT) {
      tempSellingPrice = parseFloat(farmPrice) + parseFloat(serviceCharge);
      setSellingPrice(parseFloat(tempSellingPrice).toFixed(2));
    }

    if (serviceChargeType == myConst.constServiceChargeType.PERCENTAGE) {
      percentage = serviceCharge / 100;
      resultPercentage = farmPrice * percentage;
      tempSellingPrice = parseFloat(farmPrice) + resultPercentage;
      setSellingPrice(parseFloat(tempSellingPrice).toFixed(2));
    }

  }, [changeFarmPrice])

  useEffect(() => {
    let tempFarmPrice = null;
    let percentage = null;
    let resultPercentage = null;
    //fix amount
    setFarmPriceBid(parseFloat(sellingPriceBid).toFixed(2));
    if (serviceChargeType == myConst.constServiceChargeType.FIX_AMOUNT) {
      tempFarmPrice = parseFloat(sellingPriceBid) - serviceCharge;
      setFarmPriceBid(parseFloat(tempFarmPrice).toFixed(2));
    }
    if (serviceChargeType == myConst.constServiceChargeType.PERCENTAGE) {
      percentage = serviceCharge / 100;
      resultPercentage = sellingPriceBid * percentage;
      tempFarmPrice = parseFloat(sellingPriceBid) - resultPercentage;
      setFarmPriceBid(parseFloat(tempFarmPrice).toFixed(2));
    }
  }, [changeFarmPriceBid]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  //function total deposite amount
  useEffect(() => {
    let totalDepositeAmount = 0;
    //fix amount
    if (depositAmountTypeBid == 1) {
      totalDepositeAmount = minDepositAmountBid;
    } else {
      let percentage = minDepositAmountBid / 100;
      totalDepositeAmount = availableQuantityBid * sellingPriceBid * percentage;
    }
    setTotalDepositeAmount(numberWithCommas(Number(totalDepositeAmount).toFixed(2)));
    setTotalDepositeAmountCuren(Number(totalDepositeAmount).toFixed(2));
  }, [minDepositAmountBid, availableQuantityBid, sellingPriceBid, depositAmountTypeBid]);

  const selectGrade = (gradeId, price, type) => {
    // console.log(selectedCrop);
    // let selectedGrade = selectedCrop.grades.data.find(grade => grade?.id === parseInt(target.value));
    // console.log(selectedGrade);

    // if (selectedGrade) {
    if (type === 'advanced') {
      // setFarmPrice(selectedGrade?.price_formatted)
      // setSellingPrice(selectedGrade?.selling_price)
      // setGradeId(target.value);
      setFarmPrice(parseFloat(price).toFixed(2));
      setGradeId(gradeId);
      setChangeFarmPrice(changeFarmPrice + 1);
    } else {
      // setSellingPriceBid(selectedGrade?.price_formatted);
      // setGradeIdBid(target.value);
      setSellingPriceBid(parseFloat(price).toFixed(2));
      setGradeIdBid(gradeId);
      setChangeFarmPriceBid(changeFarmPriceBid + 1);
    }
    // }
  }

  const handleBiddingPrice = target => {
    setSellingPriceBid(target.value);

    if (farmPriceBid) {
      if (parseFloat(target.value) < parseFloat(farmPriceBid)) {
        snackbarCtx.displayMsg("Start bidding price is lower than farm price", "failure", true);
      }
    }
  }

  return (
    <AccountLayout>
      <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
        <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <div className="title-content mb-3">
          Edit Plot Details
        </div>
        <div className="flex flex-col rounded-lg shadow bg-card-white-new">
          <Row>
            <Col md={6}>
              <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadPlot} />
              {/*{photos.map((photo) => {*/}
              {/*return <img src={photo.src} alt="Photo of this farm" className="rounded-lg shadow" />;*/}
              {/*})}*/}

              {/*<img src="/assets/images/PlotPhoto2.svg" alt="Plot photo" className="w-full rounded-lg shadow" />*/}

              {/*<button type="button" className="saveButton">*/}
              {/*Upload Photos*/}
              {/*</button>*/}
              <button type="button" className="btn-border-green w-full mt-2 mb-2 justify-center" onClick={() => {
                inputElementPhoto.click();
              }}>
                <label
                  className="cursor-pointer text-sm"
                >
                  Upload Photos
                </label>
                <input
                  className={"hidden"}
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  onChange={uploadPhotos}
                  ref={(input) => setInputElementPhoto(input)}
                />
              </button>
              <Row style={{ padding: "12px" }}>
                <Col md={12} className={clsx('border-green', styles.boxMobile)}>
                  <p className="title-section mt-1">Plot Status : <span className={(publishStatus) ? 'text-green' : 'text-red'}>{(publishStatus) ? 'Publish' : 'Archived'}</span></p>
                  <p className="title-section mt-1"><b>Buyer will view plot as</b></p>
                  <Row className="mt-3">
                    <Col md={4} className={clsx(styles.plotMarginMobile)}>
                      <button type="button" className={clsx("btn-border-green btn-line-2", isAvbSubs ? ((bookingType != "advanced") ? "" : "bg-green") : "bg-gray")} onClick={() => {
                        if (isAvbSubs) {
                          popupShowAdvanceBookingForm();
                        } else {
                          setShowWarSubs(true);
                        }
                      }}>
                        <label className="cursor-pointer text-sm">
                          Open For Advanced Booking
                        </label>
                      </button>
                    </Col>
                    <Col md={4} className={clsx(styles.plotMarginMobile)}>
                      <button type="button" className={clsx("btn-border-green btn-line-2", isAvbSubs ? (((bookingType == "auction") && (auctionPlot != null)) ? "bg-green" : "") : "bg-gray")} onClick={() => {
                        if (isAvbSubs) {
                          popupShowAuctionBiddingForm();
                        } else {
                          setShowWarSubs(true);
                        }
                      }}>
                        <label className="cursor-pointer text-sm">
                          Available for Auction
                        </label>
                      </button>
                    </Col>
                    <Col md={4} className={clsx(styles.plotMarginMobile)}>
                      <button type="button" className={clsx("btn-border-green btn-line-2", isAvbSubs ? ((bookingType != "view_only") ? "" : "bg-green") : "bg-gray")} onClick={() => {
                        if (isAvbSubs) {
                          popupShowModalConfirmViewOnly();
                        } else {
                          setShowWarSubs(true);
                        }
                      }}>
                        <label className="cursor-pointer text-sm">
                          View Only
                        </label>
                      </button>
                    </Col>
                  </Row>

                  <div className="mt-3" hidden={(bookingType == 'advanced') ? false : true}>
                    <p className="title-section mt-1">Advanced Booking Details</p>
                    <Row className="mt-1">
                      <label className={clsx(styles.advancedBookingDetailsMarginMobile, "col-md-6")}>Crop Grade Name</label>
                      <label className={clsx(styles.advancedBookingDetailsWidthMobile, "col-md-4 text-green")}>: {gradeName}</label>
                    </Row>
                    <Row className="mt-1">
                      <label className={clsx(styles.advancedBookingDetailsMarginMobile, "col-md-6")}>Farm Price</label>
                      <label className={clsx(styles.advancedBookingDetailsWidthMobile, "col-md-4 text-green")}>: RM {farmPrice} /{farmPriceUnit}</label>
                    </Row>
                    <Row className="mt-1">
                      <label className={clsx(styles.advancedBookingDetailsMarginMobile, "col-md-6")}>Selling Price<br /><span style={{ fontSize: "10px" }}>(Inclusive of service charge)</span></label>
                      <label className={clsx(styles.advancedBookingDetailsWidthMobile, "col-md-4 text-green")}>: RM {sellingPrice} /{sellingPriceUnit}</label>
                    </Row>
                    <Row className="mt-1">
                      <label className={clsx(styles.advancedBookingDetailsMarginMobile, "col-md-6")}>Available Quantity</label>
                      <label className={clsx(styles.advancedBookingDetailsWidthMobile, "col-md-4 text-green")}>: {availableQuantity} {availableQuantityUnit}</label>
                    </Row>
                    <Row className="mt-1 mb-3">
                      <label className={clsx(styles.advancedBookingDetailsMarginMobile, "col-md-6")}>Maximum Booking Quantity <br /><span style={{ fontSize: "10px" }}>(Per User)</span></label>
                      <label className={clsx(styles.advancedBookingDetailsWidthMobile, "col-md-4 text-green")}>: {maxBookingQuantity} {maxBookingQuantityUnit}</label>
                    </Row>
                    <Row className="mt-1 mb-3" hidden={(isEnabledDeposit == 1) ? false : true}>
                      <label className={clsx(styles.advancedBookingDetailsMarginMobile, "col-md-6")}>Deposit Amount <br /><span style={{ fontSize: "10px" }}></span></label>
                      <label className={clsx(styles.advancedBookingDetailsWidthMobile, "col-md-4 text-green")}>: {(depositAmountType == 1) ? 'RM' : ''} {minDepositAmount} {(depositAmountType == 2) ? '%' : ''}</label>
                    </Row>
                    {/* <Row className="mt-1 mb-3" hidden={(depositAmountType == 1) ? true : false}>
                      <label className="col-md-6">Total Deposit Amount <br /><span style={{ fontSize: "10px" }}>(Quantity x Selling Price x Deposit)</span></label>
                      <label className="col-md-4 text-green">: RM 100</label>
                    </Row> */}

                    <Row className="mt-3">
                      <Col md={3} >
                      </Col>
                      {/* <Col md={2} >
                      </Col> */}

                      <Col xs={12} lg={6} >
                        <button type="button" className="bg-red-500 hover:bg-red-600 hover:ring-red-600" onClick={() => { popupShowAdvanceBookingModal(); }}>
                          <span style={{ fontSize: "14px", color: "white" }}>Cancel Advance Booking Option</span>
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-3" hidden={(bookingType == 'auction' && auctionPlot != null) ? false : true}>
                    <p className="title-section mt-1">Auction/Bidding Details</p>
                    <Row className="mt-1">
                      <label className="col-md-5">Bidding Start Date</label>
                      <label className="col-md-5 text-green">: {(auctionPlot) ? auctionPlot.bidding_start_date : "-"}</label>
                    </Row>
                    <Row className="mt-1">
                      <label className="col-md-5">Bidding End Date</label>
                      <label className="col-md-5 text-green">: {(auctionPlot) ? auctionPlot.bidding_end_date : "-"}</label>
                    </Row>
                    <Row className="mt-1">
                      <label className="col-md-5">Start Bidding Price
                      </label>
                      <label className="col-md-5 text-green">: RM {(auctionPlot) ? auctionPlot.start_bidding_price : "-"} /{(auctionPlot) ? auctionPlot.start_bidding_price_unit : "-"}</label>
                    </Row>
                    <Row className="mt-1">
                      <label className="col-md-5">Farm Price <br /><span style={{ fontSize: "10px" }}>(Selling price minus X%
                        Service Charge)</span></label>
                      <label className="col-md-5 text-green">: RM {(auctionPlot) ? auctionPlot.farm_price : "-"} /{(auctionPlot) ? auctionPlot.farm_price_unit : "-"}</label>
                    </Row>

                    <Row className="mt-1">
                      <label className="col-md-5">Include Delivery Charges?</label>
                      <label className="col-md-5 text-green">: {(auctionPlot) ? (auctionPlot.include_delivery_fee == 1) ? "Yes" : "No" : "-"}</label>
                    </Row>

                    <Row className="mt-1">
                      <label className="col-md-5">Available Quantity </label>
                      <label className="col-md-5 text-green">: {(auctionPlot) ? auctionPlot.available_quantity : "-"} {(auctionPlot) ? auctionPlot.available_quantity_unit : "-"}</label>
                    </Row>
                    <Row className="mt-1" hidden={(isEnabledDeposit == 1) ? false : true}>
                      <label className="col-md-5">Deposit Amount</label>
                      <label className="col-md-5 text-green">: {(auctionPlot) ? ((auctionPlot.deposite_amount_type == 1) ? `RM ${auctionPlot.deposite_amount}` : `${auctionPlot.deposite_amount} %`) : "-"}</label>
                    </Row>
                    <Row className="mt-1 mb-3" hidden={(isEnabledDeposit == 1) ? false : true}>
                      <label className="col-md-5">Total Deposit Amount</label>
                      <label className="col-md-5 text-green">: RM {(auctionPlot) ? auctionPlot.total_deposite_amount : "-"}</label>
                    </Row>
                    {isAllowMinBid && (
                      <>
                        <Row className="mt-1">
                          <label className="col-md-5">Minimum Bid Increment</label>
                          <label className="col-md-5 text-green">: RM {(auctionPlot) ? auctionPlot.mini_bidding_price : "-"}</label>
                        </Row>
                      </>
                    )}



                  </div>
                  <div className="mt-3" hidden={(bookingType == 'view_only') ? false : true}>
                    <p className="title-section mt-1">View Only Details</p>
                    <Row className="mt-1">
                      <label className="col-md-5">Status</label>
                      <label className="col-md-5 text-green">: View Only</label>
                    </Row>
                  </div>
                </Col>
              </Row>

            </Col>
            <Col md={1}>
            </Col>
            <Col md={5}>
              <form className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <label>Plot Name:</label>
                  <input
                    type="text"
                    className="input-search"
                    placeholder="Plot Name"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  {/*<label>Crop Name:</label>*/}
                  {/*<select defaultValue={"DEFAULT"} onChange={({ target }) => setCropName(target.value)}>*/}
                  {/*<option value="DEFAULT" disabled>*/}
                  {/*Crop*/}
                  {/*</option>*/}
                  {/*<option value="Bananas">Bananas</option>*/}
                  {/*<option value="Oranges">Oranges</option>*/}
                  {/*<option value="Apples">Apples</option>*/}
                  {/*</select>*/}

                  <label>Crop Name:</label>
                  <select className="input-search" defaultValue={"DEFAULT"} value={cropId} onChange={({ target }) => setCropId(target.value)}>
                    <option value="">
                      Select Crop
                    </option>
                    {cropList.data.map((crop, index) => {
                      return <option value={crop.id} key={index}>{crop.name}</option>;
                    })}
                  </select>

                  <label>Stage of the crop:</label>
                  <input
                    className="input-search"
                    type="text"
                    placeholder="Stage of the crop"
                    value={cropStage}
                    onChange={({ target }) => setCropStage(target.value)}
                  />

                </div>
                <div className="flex flex-col gap-2">
                  <label>Planting Start Date:</label>
                  <input
                    className="input-search"
                    type="date"
                    placeholder="Planting Start Date"
                    value={plantingStartDate}
                    onChange={({ target }) => setPlantingStartDate(target.value)}
                  />
                </div>
                <div className="flex gap-4">
                  <div className="w-full min-w-[1rem] flex flex-col gap-2">
                    <label>Harvesting Start Date:</label>
                    <input
                      className="input-search"
                      type="date"
                      placeholder="Harvesting Start Date"
                      value={harvestStartDate}
                      onChange={({ target }) => setHarvestStartDate(target.value)}
                    />
                  </div>
                  <div className="w-full min-w-[1rem] flex flex-col gap-2">
                    <label>Harvesting End Date:</label>
                    <input
                      className="input-search"
                      type="date"
                      placeholder="Harvesting End Date"
                      value={harvestEndDate}
                      onChange={({ target }) => setHarvestEndDate(target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <label>Expected Harvest Quantity:</label>
                  <div className="flex gap-4">
                    <input
                      className="input-search"
                      type="number"
                      min="0"
                      placeholder="Quantity"
                      value={harvestExpectedQuantity}
                      onChange={({ target }) => setHarvestExpectedQuantity(target.value)}
                    />

                    <select className="input-search w-1/4" defaultValue={"DEFAULT"} value={unit} onChange={({ target }) => setUnit(target.value)}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-between space-x-4 mt-3">
                  <button className="saveButton bg-red-500 hover:bg-red-600 hover:ring-red-600" onClick={(event) => {
                    event.preventDefault();
                    deletePlot()
                  }}>
                    {updating ? "Processing, please wait.." : "Delete Plot"}
                    {/*Save*/}
                  </button>
                  <button type="button" className="saveButton" onClick={update} >
                    {updating ? "Processing, please wait.." : "Save"}
                  </button>
                </div>

                <div className="flex flex-col gap-2">
                  <button className="btn-border-green" onClick={(event) => {
                    event.preventDefault();
                    if (isAvbSubs) {
                      let status = (publishStatus == 1) ? 0 : 1;
                      updatePublishStatusPlot({ publish_status: status })
                    } else {
                      setShowWarSubs(true);
                    }
                  }}>{(updating) ? 'Processing, please wait..' : (publishStatus) ? 'Archive Plot' : 'Publish Plot'}</button>
                </div>
              </form>
            </Col>
          </Row>
        </div>

      </div>


      <Modal className="modal-large-custom" show={showAdvanceBookingForm} onHide={popupCloseAdvanceBookingForm}>
        {/* <Modal.Header style={{ placeSelf: "center" }}>
          <Modal.Title className="text-center"></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Row className="mt-4 mr-4 ml-4">
            <Col md={6}>
              <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadPlot} disableEdit={true} />
              <Row className="mt-2">
                <Col md={6}>
                  <label>Harvesting Start Date:</label>
                  <br />
                  <label className="text-green">{harvestStartDateFormatted}</label>
                </Col>
                <Col md={6}>
                  <label>Harvesting End Date:</label>
                  <br />
                  <label className="text-green">{harvestEndDateFormatted}</label>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6}>
                  <label>Expected Harvest Quantity:</label>
                  <br />
                  <label className="text-green">{harvestExpectedQuantity} {unit}</label>
                </Col>
              </Row>
            </Col>
            <Col md={1}></Col>
            <Col md={5}>
              <div className={clsx('title-content mb-3', styles.advancedBookingPlotNameMarginMobile)}>
                {name}
              </div>
              <label>Crop Name:</label>
              <br />
              <label className={clsx('text-green', styles.advancedBookingCropNameMarginMobile)}>{cropName}</label>
              <br />
              <form onSubmit={saveAdvanceBooking}>
                <label>Crop Grade:</label>
                <Row className="mt-2 mb-2">
                  <Col xs={9}>
                    <select className="input-search text-green" defaultValue={null} value={gradeId} onChange={(e) => {
                      let price = e.target[e.target.selectedIndex].getAttribute('data-price');
                      selectGrade(e.target.value, price, 'advanced')
                    }}>
                      <option value="">
                        Select Grade..
                      </option>
                      {
                        gradesList.map((grade, key) => <option value={grade.id} data-price={grade.price_formatted}>{grade.name}</option>)
                      }
                    </select>
                  </Col>
                </Row>
                <label>Farm Price:</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                      <Form.Control
                        type="number"
                        className="input-group-custom text-center text-green"
                        min="0"
                        step="any"
                        placeholder=""
                        value={farmPrice}
                        onChange={({ target }) => {
                          setFarmPrice(target.value);
                          setChangeFarmPrice(changeFarmPrice + 1);
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={clsx("input-search text-green")} defaultValue={"DEFAULT"} value={farmPriceUnit} onChange={({ target }) => { setFarmPriceUnit(target.value); setSellingPriceUnit(target.value) }}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </Col>
                </Row>
                <label>Selling Price (Inclusive of service charge):</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                      <Form.Control
                        type="number"
                        className="input-group-custom text-center text-green"
                        min="0"
                        placeholder=""
                        value={sellingPrice}
                        disabled={true}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={clsx("input-search text-green")} defaultValue={"DEFAULT"} value={sellingPriceUnit} disabled={true}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </Col>
                </Row>
                <label>Include Delivery Charges?</label>
                <label className="text-green" style={{ fontSize: "9px" }}>*If you choose to include delivery charges you will be required to pay for delivery fee</label>
                <Row className="mt-2 mb-2">
                  <Col md={3} className={clsx(styles.deliveryChargeWidth)}>
                    <input
                      type="radio"
                      name="include_delivery_fee"
                      value={1}
                      checked={includeDeliveryFee == 1}
                      onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                    /> Yes
                  </Col>
                  <Col md={3} className={clsx(styles.deliveryChargeWidth)}>
                    <input
                      type="radio"
                      name="include_delivery_fee"
                      value={0}
                      checked={includeDeliveryFee == 0}
                      onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                    /> No
                  </Col>
                </Row>
                <label hidden={(includeDeliveryFee == 1) ? true : false}>Delivery Fee:</label>
                <Row className="mt-2 mb-2" hidden={(includeDeliveryFee == 1) ? true : false}>
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1" style={{ display: deliveryChargeType == 1 ? 'block' : 'none' }}>{(deliveryChargeType == 1) ? 'RM' : ''}</InputGroup.Text>
                      <Form.Control
                        type="number"
                        className={`text-center text-green ${deliveryChargeType == 1 ? 'input-group-custom' : 'input-search'}`}
                        min="0"
                        step="any"
                        placeholder=""
                        value={deliveryChargeAmount}
                        onChange={({ target }) => {
                          setDeliveryChargeAmount(target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={"input-search text-green"} defaultValue={"DEFAULT"} value={deliveryChargeType} onChange={({ target }) => { setDeliveryChargeType(target.value); }}>
                      <option value="1">Fix Amount</option>
                      <option value="2">%</option>
                    </select>
                  </Col>
                </Row>
                <label>Available Quantity:</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setAvailableQuantity(availableQuantity - 1)}>-</div></InputGroup.Text>
                      <Form.Control
                        type="number"
                        min="0"
                        step="any"
                        className="text-center text-green input-group-plot"
                        placeholder=""
                        value={availableQuantity}
                        onChange={({ target }) => setAvailableQuantity(parseInt(target.value))}
                      />
                      <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setAvailableQuantity(availableQuantity + 1)}>+</div></InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={"input-search text-green"} defaultValue={"DEFAULT"} value={availableQuantityUnit} onChange={({ target }) => setAvailableQuantityUnit(target.value)}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </Col>
                </Row>
                <label>Maximum Booking Quantity (Per User):</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMaxBookingQuantity(maxBookingQuantity - 1)}>-</div></InputGroup.Text>
                      <Form.Control
                        type="number"
                        min="0"
                        className="text-center text-green input-group-plot"
                        placeholder=""
                        value={maxBookingQuantity}
                        onChange={({ target }) => setMaxBookingQuantity(parseInt(target.value))}
                      />
                      <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMaxBookingQuantity(maxBookingQuantity + 1)}>+</div></InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className="input-search text-green" defaultValue={"DEFAULT"} value={maxBookingQuantityUnit} onChange={({ target }) => setMaxBookingQuantityUnit(target.value)}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </Col>
                </Row>
                <label hidden={(isEnabledDeposit == 1) ? false : true}>Minimum Deposit Amount:</label>
                <Row className="mt-2 mb-2" hidden={(isEnabledDeposit == 1) ? false : true}>
                  <Col md={6} xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMinDepositAmount(minDepositAmount - 1)}>-</div></InputGroup.Text>
                      <Form.Control
                        type="number"
                        min="0"
                        className="text-center text-green input-group-plot"
                        placeholder=""
                        value={minDepositAmount}
                        onChange={({ target }) => {
                          setMinDepositAmount(target.value);
                          setTotalDepositAmount(target.value);
                        }}
                      />
                      <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMinDepositAmount(minDepositAmount + 1)}>+</div></InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col md={3} xs={5}>
                    <select className="input-search text-green" defaultValue={"DEFAULT"} value={depositAmountType} onChange={({ target }) => { setDepositAmountType(target.value); }}>
                      <option value="DEFAULT">
                        Select Type..
                      </option>
                      <option value="1">Fix Amount</option>
                      <option value="2">%</option>
                    </select>
                  </Col>
                </Row>
                {/* Total Deposit Amount:
                <br />
                <label hidden={(depositAmountType == 1) ? true : false}>
                  (Quantity x Selling Price x Deposit)
                  <br />
                </label>
                <Row className="mt-2 mb-2">
                  <Col md={9}>
                    <input
                      type="text"
                      className="input-search text-center"
                      name="example"
                      value={"RM "+totalDepositAmount}
                      disabled={true}
                    />
                  </Col>
                </Row> */}
                <Row className="mt-4">
                  {/* <Col md={1}></Col> */}
                  <Col md={12}>
                    <center>
                      <button type="submit" className="btn-border-green" style={{ minHeight: "50px" }}>
                        {updating ? "Processing, please wait.." : "Open For Booking"}
                      </button>
                    </center>
                  </Col>
                  {/* <Col md={2}></Col> */}
                </Row>
              </form>
            </Col>

          </Row>
        </Modal.Body>
        {snackbarCtx.isDisplayedOnOverlay && <Snackbar />}

      </Modal>

      {/* modal for auction biddiong */}
      <Modal className="modal-large-custom" show={showAuctionBiddingForm} onHide={popupCloseAuctionBiddingForm}>
        <Modal.Body>
          <Row className="mt-4 mr-4 ml-4">
            <Col md={6}>
              <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadPlot} disableEdit={true} />
              <Row className="mt-2">
                <Col md={6}>
                  <label>Harvesting Start Date:</label>
                  <br />
                  <label className="text-green">{harvestStartDateFormatted}</label>
                </Col>
                <Col md={6}>
                  <label>Harvesting End Date:</label>
                  <br />
                  <label className="text-green">{harvestEndDateFormatted}</label>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6}>
                  <label>Expected Harvest Quantity:</label>
                  <br />
                  <label className="text-green">{harvestExpectedQuantity} {unit}</label>
                </Col>
              </Row>
            </Col>
            <Col md={1}></Col>
            <Col md={5}>
              <div className="title-content mb-3">
                {name}
              </div>
              <label>Crop Name:</label>
              <br />
              <label className="text-green">{cropName}</label>
              <br />
              <form onSubmit={saveAuctionBidding}>
                <label>Crop Grade:</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <select className="input-search" defaultValue={null} value={gradeIdBid} onChange={(e) => {
                      let price = e.target[e.target.selectedIndex].getAttribute('data-price');
                      selectGrade(e.target.value, price, 'bid')
                    }}>
                      <option value="">
                        Select Grade..
                      </option>
                      {
                        gradesList.map((grade, key) => <option value={grade.id} data-price={grade.price_formatted}>{grade.name}</option>)
                      }
                    </select>
                  </Col>
                </Row>
                <div className="flex gap-4 mb-2">
                  <div className="w-full min-w-[1rem] grid grid-rows-2">
                    <label>Bidding Start Date:</label>
                    <input
                      className="input-search"
                      type="datetime-local"
                      placeholder="Bidding Start Date"
                      value={startDateBid}
                      onChange={({ target }) => setStartDateBid(target.value)}
                    />
                  </div>
                  <div className="w-full min-w-[1rem] grid grid-rows-2">
                    <label>Bidding End Date:</label>
                    <input
                      className="input-search"
                      type="datetime-local"
                      placeholder="Bidding Start Date"
                      min={startDateBid}
                      value={endDateBid}
                      onChange={({ target }) => setEndDateBid(target.value)}
                    />
                  </div>
                </div>
                <label>Start Bidding Price:</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                      <Form.Control
                        type="number"
                        className="input-group-custom text-center text-green"
                        min="0"
                        step="any"
                        placeholder=""
                        value={sellingPriceBid}
                        onChange={({ target }) => {
                          setSellingPriceBid(target.value);
                          setChangeFarmPriceBid(changeFarmPriceBid + 1);
                          // handleBiddingPrice(target)
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={clsx("input-search text-green")} defaultValue={"DEFAULT"} value={sellingPriceUnitBid} onChange={({ target }) => { setFarmPriceUnitBid(target.value); setSellingPriceUnitBid(target.value) }}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </Col>
                </Row>
                <label>Farm Price (Selling price minus Service Charge %):</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                      <Form.Control
                        type="number"
                        className="input-group-custom"
                        min="0"
                        step="any"
                        placeholder=""
                        value={farmPriceBid}
                        disabled={true}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={clsx("input-search text-green")} defaultValue={"DEFAULT"} value={farmPriceUnitBid} disabled={true}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </Col>
                </Row>
                <label>Include Delivery Charges?</label>
                <label className="text-green" style={{ fontSize: "10px" }}>*If you choose to include delivery charges you will be required to pay for delivery fee</label>
                <Row className="mt-2 mb-2">
                  <Col md={3} className={clsx(styles.deliveryChargeWidth)}>
                    <input
                      type="radio"
                      name="include_delivery_fee"
                      value={1}
                      checked={includeDeliveryFeeBid == 1}
                      onChange={({ target }) => { setIncludeDeliveryFeeBid(target.value); }}
                    /> Yes
                  </Col>
                  <Col md={3} className={clsx(styles.deliveryChargeWidth)}>
                    <input
                      type="radio"
                      name="include_delivery_fee"
                      value={0}
                      checked={includeDeliveryFeeBid == 0}
                      onChange={({ target }) => { setIncludeDeliveryFeeBid(target.value); }}
                    /> No
                  </Col>
                </Row>
                {includeDeliveryFeeBid == 0 && (
                  <>
                    <label>Delivery Charge:</label>
                    <Row className="mt-2 mb-2">
                      <Col xs={7}>
                        <InputGroup>
                          {(deliveryChargeType != 1) && (
                            <>
                              <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge - 1)}>-</div></InputGroup.Text>
                              <Form.Control
                                type="number"
                                min="0"
                                step="any"
                                placeholder=""
                                className="text-center text-green input-group-plot"
                                value={deliveryCharge}
                                onChange={({ target }) => setDeliveryCharge(target.value)}
                              />
                            </>
                          )}
                          {(deliveryChargeType == 1) && (
                            <>
                              <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                              <Form.Control
                                type="number"
                                min="0"
                                step="any"
                                placeholder=""
                                className="input-group-custom form-control"
                                value={deliveryCharge}
                                onChange={({ target }) => setDeliveryCharge(target.value)}
                              />
                            </>
                          )}

                          {(deliveryChargeType != 1) && (
                            <>
                              <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge + 1)}>+</div></InputGroup.Text>
                            </>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs={5}>
                        <select className={"input-search text-green"} defaultValue={"DEFAULT"} value={deliveryChargeType} onChange={({ target }) => { setDeliveryChargeType(target.value); }}>
                          <option value="1">Fix Amount</option>
                          <option value="2">%</option>
                        </select>
                      </Col>
                    </Row>
                  </>
                )}

                <label>Available Quantity:</label>
                <Row className="mt-2 mb-2">
                  <Col xs={7}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setAvailableQuantityBid(availableQuantityBid - 1)}>-</div></InputGroup.Text>
                      <Form.Control
                        type="number"
                        min="0"
                        step="any"
                        placeholder=""
                        className="text-center text-green input-group-plot"
                        value={availableQuantityBid}
                        onChange={({ target }) => setAvailableQuantityBid(target.value)}
                      />
                      <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setAvailableQuantityBid(availableQuantityBid + 1)}>+</div></InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={"input-search text-green"} defaultValue={"DEFAULT"} value={availableQuantityUnitBid} onChange={({ target }) => setAvailableQuantityUnitBid(target.value)}>
                      <option value="DEFAULT">
                        Select Unit..
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </Col>
                </Row>
                <label hidden={(isEnabledDeposit == 1) ? false : true}>Deposit Amount:</label>
                <Row className="mt-2 mb-2" hidden={(isEnabledDeposit == 1) ? false : true}>
                  <Col xs={7}>
                    <InputGroup>
                      {(depositAmountTypeBid != 1) && (
                        <>
                          <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMinDepositAmountBid(minDepositAmountBid - 1)}>-</div></InputGroup.Text>
                          <Form.Control
                            type="number"
                            min="0"
                            placeholder=""
                            className="text-center text-green input-group-plot"
                            value={minDepositAmountBid}
                            onChange={({ target }) => setMinDepositAmountBid(target.value)}
                          />
                        </>
                      )}
                      {(depositAmountTypeBid == 1) && (
                        <>
                          <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                          <Form.Control
                            type="number"
                            min="0"
                            placeholder=""
                            className="input-group-custom form-control"
                            value={minDepositAmountBid}
                            onChange={({ target }) => setMinDepositAmountBid(target.value)}
                          />
                        </>
                      )}

                      {(depositAmountTypeBid != 1) && (
                        <>
                          <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMinDepositAmountBid(minDepositAmountBid + 1)}>+</div></InputGroup.Text>
                        </>
                      )}
                    </InputGroup>
                  </Col>
                  <Col xs={5}>
                    <select className={"input-search text-green"} defaultValue={"DEFAULT"} value={depositAmountTypeBid} onChange={({ target }) => { setDepositAmountTypeBid(target.value); }}>
                      <option value="DEFAULT">
                        Select Type..
                      </option>
                      <option value="1">Fix Amount</option>
                      <option value="2">%</option>
                    </select>
                  </Col>
                </Row>
                <label hidden={(isEnabledDeposit == 1) ? false : true}>
                  Total Deposit Amount:
                  {(depositAmountTypeBid == 2) && (
                    <>
                      <br />
                      (Quantity x Selling Price x Deposit)
                    </>
                  )}
                  <br />
                </label>
                <Row className="mt-2 mb-2" hidden={(isEnabledDeposit == 1) ? false : true}>
                  <Col xs={7}>
                    <input
                      type="text"
                      className="input-search"
                      name="example"
                      value={`RM ${totalDepositeAmount}`}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <div className="mb-1">
                  <div className="w-full">
                    <label>Allow Minimum Bid Increment ?</label>
                  </div>
                </div>
                <div className="flex gap-4 mb-2">
                  <div className="w-full min-w-[1rem] flex flex-col gap-1" onClick={() => {
                    setIsAllowMinBid(false);
                  }}>
                    <div className={clsx((!isAllowMinBid) ? "btn-full-green" : "btn-border-green")}>
                      Disable
                    </div>
                  </div>
                  <div className="w-full min-w-[1rem] flex flex-col gap-1">
                    <div className={clsx((isAllowMinBid) ? "btn-full-green" : "btn-border-green")} onClick={() => {
                      setIsAllowMinBid(true);
                    }}>
                      Enable
                    </div>
                  </div>
                </div>
                {isAllowMinBid && (
                  <>
                    <label>Minimum Bid Increment:</label>
                    <Row className="mt-2 mb-2">
                      <Col xs={7}>
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMiniBidIncre(miniBidIncre - 1)}>-</div></InputGroup.Text>
                          <Form.Control
                            type="number"
                            min="0"
                            placeholder=""
                            className="text-center text-green input-group-plot"
                            value={miniBidIncre}
                            onChange={({ target }) => setMiniBidIncre(target.value)}
                          />
                          <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setMiniBidIncre(miniBidIncre + 1)}>+</div></InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="mt-4 flex justify-center">
                  {/* <Col md={1}></Col> */}
                  <Col xs={9}>
                    <center>
                      <button type="submit" className="btn-border-green" style={{ minHeight: "50px" }}>
                        {updating ? "Processing, please wait.." : "Open For Auction"}
                      </button>
                    </center>
                  </Col>
                  {/* <Col md={2}></Col> */}
                </Row>
              </form>
            </Col>

          </Row>
        </Modal.Body>
        {snackbarCtx.isDisplayedOnOverlay && <Snackbar />}
      </Modal>

      {/* modal confirmation cancel advance booking */}
      <Modal className="modal-medium-custom" show={showCancelAdvanceBookingModal} onHide={popupCloseAdvanceBookingModal}>
        {/* <Modal.Header style={{ placeSelf: "center" }}>
          <Modal.Title className="text-center"></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Row className="mt-4 mr-4 ml-4">
            <Col md={12}>
              <center>
                <img src="/assets/images/plots/question.png" width={100} height={100} />
              </center>
              <br />
              <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>Do you want to cancel Advance Booking option? <br />
                *Buyer will only be able to view this plot.</p>
              <br />
              <form onSubmit={cancelAdvanceBooking}>
                <Row className="mt-4">
                  <Col xs={6}>
                    <center>
                      <button type="submit" className="btn-border-red" style={{ minHeight: "50px" }}>
                        {updating ? "Processing, please wait.." : "Yes"}
                      </button>
                    </center>
                  </Col>
                  <Col xs={6}>
                    <center>
                      <button type="button" onClick={popupCloseAdvanceBookingModal} className="saveButton bg-red-500 hover:bg-red-600 hover:ring-red-600" style={{ minHeight: "50px" }}>
                        {updating ? "Processing, please wait.." : "No"}
                      </button>
                    </center>
                  </Col>
                </Row>
              </form>
            </Col>

          </Row>
        </Modal.Body>
        {snackbarCtx.isDisplayedOnOverlay && <Snackbar />}
      </Modal>

      <Modal className="modal-confirmation" show={modalConfirmViewOnly} onHide={popupCloseModalConfirmViewOnly}>
        <Modal.Body>
          <Row className="mt-2" style={{ placeContent: "center" }}>
            <Col md={12}>
              <Row style={{ placeContent: "center" }}>
                <Col md={12} className="text-center">
                  {(!auctionPlot) && (
                    <>
                      <label className="text-center">
                        Do you want to proceed view only?
                      </label>
                      <br />
                      <br />
                    </>
                  )}
                  {(auctionPlot) && (
                    <>
                      <label className="text-center">
                        The auction is currently <b>{(auctionPlot) ? auctionPlot.status_current : "-"}</b>
                        <br />
                        Do you want to proceed view only?
                      </label>
                      <br />
                      <br />
                    </>
                  )}

                </Col>
              </Row>

              <Row style={{ placeContent: "center" }}>
                {(auctionPlot) && ((auctionPlot.status_current === "In Progress") || (auctionPlot.status_current === "New")) && (
                  <>
                    <Col xs={5}>
                      <center>
                        <button type="button" className="btn-red" onClick={() => {
                          updateViewOnlyPubStatus({ action_status: 1 });
                        }}>{(isLoading) ? 'Processing, please wait..' : 'Cancel Auction'}</button>
                      </center>
                    </Col>
                  </>
                )}
                {/* {(auctionPlot) && ((auctionPlot.status_current === "In Progress")) && (
                  <>
                    <Col md={5}>
                      <center>
                        <button type="button" className="btn-green" onClick={() => {
                          updateViewOnlyPubStatus({ action_status: 2 });
                        }}>{(isLoading) ? 'Processing, please wait..' : 'Finish Auction'}</button>
                      </center>
                    </Col>
                  </>
                )} */}
                {(!auctionPlot) && (
                  <>
                    <Col xs={5}>
                      <center>
                        <button type="button" className="btn-green" onClick={() => {
                          updateViewOnlyPubStatus({ action_status: 1 });
                        }}>{(isLoading) ? 'Processing, please wait..' : 'Yes'}</button>
                      </center>
                    </Col>

                    <Col xs={5}>
                      <center>
                        <button type="button" className="btn-white" onClick={popupCloseModalConfirmViewOnly}>{(isLoading) ? 'Processing, please wait..' : 'No'}</button>
                      </center>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {showWarSubs && (
        <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
          <section data-testid="popup-layout" className="absolute bg-white w-4/5 tablet:w-4/5 tablet:h-1/4 desktop:w-1/3 laptop:h-1/3 desktop:h-1/4 flex flex-col shadow pb-4">
            <div className="bg-white w-full flex flex-col rounded-t-xl px-8 py-2">
              <WarSubsPack />
              <div className="w-full flex justify-center mb-2">
                <button className="borderedButton w-2/5 bg-green" onClick={() => {
                  goSubPage();
                }}>
                  Subscription
                </button>
              </div>
              <div className="w-full flex justify-center mb-2">

                <button className="borderedButton w-2/5" onClick={() => setShowWarSubs(false)}>
                  Close
                </button>
              </div>
            </div>
          </section>
        </div>
      )}

    </AccountLayout>
  );
};

export default PlotEdit;