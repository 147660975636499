import CartProgress from "components/cart/CartProgress";
import CartSidebar from "components/cart/CartSidebar";
import Footer from "components/footer/footer";

const CartLayout = ({ children }) => {
  return (
    <div className="bg-gray-50 min-h-screen p-4 grid gap-4 grid-cols-1 tablet:grid-rows-3 laptop:grid-cols-4 desktop:grid-cols-8 mb-5">
      
      <div className="max-w-[60rem] w-full justify-self-center flex flex-col gap-4 col-span-1 tablet:col-span-2 laptop:col-span-3 desktop:col-span-4 desktop:col-start-3">
        <CartProgress />
        {children}
      </div>
      <CartSidebar />
      <Footer />
    </div>
  );
};

export default CartLayout;
