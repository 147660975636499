import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import  paymentService from "./paymentService";

export const makePayment = createAsyncThunk(
    "package/index",
    async (args, thunkAPI) => {
        try {
            return await paymentService.makePayment(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    package: {},
    packages:  {
        data:[]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
    },
    extraReducers : {
        [makePayment.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [makePayment.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.packages = payload.data.packages;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [makePayment.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default paymentSlice;