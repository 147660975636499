import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserInfo, authRoleActiveChange } from "../../features/auth/authSlice";

import ProfileMenuCustomer from "./ProfileMenuCustomer";
import ProfileMenuFarmer from "./ProfileMenuFarmer";
import ProfileMenuSuperAdmin from "./ProfileMenuSuperAdmin";
import ProfileEtc from "./ProfileEtc";

const ProfileManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser} = useSelector((state) => state.auth);
  const [showMenu, setShowMenu] = useState(false);
  const setRoleActiveChange = (roleName) => {
    dispatch(
      authRoleActiveChange({
        role_name: roleName,
      })
    ).then(function () {
      window.location = "/account/edit";
    });
  };

  useEffect(() => {
    dispatch(getCurrentUserInfo());
  }, []);

  let currentRoute;
  if (currentUser) {
    switch (currentUser.role_active) {
      case "super-admin":
        currentRoute = routesPaths.ACCOUNTADMIN;
        break;
      default:
        currentRoute = routesPaths.ACCOUNT;
    }
  } 

  const menuList = () => {
    return (
      <>
        <div
          className="unborderedButton"
          style={!window.location.pathname.includes(routesPaths.ACCOUNTPATH) ? {backgroundColor:"white", color:"black"} : {backgroundColor:"#00AF54" , color:"white"}}
          onClick={() => {
            navigate(currentRoute);
          }}
        >
        {currentUser.role_active === 'customer' && 'Edit Account Buyer'} {currentUser.role_active === 'farmer' && 'Edit Account Farmer'}{currentUser.role_active === 'super-admin' && 'Account Details'}
        </div>
        {currentUser && currentUser.role_active == "farmer" && <ProfileMenuFarmer />}
        {currentUser && currentUser.role_active == "customer" && <ProfileMenuCustomer />}
        {currentUser && currentUser.role_active == "super-admin" && <ProfileMenuSuperAdmin />}
        <div
          className="unborderedButton"
          style={!window.location.pathname.includes(routesPaths.ROOM_CHAT_PAGE) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }}
          onClick={() => {
            navigate(routesPaths.ROOM_CHAT_PAGE);
          }}
        >
          Message
        </div>
        {currentUser && currentUser.role_name === "both" && currentUser.role_active === "farmer" && (
          <div
            className="unborderedButton"
            style={{backgroundColor:"white", color:"black"}}
            onClick={() => {
              setRoleActiveChange("customer");
            }}
          >
            View as Buyer 
          </div>
        )}
        {currentUser && currentUser.role_name === "both" && currentUser.role_active === "customer" && (
          <div
            className="unborderedButton"
            style={{backgroundColor:"white", color:"black"}}
            onClick={() => {
              setRoleActiveChange("farmer");
            }}
          >
            View as Farmer
          </div>
        )}
        {/* <div className="flex pt-3 gap-8 self-center">
          {currentUser && currentUser.role_name === "both" && currentUser.role_active === "farmer" && (
            <button onClick={() => setRoleActiveChange("customer")}>View as Buyer</button>
          )}

          {currentUser && currentUser.role_name === "both" && currentUser.role_active === "customer" && (
            <button onClick={() => setRoleActiveChange("farmer")}>View as Farmer</button>
          )}
        </div> */}
        <ProfileEtc />
      </>
    );
  }

  return (
    <>
      <div className="hidden tablet:flex laptop:flex flex-col gap-2">
        {menuList()} 
      </div>
      <button onClick={() => {
        setShowMenu(!showMenu);
      }} className="inline-flex justify-between items-center p-2 text-sm text-gray-500 rounded-lg tablet:hidden lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" >
        <span>Open menu</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"></path></svg>
      </button>
      {(showMenu) && (
        <>
          <div className="flex flex-col gap-2">
            {menuList()} 
          </div>
        </>
      )}
    </>
  );
};

export default ProfileManagement;
