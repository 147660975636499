import clsx from "clsx";
import { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Text from "components/text/Text";
import { newPassword } from "../../features/auth/newPasswordSlice";
import SnackbarContext from "_helpers/snackbar-context";

import googleLogo from "images/Google.svg";
import facebookLogo from "images/Facebook.svg";
import buyyerPng from "images/login/buyer.png";
import farmerPng from "images/login/farmer.png";
import styles from "./NewPassword.module.scss";
import { getAuthRememberMe, getCurrentUserInfo, setAuthRememberMe } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";

const NewPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [accountType, setAccountType] = useState(0);
  const snackbarCtx = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let params = useParams();

  // const reduxAuth = useSelector((state) => state.auth);
  const { _rememberMe } = useSelector((state) => state.auth);

  const submitHandler = (event) => {
    // console.log(params);
    if(password != confirmPassword){
      snackbarCtx.displayMsg("Password not match", "failure");
    }else{
      if(password.length < 8){
        snackbarCtx.displayMsg("The password must be at least 8 characters.", "failure");
      }else{
        dispatch(
          newPassword({
            token: params.token,
            password: password,
          })
        ).then(function (response) {
            // console.log(response);
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure");
          } else {
            snackbarCtx.displayMsg(response.payload.message, "success");
            navigate("/login");
          }
        });
      }
    }

    event.preventDefault();
  };

  return (
    <div className="flex justify-center">
      <div>
        
        <form data-testid="newPassword" className={clsx("flex", "flex-col", "p-8", "space-y-6", styles.loginForm)} onSubmit={submitHandler}>
          <span className={clsx("self-center", "text-2xl", styles.titleLogin)}>
            <Text id="New Password" />
          </span>
          <div className="flex flex-col space-y-4">
            <input
              type="password"
              className={clsx("", styles.inputUserPass)}
              placeholder="New Password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />

            <input
              type="password"
              className={clsx("", styles.inputUserPass)}
              placeholder="Confirmation Password"
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
            />
          </div>
        
          <div className={clsx(styles.dontHaveAccount)}>
            Don’t have an account? <Link
              to="/register/details"
              className={clsx("cursor-pointer")}
            >
              <u>Sign up here!</u>
            </Link>
          </div>

          <button className={clsx(styles.submit, "bg-green-500", "text-white", "hover:bg-green-600")} type="submit" style={{ marginTop: "0px" }}>
            <Text id="Submit" />
          </button>


        </form>
      </div>

    </div>

  );
};

export default NewPasswordForm;
