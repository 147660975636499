import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import AccountLayout from "pages/AccountLayout";
import { routesPaths } from "constants/routes.constants";
import MessageEmpty from "../ui/message/empty/MessageEmpty"

// import Order from "./Order";
// import {catalogDestroy} from "../../../features/catalog/catalogSlice";
// import {
//     orderDetailIndex, orderDetailMadeByCustomer,
//     orderDetailReceivableForFarmer
// } from "../../../features/order-detail/orderDetailSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { advanceBookingList } from "../../features/advance-booking/advanceBookingSlice";
import AdvanceBooking from "./AdvanceBooking";
import SnackbarContext from "_helpers/snackbar-context";

import { faSortAmountAsc, faSortDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { current } from "@reduxjs/toolkit";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const AdvanceBookingList = () => {
  const [typeOrder, setTypeOrder] = useState("Advanced");
  // const [bookingPlots, setBookingPlots] = useState(null);
  const navigate = useNavigate();
  const snackbarCtx = useContext(SnackbarContext);

  const params = useParams();
  const dispatch = useDispatch();
  const {currentUser} = useSelector((state) => state.auth);
  // const reduxAdvanceBooking = useSelector((state) => state.advanceBooking);

  const [bookingList, setBookingList] = useState({
    data: []
  })

  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [currentPage, setCurrentPage] = useState(1);
  const loadList = (pageNumber,itemsPerPage) => {
    dispatch(
      advanceBookingList({
        limit: itemsPerPage,
        page: pageNumber,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
        // alert(response.payload);
      } else {
        let result = response.payload.data.booking_plots;
        setBookingList(result);
      }
    });
  }

  const handlePageChange = (newPageNumber, newItemsPerPage) => {
    setCurrentPage(newPageNumber);
    setItemsPerPage(newItemsPerPage);
    loadList(newPageNumber, newItemsPerPage);
  };
  
  const handleChangePage = (event, newPageNumber) => {
    handlePageChange(newPageNumber, itemsPerPage);
  };
  
  const handleLimitPage = (newItemsPerPage) => {
    setCurrentPage(1); 
    setItemsPerPage(newItemsPerPage);
    loadList(1, newItemsPerPage);
  };

  useEffect(() => {
    loadList()
  }, []);

  return (
    <AccountLayout>
      <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4 mb-5">
        <h1 className="text-2xl text-left font-bold" style={{color: "#395B50"}}>{(currentUser) ? (currentUser.role_active == 'farmer') ? 'Manage Orders' : 'My Orders' : ''}</h1>
        <div className="ml-1 mb-2 mt-2">
          <section className="flex gap-8">
            <label
              className={
                typeOrder === "Incoming"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => {
                setTypeOrder("Incoming");
                if(currentUser){
                  if(currentUser.role_active == 'customer'){
                    navigate(routesPaths.CUSTOMER_ORDER_FARMERS);
                  }else if(currentUser.role_active == 'farmer'){
                    navigate(routesPaths.ORDER_FARMERS);
                  }
                }
              }}
            >
              Incoming Orders
            </label>
            <label
              className={
                typeOrder === "Advanced"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => setTypeOrder("Advanced")}
            >
              Advanced Booking
            </label>
            <label
              className={
                typeOrder === "Auction"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => {
                setTypeOrder("Auction")
                navigate(routesPaths.MANAGE_AUCTION_BIDDING);
              }}
            >
              Auction / Bidding
            </label>
          </section>
        </div>
        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
          <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Sort
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom mr-2">
                <option value="DATE" selected>
                  Date
                </option>
                {/* <option value="DATE" selected>
                  Price
                </option> */}
              </select>
              <FontAwesomeIcon icon={faSortAmountAsc} />
            </div>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Filter
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom ">
                <option value="DATE" selected>
                  Farm
                </option>
                {/* <option value="DATE" selected>
                  Product
                </option> */}
              </select>
            </div>

            <div className="flex items-center tablet:flex justify-start w-full desktop:w-fit">
              <form className="tablet:flex justify-start w-full desktop:w-fit items-center">
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 mr-5 dark:text-white laptop:hidden desktop:hidden">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                </div>
              </form>
            </div>

          </div>
          <div className="p-4 w-full max-w">
            {/* <div className="hidden laptop:flex flex-row items-center justify-center pb-3" style={{ borderBottomWidth: "1px" }}>
              <div className="basis-1/6 flex items-center justify-center font-medium"></div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Booking ID</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Quantity</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Price</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Amount Paid</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Order ID</div>
            </div> */}
            {/* {bookingPlots ? bookingPlots.data ? bookingPlots.data.map((bookingPlot, index) => (
              <AdvanceBooking bookingPlots={bookingPlots} currentUser={currentUser} key={index} />
            )) : '' : ''} */}
            
            <AdvanceBooking bookingPlots={bookingList} currentUser={currentUser} />
            
          </div>
        </div>
        <div className="grid sm:grid-cols-2 sm:grid-rows-2 w-full">
            <div className="flex items-center tablet:flex lg:justify-start justify-center w-full ">
                <span>
                <select className="select-custom" onChange={(e) => handleLimitPage(e.target.value)}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={1000}>All</option>
                </select> 
                </span>
                <span className="ml-2 text-sm">
                    <label>entries per page</label>
                </span>
            </div>
            <div className="flex lg:justify-end justify-center">
                <Stack spacing={2} >
                <Pagination count={bookingList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage}/>
                </Stack>
            </div>
          </div>
        {/* <MessageEmpty loading={reduxAdvanceBooking.loading} length={reduxAdvanceBooking.booking_plots.data.length} message={reduxAdvanceBooking.data.message} /> */}

      </div>
    </AccountLayout>
  );
};

export default AdvanceBookingList;
