import { useLocation } from "react-router-dom";

const CartProgress = () => {
  const location = useLocation();

  let progress = 0;
  if (location.pathname === "/cart/products") {
    progress = 1;
  // } else if (location.pathname === "/cart/delivery-payment") {
  //   progress = 2;
  } else if (location.pathname === "/cart/done") {
    progress = 2;
  }

  const progressBar = {
    width: `${(progress / 2) * 100}%`,
  };

  return (
    <div data-testid="cart-progress" className="flex flex-col gap-2">
      {/* <section className="flex gap-4 justify-between items-center">
        <label className={progress == 0 ? "text-green-500 text-2xl" : "text-2xl"}>Cart</label>
        <label className={progress == 1 ? "text-green-500 text-2xl" : "text-2xl"}>Delivery & Payment</label>
        <label className={progress == 2 ? "text-green-500 text-2xl" : "text-2xl"}>Done</label>
      </section> */}
      <section className="bg-white flex w-full h-[1.5rem] rounded-lg shadow">
        <div className="bg-green-500 flex h-[1.5rem] rounded-lg" style={progressBar}></div>
      </section>
    </div>
  );
};

export default CartProgress;
