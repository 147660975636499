import  storage from "../../support/support_storage";

import  api from "../../support/support_api";

const auth = async () => {
   return JSON.parse(localStorage.getItem("user"));
}

const authUpdate = async (params) => {
    const response  = await  api.axiosPatch('/users', params);

    return response.data;
}

const authUpdateCompany = async (params) => {
    const response  = await  api.axiosPatch('/companies', params);

    return response.data;
}

const authGetCompany = async () => {
    const response  = await  api.axiosGet('/companies/authenticated');
    // console.log(response);

    return response.data;
}

const authUpdateCompanyPhoto = async (params) => {
    const response  = await api.axiosPost(`/files/upload/${params.get('company_id')}`, params);

    return response.data;
}

const authUpdatePhoto = async (params) => {
    let config = await storage.getConfig();

    const response  = await api.axiosPost(`/files/upload/${params.get('user_id')}`, params);

    return response.data;
}

const authRoleActiveChange = async (params) => {
    let config = await storage.getConfig();

    const response  = await api.axiosPost(`/users/role-active-change`, params);

    return response.data;
}

const fcmTokenUpdate = async (params) => {
    const response  = await api.axiosPost(`/users/update-fcm-token`, params);
    return response.data;
}

const authGetUser = async () => {
    const response  = await api.axiosGet(`/users/get/current-user-info`);

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}

const getStatesList = async (params) => {
    const response  = await api.axiosGet(`/states/list`);

    return response.data;
}

const storeAddress = async (params) => {
    const response  = await api.axiosPost(`/users/store/userAddress`, params);

    return response.data;
}

const getAddress = async (params) => {
    const response  = await api.axiosGet(`/users/get/userAddress`, params);

    return response.data;
}

const getBankDetail = async (params) => {
    const response  = await api.axiosGet(`/users/get/bankDetail`, params);

    return response.data;
}

const storeBankDetail = async (params) => {
    const response  = await api.axiosPost(`/users/store/bankDetail`, params);

    return response.data;
}


const authService = {
    auth, authUpdate, authUpdateCompany, authUpdateCompanyPhoto, authUpdatePhoto, authRoleActiveChange, fcmTokenUpdate, authGetUser, authGetCompany, getStatesList, storeAddress, getAddress, getBankDetail, storeBankDetail,
}

export default authService;