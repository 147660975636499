import React, { useState, useEffect, useContext } from "react";

import PageLayout from "pages/AccountLayout";
import { categoryIndex } from "../../features/category/categorySlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gradeIndex } from "../../features/grade/gradeSlice";
import { demandStore } from "../../features/demand/demandSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { cropGuest, cropShow } from "../../features/crop/cropSlice";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const DemandCreate = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [name, setName] = useState("");

  // Keeps track of the selected frequency
  const [frequency, setFrequency] = useState("");
  // Sets the selectable frequencies in the dropdown menu
  const [selectableFrequencies, setSelectableFrequencies] = useState([{ name: "once" }, { name: "weekly" }]);
  const [selectableFrequencyDays, setSelectableFrequencyDays] = useState([
    { name: "monday" },
    { name: "tuesday" },
    { name: "wednesday" },
    { name: "thursday" },
    { name: "friday" },
    { name: "saturday" },
    { name: "sunday" },
  ]);
  const [frequencyDay, setFrequencyDay] = useState("");
  const [requirementOpenDate, setRequirementOpenDate] = useState("");
  const [requirementCloseDate, setRequirementCloseDate] = useState("");
  const [deliveryOneOf, setDeliveryOneOf] = useState("");
  const [quantityUnit, setQuantityUnit] = useState("")

  const reduxCategory = useSelector((state) => state.category);
  const reduxCrop = useSelector((state) => state.crop);
  const reduxGrade = useSelector((state) => state.grade);
  const reduxDemand = useSelector((state) => state.demand);
  const [categoryId, setCategoryId] = useState("");
  const [quantityRequired, setQuantityRequired] = useState("");
  const [cropId, setCropId] = useState("");
  const [gradeId, setGradeId] = useState("");

  const snackbarCtx = useContext(SnackbarContext);

  const save = (event) => {
    dispatch(
      demandStore({
        // customer_id
        name: name,
        grade_id: gradeId,
        crop_id: cropId,
        category_id: categoryId,
        quantity_required: quantityRequired,
        frequency: frequency,
        frequency_day: frequencyDay,
        delivery_one_off: deliveryOneOf,
        requirement_open_date: requirementOpenDate,
        requirement_close_date: requirementCloseDate,
      })
    ).then(function (response) {
      // console.log("done", response);
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
        //alert(response.payload);
      } else {
        // console.log(response.payload.message);
        snackbarCtx.displayMsg(response.payload.message, "success");
        let demand = response.payload.data.demand;
        navigate(`/demands/${demand.id}/edit`);
      }
    });

    event.preventDefault();
  };

  const loadCategories = () => {
    dispatch(
      categoryIndex({
        page: 1,
        limit: 100,
      })
    ).then(function (response) {
      // console.log(response.payload.message);
    });
  };

  const loadGrades = () => {
    dispatch(
      gradeIndex({
        page: 1,
        limit: 100,
      })
    ).then(function (response) {
      // console.log("done", response);

      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        // console.log(response.payload.message);
      }
    });
  };

  const loadCrop = (cropId) => {
    dispatch(cropShow({
      crop_id: cropId
    })).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        // snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });
  }

  const loadCrops = () => {
    dispatch(cropGuest({
      crop_id: cropId
    })).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        //  snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });
  }

  useEffect(() => {
    loadCategories();
    // loadGrades();
    loadCrops();
  }, []);






  // Todo - redo this and move to backend later.
  useEffect(() => {
    if (gradeId) {
      const chosenGrade = reduxCrop.crop?.grades?.data.find(grade => grade.id === Number(gradeId));
      setQuantityUnit(chosenGrade?.quantity_unit)
    }
  }, [gradeId, reduxCrop.crop.grades?.data])


  useEffect(() => {
    if (frequency !== "once") {
      setDeliveryOneOf("");
    } else {
      setFrequencyDay("");
    }
  }, [frequency]);

  return (
    <>
      <PageLayout>
        <div
          data-testid="demand-create"
          className="flex flex-col items-start"
        >

          {/* Actual Component */}
          <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
            <div className="head-bar-back">
              <section>
                <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
                  <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                  {" Go Back"}
                </label>
              </section>
              <div className="mb-2">
                <label className="title-content">
                  Add Demand
                </label>
              </div>
            </div>

            <div className="flex flex-col rounded-lg shadow bg-card-white-new">
              <form onSubmit={save}>

                <Row>
                  {/* image  */}
                  <Col md={6}>
                    <button type="button" className=" btn-greyed" title={"Upload photos can be done after saving"} >Upload Photos</button>
                  </Col>

                  <Col md={6} className="padding-form-market">
                    <div className="self-center w-full flex flex-col gap-4">
                      <div className="w-full flex flex-col gap-4">
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Product Name
                          </Col>
                          <Col md={8}>
                            <input type="text" placeholder="Product Name" value={name} onChange={({ target }) => setName(target.value)} className={"input-search"} />
                          </Col>
                        </Row>
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Categories
                          </Col>
                          <Col md={8}>
                            {
                              <select
                                className="input-search"
                                // defaultValue={""}
                                value={categoryId || ''}
                                onChange={({ target }) => setCategoryId(target.value)}
                              >
                                <option value="">Select Category</option>
                                {reduxCategory.categories.data.map((category, index) => {
                                  return (
                                    <option value={category.id} key={index}>
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          </Col>
                        </Row>
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Available Crops
                          </Col>
                          <Col md={8}>
                            {
                              <select
                                className="input-search"
                                // defaultValue={""}
                                value={cropId || ''}
                                onChange={({ target }) => {
                                  setCropId(target.value);
                                  loadCrop(target.value)


                                  reduxCrop.crops?.data?.map((crop, index) => {
                                    if (crop.id == target.value) {
                                      setName(crop?.name)
                                    }
                                  })

                                }}
                              >
                                <option value="" disabled={true}>Select Crop</option>
                                {reduxCrop.crops.data && reduxCrop.crops.data.map((crop, index) => {
                                  return (
                                    <option value={crop.id} key={index}>
                                      {crop.name}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          </Col>
                        </Row>
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Grades
                          </Col>
                          <Col md={8}>
                            <select
                              className="input-search"
                              // defaultValue={""}
                              value={gradeId || ''}
                              onChange={({ target }) => setGradeId(target.value)}
                            >
                              <option value="" disabled={true}>Select Grade</option>
                              {reduxCrop.crop.grades.data &&
                                reduxCrop.crop.grades.data.map((grade, index) => {
                                  return (
                                    <option value={grade.id} key={index}>
                                      {grade.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </Col>
                        </Row>
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Amount Required
                          </Col>
                          <Col md={8}>
                            <input
                              className="input-search w-2/3"
                              type="number"
                              min={1}
                              placeholder="Amount Required"
                              value={quantityRequired}
                              onChange={({ target }) => setQuantityRequired(target.value)}
                            />
                          </Col>
                        </Row>
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Frequency
                          </Col>
                          <Col md={8}>
                            <select
                              className="input-search"
                              defaultValue={"DEFAULT"}
                              onChange={({ target }) => setFrequency(target.value)}
                            >
                              <option value="DEFAULT" disabled>
                                Select Frequency
                              </option>
                              {selectableFrequencies.map((frequency, index) => (
                                <option value={frequency.name} key={index}>
                                  {frequency.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                        {frequency === "weekly" && (
                          <Row style={{ placeItems: "center" }}>
                            <Col md={4} className="head-bar-nav">
                              Frequency Day
                            </Col>
                            <Col md={8}>
                              <select
                                className="input-search"
                                defaultValue={"DEFAULT"}
                                onChange={({ target }) => setFrequencyDay(target.value)}
                              >
                                <option value="DEFAULT" disabled>
                                  Select Frequency
                                </option>
                                {selectableFrequencyDays.map((frequencyDay, index) => (
                                  <option value={frequencyDay.name} key={index}>
                                    {frequencyDay.name}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                        )}
                        {frequency == "once" && (
                          <Row style={{ placeItems: "center" }}>
                            <Col md={4} className="head-bar-nav">
                              Delivery one off
                            </Col>
                            <Col md={8}>
                              <input
                                className="input-search"
                                type="date"
                                placeholder="Date Requirement Open"
                                value={deliveryOneOf}
                                onChange={({ target }) => setDeliveryOneOf(target.value)}
                              />
                            </Col>
                          </Row>
                        )}

                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Demand Period
                          </Col>
                          <Col md={8}>
                            <Row style={{ placeItems: "center" }}>
                              <Col md={6} sm={6} xs={6}>
                                <input
                                  className="input-search"
                                  type="date"
                                  placeholder="Date Requirement Open"
                                  value={requirementOpenDate}
                                  onChange={({ target }) => setRequirementOpenDate(target.value)}
                                />
                              </Col>
                              <Col md={6} sm={6} xs={6}>
                                <input
                                  className="input-search"
                                  type="date"
                                  placeholder="Date Requirement Close"
                                  value={requirementCloseDate}
                                  onChange={({ target }) => setRequirementCloseDate(target.value)}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ placeItems: "center" }}>
                          <Col md={12} >
                            <button type="submit" className="saveButton">
                              {/*Post Demand*/}

                              {reduxDemand.updating ? "Processing, please wait.." : "Save"}
                            </button>
                          </Col>
                        </Row>
                      </div>

                    </div>
                  </Col>

                </Row>

              </form>
            </div>

          </div>
        </div>

      </PageLayout>
    </>
  );
};

export default DemandCreate;
