import api from "../../support/support_api";

const makePayment = async (params) => {
    const response = await api.axiosPost('/payment/make-payment', params);

    return response.data;
}

const paymentService = {
    makePayment,
}

export default paymentService;