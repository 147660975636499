import styles from "./Account.module.scss";
import clsx from "clsx";

const AccountControls = ({ page, pageHandler }) => {
  return (
    <div className={clsx(styles.scroll,"flex gap-x-4 w-full overflow-x-scroll")}>
      <button
        className={page === "Personal Details" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
         "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("Personal Details")}
      >
        Personal Details
      </button>
      <button
        className={page === "Company Details" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
         "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("Company Details")}
      >
        Company Details
      </button>
      <button
        className={page === "Address" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
         "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("Address")}
      >
        Delivery Address
      </button>
      <button
        className={page === "Package" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
         "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("Package")}
      >
        Package Subscription
      </button>
      <button
        className={page === "Bank" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
         "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("Bank")}
      >
        Bank Details
      </button>
      <button
        className={page === "Billing" ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new" :
         "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"}
        onClick={() => pageHandler("Billing")}
      >
        Billing
      </button>
    </div>
  );
};

export default AccountControls;
