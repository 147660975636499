import { useNavigate } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";

const DemandMarketWidget = ({ demand }) => {
  const navigate = useNavigate();

  const photo_default = "/assets/images/products/WheatIcon.svg";

  const clickHandler = () => {
    navigate(`${routesPaths.APPLYDEMANDS}/${demand.id}`)
  }

  return (
    <div
      data-testid="market-demand-widget"
      className="cursor-pointer relative w-full h-full rounded-lg shadow-lg border-2"
      onClick={clickHandler}
    >
      <section className="absolute bg-black/50 text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
        <label className="cursor-pointer font-in-box-market">{`Required: ${demand.name}`}</label>
        <label className="cursor-pointer font-in-box-market">{`${demand.grade && demand.grade.name}`}</label>
        <div className="flex justify-between font-in-box-sub-market">
          <label className="cursor-pointer hidden marketDemandWidget:flex">{`${demand.quantity_required} ${demand.unit} / ${demand.frequency_camel_case}`}</label>
          <label className="cursor-pointer">{`Status: ${demand.status_text_1}`}</label>
        </div>
      </section>
      <img
        src={demand.photo_default}
        alt="photo of the demand"
        className="object-cover w-full h-full aspect-square rounded-lg"
      />
      <section className="absolute bg-black/50 text-white flex flex-col left-0 bottom-0 rounded-b-lg w-full h-fit px-2 py-2 box-img-card">
        <label className="cursor-pointer font-in-box-market">{`${demand.company?.billing_address_short}`}</label>
        <label className="cursor-pointer font-in-box-sub-market">{`${demand.frequency_label}`}</label>
      </section>
    </div>
  );
};

export default DemandMarketWidget;
