import CartLayout from "pages/CartLayout";
import { useState, useEffect, useContext } from "react";

import CartProduct from "./CartProduct";
import {useNavigate, useParams} from "react-router-dom";
import {catalogShow} from "../../features/catalog/catalogSlice";
import {orderShow} from "../../features/order/orderSlice";
import {orderDetailIndex} from "../../features/order-detail/orderDetailSlice";
import {useDispatch, useSelector} from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";

const CartProductList = () => {

    const navigate = useNavigate();
    const [productAdded, setProductAdded] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);

  // const dummyData = [
  //   { name: "Bananas", photo: "/assets/images/products/BananasPhoto.svg", quantity: 10, fruit_price: 25, currency: "$" },
  //   { name: "Oranges", photo: "/assets/images/products/OrangesPhoto.svg", quantity: 5, fruit_price: 15, currency: "$" },
  //   { name: "Loading...", photo: "/assets/images/products/WheatIcon.svg", quantity: 0, fruit_price: 1, currency: "" },
  // ];
  //
  // const [products, setProducts] = useState(dummyData);

  // const quantityHandler = (id, operator) => {
  //   if (operator === "+") {
  //     setProducts(
  //       products.map((product, index) => (index === id ? { ...product, quantity: product.quantity + 1 } : product))
  //     );
  //   } else if (operator === "-") {
  //     setProducts(
  //       products.map((product, index) => (index === id ? { ...product, quantity: product.quantity - 1 } : product))
  //     );
  //   }
  // };

  // const deleteHandler = (id) => {
  //   setProducts(products.filter((product, index) => index !== id));
  // }

  const reduxOrderDetail = useSelector((state) => state.orderDetail);

  const loadOrder = () => {
      dispatch(
          orderDetailIndex({
              status:'pending',
              page:1,
              limit:100
          })
      ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure")
              //alert(response.payload);
          } else {
            // if(reduxOrderDetail != null && reduxOrderDetail.order_details.data){
              //process order group by farm
            // }
          }
      });
  }
  
  useEffect(() => {
    loadOrder();
  }, []);

    return (
    <CartLayout>
      <div data-testid="cart-product-list" className="flex flex-col gap-4 ">
        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
            <div className="hidden w-full laptop:flex flex-row items-center justify-center py-3" style={{borderBottomWidth: "1px"}}>
              <div className="basis-2/4 flex items-center justify-center font-medium">Item</div>
              <div className="basis-1/3 flex items-center justify-center font-medium">Quantity</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Price</div>
              <div className="basis-1/6 flex items-center justify-center font-medium"></div>
            </div>
        {reduxOrderDetail.order_details.data && reduxOrderDetail.order_details.data.map((orderDetail, index) => (
          <CartProduct orderDetail={orderDetail} key={index} loadOrder={loadOrder}/>
        ))}
      </div>
          {reduxOrderDetail.order_details.data && reduxOrderDetail.order_details.data.length == 0 && (<h1>{reduxOrderDetail.message}</h1>)}
      </div>
    </CartLayout>
  );
};

export default CartProductList;
