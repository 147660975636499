import parse from 'html-react-parser';

const PaymentDetail = ({ htmlpage }) => {
    console.log(htmlpage);
    return (
        parse(htmlpage)
    );
};

export default PaymentDetail;
