import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  catalogService from "./catalogService";

export const catalogSearch = createAsyncThunk(
    "catalogs/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await catalogService.catalogSearch(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const catalogIndex = createAsyncThunk(
    "catalogs/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await catalogService.catalogIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const catalogStore = createAsyncThunk(
    "catalogs/store",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await catalogService.catalogStore(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const catalogUpdate = createAsyncThunk(
    "catalogs/update",
    async (args, thunkAPI) => {
        try {
            return await catalogService.catalogUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const catalogUploadPhoto = createAsyncThunk(
    "catalogs/upload-photo",
    async (args, thunkAPI) => {
        try {
            return await catalogService.catalogUploadPhotos(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const catalogShow = createAsyncThunk(
    "catalogs/:id/show",
    async (args, thunkAPI) => {
        try {
            return await catalogService.catalogShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const catalogDestroy = createAsyncThunk(
    "catalogs/:id/destroy",
    async (args, thunkAPI) => {
        try {
            return await catalogService.catalogDestroy(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    farm:{},
    grades: {
        data: [

        ]
    },
    product: {
        farm: {},
        crop: {
            grades: {
                data: [

                ]
            }
        }
    },
    catalog: {},
    catalogs: {
        data: []
    },
    products: {
        data: []
    },
    plots: {
        data: []
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
    },
    extraReducers : {
        [catalogStore.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [catalogStore.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating=false;
            state.catalog = payload.data.catalog;
            state.loading=false;
            state.isSuccess = true;
        },
        [catalogStore.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },

        [catalogUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [catalogUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [catalogUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [catalogShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [catalogShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.catalog = payload.data.catalog;
            state.product = payload.data.product;
            state.grades  = payload.data.product.grades;
            state.farm    = payload.data.product.farm;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [catalogShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [catalogIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [catalogIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.catalogs = payload.data.catalogs;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [catalogIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },


        [catalogSearch.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
            state.products = {
                data: [

                ]
            }
            state.plots = {
                data: [

                ]
            }
        },
        [catalogSearch.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.products = payload.data.products;
            state.plots = payload.data.plots;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [catalogSearch.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },


        [catalogDestroy.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [catalogDestroy.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.catalogs = {};
            // state.catalogs = payload.data.catalogs;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [catalogDestroy.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

// export const {  } = catalogSlice.actions

export default catalogSlice;