import { useState, useEffect, useContext } from "react";
import GradeEdit from "../grade/GradeEdit";
import { cropGuest } from "../../../features/crop/cropSlice";
import { useDispatch, useSelector } from "react-redux";
import { gradeStore } from "../../../features/grade/gradeSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { categoryIndex } from "../../../features/category/categorySlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { varietyDelete, varietyUpdate } from "features/variety/varietySlice";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const CropEdit = ({ variety, varietyIndex, reloadVariety }) => {
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(false);
    const [cropId, setCropId] = useState(variety?.crop?.id || '');
    const [cropName, setCropName] = useState(variety?.crop?.name || '');
    const [varietyName, setVarietyName] = useState(variety.name || '');
    const [categoryId, setCategoryId] = useState(variety?.crop?.category_id || "");
    const [isCropSelected, setIsCropSelected] = useState(false);
    const [wait, setWait] = useState(false);
    const [filteredCrop, setFilteredCrop] = useState([]);
    const snackbarCtx = useContext(SnackbarContext);
    const reduxcategory = useSelector((state) => state.category);
    const reduxCrop = useSelector((state) => state.crop);

    const updateVariety = () => {
        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            dispatch(varietyUpdate({
                id: variety.id,
                name: varietyName,
                crop_id: cropId
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    reloadVariety();
                }
            });

        }, 1000))
    }

    const deleteVariety = () => {
        if (window.confirm("Are you sure you want to delete this crop?")) {
            dispatch(varietyDelete({
                id: variety.id
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    reloadVariety();
                }
            });
        }
    }

    const addGrade = () => {
        if (cropId === '') {
            snackbarCtx.displayMsg('Please select crop first', "failure")
        } else {
            dispatch(gradeStore({
                name: '',
                price: '',
                price_unit: '',
                quantity: '',
                quantity_unit: '',
                criteria: '',
                crop_id: cropId
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    reloadVariety();
                }
            });
        }
    }

    useEffect(() => {
        return (() => {
            setUpdate(false)
        })
    }, [update]);

    const loadCategories = () => {
        dispatch(
            categoryIndex({
                page: 1,
                limit: 100
            })
        );
    }

    const loadCrops = () => {
        dispatch(cropGuest({}))
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Add Grade
        </Tooltip>
    );

    useEffect(() => {
        loadCategories();
        loadCrops();
    }, []);

    return (
        <>
            <div className="flex flex-col space-y-4 w-full">
                <div className="flex flex-row space-x-8 relative">
                    <label className="title-crops-17-500 w-[60px]">Crop {varietyIndex + 1}</label>
                    <FontAwesomeIcon onClick={deleteVariety} icon={faTrashCan} className={"cursor-pointer title-crops-17-500 py-0.5 px-2 rounded absolute right-0 text-right"} />
                </div>

                <div className="w-full grid justify-stretch lg:grid-cols-3 md:grid-cols-2 lg:space-x-1">
                    <div className="flex flex-col">
                        <section>
                            {
                                <select
                                    className="bg-gray-300 input-search"
                                    defaultValue={""}
                                    value={categoryId || "DEFAULT"}
                                    disabled
                                    onChange={({ target }) => {
                                        setUpdate(true);
                                        setCategoryId(target.value)
                                    }}>
                                    <option value="">
                                        Please select crop
                                    </option>
                                    {reduxcategory.categories.data.map((category, index) => {
                                        return (
                                            <option value={category.id} key={index}>{category.name}</option>
                                        )
                                    })}
                                </select>
                            }
                        </section>
                    </div>
                    <div className="flex flex-col">
                        <div>
                            <input
                                type="search"
                                className={filteredCrop.length > 0 ? (isCropSelected ? "input-search" : "input-search-with-dropdown") : "input-search"}
                                placeholder="Search.."
                                value={cropName}
                                onChange={({ target }) => {
                                    setIsCropSelected(false)
                                    setCropName(target.value)
                                    setFilteredCrop(reduxCrop?.crops?.data.filter((crop) => crop?.name.toLowerCase().includes(target.value.toLowerCase())));
                                }}
                            />
                            <div className={filteredCrop.length > 0 ? (isCropSelected ? "hidden" : "input-search-dropdown") : "hidden"}>
                                {filteredCrop.map((crop, index) => {
                                    return (
                                        <div value={crop.name} key={index} onClick={() => { setIsCropSelected(true); setCropId(crop.id); setCategoryId(crop.category_id); setCropName(crop.name) }}>{crop.name}</div>
                                    )
                                })}
                                {filteredCrop.length === 0 && <div>No crop displayed</div>}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <section>
                            <input className="input-search" type="text" placeholder="Add variety name" value={varietyName}
                                onChange={({ target }) => {
                                    setUpdate(true);
                                    setVarietyName(target.value)
                                }}
                            />
                        </section>
                    </div>
                </div>
                <div className="flex justify-between">
                    <div className="w-1/2 flex flex-col gap-2">
                    </div>
                    <div className="w-1/2 flex flex-col gap-2"></div>
                    <div className="w-1/2 flex flex-col gap-2"><button className="saveButton" onClick={updateVariety}>Save</button></div>
                </div>
                <div className="mt-5">
                    {
                        variety?.crop && variety?.crop?.grades.data.map((grade, gradeIndex) => {
                            return (
                                <GradeEdit key={gradeIndex} grade={grade} gradeIndex=
                                    {gradeIndex} reloadVariety={reloadVariety} />)
                        }
                        )
                    }
                </div>
                <div className="w-full text-left">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <FontAwesomeIcon onClick={() => addGrade()} icon={faCirclePlus} className={"cursor-pointer title-content text-left"} />
                    </OverlayTrigger>
                </div>
                <div className="w-full border-b-2 mt-4">
                </div>

            </div>
        </>


    );
};

export default CropEdit;
