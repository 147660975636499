import { routesPaths } from "constants/routes.constants";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AccountLayout from "pages/AccountLayout";
import DemandAccept from "../demands/DemandAccept";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { farmShow } from "../../features/farm/farmSlice";
import PhotoModal from "../ui/photo/modal/PhotoModal";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import "./FarmShow.css";
import ProfileRating from "components/profile/ProfileRating";
import MultipleMarkerMapLocation from "components/map/MultipleMarkerMapLocation";
import Footer from "components/footer/footer";

const FarmShow = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [certificate, setCertificate] = useState("DEFAULT");
    const [accept, setAccept] = useState(false);
    const [isPhotoModalOpen, setPhotoModalOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState("");
    const snackbarCtx = useContext(SnackbarContext);
    const [listLocationGlobal, setListLocationGlobal] = useState([{ lat: 1.966773, lng: 102.881449, name: "Farm" }]);
    const [plotsIsPublish, setPlotsIsPublish] = useState(false);

    const acceptHandler = () => {
        setAccept(true);
    };

    const demand_id = 1;

    const dummyFarm = {
        data: {
            name: "",
            fields: 0,
            plots: 0,
            certificates: [{ name: "Food Standard 1" }, { name: "Food Standard 2" }, { name: "Food Standard 3" }],
            photos: [
                {
                    photo: "/assets/images/plots/PlotPhoto1.svg",
                    date: { day: 25, month: 5, year: 2022 },
                },
                {
                    photo: "/assets/images/plots/PlotPhoto2.svg",
                    date: { day: 25, month: 5, year: 2022 },
                },
                {
                    photo: "/assets/images/plots/PlotPhoto3.svg",
                    date: { day: 25, month: 5, year: 2022 },
                },
            ],
        },
    };

    const info = dummyFarm;

    const reduxFarm = useSelector((state) => state.farm);

    const loadFarm = () => {
        dispatch(farmShow({
            farm_id: params.farm_id,
            plot_publish_status:1
        })).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
                //alert(response.payload);
            } else {
                setListLocationGlobal([{ lat: response.payload.data.farm.latitude, lng: response.payload.data.farm.longitude, name: response.payload.data.farm.name }]);
                if(response.payload.data.farm.plots){
                    let plots = response.payload.data.farm.plots.data;
                    for(let i=0; i<plots.length; i++){
                        if(plots[i].publish_status == 1){
                            setPlotsIsPublish(true);
                        }
                    }
                }
            }
        });
    }

    const showPdf = (certificateName) => {
        const pdfName = certificateName;
        if (pdfName === "DEFAULT") {
            //alert("Please, select a certificate to view.")
            snackbarCtx.displayMsg("Please, select a certificate to view.", "failure");
            return;
        }
        let pdfCollection = reduxFarm.farm.certificates;
        let onePdf = pdfCollection.find(pdf => pdf.name === pdfName);
        window.open(onePdf.url);
    }

    const closePhotoModal = () => {
        setPhotoModalOpen(false);
    }

    useEffect(() => {

        loadFarm()
        // if (farmId) {
        //     // console.log("farm id was changed ", farmId);
        //     // setGradeId("");
        //     //
        //     // loadGrades();
        // }
    }, []);

    return (
        // <AccountLayout>
        <>
            <div className="bg-gray-50">
                <div>
                    {accept && <DemandAccept />}
                    {/* Page Filler */}
                    <div data-testid="demand-farm-show" className="w-full flex flex-col items-center gap-8">
                        {/* Flex Column */}
                        <div className="w-full p-2 max-w-[65rem] self-center flex flex-col gap-3">
                            {/* Navigation */}
                            <Row className="mt-2">
                                <Col md={12} className="head-bar-nav">
                                    <Link to={routesPaths.HOME} className="head-menu">
                                        <span>Home</span>
                                    </Link>
                                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                                    <Link to={routesPaths.CATALOG} className="head-menu">
                                        <span>Farmers Produce</span>
                                    </Link>
                                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                                    <span className="tag-current-bar">Farm Details</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="title-content mb-3">
                                        Farm Details
                                    </div>
                                    <div className="flex flex-col rounded-lg shadow bg-card-white-farm-show">
                                        <div className="title-content-farm">
                                            {reduxFarm.farm.name}
                                        </div>
                                        {/* <div className="flex items-center">
                                            <span className="sub-content-farm mr-2 font-medium">
                                                Rating :
                                            </span>
                                            <ProfileRating />
                                            <span className="sub-content-farm ml-3">
                                                5 ratings
                                            </span>
                                            <span className="sub-content-farm ml-3 mr-3">
                                                /
                                            </span>
                                            <span className="sub-content-farm italic">
                                                2500 product sold
                                            </span>
                                        </div> */}
                                        <div className="flex items-center space-x-2 mt-2">
                                            <div className="bg-card-fields">
                                                <div className="text-center">
                                                    <div className="font-medium text-4xl">
                                                        {reduxFarm.farm.owner.farms_count}
                                                    </div>
                                                    <div className="text-xs">
                                                        Fields
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-card-plots">
                                                <div className="text-center">
                                                    <div className="font-medium text-4xl">
                                                        {reduxFarm.farm.plots_count}
                                                    </div>
                                                    <div className="text-xs">
                                                        Plots
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-card-certificate">
                                                <div className="text-center">
                                                    <div className="font-medium text-4xl">
                                                        {reduxFarm.farm.certificates_count}
                                                    </div>
                                                    <div className="text-xs">
                                                        Certificates
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="title-content mb-3">
                                        Documentation
                                    </div>
                                    <div className="flex flex-col rounded-lg shadow bg-card-white-farm-show overflow-y-scroll">
                                        {reduxFarm.farm.certificates && reduxFarm.farm.certificates.map((certificate, index) => (
                                            <>
                                                <label className="flex py-2 gap-2 text-sm" index={index} key={certificate.name}>
                                                    {certificate.name}

                                                    <button className="ml-auto" onClick={(e) => {
                                                        e.preventDefault();
                                                        showPdf(certificate.name);
                                                        // itemDelete(certificate)
                                                    }}>
                                                        <FontAwesomeIcon icon={faDownload} className={"text-gray-400"} />
                                                    </button>
                                                </label>
                                                <div className="w-full border-b-2">

                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="title-content">
                                    <span>Fields</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className="w-full mb-2">
                                        <MultipleMarkerMapLocation listLocation={listLocationGlobal} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="title-content">
                                    <span>Plots</span>
                                </Col>
                            </Row>
                            {(plotsIsPublish == false) ? 
                                <>
                                    <Row>
                                        <Col md={12}>Plots not published.</Col>
                                    </Row>
                                </>
                                : ""
                            }
                            <Row>
                                <Col md={12}>
                                    <section
                                        className="w-full rounded-lg grid gap-4 justify-items-center grid-cols-1 productPlotWidgetSmall:grid-cols-2 productPlotWidgetLarge:grid-cols-4">
                                        {reduxFarm.farm.plots && reduxFarm.farm.plots.data.map((plot, index) => (
                                            (plot.publish_status == 1) ? 
                                                <>
                                                    <div data-testid="market-demand-widget" className="cursor-pointer relative w-full h-full rounded-lg shadow-lg border-2" onClick={() => navigate(`/plots/${plot.id}`)}>
                                                        <section className="absolute bg-black/50 text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
                                                            <label className="cursor-pointer font-in-box-market">{plot.name}</label>
                                                            <div className="flex justify-between font-in-box-sub-market">
                                                                <label className="cursor-pointer hidden marketDemandWidget:flex">Crop : </label>
                                                                <label className="cursor-pointer">{plot.crop_name}</label>
                                                            </div>
                                                            <div className="flex justify-between font-in-box-sub-market">
                                                                <label className="cursor-pointer hidden marketDemandWidget:flex">Capacity : </label>
                                                                <label className="cursor-pointer">{plot.harvest_expected_quantity} {plot.unit}</label>
                                                            </div>
                                                        </section>
                                                        <img
                                                            src={plot.photo_default}
                                                            alt="plot photo"
                                                            className="object-cover w-full h-full aspect-square rounded-lg"
                                                        />
                                                        <section className="absolute bg-black/50 text-white flex flex-col left-0 bottom-0 rounded-b-lg w-full h-fit px-2 py-2 box-img-card">
                                                            <div className="flex justify-between font-in-box-sub-market">
                                                                <label className="cursor-pointer font-in-box-market">Photo taken on: {plot.photo && plot.photo.date_client_format}</label>
                                                                <label className="cursor-pointer font-in-box-sub-market">{plot.photo && plot.photo.description}</label>
                                                            </div>

                                                        </section>
                                                    </div>
                                                </>
                                            : ""
                                        ))}
                                    </section>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        // </AccountLayout>
    );
};

export default FarmShow;


