import { useNavigate } from "react-router-dom";
import {useState, useEffect, useContext} from "react";

import AccountLayout from "pages/AccountLayout";
import { routesPaths } from "constants/routes.constants";
import MessageEmpty from "../ui/message/empty/MessageEmpty"

// import Order from "./Order";
// import {catalogDestroy} from "../../../features/catalog/catalogSlice";
// import {
//     orderDetailIndex, orderDetailMadeByCustomer,
//     orderDetailReceivableForFarmer
// } from "../../../features/order-detail/orderDetailSlice";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {orderFarmerMadeByCustomer, orderFarmerReceivableForFarmer} from "../../features/order-farmer/orderFarmerSlice";
import OrderFarmer from "./OrderFarmer";
import SnackbarContext from "_helpers/snackbar-context";

import { faSortAmountAsc,faSortDown,faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const OrderFarmerList  = () => {
  const navigate = useNavigate();
  const snackbarCtx = useContext(SnackbarContext);

  // const addHandler = () => {
  //   navigate(routesPaths.CATALOG);
  // };

  const dummyOrders = [
    { id: 1, photo: "/assets/images/products/BananasPhoto.svg", price: 50, currency: "$", status: "Ongoing", quantity: 150, unit: "KG", frequency: "Week" },
    { id: 2, photo: "/assets/images/products/OrangesPhoto.svg", price: 15, currency: "$", status: "Ongoing", quantity: 50, unit: "KG", frequency: "Week" },
    { id: 2, photo: "/assets/images/products/GrapesPhoto.svg", price: 30, currency: "$", status: "Ongoing", quantity: 100, unit: "LBS", frequency: "Month" },
    { id: 3, photo: "", price: 0, currency: "", status: "", quantity: 0, unit: "", frequency: "Loading..." },
  ];

  const orders = dummyOrders;

    const params = useParams();
    const dispatch = useDispatch();
    const [typeOrder, setTypeOrder] = useState("Incoming");
    // const reduxOrderDetail = useSelector((state) => state.orderDetail);
    const reduxOrderFarmer = useSelector((state) => state.orderFarmer);

   //  const loadOrderDetails = () => {
   //
   //     dispatch(
   //         orderDetailMadeByCustomer()
   //     ).then(function (response) {
   //         if (response.error && response.error.message == "Rejected") {
   //             alert(response.payload);
   //         } else {
   //             console.log(response.payload.message);
   //         }
   //     });
   //     console.log(" loading order details");
   // }
    const [orderList, setOrderList] = useState({
        data: []
    })
    
    const [itemsPerPage, setItemsPerPage] = useState(10); // Initial value, you can change this
    const [currentPage, setCurrentPage] = useState(1);
    const loadOrderFarmers = (pageNumber,itemsPerPage) => {
       dispatch(
           orderFarmerReceivableForFarmer({
              limit: itemsPerPage,
              page: pageNumber,
        })
       ).then(function (response) {
           if (response.error && response.error.message === "Rejected") {
             snackbarCtx.displayMsg(response.payload, "failure");
              // alert(response.payload);
           } else {
              // snackbarCtx.displayMsg(response.payload.message, "success")

              // console.log(response.payload.message);
              let orders = response.payload.data.order_farmers;
              // Filter orders based on your conditions
              // let filteredOrders = orders.filter(orderFarmer => {
              //   return (
              //       orderFarmer.detail.product !== null &&
              //       orderFarmer.order.payment_id !== null
              //   );
              // });
              setOrderList(orders);
           }
       });
    }

    const handlePageChange = (newPageNumber, newItemsPerPage) => {
      setCurrentPage(newPageNumber);
      setItemsPerPage(newItemsPerPage); // Assuming you have an itemsPerPage state variable
    
      // Now you can call your data loading function with the updated values
      loadOrderFarmers(newPageNumber, newItemsPerPage);
    };
    
    const handleChangePage = (event, newPageNumber) => {
      handlePageChange(newPageNumber, itemsPerPage); // Call the common function
    };
    
    const handleLimitPage = (newItemsPerPage) => {
      setCurrentPage(1); // Reset the current page to 1
      setItemsPerPage(newItemsPerPage); // Update the itemsPerPage state variable
      loadOrderFarmers(1, newItemsPerPage);
    };

    useEffect(() => {
      setTimeout(() => {
        loadOrderFarmers()
      }, 500);
        // loadCategories()
    }, []);

  return (
    <AccountLayout>
      <div data-testid="order-list" className="flex flex-col items-start gap-6 p-4 mb-5">
        <h1 className="text-2xl text-left font-bold" style={{color: "#395B50"}}>Incoming Orders</h1>
        <div className="ml-1 mb-2 mt-2">
          <section className="flex gap-8">
            <label
              className={
                typeOrder === "Incoming"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => {
                setTypeOrder("Incoming");
              }}
            >
              Incoming Orders
            </label>
            <label
              className={
                typeOrder === "Advanced"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => {
                setTypeOrder("Advanced")
                navigate(routesPaths.MANAGE_ADVANCE_BOOKING);
              }}
            >
              Advanced Booking
            </label>
            <label
              className={
                typeOrder === "Auction"
                  ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                  : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
              }
              onClick={() => {
                setTypeOrder("Auction")
                navigate(routesPaths.MANAGE_AUCTION_BIDDING);
              }}
            >
              Auction / Bidding
            </label>
          </section>
        </div>
        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
          <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{borderBottomWidth: "1px", width:"100%"}}>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Sort  
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom mr-2">
                <option value="DATE" selected>
                  Date
                </option>
                <option value="DATE" selected>
                  Price
                </option>
              </select>
              <FontAwesomeIcon icon={faSortAmountAsc} />
            </div>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Filter  
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom ">
                <option value="DATE" selected>
                  Farm
                </option>
                <option value="DATE" selected>
                  Product
                </option>
              </select>
            </div>
            
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-fit">
              <form className="tablet:flex justify-start w-full desktop:w-fit items-center">   
                  <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 mr-5 dark:text-white laptop:hidden desktop:hidden">Search</label>
                  <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      </div>
                      <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" />
                  </div>
              </form>
            </div>
            
          </div>
          {/*<button type="button" className="borderedButton h-[9rem]" onClick={addHandler}>*/}
            {/*<label className="cursor-pointer text-2xl">Add New Order</label>*/}
          {/*</button>*/}
          <div className="p-4 w-full max-w">
            <div className="hidden laptop:flex flex-row items-center justify-center pb-3" style={{borderBottomWidth: "1px"}}>
              <div className="basis-1/4 flex items-center justify-center font-medium">Orders</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Price</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
              <div className="basis-1/4 flex items-center justify-center font-medium"></div>
            </div>
            {reduxOrderFarmer.order_farmers.data && reduxOrderFarmer.order_farmers.data.map((orderFarmer, index) => (
              // orderFarmer.detail.product && (orderFarmer.order.is_from_booking === 0 && orderFarmer.order.payment_id !== null) || (orderFarmer.order.is_from_booking === 1)  &&  
              <OrderFarmer orderFarmer={orderFarmer} key={index} />
            ))}
          </div>
        </div>
        <div className="grid md:grid-cols-2 sm:grid-rows-2 w-full">
            <div className="flex items-center tablet:flex lg:justify-start justify-center w-full ">
                <span>
                <select className="select-custom" onChange={(e) => handleLimitPage(e.target.value)}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={1000}>All</option>
                </select> 
                </span>
                <span className="ml-2 text-sm">
                    <label>entries per page</label>
                </span>
            </div>
            <div className="flex lg:justify-end justify-center">
                <Stack spacing={2} >
                <Pagination count={orderList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage}/>
                </Stack>
            </div>
          </div>
          <MessageEmpty loading={reduxOrderFarmer.loading} length={reduxOrderFarmer.order_farmers.data.length} message={reduxOrderFarmer.data.message} />

      </div>
    </AccountLayout>
  );
};

export default OrderFarmerList;
