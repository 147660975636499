const PopupLayoutConfirmation = ({ children }) => {
  return (
    <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center ">
      <section
        data-testid="popup-layout"
        className="absolute bg-white w-[30rem] flex flex-col rounded-xl shadow   pb-4"
      >
        <div className="bg-white w-full flex flex-col items-center rounded-t-xl px-8 py-4">
          <img src="/assets/images/QuestionMarkIcon.png" alt="checkmark icon" className="w-[10rem] h-[10rem]" />
        </div>
        <div className="self-center w-3/4 flex flex-col px-8 gap-8 text-center items-center">{children}</div>
      </section>
    </div>
  );
};

export default PopupLayoutConfirmation;
