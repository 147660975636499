import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import md5 from 'md5';
import { advanceBookingDetail, advanceBookingPayDeposit, advanceBookingReject, generateInvoiceNumber } from "../../../features/advance-booking/advanceBookingSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Modal, Row } from "react-bootstrap";
import DepositProgress from "./DepositProgress";
import iconTrashBlack from "images/iconTrashBlack.png";
import Footer from "components/footer/footer";
import FooterStyles from "components/footer/footer.module.scss";
import clsx from "clsx";
// import { useSearchParams } from "react-router-dom";
// import {Route, Link, Routes, useParams} from 'react-router-dom';

const AdvanceBookingDeposit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const snackbarCtx = useContext(SnackbarContext);
  const [bookingPlot, setBookingPlot] = useState(null);
  const [vcode, setVCode] = useState(null);
  const id = params.booking_id;
  const { currentUser } = useSelector((state) => state.auth);
  const [paymentType, setPaymentType] = useState(null);
  // const { advanceBooking } = useSelector((state) => state);
  // const bookingPlot = advanceBooking.data.data.booking_plot;
  // console.log("bookingplot", bookingPlot);

  const payDeposit = () => {
    if (paymentType == null) {
      alert("Please select payment method.");
    } else {
      if (window.confirm("Are you sure want to pay deposit this booking?")) {
        //online payment
        if (paymentType == 1) {
          document.getElementById("razerForm").submit();
        } else {
          dispatch(generateInvoiceNumber({
            id: id,
          })).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
              snackbarCtx.displayMsg(response.payload, "failure");
            } else {
              let result = response.payload.data.booking_plot;
              navigate(`/manage-advance-booking/deposit-offline/${id}`);
              snackbarCtx.displayMsg(response.payload.message, "success");
            }
          });
        }

      }
    }
  };

  const cancelBooking = () => {
    if (window.confirm("Are you sure want to cancel this booking?")) {
      dispatch(advanceBookingReject({
        id: id,
      })).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          let result = response.payload.data.booking_plot;
          navigate(`/manage-advance-booking/detail/${result.id}`);
          snackbarCtx.displayMsg(response.payload.message, "success");
        }
      });
    }
  }

  const loadDetail = () => {
    dispatch(
      advanceBookingDetail({
        id: id,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let result = response.payload.data.booking_plot;
        // console.log(result);
        setBookingPlot(result);
        setVCode(md5(result.total_price + process.env.REACT_APP_RAZER_MERCHANT_ID + "DEPOSIT_" + result.booking_number + process.env.REACT_APP_RAZER_VERIFY_KEY));
      }
    });
  };

  useEffect(() => {
    loadDetail();
  }, []);

  return (
    <Row className="container mb-5 justify-center">
      <Col lg={3}></Col>
      <Col lg={6}>
        <div className="flex flex-col items-center mt-4">
          <div style={{ minWidth: "80%" }} >
            <DepositProgress />
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg ">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="w-full max-w ">
                  <div className="hidden tablet:flex flex-row items-center justify-center pb-3" style={{ borderBottomWidth: "1px" }}>
                    <div className="desktop:basis-1/3 laptop:basis-1/3 tablet:basis-1/2 flex items-center justify-center font-medium ">Booking List</div>
                    <div className="desktop:basis-1/4 basis-1/2  tablet:basis-1/3 flex items-center justify-center font-medium">Actual Quantity</div>
                    <div className="desktop:basis-1/4  basis-1/3 tablet:basis-1/4 flex items-center justify-center font-medium">Price</div>
                    <div className="basis-1/5 flex items-center justify-center font-medium"></div>
                  </div>
                  <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full">
                    <label className="tablet:hidden basis-1/3 flex items-center justify-center text-lg">Booking List</label>
                    <div className="w-full flex flex-row p-2 items-center justify-center">
                      <section className="desktop:basis-1/3 tablet:basis-1/2 flex items-center gap-x-6">
                        <img
                          // src={orderDetail.product.photo ? info.photo : }
                          src={(bookingPlot) ? bookingPlot.plot ? bookingPlot.plot.farm ? bookingPlot.plot.farm.photo_default : "/assets/images/products/WheatIcon.svg" : "/assets/images/products/WheatIcon.svg" : "/assets/images/products/WheatIcon.svg"}
                          // alt={bookingPlot.plot.farm ? bookingPlot.plot.farm.name : "-"}
                          className="orderLarge:inline min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] object-cover aspect-square rounded-full"
                        />
                        <div className="flex flex-col basis-1/2">
                          {/* <label className="text-2md font-semibold">Plot {bookingPlot.booking_number} </label> */}
                          <p className="fs-10-black"><b>{(bookingPlot) ? bookingPlot.plot ? bookingPlot.plot.name : '' : ''}</b></p>
                          <p className="fs-10-black"><b>{(bookingPlot) ? bookingPlot.plot ? bookingPlot.plot.crop.name : '' : ''}</b></p>
                          <p className="fs-10-black font-light"><b>Booking Quantity:</b></p>
                          <p className="fs-10-black"><b>{(bookingPlot) ? bookingPlot.quantity : ''} {(bookingPlot) ? bookingPlot.quantity_unit : ''}</b></p>
                          <div className="tablet:hidden">
                            <p className="fs-10-black font-light"><b>Actual Quantity:</b></p>
                            <p className="fs-10-black"><b>{(bookingPlot) ? bookingPlot.plot.harvest_expected_quantity : ''} {(bookingPlot) ? bookingPlot.plot.unit : ''}</b></p> 
                            <p className="fs-10-black font-light"><b>Total Price:</b></p>
                            <p className="fs-10-black"><b> RM {(bookingPlot) ? bookingPlot.total_price : ''}</b></p> 
                          </div>
                        </div>
                      </section>
                      <section className="laptop:basis-1/3 tablet:basis-1/4 flex gap-4 items-center justify-center productSmall:flex-col">
                        <div className="flex flex-col laptop:gap-3 items-center ">
                          <label className="hidden tablet:flex text-center">
                            {(bookingPlot) ? bookingPlot.plot.harvest_expected_quantity : ''} {(bookingPlot) ? bookingPlot.plot.unit : ''}
                          </label>
                        </div>
                      </section>
                      <section className="desktop:basis-1/4 laptop:basis-1/2 tablet:basis-1/4 flex gap-4 items-center justify-center hidden tablet:flex">
                        <div className="flex flex-col gap-3 items-center ">
                          {/* <label className="lg:hidden text-2md font-semibold">{(bookingPlot) ? bookingPlot.booking_number : ''} </label>
                          <label className="lg:hidden">{(bookingPlot) ? `${bookingPlot.quantity} ${bookingPlot.quantity_unit}` : ''}</label> */}
                          <label className=" text-center">
                            RM {(bookingPlot) ? bookingPlot.total_price : ''}
                          </label>
                        </div>
                      </section>
                      <section className="basis-1/5 flex gap-4 items-center justify-center  hidden tablet:flex">
                        <div className="flex flex-col gap-3 items-center ">
                          {/* <label className="lg:hidden text-2md font-semibold">{(bookingPlot) ? bookingPlot.booking_number : ''} </label>
                          <label className="lg:hidden">{(bookingPlot) ? `${bookingPlot.quantity} ${bookingPlot.quantity_unit}` : ''}</label> */}
                          <label className=" text-center">
                            <div style={{ cursor: "pointer" }}>
                              <img width={25} src={iconTrashBlack} onClick={(event) => {
                                event.preventDefault();
                                cancelBooking();
                              }} alt=""
                              />
                            </div>
                          </label>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col className={clsx(FooterStyles.menuMobile)}>
        <div className="hidden laptop:flex flex-col items-center mt-4" >
          <div style={{ minWidth: "80%", height: "17px" }} >
            {/* <DepositProgress/> */}
          </div>
        </div>
        <Row className="mt-3">
          <Col md={12}>
            <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
              <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{ borderBottomWidth: "1px", width: "100%" }}>
                <div className="lg:hidden flex flex-col" style={{ borderBottomWidth: "1px", width: "100%" }}>
                  <label className="basis-1/3 flex items-center justify-center text-lg">Booking Order</label>
                  <label className="mt-3 flex">
                    <span className="text-sm font-semibold mr-2">Booking Quantity: </span>
                    <span className="">{(bookingPlot) ? bookingPlot.quantity : ''} {(bookingPlot) ? bookingPlot.quantity_unit : ''}</span>
                  </label>
                  <label className="mt-1 mb-1">
                    <span className="text-sm font-semibold mr-2">Price: </span>
                    <span className="">RM {(bookingPlot) ? bookingPlot.total_price : ''}</span>
                  </label>
                </div>
                <div className="p-4 w-full max-w">
                  <p><b>Total Deposit</b> <span className="float-right">RM {(bookingPlot) ? bookingPlot.deposit_amount : ''}</span></p>
                  {/* <input
                    type="radio"
                    name="payment_type"
                    value={0}
                    checked={paymentType == 0}
                    onChange={({ target }) => { setPaymentType(target.value); }}
                  /> Offline Payment
                  <br />
                  <input
                    type="radio"
                    name="payment_type"
                    value={1}
                    checked={paymentType == 1}
                    onChange={({ target }) => { setPaymentType(target.value); }}
                  /> Online Payment */}
                  <select className="input-search" defaultValue={null} value={paymentType} onChange={({ target }) => { setPaymentType(target.value); }}>
                    <option key={"paymentMethod-1"} value={null}>
                      Select Payment Method
                    </option>
                    <option key={"paymentMethod0"} value={0}>Offline</option>
                    <option key={"paymentMethod1"} value={1}>Online</option>
                  </select>
                  <br />
                  <br />
                  <button type="button" className="btn-green" onClick={(event) => {
                    event.preventDefault();
                    payDeposit();
                  }}>
                    Pay Deposit
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Footer />
      </Col>
      <form id="razerForm" action={process.env.REACT_APP_RAZER_PAYMENT_URL} method="POST">
        <input type="hidden" name="merchant_id" value={process.env.REACT_APP_RAZER_MERCHANT_ID} />
        <input type="hidden" name="amount" value={bookingPlot?.deposit_amount} />
        <input type="hidden" name="orderid" value={"DEPOSIT_" + bookingPlot?.booking_number} />
        <input type="hidden" name="bill_name" value={bookingPlot?.buyer.name} />
        <input type="hidden" name="bill_email" value={bookingPlot?.buyer.email} />
        <input type="hidden" name="bill_mobile" value={bookingPlot?.buyer.phone_number} />
        <input type="hidden" name="bill_desc" value={bookingPlot?.booking_number} />
        {/* <input type="hidden" name="returnurl" value={process.env.REACT_APP_FRONTEND + "/manage-advance-booking/deposit/" + bookingPlot?.id} /> */}
        <input type="hidden" name="returnurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-deposit-return"} />
        <input type="hidden" name="callbackurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-deposit-callback"} />
        <input type="hidden" name="vcode" value={vcode} />
        {/* <input type="submit"/> */}
      </form>

    </Row>
  );
};

export default AdvanceBookingDeposit;
