import { useState, useEffect, useContext } from "react";

import profileIcon from "../../images/ProfileIcon.png";
import uploadIcon from "../../images/UploadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserInfo,
  setCurrentUser,
  authUpdate,
  authUpdateCompany,
  setCurrentCompany,
  authUpdatePhoto,
  authUpdateCompanyPhoto,
  authGetCompany
} from "../../features/auth/authSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { fileDelete } from "features/file/fileSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountCompany = () => {
  const { currentUser, updating, currentCompany } = useSelector((state) => state.auth);
  const [companyID, setCompanyID] = useState(currentCompany?.identity_id);
  const [companyName, setCompanyName] = useState(currentCompany?.name);
  const [regNumber, setRegNumber] = useState(currentCompany?.registration_number);
  const [companyAddress, setCompanyAddress] = useState(currentCompany?.billing_address);
  const [contactNumber, setContactNumber] = useState(currentCompany?.business_contact_number);
  const [inputElement, setInputElement] = useState("");

  const dispatch = useDispatch();

  const snackbarCtx = useContext(SnackbarContext);

  const submitHandler = (event) => {
    dispatch(
      authUpdateCompany({
        identity_id: companyID,
        name: companyName,
        registration_number: regNumber,
        billing_address: companyAddress,
        business_contact_number: contactNumber,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        //alert(response.payload.message);
      }
    });

    event.preventDefault();
  };

  const onFileChange = (event) => {
    let selectedFile = event.target.files[0];

    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);
    formData.append("store", "single");
    formData.append("model", "company");
    formData.append("type", "photo");
    formData.append("company_id", currentCompany.id);

    dispatch(authUpdateCompanyPhoto(formData)).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        alert(response.payload);
      } else {
        // alert(response.payload.message);
      }
    });
  };

  const itemDelete = (photo) => {
    if (window.confirm("Are you sure you want to delete this Photo?")) {
      dispatch(
        fileDelete({
          file_id: photo.id,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
        } else {
          let message = response.payload.message;
          dispatch(authGetCompany())
        }
      });
    }
    else {
    }
  }

  const companyPhotoSrc = typeof currentCompany.photo === 'object' ? currentCompany.photo.src
    : currentCompany.photo;

  const deletablePhoto = companyPhotoSrc.indexOf("ProfileIcon.png") > 0 ? false : true; //typeof currentCompany.photo === 'object' ? true : false;


  useEffect(() => {
    reloadCompany();
  }, []);

  const reloadCompany = () => {
    dispatch(getCurrentUserInfo());

    setCompanyID(currentCompany?.identity_id);
    setCompanyName(currentCompany?.name);
    setRegNumber(currentCompany?.registration_number);
    setCompanyAddress(currentCompany?.billing_address);
    setContactNumber(currentCompany?.business_contact_number);
  }

  return (
    <form className="self-start flex flex-col gap-4 col-md-8 col-lg-5" onSubmit={submitHandler}>
      <div className="flex items-start ">
        <img
          src={companyPhotoSrc}
          alt="profile"
          onClick={() => currentUser.is_company_owner ? inputElement.click() : null}
          className="bg-white w-25 h-25 cursor-pointer object-cover aspect-square border-[.01rem] border-green-400 rounded-md"
        />
        <input accept="image/png, image/jpeg"  className={"hidden"} type="file" onChange={onFileChange}
                         ref={(input) => setInputElement(input)}/>
        {/* {currentUser.is_company_owner ? (
              <button type="button" className="w-3/5 space-x-4 hover:bg-green-500 hover:text-white ml-3">
                  <img src={uploadIcon} alt="upload button" className="w-6 h-6" onClick={() => inputElement.click()}/>
                  <label className="cursor-pointer text-sm" onClick={() => {
                      inputElement.click()
                  }}>
                      Upload Image
                  </label>
                  <input accept="image/png, image/jpeg"  className={"hidden"} type="file" onChange={onFileChange}
                         ref={(input) => setInputElement(input)}/>

              </button>
        ) : null}

        <button className={`ml-3 ${deletablePhoto ? '' : 'hidden'} `} onClick={(e) => {
          e.preventDefault();
          itemDelete(currentCompany.photo)
        }}>
          <FontAwesomeIcon icon={faTrashCan} className={"text-red-400"} />
        </button> */}
      </div>

      <div className="flex flex-col space-y-6">
        <section className="flex flex-col space-y-1">
          <label>Company ID:</label>
          <input
            type="text"
            className={"bg-gray-300 input-search"}
            placeholder={currentCompany?.identity_id}
            value={companyID}
            onChange={({ target }) => setCompanyID(target.value)}
            disabled={true}
          />
        </section>
        <section className="flex flex-col space-y-1">
          <label>Company Name:</label>
          <input
            type="text"
            className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
            placeholder={currentCompany?.name}
            value={companyName}
            onChange={({ target }) => setCompanyName(target.value)}
            disabled={!currentUser.is_company_owner}
          />
        </section>
        <section className="flex flex-col space-y-1">
          <label>Company Registration Number:</label>
          <input
            type="text"
            className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
            placeholder={currentCompany?.registration_number}
            value={regNumber}
            onChange={({ target }) => setRegNumber(target.value)}
            disabled={!currentUser.is_company_owner}
          />
        </section>
        <section className="flex flex-col space-y-1">
          <label>Company Address (Billing Address):</label>
          <input
            type="text"
            className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
            placeholder={currentCompany?.billing_address}
            value={companyAddress}
            onChange={({ target }) => setCompanyAddress(target.value)}
            disabled={!currentUser.is_company_owner}
          />
        </section>
        <section className="flex flex-col space-y-1">
          <label>Business Contact Number:</label>
          <input
            type="text"
            className={currentUser.is_company_owner ? "input-search" : "bg-gray-300 input-search"}
            placeholder={currentCompany?.business_contact_number}
            value={contactNumber}
            onChange={({ target }) => setContactNumber(target.value)}
            disabled={!currentUser.is_company_owner}
          />
        </section>
        <div className="row">
          <div className="col-md-4">
            <button
              type="submit"
              className={currentUser.is_company_owner ? "saveButton" : "saveButton bg-gray-500 text-white"}
              disabled={!currentUser.is_company_owner}
            >
              {updating ? "Processing, please wait.." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountCompany;
