import React, { useState, useContext } from "react";
import { useEffect } from "react";
import AccountLayout from "pages/AccountLayout";
import { productStore } from "../../features/product/productSlice";
import { farmIndex } from "../../features/farm/farmSlice";
import { gradeIndex, gradeShow } from "../../features/grade/gradeSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { categoryIndex } from "../../features/category/categorySlice";
import { cropIndex, cropShow } from "../../features/crop/cropSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, InputGroup, Form } from "react-bootstrap";

const ProductCreate = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [scientificName, setScientificName] = useState("");
    const [quantityNumber, setQuantityNumber] = useState(0);
    const [quantityTarget, setQuantityTarget] = useState(0);
    const [farmPrice, setFarmPrice] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [categoryId, setCategoryId] = useState("");
    const [variety, setVariety] = useState("");
    const [includeDeliveryFee, setIncludeDeliveryFee] = useState(null);
    const [deliveryChargeType, setDeliveryChargeType] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);

    const { updating } = useSelector((state) => state.product);
    const { currentCompany } = useSelector((state) => state.auth);

    let companyPackage = currentCompany?.company_packages?.data.find(companyPackage => companyPackage.status === 1);

    const [gradeList, setGradeList] = useState({
        data: []
    });
    const [farmList, setFarmList] = useState({
        data: []
    })

    const [gradeId, setGradeId] = useState("");
    const [cropId, setCropId] = useState("");
    const [farmId, setFarmId] = useState("");

    const reduxCrop = useSelector((state) => state.crop);
    const snackbarCtx = useContext(SnackbarContext)

    const save = (event) => {
        if (parseInt(quantityNumber) < 0 || parseInt(quantityTarget) < 0) {
            snackbarCtx.displayMsg("Quantities cannot be less than zero.", "failure");
            return;
        }

        dispatch(
            productStore({
                farm_id: farmId,
                name: name,
                quantity_number: quantityNumber,
                quantity_target: quantityTarget,
                category_id: categoryId,
                scientific_name: scientificName,
                crop_id: cropId,
                selling_price: parseFloat(sellingPrice),
                delivery_charge: parseFloat(deliveryCharge),
                delivery_charge_type: deliveryChargeType
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
                //alert(response.payload);
            } else {
                let product = response.payload.data.product;

                navigate(`/products/${product.id}/edit`);
            }
        });

        event.preventDefault();
    };

    const loadFarms = () => {
        dispatch(
            farmIndex({
                page: 1,
                limit: 100
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
                //alert(response.payload);
            } else {
                let farms = response.payload.data.farms;

                setFarmList(farms);
                // navigate(`/product/${product.id}/edit`);
            }
        });
    }

    const loadGrades = () => {
        dispatch(
            gradeIndex({
                page: 1,
                limit: 100,
                farm_id: farmId
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
                //alert(response.payload);
            } else {
                let grades = response.payload.data.grades;

                setGradeId("")

                setGradeList(grades);
            }
        });
    }


    const loadCrops = (selectedCategoryId) => {
        let catId = categoryId;

        if (selectedCategoryId) {
            catId = selectedCategoryId;
        }

        dispatch(
            cropIndex({
                page: 1,
                limit: 100,
                farm_id: farmId,
                category_id: catId
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            }
        });
    }


    const loadCategories = () => {
        dispatch(
            categoryIndex({
                page: 1,
                limit: 100
            })
        ).then(function (response) {
        });
    }

    useEffect(() => {
        loadFarms()
        loadCategories()
    }, []);


    // only run if the farmId was updated
    useEffect(() => {
        if (farmId) {
            loadGrades();
            loadCrops();
        }
    }, [farmId]);

    useEffect(() => {
        setFarmPrice(0);
        setSellingPrice(0);
        setQuantityNumber(0);
    }, [categoryId, farmId, cropId])

    return (
        <AccountLayout>
            <div data-testid="product-create" className="flex flex-col h-full max-h items-start gap-6 p-4 mb-5" >
                {/* Title */}
                <h1 className="text-center text-2xl font-bold" style={{ color: "#395B50" }}>Add Product</h1>
                {/* Widget */}
                <div className="w-full max-w bg-white p-4 rounded-lg shadow min-h-[30rem]">
                    <div className="grid laptop:grid-cols-4 desktop:grid-cols-5 gap-4">
                        {/* Product Details */}
                        {/* <div className="flex flex-col items-center col-span-2">
                            <button
                                className="min-w-[12rem] w-full min-h-[12rem] h-full flex flex-col justify-center items-center bg-white rounded-md border-[.01rem] border-green-500 space-y-2"
                                onClick={() => snackbarCtx.displayMsg("Upload photos can be done after saving", "failure")}
                            >
                                <FontAwesomeIcon icon={faPlus} className={"change-icon-color-green"} />
                                <label className="cursor-pointer text-icon-color-green">Upload Photos</label>
                            </button>
                        </div> */}
                        <form className="w-full max-w place-self-center flex flex-col space-y-5 col-span-6" onSubmit={save}>

                            <section className="w-full flex items-center">
                                <label className="w-1/6">Product Name:</label>
                                <input type="text" placeholder={name} onChange={({ target }) => { setName(target.value) }} className={"w-1/3 input-search"} />
                                <div className="flex w-1/3 ml-4 items-center"><input
                                    type="checkbox"
                                    name="agree_term_condition"
                                />
                                    &nbsp;&nbsp;
                                    <label>Show in Produce</label></div>

                            </section>
                            <section className="w-full flex items-center">
                                <label className="w-1/6">Farm of Origin:</label>
                                <select className="w-1/3 input-search" defaultValue={"DEFAULT"} onChange={({ target }) => setFarmId(target.value)}>
                                    <option value="DEFAULT" disabled>
                                        Select Farm
                                    </option>
                                    {farmList.data.map((farm, index) => (
                                        <option value={farm.id} key={index}>{farm.name}</option>
                                    ))}
                                </select>
                            </section>




                            <section className="w-full flex items-center">
                                <label className="w-1/6">Crop & Variety:</label>
                                {
                                    (
                                        reduxCrop.crops && reduxCrop.crops.data.length > 0 ?
                                            (<select
                                                className="w-2/3 input-search"
                                                defaultValue={""}
                                                value={cropId}
                                                onChange={({ target }) => {

                                                    setCropId(target.value)

                                                    dispatch(cropShow({
                                                        crop_id: target.value
                                                    }));

                                                    setQuantityNumber(0)
                                                    setQuantityTarget(0)

                                                    setGradeId("")

                                                    reduxCrop.crops.data.map((crop, index) => {
                                                        if (target.value == crop.id) {
                                                            setVariety(crop.variety_name)
                                                            setCategoryId(crop.category_id)
                                                        }
                                                    })
                                                }} >
                                                <option value="">
                                                    Select Crop
                                                </option>
                                                {reduxCrop.crops.data.map((crop, index) => {
                                                    return (
                                                        <option value={crop.id} key={index}>{crop.name}</option>
                                                    )
                                                })}
                                            </select>)
                                            :
                                            <span>No Crops available.</span>
                                    )
                                }
                            </section>

                            {/* <section className="flex justify-between items-center">
                                <label className="w-1/3">Crop Variety:</label>
                                <input
                                    className="w-2/3 input-search bg-slate-300"
                                    type="number"
                                    min="0"
                                    disabled
                                    placeholder={variety}
                                    value={variety} />
                            </section> */}
                            <section className="w-full flex items-center">
                                <label className="w-1/6">Grades: </label>
                                {reduxCrop.crop?.grades?.data?.length > 0 ? (
                                    <select
                                        className="w-2/3 input-search"
                                        // defaultValue={gradeId}
                                        value={gradeId}
                                        onChange={({ target }) => {
                                            setGradeId(target.value);

                                            reduxCrop.crop.grades.data.map((grade, index) => {
                                                if (target.value == grade.id) {
                                                    setQuantityNumber(grade.quantity)
                                                    setQuantityTarget(grade.quantity)
                                                    setFarmPrice(grade.price_formatted)
                                                    setSellingPrice((grade.selling_price) ? grade.selling_price : grade.price_formatted)
                                                }
                                            })
                                        }}
                                    >
                                        <option value="">
                                            Select Grade
                                        </option>

                                        {/*<option value="">Select Grade</option>*/}
                                        {reduxCrop.crop?.grades?.data?.map((grade, index) => {
                                            return (
                                                <option value={grade.id} key={index}>
                                                    {grade.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                ) : (
                                    <span>No grades available.</span>
                                )}
                            </section>


                            {/*<section className="flex justify-between items-center hidden ">*/}
                            {/*<label>Farm Grades:</label>*/}
                            {/*{*/}
                            {/*(*/}
                            {/*gradeList.data.length > 0 ?*/}
                            {/*(<select*/}
                            {/*className="w-[13rem]"*/}
                            {/*defaultValue={""}*/}
                            {/*value={gradeId}*/}
                            {/*onChange={({target}) => setGradeId(target.value)} >*/}
                            {/*<option value="">*/}
                            {/*Select Grade*/}
                            {/*</option>*/}
                            {/*{gradeList.data.map((grade, index) => {*/}
                            {/*return (*/}
                            {/*<option value={grade.id} key={index}>{grade.name}</option>*/}
                            {/*)*/}
                            {/*})}*/}
                            {/*</select>)*/}
                            {/*:*/}
                            {/*<span>No grades available.</span>*/}
                            {/*)*/}
                            {/*}*/}
                            {/*</section>*/}





                            {/*{gradeList.map((grade, index) => (*/}
                            {/*<section className="flex justify-between items-center" key={index}>*/}
                            {/*<label>Grade:</label>*/}
                            {/*<section className="flex gap-2">*/}

                            {/*/!*<img*!/*/}
                            {/*/!*src="/assets/images/PlusIcon.svg"*!/*/}
                            {/*/!*alt="plus icon"*!/*/}
                            {/*/!*className="cursor-pointer"*!/*/}
                            {/*/!*onClick={() => setGradeId(grade.id)} />*!/*/}


                            {/*<select*/}
                            {/*className="w-[13rem]"*/}
                            {/*defaultValue={"DEFAULT"}*/}
                            {/*onClick={() => setGradeId(grade.id)} >*/}
                            {/*<option value="DEFAULT" disabled>*/}
                            {/*Select Grade*/}
                            {/*</option>*/}
                            {/*{selectableGrades.map((gradeOption, index) => (*/}
                            {/*<option value={gradeOption.name} key={index}>{gradeOption.name}</option>*/}
                            {/*))}*/}
                            {/*</select>*/}
                            {/*</section>*/}
                            {/*</section>*/}
                            {/*))}*/}

                            <section className="w-full flex items-center">
                                {/*<label>Quantity:</label>*/}
                                <label className="w-1/6">Available Quantity:</label>
                                <input className="w-1/3 input-search" type="number" placeholder={quantityNumber} min="0" onChange={({ target }) => setQuantityNumber(target.value)} />
                            </section>
                            {/* <section className="w-full flex items-center">
                                <label className="w-1/6">Farm Price:</label>
                                <div className="flex w-2/3 items-center">
                                    <label className="w-1/6 text-center">
                                        RM
                                    </label>
                                    <input
                                        className="w-1/3 input-search"
                                        type="number"
                                        min="0"
                                        placeholder={farmPrice}
                                        onChange={({ target }) => { setFarmPrice(target.value || 0.00); setSellingPrice((parseFloat(target.value) * parseFloat(companyPackage?.service_charge) / 100) + parseFloat(target.value) || 0.00) }}
                                    />
                                </div>
                            </section>
                            <section className="w-full flex justify-between items-center">
                                <label className="w-1/6">Selling Price:</label>
                                <div className="flex w-1/3 justify-start items-center">
                                    <label className="w-1/6 text-center">
                                        RM
                                    </label>
                                    <input
                                        disabled
                                        className="bg-slate-300 w-1/3 input-search"
                                        type="number"
                                        min="0"
                                        placeholder={sellingPrice}
                                    />
                                </div>
                            </section> */}
                            <label>Include Delivery Charges?</label>
                            <label className="text-green italic" style={{ fontSize: "15px" }}>*If you choose to include delivery charges you will be required to pay for delivery fee</label>
                            <Row className="mt-2 mb-2">
                                <Col md={3} style={{ width: '3cm' }}>
                                    <input
                                        type="radio"
                                        name="include_delivery_fee"
                                        value={1}
                                        checked={includeDeliveryFee == 1}
                                        onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                                    /> Yes
                                </Col>
                                <Col md={3} style={{ width: '3cm' }}>
                                    <input
                                        type="radio"
                                        name="include_delivery_fee"
                                        value={0}
                                        checked={includeDeliveryFee == 0}
                                        onChange={({ target }) => { setIncludeDeliveryFee(target.value); }}
                                    /> No
                                </Col>
                            </Row>
                            {includeDeliveryFee == 0 && (
                                <>
                                    <label>Delivery Charge:</label>
                                    <Row className="mt-2 mb-2">
                                        <Col md={6} xs={7}>
                                            <InputGroup>
                                                {(deliveryChargeType != 1) && (
                                                    <>
                                                        <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge - 1)}>-</div></InputGroup.Text>
                                                        <Form.Control
                                                            type="number"
                                                            min="0"
                                                            placeholder=""
                                                            className="text-center text-green input-group-plot"
                                                            value={deliveryCharge}
                                                            onChange={({ target }) => setDeliveryCharge(parseInt(target.value))}
                                                        />
                                                    </>
                                                )}
                                                {(deliveryChargeType == 1) && (
                                                    <>
                                                        <InputGroup.Text id="basic-addon1">RM</InputGroup.Text>
                                                        <Form.Control
                                                            type="number"
                                                            min="0"
                                                            placeholder=""
                                                            className="input-group-custom form-control"
                                                            value={deliveryCharge}
                                                            onChange={({ target }) => setDeliveryCharge(parseInt(target.value))}
                                                        />
                                                    </>
                                                )}
                                                {(deliveryChargeType != 1) && (
                                                    <>
                                                        <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}><div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => setDeliveryCharge(deliveryCharge + 1)}>+</div></InputGroup.Text>
                                                    </>
                                                )}
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} xs={5}>
                                            <select className="input-search text-green" defaultValue={"DEFAULT"} value={deliveryChargeType} onChange={({ target }) => setDeliveryChargeType(target.value)}>
                                                <option value="DEFAULT">
                                                    Select Type..
                                                </option>
                                                <option value="1">Fix Amount</option>
                                                <option value="2">%</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <section className="flex justify-between items-center hidden">
                                <label className="w-1/3">Target Quantity:</label>
                                <input className="w-2/3 input-search" type="number" placeholder={quantityTarget} min="0" onChange={({ target }) => setQuantityTarget(target.value)} />
                            </section>
                            {/*<button type="submit" className="saveButton">*/}
                            {/*Add To Catalog*/}
                            {/*</button>*/}
                            <div className="flex">
                                <button type="submit" className="saveButton rounded-md w-1/6">
                                    {updating ? "Processing, please wait.." : "Save"}

                                </button>
                                <button type="button" className="btn-red w-1/6 ml-4 rounded-md">
                                    Unpublish
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AccountLayout>
    );
};

export default ProductCreate;
