import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserInfo, authRoleActiveChange } from "../../features/auth/authSlice";
import editUserIcon from "./../../images/EditUserIcon.png";
import profileIcon from "../../images/ProfileIcon.png";
import ProfileRating from "./ProfileRating";

const ProfileInfo = () => {
  const dispatch = useDispatch();

  const { currentUser, token, currentCompany } = useSelector((state) => state.auth);
  const role = currentUser.role_name.charAt(0).toUpperCase() + currentUser.role_name.slice(1);
  const setRoleActiveChange = (roleName) => {
    dispatch(
      authRoleActiveChange({
        role_name: roleName,
      })
    ).then(function () {
      window.location = "/account/edit";
    });
  };
  useEffect(() => {
    dispatch(getCurrentUserInfo());
  }, []);

  const userPhotoSrc = typeof currentUser.photo === 'object' ? currentUser.photo.src
    : currentUser.photo;

  return (
    <div className="flex items-center gap-x-4">
      <img
        src={userPhotoSrc}
        alt="profile icon"
        className="bg-white border-[.01rem] border-buttonBorder rounded-full w-16 h-16 object-cover aspect-square"
      />
      <div className="flex flex-col" style={{ width: "100%" }}>
        <div className="flex flex-wrap">
          <p className="text-basis font-semibold mr-2">{currentUser.first_name}</p>
          <p className="text-basis font-semibold">{currentUser.last_name}</p>
        </div>
        <p className="text-xs text-slate-500 pb-1">{currentCompany?.billing_address_short ? currentCompany.billing_address_short : "Address"}</p>

        {/**/}
        {/*<p>Name: {currentUser.name}</p>*/}
        {/*<p>Surname</p>*/}
        {/*<p>Address</p>*/}
        {/*<p>Farmer</p>*/}
        <div className="flex gap-x-4 justify-between">
          <div className="flex flex-col">
            <p style={{ color: "#00AF54" }} className="text-sm font-semibold">{currentUser.role_name ? role : "Role"} </p>
            {/* <ProfileRating></ProfileRating> */}
          </div>
          {currentUser && currentUser.role_name === "both" && currentUser.role_active === "farmer" && (
            <img
              src={editUserIcon}
              className="w-8 h-8 cursor-pointer"
              onClick={() => {
                setRoleActiveChange("customer");
              }}
            />
          )}
          {currentUser && currentUser.role_name === "both" && currentUser.role_active === "customer" && (
            <img
              src={editUserIcon}
              className="w-8 h-8 cursor-pointer"
              onClick={() => {
                setRoleActiveChange("farmer");
              }}
            />
            // <div
            //   className="unborderedButton"
            //   style={{backgroundColor:"white", color:"black"}}
            //   onClick={() => {
            //     setRoleActiveChange("farmer");
            //   }}
            // >
            //   View as Farmer
            // </div>
          )}

        </div>
      </div>

    </div>
  );
};

export default ProfileInfo;
