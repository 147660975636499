import clsx from "clsx";
import { Col, Row } from "react-bootstrap";
import CatalogControls from "./CatalogControls";
import ProductWidget from "./ProductWidget";
import PlotWidget from "./PlotWidget";
import { Pagination, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross } from "@fortawesome/free-solid-svg-icons";
const PlotPageWidget = ({ search, products, plots, searchByHandler, inputHandler, showSideBar, searchPlotByHandler, handleChangePage }) => {
  // console.log('products', products);
  // console.log('plots', plots);
  return (
    <>
      <div className="ml-1 mb-3">
        <section className="flex gap-8" style={{ fontSize: 15, justifyContent: "right" }}>
          <label
            className={
              search.searchPlotBy === "advanced"
                ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
            }
            onClick={() => searchPlotByHandler("advanced")}
          >
            Advance Booking
          </label>
          <label
            className={
              search.searchPlotBy === "auction"
                ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
            }
            onClick={() => searchPlotByHandler("auction")}
          >
            Auction Bidding
          </label>
          <label
            className={
              search.searchPlotBy === "view_only"
                ? "cursor-pointer font-medium border-b-[.2rem] border-b-main tab-active-new"
                : "cursor-pointer font-medium border-b-[.2rem] border-b-gray-300 tab-not-active-new"
            }
            onClick={() => searchPlotByHandler("view_only")}
          >
            View Only
          </label>
        </section>
      </div>
      {/* Actual Catalog */}

      <div className={clsx("w-full grid place-items-center tablet:grid-cols-3 mb-5 gap-4", !showSideBar ? "laptop:grid-cols-6 wide:grid-cols-7" : "laptop:grid-cols-3 wide:grid-cols-6")}>

        {plots && plots.data?.map((plot, index) => {
          return <PlotWidget plot={plot} key={index} />;
        })}
      </div>
      {plots && plots.data.length == 0 && (<>
        <div className="flex lg:justify-center justify-center">
          <div>
            Empty data
          </div>
        </div>
      </>)}
      {plots && plots.data.length > 0 && (<>
        <div className="flex lg:justify-end justify-center">
          <Stack spacing={2} >
            <Pagination count={plots.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={search.page} />
          </Stack>
        </div>
      </>)}



    </>

  );
};

export default PlotPageWidget;
