import api from "../../support/support_api";

const companyIndex = async (params) => {
    const response  = await api.axiosGet(`/companies`, params);

    return response.data;
}

const companyShow = async (params) => {
    const response  = await api.axiosShow(`/companies/${params.company_id}`, params);

    return response.data;
}

const companyUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/companies/${params.company_id}/companyUpdate`, params);

    return response.data;
}

const companyDelete = async (params) => {
    const response  = await  api.axiosDelete(`/companies/${params.company_id}`);

    return response.data;
}

const companyService = {
    companyIndex, companyShow, companyUpdate, companyDelete
}

export default companyService;