import { useContext, useState, useEffect  } from "react";
import Toggle from 'react-styled-toggle';
import editUser from "./../../images/pencil.svg";
import deleteUser from "./../../images/redBin.svg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import index from "axios-middleware";
import PopupConfirmation from "components/ui/popup/PopupConfirmation";
import { userDelete, userStatusUpdate } from "features/user/userSlice";
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import clsx from "clsx";
import styles from "../account/Account.module.scss";

const UserDetail = ({ users, loadUser }) => {
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    const [isDelete, setDelete] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    
    const editHandler = () => {
      navigate(`/user-detail/${users.id}/edit`);
    };

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'max-content',
      bgcolor: 'background.paper',
      border: '1px solid #000',
      boxShadow: 24,
      p: 2,
    };

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const doDelete = () =>{
      let userID = users.id;
      dispatch(
        userDelete({
          user_id: userID,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
          //alert(response.payload);
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success")
          loadUser();
          setDelete(false);
        }
      });
    
    }

    const doChange = () =>{
      let userID = users.id;
      let userActive = users.active;
      // console.log(users.active)
      dispatch(
        userStatusUpdate({
          user_id: userID,
          active : userActive,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success")
          loadUser();
        }
      });
    
    }

  return (
    <>      
    {isDelete && <PopupConfirmation title="Are you sure want to delete this user?" successButtonTitle="Delete" cancelButtonTitle="Cancel" onSuccess={doDelete} onCancel={setDelete}/>}
      <div data-testid="users" className="p-1" style={{borderBottomWidth: "1px"}}>
        <div className="hidden laptop:flex flex-row items-center justify-center p-2 text-sm" key={index}>
          <section className="flex items-center justify-center font-medium px-2">
            <button onClick={handleOpen}><FontAwesomeIcon icon={faEllipsisVertical} style={{ fontSize: "15px" }} /></button>
          </section>
          <section className="basis-1/4 flex items-center justify-center font-medium ">
            <div className="w-[100px]">{users.name}
              </div></section>
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className="w-[120px]">{users.username}
              </div></section>
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className="">{users.phone_number}
              </div></section>
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className={clsx(styles.scroll,"w-[200px]")}>{users.email}
            </div></section>
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className="">{users.company?.registration_number}
              </div></section>
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <div className="w-[140px]">
                <Toggle backgroundColorChecked="#00AF54" width={46} height={20} sliderHeight={12} sliderWidth={12} 
                labelRight={users.active ? "Activate" : "Inactive"} checked={users.active} onChange={doChange}/>
            </div>
          </section>
          <section className="basis-1/4 flex items-center justify-center font-medium">
            <img src={editUser} className="w-4 h-4 cursor-pointer mr-2" onClick={() => editHandler()}/> / <img src={deleteUser} className="w-4 h-4 cursor-pointer ml-2" onClick={() => setDelete(true)}/></section>
        </div>
        <div className="font-regular">
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="flex justify-end" style={{borderBottomWidth: "1px"}}>
            <button onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
            </button>
          </div>
          </Typography>
          <Typography id="modal-modal-description" >
            <div className="flex col-span-2 gap-4 items-center justify-center font-regular text-sm">
              <div className="mr-8 font-semibold" >
                <div className="p-2">Name: </div>
                <div className="p-2">Username: </div>
                <div className="p-2">Contact No: </div>
                <div className="p-2">Email: </div>
                <div className="p-2">First Name: </div>
                <div className="p-2">Last Name: </div>
                <div className="p-2 h-[40px]">Address: </div>
                <div className="p-2">Role: </div>
                <div className="p-2">Created at: </div>
                <div className="p-2">Updated at: </div>
                <div className="p-2">Actions: </div>
              </div>
              <div>
                <div className="p-2">{users.name || '-' }</div>
                <div className="p-2">{users.username || '-' }</div>
                <div className="p-2">{users.phone_number || '-' }</div>
                <div className="p-2">{users.email || '-' }</div>
                <div className="p-2">{users.first_name || '-' }</div>
                <div className="p-2">{users.last_name || '-' }</div>
                <div className="p-2 w-[400px]" >{users.company?.billing_address || '-' }</div>
                <div className="p-2">{users.role_name || '-' }</div>
                <div className="p-2">{formatDate(users.created_at)}</div>
                <div className="p-2">{formatDate(users.updated_at)}</div>
                <div className="basis-1/4 flex items-center justify-start p-2">
                  <img src={editUser} className="w-4 h-4 cursor-pointer mx-2" onClick={() => editHandler()}/>Edit / <img src={deleteUser} className="w-3 h-3 cursor-pointer mx-2"  onClick={() => setDelete(true)}/> Delete</div>
              </div>
            </div>
          </Typography>
        </Box>
        </Modal>
        </div>
      </div>
    </>
  );
};

export default UserDetail;