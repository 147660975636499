import api from "../../support/support_api";

const advanceBookingList = async (params) => {
    const response  = await  api.axiosGet('/booking-plots/getData', params);

    return response.data;
}

const advanceBookingDetail = async (params) => {
    const response  = await  api.axiosGet('/booking-plots/'+params.id);

    return response.data;
}

const advanceBookingDetailByBookingNumber = async (params) => {
    const response  = await  api.axiosGet('/booking-plots/getByBookingNumber/'+params.booking_number);

    return response.data;
}

const advanceBookingReject = async (params) => {
    const response  = await  api.axiosPut('/booking-plots/'+params.id+'/reject');

    return response.data;
}

const advanceBookingApprove = async (params) => {
    const response  = await  api.axiosPut('/booking-plots/'+params.id+'/approve');

    return response.data;
}

const advanceBookingPayDeposit = async (params) => {
    const response  = await  api.axiosPut('/booking-plots/'+params.id+'/payDeposit', params);

    return response.data;
}

const advanceBookingCreateOrder = async (params) => {
    const response  = await  api.axiosPost('/booking-plots/createOrder', params);

    return response.data;
}

const confirmOfflinePaymentDeposit = async (params) => {
    const response  = await  api.axiosPost('/booking-plots/confirmOfflinePayment', params);

    return response.data;
}

const downloadInvoice = async (params) => {
    const response  = await  api.axiosDownloadPDF('/booking-plots/download-invoice/'+params.id, params);

    return response.data;
}

const uploadPaymentReceipt = async (params) => {
    const response  = await  api.axiosPost('/booking-plots/uploadPaymentReceipt', params);

    return response.data;
}

const generateInvoiceNumber = async (params) => {
    const response  = await  api.axiosPut('/booking-plots/'+params.id+'/generateInvoiceNumber', params);

    return response.data;
}

const advanceBookingService = {
    advanceBookingList,
    advanceBookingDetail,
    advanceBookingDetailByBookingNumber,
    advanceBookingReject,
    advanceBookingApprove,
    advanceBookingPayDeposit,
    advanceBookingCreateOrder,
    confirmOfflinePaymentDeposit,
    downloadInvoice,
    uploadPaymentReceipt,
    generateInvoiceNumber,
}


export default advanceBookingService;