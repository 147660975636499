import CartLayout from "pages/CartLayout";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { orderDetailIndex, updateDeliveryFee } from "../../features/order-detail/orderDetailSlice";
import { orderPlace, orderStore, orderUpdateByToken } from "../../features/order/orderSlice";
import { routesPaths } from "constants/routes.constants";
import { useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import CartProgress from "./CartProgress";
import Footer from "components/footer/footer";
import FooterStyles from "components/footer/footer.module.scss";
import clsx from "clsx";

const CartDeliveryPayment = () => {

  const navigate = useNavigate();
  const [productAdded, setProductAdded] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);


  const [deliveryAddress, setDeliveryAddress] = useState({
    name: "",
    street: "",
    city: "",
    zip: "",
  });
  const [sameAsDelivery, setSameAsDelivery] = useState(false);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState("DEFAULT");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("DEFAULT");
  const [useInvoice, setUseInvoice] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");

  const reduxOrderDetail = useSelector((state) => state.orderDetail);
  const dummyDeliveryTypes = [
    { name: "Ground", cost: 5, currency: "RM" },
    { name: "Express", cost: 25, currency: "RM" },
    { name: "Air", cost: 60, currency: "RM" },
  ];

  const deliveryTypes = dummyDeliveryTypes;

  const dummyPaymentMethods = [{ name: "Visa" }, { name: "MasterCard" }, { name: "PayPal" }];

  const paymentMethods = dummyPaymentMethods;

  useEffect(() => {
  }, [deliveryAddress]);

  useEffect(() => {
  }, [sameAsDelivery]);

  useEffect(() => {
    dispatch(updateDeliveryFee(selectedDeliveryType.cost))
    loadOrder()
  }, [selectedDeliveryType]);

  useEffect(() => {
  }, [selectedPaymentMethod]);

  useEffect(() => {
  }, [useInvoice]);

  useEffect(() => {
  }, [additionalInfo]);

  // {selectedDeliveryType.name}, ${selectedDeliveryType.currency}${selectedDeliveryType.cost

  const reduxOrder = useSelector((state) => state.order);

  const storeOrder = () => {
    dispatch(
      orderPlace({
        delivery_address_name_and_username: deliveryAddress.name,
        delivery_address_city: deliveryAddress.city,
        delivery_address_street: deliveryAddress.street,
        delivery_address_zipcode: deliveryAddress.zip,
        delivery_address_same_as_billing_address: '',
        delivery_type: selectedDeliveryType.name,
        delivery_method: selectedPaymentMethod.name,
        additional_information: additionalInfo,
        amount_delivery: selectedDeliveryType.cost,
        use_invoice: useInvoice,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        //  console.log(response.payload.message);

        // navigate(routesPaths.CART_DONE)
      }
    });
  }
  const loadOrder = () => {
    dispatch(
      orderDetailIndex({
        status: 'pending',
        page: 1,
        limit: 100
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
      }
    });
  }

  useEffect(() => {
    loadOrder();
  }, []);


  return (
    <div className={clsx(FooterStyles.menuMobile,"bg-gray-50 min-h-screen p-4 grid gap-4 grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-4 desktop:grid-cols-8")}>

      <div className="max-w-[60rem] w-full justify-self-center flex flex-col gap-4 col-span-1 tablet:col-span-2 laptop:col-span-3 desktop:col-span-4 desktop:col-start-3">
        {/* Delivery Address */}
        <CartProgress />
        <section data-testid="cart-delivery-payment" className="bg-white w-full flex flex-col p-4 gap-2 rounded-lg shadow">
          <label className="text-xl font-semibold">Delivery Address</label>
          {/* <div className="flex items-center gap-2">
          <label>Same As Delivery Address</label>
          <input type="checkbox" value={sameAsDelivery} onChange={() => setSameAsDelivery(!sameAsDelivery)} />
        </div> */}
          <div className="flex flex-col gap-4 py-3">
            <div className="flex gap-4">
              <input
                type="text"
                className="input-search"
                placeholder="Unit No"
                value={deliveryAddress.name}
                onChange={({ target }) => setDeliveryAddress({ ...deliveryAddress, name: target.value })}
              />
              <input
                type="text"
                placeholder="Street"
                className="input-search"
                value={deliveryAddress.street}
                onChange={({ target }) => setDeliveryAddress({ ...deliveryAddress, street: target.value })}
              />
            </div>
            <div className="flex gap-4">
              <input
                type="text"
                placeholder="City"
                className="input-search"
                value={deliveryAddress.city}
                onChange={({ target }) => setDeliveryAddress({ ...deliveryAddress, city: target.value })}
              />
              <input
                type="text"
                placeholder="State"
                className="input-search"
                value={deliveryAddress.state}
                onChange={({ target }) => setDeliveryAddress({ ...deliveryAddress, state: target.value })}
              />
            </div>
            <div className="flex gap-4">
              <input
                type="text"
                placeholder="Zip Code"
                className="input-search"
                value={deliveryAddress.zip}
                onChange={({ target }) => setDeliveryAddress({ ...deliveryAddress, zip: target.value })}
              />
              <input
                type="text"
                placeholder="Country"
                className="input-search"
                value={deliveryAddress.country}
                onChange={({ target }) => setDeliveryAddress({ ...deliveryAddress, country: target.value })}
              />
            </div>
          </div>
          <label className="text-xl font-semibold">Delivery</label>
          <div className="flex gap-4 items-center justify-between py-3">
            <label className="font-semibold w-1/6"> Order #1
            </label>
            <select
              value={selectedDeliveryType}
              className="input-search w-4/6"
              onChange={({ target }) => {
                setSelectedDeliveryType(JSON.parse(target.value));
              }}
            >
              <option value="DEFAULT">
                {selectedDeliveryType.name ? selectedDeliveryType.name : "Select Delivery Type"}
              </option>
              {deliveryTypes.map((type, index) => (
                <option value={JSON.stringify(type)} key={index}>
                  {type.name}
                </option>
              ))}
            </select>
            <label className="font-semibold w-1/6" style={{ textAlign: "right" }}>
              {selectedDeliveryType.currency && selectedDeliveryType.currency}{" "}
              {selectedDeliveryType.cost ? selectedDeliveryType.cost : "Cost"}
            </label>


          </div>

          <label className="text-xl font-semibold">Additional Information for Farmer</label>
          <div className="flex flex-col gap-4 items-start justify-between py-3">
            <textarea
              className="rounded-xl min-h-[10rem] max-h-[20rem] input-search"
              value={additionalInfo}
              placeholder="Type something..."
              maxLength={1200}
              onChange={({ target }) => setAdditionalInfo(target.value)}
            />


          </div>
          <label className="text-xl font-semibold">Payment Method</label>
          <div className="flex gap-2 py-3 flex-col">
            <div className="w-1/2 flex flex-col gap-2">
              <select
                value={selectedPaymentMethod}
                className="input-search"
                onChange={({ target }) => {
                  setSelectedPaymentMethod(JSON.parse(target.value));
                }}
              >
                <option value="DEFAULT">
                  {selectedPaymentMethod.name ? selectedPaymentMethod.name : "Select Payment Method"}
                </option>
                {paymentMethods.map((method, index) => (
                  <option value={JSON.stringify(method)} key={index}>
                    {method.name}
                  </option>
                ))}
              </select>
              {/* <div className="flex items-center gap-2">
                <label>Use Invoice</label>
                <input type="checkbox" value={useInvoice} onChange={() => setUseInvoice(!useInvoice)} />
              </div> */}
            </div>
            <div className="w-1/2 flex flex-col gap-2">
              <button className="saveButton h-[2.3rem] w-[15rem]">Pay</button>
            </div>
          </div>
        </section>

        {/* Payment Method */}
        {/* <section className="bg-formLight w-full flex flex-col p-4 gap-2 rounded-lg shadow">
        <label className="text-xl">Payment Method</label>
        <div className="flex gap-2">
          <div className="w-1/2 flex flex-col gap-2">
            <select
              value={selectedPaymentMethod}
              onChange={({ target }) => {
                setSelectedPaymentMethod(JSON.parse(target.value));
              }}
            >
              <option value="DEFAULT">
                {selectedPaymentMethod.name ? selectedPaymentMethod.name : "Select Payment Method"}
              </option>
              {paymentMethods.map((method, index) => (
                <option value={JSON.stringify(method)} key={index}>
                  {method.name}
                </option>
              ))}
            </select>
            <div className="flex items-center gap-2">
              <label>Use Invoice</label>
              <input type="checkbox" value={useInvoice} onChange={() => setUseInvoice(!useInvoice)} />
            </div>
          </div>
          <div className="w-1/2 flex flex-col gap-2">
            <button className="saveButton">Payment</button>
          </div>
        </div>
      </section> */}

        {/* Additional Information */}
        {/* <section className="bg-formLight w-full flex flex-col p-4 gap-2 rounded-lg shadow">
        <label className="text-xl">Additional Information for Farmer</label>
        <textarea
          className="rounded-xl min-h-[10rem] max-h-[20rem]"
          value={additionalInfo}
          placeholder="Type something..."
          maxLength={1200}
          onChange={({ target }) => setAdditionalInfo(target.value)}
        />
      </section> */}

        {/* <section className="bg-formLight w-full flex flex-col p-4 gap-2 rounded-lg shadow">
            <button className="saveButton" onClick={() => storeOrder()}>
                {reduxOrder.loading ? "Processing, please wait.."  : "Finish the Order" }
            </button>
        </section> */}
      </div>
      {/* <CartSidebar /> */}
      <div data-testid="cart-sidebar" className="bg-white justify-self-center laptop:justify-self-end w-full desktop:col-span-2 desktop:col-start-7 flex flex-col h-fit p-3 gap-4 rounded-lg shadow">
        <section className="flex justify-between">
          <label className="font-semibold">Product Price:</label>
          <label className="font-semibold">
            {reduxOrderDetail.order?.price_unit} {parseFloat(reduxOrderDetail.order.amount_total_formatted)}
            {/*{cart.currency} {cart.product_price.toFixed(2)}*/}
          </label>
        </section>
        {reduxOrderDetail.order.amount_delivery !== 0 && <section className="flex justify-between">
          <label className="font-semibold">+ Delivery:</label>
          <label className="font-semibold">
            {reduxOrderDetail.order?.price_unit}  {reduxOrderDetail.order?.amount_delivery}

            {/*{cart.currency} {cart.delivery_fee.toFixed(2)}*/}
          </label>
        </section>}
        <hr className="bg-black text-black w-full h-[0.1rem]" />
        <section className="flex justify-between">
          <label className="font-semibold">Total:</label>
          <label className="font-semibold">
            {reduxOrderDetail.order?.price_unit}  {reduxOrderDetail.order.amount_total_overall_formatted}

            {/*{cart.currency} {(cart.product_price + cart.delivery_fee).toFixed(2)}*/}
          </label>
        </section>
        <button className="saveButton rounded-md" onClick={() => storeOrder()}>
          {reduxOrder.loading ? "Processing, please wait.." : "Finish the Order"}
        </button>
        <button className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-md text-sm px-5 py-2.5 text-center" onClick={() => navigate("/cart/products")}>
          {reduxOrder.loading ? "Processing, please wait.." : "Cancel"}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default CartDeliveryPayment;
