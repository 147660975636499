import React, { useEffect } from 'react';
import { useState } from 'react';
import styles from './CustomSliderProduct.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faClose, faCross, faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import PopupLayout from 'pages/PopupLayout';
import { routesPaths } from "constants/routes.constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fileDelete } from "../../features/file/fileSlice";
export default function PopupImageSlider(props) {

  const [imageIndex, setImageIndex] = useState(props.defaultSlideIndex + 1);
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const nextSlide = () => {
    if (imageIndex !== props.photos.data.length) {
      setImageIndex(imageIndex + 1)
    } else if (imageIndex === props.photos.data.length) {
      setImageIndex(1)
    }
  }

  const prevSlide = () => {
    if (imageIndex !== 1) {
      setImageIndex(imageIndex - 1)
    } else if (imageIndex === 1) {
      setImageIndex(props.photos.data.length)
    }
  }

  const closePopUp = () => {
    props.setIsShowPopupImage(false);
  }

  const changeImage = (i) => {
    setImageIndex(i);
  }
  const itemDelete = (photo) => {
    if (window.confirm("Are you sure you want to delete this Photo?")) {
        // console.log(" deleted ",  photo.id)

        dispatch(
            fileDelete({
                file_id: photo.id,
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                // console.log(response.payload);
            } else {
                let message = response.payload.message;

                // console.log(" message ", message);
                props.reloadData()
            }
        });
    }
    else {
        // console.log(" delete was cancelled")
    }
}
  return (
    <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
      <section
        data-testid="popup-layout"
        className="absolute bg-formLight flex flex-col rounded-xl shadow"
      >


        <div className={styles.sliderContainer}>

          {props.photos.data.length > 0 ? props.photos.data.map((obj, index) => {
            return (
              <div
                key={index}
                className={imageIndex === index + 1 ? `${styles.slide}${styles.active}` : `${styles.slide}`}
              >
                <section className="absolute text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
                    <section className="absolute top-5 right-2">
                        <button className="absolute top-5 right-2 fosuc:pointer-events-auto" onClick={(e) => {
                            e.preventDefault();
                            itemDelete(obj)
                        }}>
                            <div>
                                {/* <span className={'text-white mr-2'}>Delete</span> */}
                                <FontAwesomeIcon icon={faTrashCan} className={"text-cyan-400"} />
                            </div>
                        </button>
                    </section>
                    <section className="absolute top-5 left-2">
                        <button className="absolute top-5 left-2 fosuc:pointer-events-auto" onClick={(e) => {
                            e.preventDefault();

                            navigate(`${routesPaths.FILES}/${obj.id}/edit`);
                        }}>
                            <div>
                                {/* <span className={'text-white mr-2'}>Edit</span> */}
                                <FontAwesomeIcon icon={faPenToSquare} className={"text-rose-400"} />
                            </div>
                        </button>
                    </section>
                </section>
                <img
                  src={obj.src}
                  alt="product"
                  className={styles.sliderImage}
                />
                <div className="absolute bg-black/50 text-white left-0 bottom-0 rounded-b-lg flex flex-col w-full h-1/4 p-2 box-img-card" key={0}>
                    <section className="absolute bottom-5 left-5">
                        <div>
                            <label className="font-in-box">{obj.date}</label>

                        </div>
                        <div>
                            <label className="font-in-box">{obj.description_short}</label>

                        </div>
                    </section>
                </div>
                {/* <div className={styles.sliderImage_description}>
                  <p>{obj.date ? obj.date : ""}</p>
                  <p>{obj.description ? obj.description_short : "No description"}</p>
                </div> */}
              </div>
            )
          }) : <img src={props.defaultPhoto} className={styles.sliderImage} alt="default" />}

          {props.photos?.data.length > 0 ? (<><button onClick={prevSlide} className={styles.buttonLeft}>
            <FontAwesomeIcon icon={faChevronLeft} size="3x" className={"text-gray-400"} />
          </button>
            <button onClick={nextSlide} className={styles.buttonRight}>
              <FontAwesomeIcon icon={faChevronRight} size="3x" className={"text-gray-400"} />
            </button></>) : null}

          <button
            onClick={() => {
              closePopUp();
            }}
            className={styles.buttonRightCross}
            style={{color:"white"}}>
            <FontAwesomeIcon icon={faClose} size="2x" color='white' />
          </button>


          <div className={styles.dotContainer}>
            {props.photos.data && Array.from({ length: props.photos.data.length }).map((dot, index) => (
              <div
                onClick={() => changeImage(index + 1)}
                className={imageIndex === index + 1 ? `${styles.imageDot} ${styles.dotActive}` : `${styles.imageDot}`}
              >

              </div>
            ))}
          </div>

        </div>
      </section>
    </div>
  )
}