import React, { useState, useContext } from "react";
import { makePayment } from "features/payment/paymentSlice";
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import AccountLayout from "pages/AccountLayout";
import AccountControls from "components/account/AccountControls";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import md5 from 'md5';

const Payment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbarCtx = useContext(SnackbarContext);
    const [page, setPage] = useState("Personal Details");

    const pageHandler = (newPage) => {
        setPage(newPage);
    };

    const handlePayment = () => {
        // dispatch(
        //     makePayment({
        //         amount: 1.15,
        //         orderid: 'DEMO5179',
        //         bill_name: 'RMS+Demo',
        //         bill_email: 'demo@RMS.com',
        //         bill_mobile: 55218438,
        //         bill_desc: 'testing+by+RMS',
        //     })
        // ).then(function (response) {
        //     if (response.error && response.error.message === "Rejected") {
        //         snackbarCtx.displayMsg(response.payload.message, "failure")
        //     } else {
        //         navigate(`/payment/detail/${response.payload}`);
        //     }
        // });

        let paymentPayload = { merchant_id: 'SB_satasia', amount: 1.15, orderid: 'DEMO5179', bill_name: 'RMS+Demo', bill_email: 'demo@RMS.com', bill_mobile: 55218438, bill_desc: 'testing+by+RMS' };
        let str = paymentPayload.amount + paymentPayload.merchant_id + paymentPayload.orderid + 'fcc5bedd64e2aa8dea6d055d6b1f023b';
        let signature = md5(str)
        paymentPayload.vcode = signature;

        let paymentHeaders = {
            'Content-Type': 'application/json'
        }

        axios.post(`${process.env.REACT_APP_RAZER_ROOT}`+`/RMS/pay/SB_satasia/index.php`, paymentPayload, paymentHeaders)
            .then(response => {
            })
            .catch(error => {
            });
    }

    return (
        <AccountLayout>
            <div className="flex flex-col items-start gap-6 p-4">
                <h1 className="text-3xl">Edit Account Details</h1>
                <AccountControls page={page} pageHandler={pageHandler} />
                <div className="w-full max-w bg-white flex flex-col items-start rounded-lg shadow gap-4 p-4">
                    <div className="row" style={{ width: "100%" }}>
                        <button onClick={handlePayment}>Make Payment</button>
                    </div>
                </div>
            </div>
        </AccountLayout>
    );
};

export default Payment;
