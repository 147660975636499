import PageLayout from "pages/AccountLayout";
import Company from "./CompanyDetail";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountAsc} from '@fortawesome/free-solid-svg-icons';

import {useState, useEffect, useContext} from "react";
import {companyIndex} from "../../features/company/companySlice";

import {useDispatch} from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import {useSelector} from "react-redux/es/index";
import MessageEmpty from "../ui/message/empty/MessageEmpty";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const CompanyList = () => {
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  const [companiesList, setCompaniesList] = useState({
      data: []
  });
  const reduxUser = useSelector((state) => state.companies);
  const [currentPage, setCurrentPage] = useState(1);

  const loadCompany = (pageNumber) => {
      dispatch(
        companyIndex({
              limit: 100,
              page: pageNumber
          })
      ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
              snackbarCtx.displayMsg(response.payload, "failure")
              //alert(response.payload);
          } else {
              // console.log(response.payload.message);

              let companies = response.payload.data.companies;

              setCompaniesList(companies);

              // console.log(companies);
          }
      });
  }

  const handleChangePage = (event, pageNumber) => {
    // if (pageNumber > currentPage) {
    //   console.log("User clicked on the next page.");
    // } else if (pageNumber < currentPage) {
    //   console.log("User clicked on the previous page.");
    // }
    setCurrentPage(pageNumber);
    loadCompany(pageNumber);
    // console.log(companiesList.pagination?.total_pages)
  };
  useEffect(() => {
    loadCompany()
  }, []);
  return (
    <>
    <PageLayout>
    <div data-testid="company-list" className="flex flex-col items-start gap-6 p-4">
        <h1 className="text-3xl text-left font-semibold font-regular" style={{ color: "#395B50" }}>Companies' Details</h1>
      <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg font-regular">
          <div className="flex gap-x-4 justify-start items-center py-3 px-4 tablet:flex flex-col gap-y-2 desktop:flex-row" style={{borderBottomWidth: "1px", width:"100%"}}>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
              Sort  
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom">
                <option value="DATE" selected>
                  Company Name
                </option>
                <option value="DATE" selected>
                  Company ID
                </option>
                <option value="DATE" selected>
                  Package 
                </option>
              </select>

            </div>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit">
            <FontAwesomeIcon icon={faSortAmountAsc}/>
            </div>
            <div className="flex flex-col font-semibold tablet:flex justify-start w-full desktop:w-fit ml-6">
              Filter  
            </div>
            <div className="flex items-center tablet:flex justify-start w-full desktop:w-1/5">
              <select className="select-custom ">
                <option value="DATE" selected>
                  Company Name
                </option>
                <option value="DATE" selected>
                  Company ID
                </option>
                <option value="DATE" selected>
                  Package
                </option>
                <option value="DATE" selected>
                  Account Type
                </option>
              </select>
            </div>
            <div className="flex items-center tablet:flex desktop:w-2/3 justify-end">
              <form className="tablet:flex justify-start w-full desktop:w-fit">   
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 laptop:hidden desktop:hidden">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </div>
                    <input type="search" id="default-search" className="form-border-green block w-full pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search" />
                </div>
              </form>
            </div>
          </div>

          <div className="p-2 w-full max-w" style={{backgroundColor:"#00AF54", color:"white"}}>
            <div className="hidden laptop:flex flex-row items-center justify-center text-sm text-semibold font-regular">
            <div className="flex items-center justify-center font-medium"></div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Company Name</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Company ID</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Address</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Contact No</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Package</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Account Type</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Options</div>
            </div>
          </div>
         <div className="p-2 w-full max-w mt-1">
            {/* <Company/> */}
            {companiesList.data && companiesList.data.map((company, index) => (
                <Company company={company} loadCompany={loadCompany} key={index}/>
            ))}
        </div>
      </div>
      <Stack spacing={2}>
        <Pagination count={companiesList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage}/>
      </Stack>
    </div>
    </PageLayout>
    </>
  );
};
export default CompanyList;