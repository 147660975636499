import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { getPackages } from "features/auth/packageSubscriptionSlice";
import { resubscribePackage, subscribePackage } from "features/package-subscription/companyPackageSlice";
import { InputGroup } from "react-bootstrap";
import styles from "./Account.module.scss";
import clsx from "clsx";
import PopupPay from "components/ui/popup/PopupPay";

const ChangePackage = ({ cancelHandler }) => {
  const { currentUser, currentCompany } = useSelector((state) => state.auth);
  const [packages, setPackages] = useState([]);
  const [subscriptionFee, setSubscriptionFee] = useState(0)
  const [serviceCharge, setServiceCharge] = useState(0);
  const [serviceChargeType, setServiceChargeType] = useState(0)
  const [packageDetails, setPackageDetails] = useState("")
  const [packageDays, setPackageDays] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [subsStartDate, setSubsStartDate] = useState("");
  const [subsEndDate, setSubsEndDate] = useState("");
  const [subsEndDateString, setSubsEndDateString] = useState("");
  const [paymentDueDate, setPaymentDueDate] = useState(null);
  const [confirmPay, setConfirmPay] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const calculateRange = (package_period_format, package_period) => {
    let totalDays = 0
    switch (package_period_format) {
      case 'Days':
        totalDays = package_period;
        break;
      case 'Months':
        totalDays = package_period * 30;
        break;
      case 'Years':
        totalDays = package_period * 30 * 12;
        break;
      default:
        totalDays = package_period;
    }
    return totalDays;
  }

  useEffect(() => {
    const loadPackages = () => {
      dispatch(
        getPackages()
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
        } else {
          let result = response?.payload?.data?.packages?.data;
          let endSubsDate = new Date();
          let paymentDate = new Date();

          setPackages(result);
          setSelectedPackage(result[0]);
          setSubscriptionFee(result[0]?.subscription_fee);
          setServiceCharge(result[0]?.service_charge);
          setPackageDetails(result[0]?.package_details);
          setPackageDays(calculateRange(result[0]?.package_period_format, result[0]?.package_period));
          setSubsStartDate(new Date().toISOString().substr(0, 10));

          endSubsDate.setDate(endSubsDate.getDate() + calculateRange(result[0]?.package_period_format, result[0]?.package_period));
          setSubsEndDate(endSubsDate.toISOString().substr(0, 10));
          setSubsEndDateString(endSubsDate.toLocaleDateString(undefined, dateOptions))
          paymentDate.setDate(paymentDate.getDate() + 7);
          setPaymentDueDate(paymentDate.toISOString().substr(0, 10));
        }
      });
    }

    loadPackages();
  }, [dispatch, snackbarCtx]);

  const handlePackageChange = event => {
    currentCompany?.company_packages?.data.filter((item) => {
      if (item?.package?.package_name === event.target.value) {
        snackbarCtx.displayMsg('This package has been selected before.', "failure");
        setDisableSaveButton(true);
      } else {
        setDisableSaveButton(false);
        let companyPackage = packages.filter((item) => {

          return item.package_name === event.target.value;
        })

        setSelectedPackage(companyPackage[0]);
        setSubscriptionFee(companyPackage[0].subscription_fee);
        setServiceCharge(companyPackage[0].service_charge);
        setPackageDetails(companyPackage[0].package_details)
      }
    })
  };

  const handleSave = () => {
    currentCompany?.company_packages?.data.filter((item) => {
      if (item?.package?.package_name === selectedPackage?.package_name) {
        snackbarCtx.displayMsg('This package has been selected before.', "failure");
        setDisableSaveButton(true);
      } else {
        setDisableSaveButton(false);
        setConfirmPay(true);
      }
    })
  };

  const handleSubsStartDate = target => {
    setSubsStartDate(target.value);
    let endSubsDate = new Date(target.value);
    endSubsDate.setDate(endSubsDate.getDate() + packageDays);
    setSubsEndDate(endSubsDate.toISOString().substr(0, 10));
  };

  const handleSubsEndDate = target => {
    setSubsEndDate(target.value);
    let startSubsDate = new Date(target.value);
    startSubsDate.setDate(startSubsDate.getDate() - packageDays);
    setSubsStartDate(startSubsDate.toISOString().substr(0, 10));
  };

  const rewriteSubs = () => {
    let newEndSubsDate = new Date();

    newEndSubsDate.setDate(newEndSubsDate.getDate() + calculateRange(selectedPackage.package_period_format, selectedPackage.package_period))
    newEndSubsDate = newEndSubsDate.toISOString().substr(0, 10)

    dispatch(
      resubscribePackage({
        package_id: selectedPackage?.id || packages[0]?.id,
        company_id: currentCompany.id,
        service_charge_type: parseInt(serviceChargeType),
        service_charge: parseFloat(serviceCharge),
        subs_start_date: subsStartDate,
        subs_end_date: newEndSubsDate,
        payment_date: paymentDueDate,
        status: 1
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        cancelHandler();
      }
    });
  }

  const startLater = () => {
    let activePackage = currentCompany?.company_packages?.data.find((companyPackage) =>
      companyPackage.status === 1
    )
    let newStartSubsDate = new Date(activePackage.subs_end_date);
    let newPaymentDate = new Date(activePackage.subs_end_date);
    let newEndSubsDate = new Date(activePackage.subs_end_date);;

    newStartSubsDate.setDate(newStartSubsDate.getDate() + 2)
    newStartSubsDate = newStartSubsDate.toISOString().substr(0, 10)

    newEndSubsDate.setDate(newEndSubsDate.getDate() + calculateRange(selectedPackage.package_period_format, selectedPackage.package_period));
    newEndSubsDate = newEndSubsDate.toISOString().substr(0, 10)

    newPaymentDate.setDate(newPaymentDate.getDate() + 7)
    newPaymentDate = newPaymentDate.toISOString().substr(0, 10)

    dispatch(
      subscribePackage({
        package_id: selectedPackage?.id || packages[0]?.id,
        company_id: currentCompany.id,
        service_charge_type: parseInt(serviceChargeType),
        service_charge: parseFloat(serviceCharge),
        subs_start_date: newStartSubsDate,
        subs_end_date: newEndSubsDate,
        payment_date: paymentDueDate
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        cancelHandler();
      }
    });
  }

  return (
    <div data-testid="order-list" className="w-full flex flex-col items-start">
      <div className="flex flex-col items-start gap-2 p-4 w-full">
        <label className="cursor-pointer head-bar-nav font-regular font-semibold text-sm" onClick={cancelHandler}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <div className="w-full max-w bg-white flex flex-col items-start rounded-lg shadow gap-4 p-4">
          <h1 className="text-xl font-regular font font-semibold" style={{ color: "#395B50" }}>Package Subscription Details</h1>
          <div className="row font-regular text-sm" style={{ width: "100%" }}>
            <div className="self-start flex flex-col gap-4 col-md-5">
              <div className="flex flex-col space-y-6">
                <section className="flex flex-col space-y-1">
                  <label>Package Name:</label>
                  <div className="w-full">
                    <select
                      value={selectedPackage?.package_name}
                      className="input-search"
                      onChange={handlePackageChange}
                    >
                      {
                        packages.filter((item) => {
                          let packageItem = item;
                          if (currentUser?.role_name === 'both') {
                            return packageItem.role_id === 1;
                          } else {
                            return packageItem;
                          }
                        }).map((item, key) => <option key={item.id} value={item.package_name}>{item.package_name}</option>)
                      }
                    </select>
                  </div>
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Subscription Fee (PER MONTH):</label>
                  <input
                    type="text"
                    disabled
                    className={"input-search bg-slate-300"}
                    placeholder={"Subscription / month"}
                    value={parseInt(subscriptionFee)}
                    onChange={({ target }) => setSubscriptionFee(target.value)}
                  />
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Service Charge (PER TRANSACTION):</label>
                  <div className="flex items-center col-span-2 gap-5">
                    {serviceChargeType === 1 && <div className="flex w-full">
                      <InputGroup.Text id="basic-addon1" className="rounded-r-none">RM</InputGroup.Text>
                      <input
                        type="text"
                        disabled
                        className={clsx(styles.removeLeftBorder, "input-search bg-slate-300")}
                        placeholder={"Service Charge %"}
                        value={parseInt(serviceCharge)}
                        onChange={({ target }) => setServiceCharge(target.value)}
                      />
                    </div>}
                    {serviceChargeType === 0 &&
                      <input
                        type="text"
                        disabled
                        className="input-search w-full bg-slate-300"
                        placeholder={"Service Charge %"}
                        value={parseInt(serviceCharge)}
                        onChange={({ target }) => setServiceCharge(target.value)}
                      />}
                    <select
                      disabled
                      value={serviceChargeType}
                      className="input-search bg-slate-300"
                      onChange={({ target }) => setServiceChargeType(parseInt(target.value))}
                    >
                      <option value="" disabled>
                        {"Select Service Type"}
                      </option>
                      <option value={0}>Percentage</option>
                      <option value={1}>Fixed</option>
                    </select>
                  </div>
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Package Details:</label>
                  <input
                    disabled
                    type="text"
                    className={"input-search bg-slate-300"}
                    placeholder={"Package Details"}
                    value={packageDetails}
                    onChange={({ target }) => setPackageDetails(target.value)}
                  />
                </section>
                <section className="flex flex-col space-y-1">
                  <div className="flex items-center col-span-2 gap-5">
                    <div>
                      <label>Subscription Start Date:</label>
                      <input
                        disabled
                        className="input-search bg-slate-300"
                        type="date"
                        min={new Date().toISOString().substr(0, 10)}
                        placeholder={subsStartDate}
                        value={subsStartDate}
                        onChange={({ target }) => handleSubsStartDate(target)}
                      />
                    </div>
                    <div>
                      <label>Subscription End Date:</label>
                      <input
                        disabled
                        className="input-search bg-slate-300"
                        type="date"
                        max={subsEndDate}
                        placeholder={subsEndDate}
                        value={subsEndDate}
                        onChange={({ target }) => handleSubsEndDate(target)}
                      />
                    </div>
                  </div>
                </section>
                <section className="flex flex-col space-y-1">
                  <label>Payment Due Date:</label>
                  <input
                    disabled
                    className="input-search bg-slate-300"
                    type="date"
                    placeholder={paymentDueDate}
                    value={paymentDueDate}
                    onChange={({ target }) => setPaymentDueDate(target.value)}
                  />
                </section>
                <div className="row">
                  <div className="col-md-4">
                    <button
                      disabled={disableSaveButton}
                      className={disableSaveButton ? "saveButton hover:bg-gray-600 hover:ring-gray-600 bg-gray-500 text-white w-full" : " saveButton bg-green-600 text-white w-full"}
                      onClick={handleSave}
                    >Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmPay && <PopupPay title={currentCompany?.company_packages?.data.length === 0 ? (`You will subscribing to package ` + selectedPackage?.package_name + ` which will end at ` + subsEndDateString + `. Do you wish to subscribed to this package ` + selectedPackage?.package_name + ` ?`) : (`You are currently subscribed to package ` + selectedPackage?.package_name + ` which will end at ` + subsEndDateString + `. Do you wish to start this package after the end of package ` + selectedPackage?.package_name + ` or Rewrite your current subscription?`)} successButtonTitle={currentCompany?.company_packages?.data.length === 0 ? "Subscribe" : "Rewrite Subscription"} cancelButtonTitle={currentCompany?.company_packages?.data.length === 0 ? "Cancel" : "Start Later"} onSuccess={rewriteSubs} onCancel={() => { currentCompany?.company_packages?.data.length === 0 ? setConfirmPay(false) : startLater() }} open={confirmPay} />}
    </div>
  );
};

export default ChangePackage;
