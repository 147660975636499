// import { useState } from "react";
import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import exampleFarm from "../../../images/ExampleFarm.png";
import iconTrash from "images/iconTrash.png";
import { farmDelete } from "features/farm/farmSlice";
import { useContext } from "react";
import SnackbarContext from "_helpers/snackbar-context";

const FarmWidget = ({ farm, loadFarms }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [farmID, setFarmID] = useState(1);
  const [name, setName] = useState("Farm Name");
  const [address, setAddress] = useState("Address");
  const [photo, setPhoto] = useState(exampleFarm);
  const snackbarCtx = useContext(SnackbarContext);
  const [isActionEdit, setIsActionEdit] = useState(false);

  const editHandler = (farm) => {
    navigate(`/farms/${farm.id}/edit`);
  }
  const deleteFarm = (farmId) => {
    if (window.confirm("Are you sure you want to delete this farm?")) {
      dispatch(farmDelete({
        farm_id: farmId,
      })).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
          //alert(response.payload);
        } else {
          window.location.reload();
        }
      });
    }
  }

  useEffect(() => {
    if (farm.photo_default) {
      setPhoto(farm.photo_default)
    }
  }, []);

  return (
    <button className="flex flex-col relative rounded-lg shadow w-full h-full">
      <img src={photo} alt="example farm" className="w-full h-full object-cover aspect-square rounded-lg" />
      <section className="absolute text-white flex flex-col left-0 top-0 rounded-t-lg w-full h-fit px-2 py-1 box-img-card-top">
        <section className="absolute top-2 right-2">
          <img
            width={25}
            src={iconTrash}
            onClick={(event) => {
              event.preventDefault();

              deleteFarm(farm.id);
            }} alt="" />
        </section>
      </section>
      <div
        className="absolute w-full bottom-0 flex flex-col items-start p-2 rounded-b-md box-img-card-cursor"
        onClick={() => editHandler(farm)}>

      </div>
      <div
        className="absolute w-full bottom-0 bg-black/30 h-1/3 flex flex-col items-start p-2 rounded-b-md box-img-card"
        onClick={() => editHandler(farm)}
        style={{ textAlign: "left" }}>
        <label className="font-in-box-market cursor-pointer">{farm.name}</label>
        <label className="font-in-box-sub-market cursor-pointer">{farm.address}</label>
      </div>
    </button>
  );
};

export default FarmWidget;
