import api from "../../support/support_api";


const downloadBill = async (params) => {
    const response = await api.axiosDownloadPDF('/package/download-bill/'+params.id);

    return response.data;
}

const billingService = {
    downloadBill,
}


export default billingService;