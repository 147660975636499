import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import editUser from "./../../images/pencil.svg";
import deleteUser from "./../../images/redBin.svg";
import { routesPaths } from "constants/routes.constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDispatch } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import { companyDelete } from "features/company/companySlice";
import { packageIndex } from "features/package-subscription/packageSlice";
import PopupConfirmation from "components/ui/popup/PopupConfirmation";

const CompanyDetail = ({ company, loadCompany }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);
  const [isDelete, setDelete] = useState(false);

  const editHandler = () => {
    navigate(`/company-detail/${company.id}/edit`);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
  };

  const [packagesList, setPackagesList] = useState({
    data: []
  });

  const doDelete = () => {
    let companyID = company.id;
    dispatch(
      companyDelete({
        company_id: companyID,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        loadCompany();
        setDelete(false);
      }
    });

  }
  return (
    <>
      {isDelete && <PopupConfirmation title="Are you sure want to delete this company?" successButtonTitle="Delete" cancelButtonTitle="Cancel" onSuccess={doDelete} onCancel={setDelete} />}
      <div className="hidden laptop:flex flex-row items-center justify-center font-light text-sm p-2" data-testid="company" style={{ borderBottomWidth: "1px" }}>
        <section className="flex items-center justify-center font-medium px-2">
          <button onClick={handleOpen}><FontAwesomeIcon icon={faEllipsisVertical} style={{ fontSize: "15px", }} /></button>
        </section>
        <section className="basis-1/4 flex items-center justify-center font-medium">
          <div className="w-[150px]">{company.name}
          </div></section>
        <section className="basis-1/4 flex items-center justify-center font-medium">
          <div className="w-[50px]">{company.id}
          </div></section>
        <section className="basis-1/4 flex items-center justify-center font-medium">
          <div className="w-[200px]">{company.billing_address}
          </div></section>
        <section className="basis-1/4 flex items-center justify-center font-medium">
          <div className="w-[80px]">{company.business_contact_number}
          </div></section>
        <section className="basis-1/4 flex items-center justify-center font-medium">
          <div className=""> {company?.packages?.package?.package_name || 'Not Subscribe'}
          </div></section>
        <section className="basis-1/4 flex items-center justify-center font-medium">
          <div className=""> {company?.role_id?.role_name}
          </div></section>
        <section className="basis-1/4 flex items-center justify-center font-medium">
          <img src={editUser} className="w-4 h-4 cursor-pointer mr-2" onClick={() => editHandler()}
            style={!window.location.pathname.includes(routesPaths.PACKAGE_DETAIL) ? { backgroundColor: "white", color: "black" } : { backgroundColor: "#00AF54", color: "white" }} /> /
          <img src={deleteUser} className="w-4 h-4 cursor-pointer ml-2" onClick={() => setDelete(true)} /></section>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div className="flex justify-between items-start" style={{ borderBottomWidth: "1px" }}>
                <div>Company Details</div>
                <button onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} style={{ fontSize: "15px", }} />
                </button>
              </div>
            </Typography>
            <Typography id="modal-modal-description" >
              <div className="flex col-span-2 gap-4 items-center justify-center font-regular text-sm">
                <div className="mr-8 font-semibold" >
                  <div className="p-2">Company Name: </div>
                  <div className="p-2">Company ID: </div>
                  <div className="p-2">Address: </div>
                  <div className="p-2">Contact No: </div>
                  <div className="p-2">Package: </div>
                  <div className="p-2">Account Type: </div>
                  <div className="p-2">Subs Start Date: </div>
                  <div className="p-2">Subs End Date: </div>
                  <div className="p-2">Service Charge: </div>
                  <div className="p-2">Service Charge Type: </div>
                  <div className="p-2">Status: </div>
                  <div className="p-2">Payment Method: </div>
                  <div className="p-2">Payment Status: </div>
                  <div className="p-2">Payment Date: </div>
                  <div className="p-2">Created at: </div>
                  <div className="p-2">Updated at: </div>
                  <div className="p-2">Actions: </div>
                </div>
                <div>
                  <div className="p-2">{company.name || '-'}</div>
                  <div className="p-2">{company.id || '-'}</div>
                  <div className="p-2">{company.billing_address || '-'}</div>
                  <div className="p-2">{company.business_contact_number || '-'}</div>
                  <div className="p-2">{company?.packages?.package?.package_name || '-'}</div>
                  <div className="p-2">{company?.role_id?.role_name || '-'}</div>
                  <div className="p-2">{formatDate(company?.packages?.subs_start_date) || '-'}</div>
                  <div className="p-2">{formatDate(company?.packages?.subs_end_date) || '-'}</div>
                  <div className="p-2">{company.packages?.service_charge || '-'} % </div>
                  <div className="p-2">{company?.packages?.service_charge_type === 1 ? "Fixed" : company?.packages?.service_charge_type === 0 ? "Percentage" : "-"}</div>
                  <div className="p-2">{company?.packages?.status === 1 ? "Active" : company?.packages?.status === 0 ? "Not Active" : '-'}</div>
                  <div className="p-2">{company?.packages?.payment_method || '-'}</div>
                  <div className="p-2">{company?.packages?.payment_status === 1 ? "Paid" : company?.packages?.payment_status === 0 ? "Unpaid" : '-'}</div>
                  <div className="p-2">{formatDate(company.packages?.payment_date) || '-'}</div>
                  <div className="p-2">{formatDate(company.created_at)}</div>
                  <div className="p-2">{formatDate(company.updated_at)}</div>
                  <div className="basis-1/4 flex items-center justify-start p-2">
                    <img src={editUser} className="w-4 h-4 cursor-pointer mx-2" onClick={() => editHandler()} />Edit / <img src={deleteUser} className="w-3 h-3 cursor-pointer mx-2" onClick={() => setDelete(true)} /> Delete</div>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default CompanyDetail;