import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
const OrderFarmer = ({ orderFarmer }) => {
    const navigate = useNavigate();
    let status = "";
    let statusText = "";

    if (orderFarmer.status === 0) {
        status = "primary";
    } else if (orderFarmer.status === 1) {
        status = "warning";
    } else if (orderFarmer.status === -1) { //need confirm quantity from booking
        status = "warning";
    }  else if (orderFarmer.status === 9) { //need confirm quantity from booking
        statusText = 'Order created';
        status = "warning";
    }  else if (orderFarmer.status === 10) { //need confirm quantity from booking
        statusText = 'Farmer start to packaging';
        status = "warning";
    } else if (orderFarmer.status === 5) {
        status = "danger";
    } else {
        status = "success";
    }

    const editHandler = () => {
        navigate(`/customer/order-farmers/${orderFarmer.id}/tracking`);
    };

    return (
        // <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-formDark shadow rounded-lg w-full">
        //     <div className =" w-full flex p-2 gap-4 justify-between items-center">
        //        <section className="flex items-center gap-x-8">
        //           <img
        //               // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
        //               src={orderFarmer.detail.product.photo_default}
        //               alt={orderFarmer.detail.product.name}
        //               className="  orderLarge:inline min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-lg"
        //           />
        //           <div className="flex flex-col">
        //               <label className="text-2xl">Order #{orderFarmer.id} </label>

        //               <label>{`${orderFarmer.detail.quantity} ${orderFarmer.detail.grade.quantity_unit_capital }`}</label>
        //               <label>{`${orderFarmer.detail.grade.name}`}</label>

        //               <label>{`${orderFarmer.detail.product.demand ? orderFarmer.detail.product.demand.frequency_label : ""}`}</label>


        //               {/*<label className="text-2xl">Order #{orderFarmer.id} </label>*/}
        //               {/*<label>{`${orderFarmer.detail.quantity} ${orderFarmer.detail.product.grade.quantity_unit_capital }`}</label>*/}
        //               {/*<label>{`${orderDetail.quantity} ${$orderDeta.product.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
        //           </div>
        //       </section>
        //       <section className="flex gap-4 items-center">
        //           <div className="hidden laptop:flex gap-3 items-center">
        //               <label>Price</label>
        //               <label className="indentedText text-center w-[7rem]">
        //                   {/*{info.currency}{info.price.toFixed(2)}*/}

        //                   {orderFarmer?.price_unit} {orderFarmer.amount_total_overall}

        //               </label>
        //           </div>
        //           <div className="hidden orderSmall:flex gap-3 items-center">
        //               <label>Status</label>
        //               <label className="indentedText text-center w-[7rem]">
        //                   {orderFarmer.status_text}
        //                   {/*{info.status ? info.status : "Loading..."}*/}
        //               </label>
        //            </div>
        //       </section>
        //     </div>
        //     <button className="borderedButton mb-4 mt-[-10px] w-[100px] mr-auto ml-auto" onClick={editHandler}>Details</button>
        // </div>
        <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full" style={{borderBottomWidth: "1px"}}>
            {/* <div className="flex flex-row items-center justify-center pb-3" style={{borderBottomWidth: "1px"}}>
              <div className="basis-1/4 flex items-center justify-center font-medium">Orders</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Price</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
              <div className="basis-1/4 flex items-center justify-center font-medium"></div>
            </div> */}
          <div className =" w-full flex flex-row p-2 gap-4 items-center ">
               <section className="basis-1/4 desktop:flex items-center gap-x-8 ">
                  <img
                      // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
                      src={orderFarmer.detail.product.photo_default}
                      alt={orderFarmer.detail.product.name}
                      className="  orderLarge:inline min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-full"
                  />
                  <div className="hidden lg:flex flex-col">
                      <label className="text-2md font-semibold">Order #{(orderFarmer.order.order_number) ? orderFarmer.order.order_number : orderFarmer.id} </label>
                      <label>{`${orderFarmer.detail.quantity} ${orderFarmer.detail.grade.quantity_unit_capital }`}</label>
                      <label>{`${orderFarmer.detail.grade.name}`}</label>
                      <label>{`${orderFarmer.detail.product.demand ? orderFarmer.detail.product.demand.frequency_label : ""}`}</label>
                      {/*<label>{`${orderDetail.quantity} ${$orderDeta.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
                  </div>
               </section>
               <section className="basis-1/4 flex gap-4 items-center justify-center productSmall:flex-col">
                  <div className="flex flex-col gap-1 items-center ">
                    <label className="lg:hidden text-2md font-semibold text-center">Order #{(orderFarmer.order.order_number) ? orderFarmer.order.order_number : orderFarmer.id} </label>
                    <label className="lg:hidden text-center">
                        <span className="text-sm font-semibold">Quantity:</span>
                        <label className="ml-2">{`${orderFarmer.detail.quantity} ${orderFarmer.detail.grade && orderFarmer.detail.grade.quantity_unit_capital }`}</label> </label>
                    <label className="lg:hidden" style={{textAlign:"center"}}>
                        <span className="text-sm font-semibold">Grade:</span>
                        <span className="ml-2">{`${orderFarmer.detail.grade.name}`}</span> </label>
                    <label className=" text-center w-[7rem]">
                        {/*{info.currency}{info.price.toFixed(2)}*/}
                        <span className="text-sm font-semibold">Price:</span>
                        <label className="ml-2">{orderFarmer?.price_unit} {orderFarmer.amount_total_overall}</label> </label>
                  </div>
                </section>
              
                <section className="basis-1/4 flex gap-4 items-center justify-center">
                    
                  <div className="hidden orderSmall:flex gap-3 items-center">
                      
                      <label className="text-center w-[7rem]">
                      <Badge bg={status} className={(orderFarmer.status == -1) ? 'text-black' : ''}>{orderFarmer.status_text || statusText}</Badge>
                          {/*{info.status ? info.status : "Loading..."}*/}
                      </label>
                  </div>
                </section>
                <section className="basis-1/4 flex gap-4 items-center justify-end">
                    <button className="hidden laptop:flex btn-border-green w-[120px] w-[100px] items-center justify-center text-center" onClick={editHandler}>Details</button> 
                </section>
            </div>
            <div className="inline laptop:hidden wide:hidden flex w-full space-x-2 text-center mb-2">
                <button className="btn-border-green w-[150px] mr-auto ml-auto mb-3" onClick={editHandler}>Details</button>
            </div>
        </div>
    );
};

export default OrderFarmer;
