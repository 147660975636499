import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

import SnackbarContext from "_helpers/snackbar-context";

import styles from "./Snackbar.module.css";
import { Col } from "react-bootstrap";
import { requestPermission } from "firebase-config";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fcmTokenUpdate } from "features/auth/authSlice";

const SnackbarAlertPermission = ({ setFcmKey }) => {
    const dispatch = useDispatch();
    const { currentUser, fcmKey, isLoggedIn } = useSelector((state) => state.auth);
    const [isShowNotif, setIsShowNotif] = useState(false);
    const [msg, setMsg] = useState("");

    const requestPermissionAlert = () => {
        requestPermission(setFcmKey);
    }

    useEffect(() => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (fcmKey == null) {
            if (! /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setMsg("Allow notifications browser to get notifications");
                setIsShowNotif(true);
            }
        } else {
            if (isLoggedIn) {
                if (currentUser?.fcm_token == null || currentUser?.fcm_token == '') {
                    setMsg("Await Waiting for the token to be saved");
                    setIsShowNotif(true);
                    callApiUpdateToken(fcmKey);
                } else {
                    if (fcmKey == currentUser?.fcm_token) {
                        setIsShowNotif(false);
                    } else {
                        setMsg("Waiting for the token to be saved");
                        setIsShowNotif(true);
                        callApiUpdateToken(fcmKey);
                    }
                }
            } else {
                setIsShowNotif(false);
            }
        }
    }, [fcmKey, isLoggedIn]);

    const callApiUpdateToken = (token) => {
        dispatch(
            fcmTokenUpdate({
                fcm_token: token,
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
            } else {
                // let authUser = JSON.parse(localStorage.getItem("user"));
                // console.log('response: ', response.payload.data.user.fcm_token);
                // console.log(currentUser?.fcm_token);
                // authUser.data.user.fcm_token = response.payload.data.user.fcm_token;
                // localStorage.setItem("user", JSON.stringify(authUser));
                console.log(response.payload.data.user.fcm_token);
                setIsShowNotif(false);
            }
        });
    }

    return (
        <>
            {isShowNotif && (
                <>
                    <div className={`${styles.snackbarContainer}`} style={{ backgroundColor: "#ffa500", right: "10px" }}>
                        <Col>
                            <div style={{ fontSize: "16px", color: "white" }}>{msg}</div>
                        </Col>
                        {/* <button className={styles.snackbarDismiss} onClick={() => setIsShowNotif(false)}>
                            &times;
                        </button> */}
                    </div>
                </>
            )}
        </>

    )
};

export default SnackbarAlertPermission;