import { useState, useEffect, useContext } from "react";
// import {farmShow, farmStore} from "../../../features/farm/farmSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { plotStore } from "../../../features/plot/plotSlice";
import { cropIndex } from "../../../features/crop/cropSlice";
import AccountLayout from "pages/AccountLayout";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";

const PlotCreate = () => {
  const { updating } = useSelector((state) => state.plot);
  const snackbarCtx = useContext(SnackbarContext);

  const [name, setName] = useState("");
  const [cropName, setCropName] = useState("");
  const [plantingStartDate, setPlantingStartDate] = useState("");
  const [harvestStartDate, setHarvestStartDate] = useState("");
  const [harvestEndDate, setHarvestEndDate] = useState("");
  const [harvestExpectedQuantity, setHarvestExpectedQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [cropId, setCropId] = useState("");
  const [cropStage, setCropStage] = useState("")

  const [cropList, setCropList] = useState({
    data: [],
  });
  // const [farmList, setFarmList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // const snackbarCtx = useContext(SnackbarContext);
  const save = (event) => {
    event.preventDefault();

    let farmId = params.farm_id;
    if (parseInt(harvestExpectedQuantity) < 0) {
      snackbarCtx.displayMsg("Quantity cannot be zero.", "failure");
      return;
    }

    dispatch(
      plotStore({
        farm_id: params.farm_id,
        name: name,
        crop_name: cropName,
        planting_start_date: plantingStartDate,
        harvest_start_date: harvestStartDate,
        harvest_end_date: harvestEndDate,
        harvest_expected_quantity: harvestExpectedQuantity,
        unit: unit,
        crop_id: cropId,
        crop_stage: cropStage
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        let plot = response.payload.data.plot;

        navigate(`/farms/${farmId}/plots/${plot.id}/edit`);
      }
    });

  };

  const loadCrops = () => {
    let farmId = params.farm_id;

    dispatch(
      cropIndex({
        farm_id: farmId,
        page: 1,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        let crops = response.payload.data.crops;

        setCropList(crops);
        // let plot = response.payload.data.plot;

        // navigate(`/farms/${farmId}/plots/${plot.id}/edit`);
      }
    });
  };

  useEffect(() => {
    loadCrops();
  }, []);

  return (
    <AccountLayout>
      <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
        <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
          {" Go Back"}
        </label>
        <div className="title-content mb-3">
          Create Plot Details
        </div>
        <div className="flex flex-col rounded-lg shadow bg-card-white-new">
          <Row>
            <Col md={6}>
              <button type="submit" className="btn-greyed mb-2" title={"Upload photos can be done after saving"} >
                Upload Photos
              </button>
            </Col>
            <Col md={1}>
            </Col>
            <Col md={5}>
              <form className="flex flex-col gap-4" onSubmit={save}>
                <div className="flex flex-col gap-2">
                  <label>Plot Name:</label>
                  <input
                    type="text"
                    className="input-search"
                    placeholder="Plot Name"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label>Crop Name:</label>
                  <select className="input-search" defaultValue={"DEFAULT"} onChange={({ target }) => setCropId(target.value)}>
                    <option value="DEFAULT" disabled>
                      Select Crop
                    </option>
                    {cropList.data.map((crop, index) => {
                      return <option value={crop.id} key={index}>{crop.name}</option>;
                    })}
                  </select>
                </div>
                <div className="flex flex-col">
                  <label>Stage of the crop:</label>
                  <input
                    className="input-search"
                    type="text"
                    placeholder="Stage of the crop"
                    value={cropStage}
                    onChange={({ target }) => setCropStage(target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label>Planting Start Date:</label>
                  <input
                  className="input-search"
                    type="date"
                    placeholder="Planting Start Date"
                    value={plantingStartDate}
                    onChange={({ target }) => setPlantingStartDate(target.value)}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>Harvesting Start Date:</label>
                    <input
                      type="date"
                      className="input-search"
                      placeholder="Harvesting Start Date"
                      value={harvestStartDate}
                      onChange={({ target }) => setHarvestStartDate(target.value)}
                    />
                  </div>
                  <div className="w-1/2 flex flex-col gap-2">
                    <label>Harvesting End Date:</label>
                    <input
                      type="date"
                      className="input-search"
                      placeholder="Harvesting End Date"
                      value={harvestEndDate}
                      onChange={({ target }) => setHarvestEndDate(target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label>Expected Harvest Quantity:</label>
                  <div className="flex gap-4">
                    <input
                      type="number"
                      min="0"
                      className="input-search"
                      placeholder="Quantity"
                      value={harvestExpectedQuantity}
                      onChange={({ target }) => setHarvestExpectedQuantity(target.value)}
                    />
                    <select className="input-search w-1/3" defaultValue={"DEFAULT"} onChange={({ target }) => setUnit(target.value)}>
                      <option value="DEFAULT" disabled>
                        Unit
                      </option>
                      <option value="Kg">Kg</option>
                      <option value="Lbs">Lbs</option>
                    </select>
                  </div>
                </div>
                <div className=" mt-4">
                  <button type="submit" className="saveButton">
                    {/*Save*/}
                    {updating ? "Processing, please wait.." : "Save"}
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </AccountLayout>
  );
};

export default PlotCreate;
