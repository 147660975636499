// import AccountLayout from "pages/AccountLayout";
// import { useState } from "react";
// import FarmControls from "../FarmControls";
//
// import AddCrop from "./AddCrop";
// import CollectionCenter from "./CollectionCenter";
// import Crop from "./Crop";
// import CropGrade from "./CropGrade";
//
const FarmCropStock = () => {
//   const [centerName, setCenterName] = useState("Green Farms Center #7");
//   const [crops, setCrops] = useState([
//     {
//       name: "",
//       category: "",
//       grades: [
//         {
//           name: "",
//           price: "",
//           quantity: "",
//           criteria: "",
//           price_unit: "",
//           quantity_unit: "",
//         },
//       ],
//     },
//   ]);
//   // const [crops, setCrops] = useState([{ cropInfo: { cropName: "", cropCategory: "" }, cropIndex: 0 }]);
//   const [grade, setGrade] = useState("");
//   const [priceUnit, setPriceUnit] = useState("");
//   const [quantityUnit, setQuantityUnit] = useState("");
//
//   // const [cropName, setCropName] = useState(cropInfo.cropName);
//   // const [cropCategory, setCropCategory] = useState(cropInfo.cropCategory);
//
//   const addCrop = () => {
//     console.log("add crop");
//
//     // setCrops([...crops, { cropInfo: { cropName: "", cropCategory: "" }, cropIndex: crops.length }])
//     setCrops([
//       ...crops,
//       {
//         name: "",
//         category: "",
//         grades: [
//           {
//             name: "init grade",
//             price: "",
//             quantity: "",
//             criteria: "",
//             price_unit: "",
//             quantity_unit: "",
//           },
//         ],
//       },
//     ]);
//   };
//
//   const addCropGrade = (crop, cropIndex) => {
//     let clonedCrops = [...crops];
//
//     let grades = crops[cropIndex].grades;
//
//     clonedCrops[cropIndex].grades[grades.length] = {
//       name: "",
//       price: "",
//       quantity: "",
//       criteria: "",
//       price_unit: "",
//       quantity_unit: "",
//     };
//
//     setCrops(clonedCrops);
//   };
//
//   const deleteCropGrade = (crop, cropIndex, grade, gradeIndex) => {
//     console.log(" gradeIndex ", gradeIndex);
//     let clonedCrops = [...crops];
//
//     clonedCrops[cropIndex].grades.splice(gradeIndex, 1);
//
//     console.log(" cloned crops ", clonedCrops);
//
//     setCrops(clonedCrops);
//   };
//
//   const update = (event) => {
//     alert("update everything here from store information, crops information, and grades information");
//
//     event.preventDefault();
//   };
//   return (
//     <AccountLayout>
//       <FarmControls />
//       <div className="bg-formDark flex flex-col space-y-8 p-4 rounded-md drop-shadow-md">
//         <div className="self-center flex flex-col max-w-[45rem]">
//           <form onSubmit={update}>
//
//
//             <h1 className="text-xl ">Crop & Stock Basic Information</h1>
//
//             {/*This is the header and store information -->*/}
//
//             <label className="border-b-2 border-green-500">Collection Center 1</label>
//             <div className="mx-14 flex gap-x-8 justify-center items-center mt-2">
//               <input type="text" placeholder={centerName} />
//               <button type="button" className="hover:bg-green-500 hover:text-white">
//                 Manage Collection Centers
//               </button>
//             </div>
//
//
//
//
//             <div className="flex items-center gap-x-2 border-b-2 border-green-500 mt-5">
//               <label className="text-lg  ">Add Crop</label>
//               <input
//                 type={"button"}
//                 className="flex justify-center items-center bg-green-500 rounded-md h-7 w-7"
//                 onClick={addCrop}
//                 value={"+"}
//               />
//             </div>
//
//             <div className="rounded-b-md p-4 space-y-4">
//               {crops.map((crop, cropIndex) => {
//                 return (
//                   <div className="flex flex-col space-y-4">
//                     {/*This is crop information */}
//
//                     <label className="text-lg border-b-2 border-green-500">Crop</label>
//                     <div className="flex mx-6 justify-between gap-x-8">
//                       <input
//                         type="text"
//                         placeholder="Crop Name"
//                         value={crop.cropName}
//                         onChange={({ target }) => (crop.cropName = target.value)}
//                       />
//                       <select defaultValue="DEFAULT" onChange={({ target }) => (crop.categoryName = target.value)}>
//                         <option value="DEFAULT" disabled>
//                           Choose Category
//                         </option>
//                         <option value="Fruits">Fruits</option>
//                         <option value="Fish">Fish</option>
//                         <option value="Vegetables">Vegetables</option>
//                         <option value="Meat">Meat</option>
//                       </select>
//                     </div>
//
//                     {/*This is grade information */}
//                     <div className="flex flex-col space-y-4 self-center w-11/12">
//                       <label className="text-lg border-b-2 border-green-500">Grade</label>
//
//                       <div className="flex items-center gap-x-2 border-b-2 border-green-500">
//                         <label className="text-lg  ">Add Grade</label>
//                         <input
//                           type={"button"}
//                           className="flex justify-center items-center bg-green-500 rounded-md h-7 w-7"
//                           onClick={() => addCropGrade(crop, cropIndex)}
//                           value={"+"}
//                         />
//                       </div>
//
//                       {crop.grades.map((grade, gradeIndex) => {
//                         return (
//                           <div className={"border p-2"}>
//                             <hr />
//
//                             <b onClick={() => deleteCropGrade(crop, cropIndex, grade, gradeIndex)}> Delete </b>
//                             <div className="flex justify-between gap-x-8 mb-2">
//                               <input type="text" placeholder="Grade Name" />
//                               <select
//                                 defaultValue={"DEFAULT"}
//                                 onChange={({ target }) => {
//                                   grade.name = target.value;
//                                   console.log(" target.value ", target.value);
//                                 }}
//                               >
//                                 <option value="DEFAULT" disabled>
//                                   Choose Grade Criteria
//                                 </option>
//                                 <option value="Grade Criteria 1">Grade Criteria 1</option>
//                                 <option value="Grade Criteria 2">Grade Criteria 2</option>
//                                 <option value="Grade Criteria 3">Grade Criteria 3</option>
//                                 <option value="Grade Criteria 4">Grade Criteria 4</option>
//                               </select>
//                             </div>
//                             <div className="flex justify-between gap-x-8 mb-2">
//                               <input type="text" placeholder="Price" />
//                               <select defaultValue={"DEFAULT"} onChange={({ target }) => setPriceUnit(target.value)}>
//                                 <option value="DEFAULT" disabled>
//                                   Currency
//                                 </option>
//                                 <option value="USD">USD $</option>
//                                 <option value="EUR">EUR €</option>
//                                 <option value="JPY">JPY ¥</option>
//                                 <option value="GBP">GBP £</option>
//                                 <option value="AUD">AUD $</option>
//                                 <option value="RMB">CNY 元</option>
//                                 <option value="PHP">PHP ₱</option>
//                               </select>
//                             </div>
//                             <div className="flex justify-between gap-x-8 mb-2">
//                               <input type="text" placeholder="Quantity" />
//                               <select defaultValue={"DEFAULT"} onChange={({ target }) => setQuantityUnit(target.value)}>
//                                 <option value="DEFAULT" disabled>
//                                   Unit
//                                 </option>
//                                 <option value="Kg">Kg</option>
//                                 <option value="Lbs">Lbs</option>
//                               </select>
//                             </div>
//                           </div>
//                         );
//                       })}
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>
//
//             <input type={"submit"} value={"update"} />
//           </form>
//         </div>
//       </div>
//     </AccountLayout>
//   );
};
//
export default FarmCropStock;
