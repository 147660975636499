import { configureStore } from '@reduxjs/toolkit'
import registerSlice from "../features/auth/registerSlice";
import loginSlice from "../features/auth/loginSlice";
import logoutSlice from "../features/auth/logoutSlice";
import authSlice from "../features/auth/authSlice";
import farmSlice from "../features/farm/farmSlice";
import plotSlice from "../features/plot/plotSlice";
import fileSlice from "../features/file/fileSlice";
import gradeSlice from "../features/grade/gradeSlice";
import cropSlice from "../features/crop/cropSlice";
import storeSlice from "../features/store/storeSlice";
import productSlice from "../features/product/productSlice";
import catalogSlice from "../features/catalog/catalogSlice";
import categorySlice from "../features/category/categorySlice";
import demandSlice from "../features/demand/demandSlice";
import userSlice from "../features/user/userSlice";
import demandApplicationSlice from "../features/demand-application/demandApplicationSlice";
import orderSlice from "../features/order/orderSlice";
import orderDetailSlice from "../features/order-detail/orderDetailSlice";
import orderFarmerSlice from "../features/order-farmer/orderFarmerSlice";
import urustaniIntegrateionSlice from "../features/urustani-integration/urustaniIntegrationSlice";
import forgotPasswordSlice from "../features/auth/forgotPasswordSlice";
import newPasswordSlice from "../features/auth/newPasswordSlice";
import catalogPlotsSlice from "../features/catalog/plots/catalogPlotsSlice";
import advanceBookingSlice from "../features/advance-booking/advanceBookingSlice";
import orderFarmerDeliverySlice from "../features/order-farmer-delivery/orderFarmerDeliverySlice";
import settingDepositSlice from 'features/settingDeposit/settingDepositSlice';
import roomChatSlice from 'features/room-chat/roomChatSlice';
import varietySlice from 'features/variety/varietySlice';
import unitOfMeasureSlice from 'features/unit-of-measure/unitOfMeasureSlice';

export const store = configureStore({
        reducer: {
            register             : registerSlice.reducer,
            login                : loginSlice.reducer,
            logout               : logoutSlice.reducer,
            auth                 : authSlice.reducer,
            user                 : userSlice.reducer,
            farm                 : farmSlice.reducer,
            file                 : fileSlice.reducer,
            plot                 : plotSlice.reducer,
            store                : storeSlice.reducer,
            crop                 : cropSlice.reducer,
            grade                : gradeSlice.reducer,
            product              : productSlice.reducer,
            catalog              : catalogSlice.reducer,
            category             : categorySlice.reducer,
            demand               : demandSlice.reducer,
            demandApplication    : demandApplicationSlice.reducer,
            order                : orderSlice.reducer,
            orderDetail          : orderDetailSlice.reducer,
            orderFarmer          : orderFarmerSlice.reducer,
            urustaniIntegrateion : urustaniIntegrateionSlice.reducer,
            forgotPassword       : forgotPasswordSlice.reducer,
            newPassword          : newPasswordSlice.reducer,
            catalogPlotsSlice    : catalogPlotsSlice.reducer,
            advanceBooking  : advanceBookingSlice.reducer,
            orderFarmerDelivery : orderFarmerDeliverySlice.reducer,
            setting  : settingDepositSlice.reducer,
            roomChat  : roomChatSlice.reducer,
            variety  : varietySlice.reducer,
            unitOfMeasure  : unitOfMeasureSlice.reducer,
            devTools : true,
        }
    }
)