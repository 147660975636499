import clsx from "clsx";
import { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { plotDetail, bookingPlot, getBookingPlotByUser, getStatesList, getAddress, getBidPriceLast, bidAucPlot, getBidStatusUser, forefeitBid, getDataBidders } from "../../../features/catalog/plots/catalogPlotsSlice";
import { settingDepositGet } from "features/settingDeposit/settingDepositSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SnackbarContext from "_helpers/snackbar-context";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";
import { Container, Col, Row, Modal, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faCircleXmark,  faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
import PickMapLocation from "components/map/PickMapLocation";
import Snackbar from "components/ui/snackbar/Snackbar";
import Footer from "components/footer/footer";
import { onMessageListener } from "firebase-config";
import '../../css/FloatChat.css'
import ChatRoomFloating from "components/chat-room/ChatRoomFloating";
import { updateActiveRoomChat, updateInActiveRoomChat } from "features/room-chat/roomChatSlice";
const PlotsDetailForm = () => {
    const [plot, setPlot] = useState(null);
    const [photos, setPhotos] = useState([]);
    const { isLoggedIn, currentUser, currentCompany } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [isSelfPlot, setIsSelfPlot] = useState(false);
    const [showFLoatChat, setShowFLoatChat] = useState(false);

    //modal confirm booking
    const [bookingQuantity, setBookingQuantity] = useState(0);
    const [minDepositAdvancedBooking, setMinDepositAdvancedBooking] = useState(null);
    const [modalConfirmBook, setModalConfirmBook] = useState(false);
    const [bookingInProgress, setBookingInProgress] = useState(false);
    const [formBooking, setFormBooking] = useState(1);
    const popupCloseModalConfirmBook = () => setModalConfirmBook(false);
    const popupShowModalConfirmBook = () => setModalConfirmBook(true);
    const [isLoadingBooking, setIsLoadingBooking] = useState(false);
    const [isEnabledDeposit, setIsEnabledDeposit] = useState(0);

    //modal location shipping address
    const [location, setLocation] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [latModal, setLatModal] = useState(null);
    const [lngModal, setLngModal] = useState(null);
    const [statesList, setStatesList] = useState([]);
    const [stateName, setStateName] = useState(null);
    const [city, setCity] = useState(null);
    const [postCode, setPostCode] = useState(null);
    const [address, setAddress] = useState(null);
    const [fullname, setFullname] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [msgErrorInModalShipAddress, setMsgErrorInModalShipAddress] = useState("");

    const snackbarCtx = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const params = useParams();
    const currentLocation = useLocation();

    // modal auction
    const [modalConfirmAuc, setModalConfirmAuc] = useState(false);
    const popupCloseModalConfirmAuc = () => setModalConfirmAuc(false);
    const popupShowModalConfirmAuc = () => setModalConfirmAuc(true);
    const [msgErrorInModal, setMsgErrorInModal] = useState("");
    const [statusUserPlot, setStatusUserPlot] = useState("NOT_BID");
    const [statusUserAddress, setStatusUserAddress] = useState("NOT_AVAILABLE");

    // modal auction bidding
    const [modalPlaceBid, setModalPlaceBid] = useState(false);
    const popupCloseModalPlaceBid = () => setModalPlaceBid(false);
    const popupShowModalPlaceBid = () => setModalPlaceBid(true);

    // modal availabel user address 
    const [modalAvailUserAddress, setModalAvailUserAddress] = useState(false);
    const popupCloseModalAvailUserAddress = () => setModalAvailUserAddress(false);
    const popupShowModalAvailUserAddress = () => setModalAvailUserAddress(true);

    // modal auction bidding
    const [modalForefitConf, setModalForefitConf] = useState(false);
    const popupCloseModalForefitConf = () => setModalForefitConf(false);
    const popupShowModalForefitConf = () => setModalForefitConf(true);

    // modal view bidders
    const [modalViewBidders, setModalViewBidders] = useState(false);
    const popupCloseModalViewBidders = () => {
        setModalViewBidders(false);
    };
    const popupShowModalViewBidders = () => {
        setModalViewBidders(true);
        loadListBidders();
    };

    //form input bidding
    const [bidPriceYou, setBidPriceYou] = useState(0);
    const [totalbidPriceYou, setTotalBidPriceYou] = useState(0);
    const [availableQty, setAvailableQty] = useState(0);
    const [bidPriceCurrent, setBidPriceCurent] = useState(0);
    const [countDownCurrent, setCountDownCurrent] = useState(null);
    const [auctionPlotLast, setAuctionPlotLast] = useState(null);
    const [countBid, setCountBid] = useState(0);
    const [auctionPlotBidders, setAuctionPlotBidders] = useState(null);

    const goEditAccount = () => {
        navigate(`/account/edit/Address`);
    };

    const goLoginPage = () => {
        navigate('/login', { state: { from: currentLocation } });
    };

    const loadStates = () => {
        dispatch(
            getStatesList()
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
                // console.log(response.payload);
            } else {
                let states = response.payload.data.states;
                // console.log(states);
                setStatesList(states);
            }
        });
    };

    const loadPlot = () => {
        dispatch(
            plotDetail({
                plot_id: params.plot_id,
            })
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
                // console.log(response.payload);
            } else {
                let plot = response.payload.data.plot;
                // console.log(plot);
                setPlot(plot);
                let tempPhoto = [];
                tempPhoto.push({ src: plot.farm.photo_default, url: plot.farm.photo_default });
                setPhotos(tempPhoto);

                if (plot) {
                    if (plot.booking_type == 'advanced') {
                        loadSettingDeposit();
                    }
                }
            }
        });
    };

    const loadBookingPlotByUser = () => {
        setIsLoadingBooking(true);
        dispatch(
            getBookingPlotByUser({
                plot_id: params.plot_id,
                user_id: currentUser?.id 
            })
        ).then(function (response) {
            setIsLoadingBooking(false);
            if (response.error && response.error.message == "Rejected") {
                // console.log(response.payload);
            } else {
                let booking_plot = response.payload.data.booking_plot;
                if (booking_plot) {
                    if (booking_plot.status == 2) { //booking cancel
                        setBookingInProgress(false);
                    } else if (booking_plot.status == 0 || booking_plot.status == 1 || booking_plot.status == 3 || booking_plot.status == 4 || booking_plot.status == 5 || booking_plot.status == 13) {
                        //booking new, awaiting deposit, deposit paid, order created, waiting deposit confirmation
                        setBookingInProgress(true);
                    }

                    //order created
                    if (booking_plot.status == 4 && booking_plot.order_id) {
                        if (booking_plot.order_farmer.status == 5) { //order cancel
                            setBookingInProgress(false);
                        } else if (booking_plot.order_farmer.status == 3) { //order done
                            setBookingInProgress(false);
                        }
                    }
                    // setBookingInProgress((booking_plot.status != 2) ? true : false);
                }
            }
        });
    };

    const fillShippingAddress = (event) => {
        setFormBooking(2);
        // setLatitude(1.966773);
        // setLongitude(102.881449);
        event.preventDefault();
    }

    const setPickLocation = () => {
        setLatitude(latitude);
        setLongitude(longitude);
    }

    const loadAddress = () => {
        if (currentUser != null) {
            setFullname(currentUser?.name);
            setEmail(currentUser?.email);
        }
        dispatch(
            getAddress()
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                // console.log(response.payload);
            } else {
                let result = response.payload.data.user_address;
                if (result) {
                    let address = result.address;
                    setFullname(address.fullname);
                    setEmail(address.email);
                    setPhone(address.phone);
                    setStateName(address.state);
                    setCity(address.city);
                    setPostCode(address.postcode);
                    setAddress(address.address);
                    setLatitude(address.lat);
                    setLongitude(address.lon);
                } else {
                    setLatitude(1.966773);
                    setLongitude(102.881449);
                }
            }
        });
    };

    const loadSettingDeposit = () => {
        setIsLoadingBooking(true);
        dispatch(
            settingDepositGet()
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                let result = response.payload.data.setting;
                if (result) {
                    setIsEnabledDeposit(result.is_deposit_payment);
                }
                setIsLoadingBooking(false);
            }
        });
    }

    const confirmBooking = (event) => {
        setPickLocation();
        setIsLoadingBooking(true);
        let plotId = params.plot_id;
        dispatch(
            bookingPlot({
                plot_id: plotId,
                user_id: currentUser.id,
                company_id: currentCompany.id,
                quantity: bookingQuantity,
                quantity_unit: plot.selling_unit,
                shipping_address: {
                    fullname: fullname,
                    email: email,
                    phone: phone,
                    country: 'Malaysia',
                    state: stateName,
                    city: city,
                    postcode: postCode,
                    address: address,
                    lat: latitude,
                    lon: longitude
                }
            })
        ).then(function (response) {
            setIsLoadingBooking(false);
            if (response.error && response.error.message == "Rejected") {
                // popupCloseModalConfirmBook();
                setMsgErrorInModalShipAddress(response.payload);
                // snackbarCtx.displayMsg(response.payload, "failure");
            } else {
                setBookingInProgress(true);
                popupCloseModalConfirmBook();
                snackbarCtx.displayMsg(response.payload.message, "success");
            }
        });

        event.preventDefault();
    };

    const loadBidPriceLast = () => {
        dispatch(
            getBidPriceLast({
                plot_id: params.plot_id,
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                // console.log(response.payload);
                setIsLoading(false);
            } else {
                setBidPriceYou(parseFloat(response.payload.data.auction_plot.bid_price_you));
                setBidPriceCurent(parseFloat(response.payload.data.auction_plot.bid_price_current));
                setCountDownCurrent(response.payload.data.auction_plot.count_down_current);
                setAuctionPlotLast(response.payload.data.auction_plot.auction_plot_last);
                setAvailableQty(parseFloat(response.payload.data.auction_plot.auction_plot_last.available_quantity));
                setCountBid(response.payload.data.auction_plot.bidders.count);
                setIsLoading(false);
            }
        });
    };

    const loadListBidders = () => {
        setIsLoading(true);
        dispatch(
            getDataBidders({
                plot_id: params.plot_id,
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                // console.log(response.payload);
                setIsLoading(false);
            } else {
                setAuctionPlotBidders(response.payload.data.auction_plot);
                setIsLoading(false);

            }
        });
    };

    const loadBidStatusUser = () => {
        dispatch(
            getBidStatusUser({
                plot_id: params.plot_id,
                user_id: currentUser.id,
            })
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
                // console.log(response.payload);
            } else {
                setStatusUserPlot(response.payload.data.status_bid_user);
                setStatusUserAddress(response.payload.data.status_user_address);
            }
        });
    };

    const submitBidAucPlot = (event) => {
        setIsLoading(true);
        let plotId = params.plot_id;
        dispatch(
            bidAucPlot({
                plot_id: plotId,
                user_id: currentUser.id,
                company_id: currentCompany.id,
                bid_price: bidPriceYou

            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                // popupCloseModalPlaceBid();
                // snackbarCtx.displayMsg(response.payload, "failure");
                loadBidPriceLast();
                setMsgErrorInModal(response.payload);
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success");

                //load bid price last
                setBidPriceYou(parseFloat(response.payload.data.auction_plot.bid_price_you));
                setBidPriceCurent(parseFloat(response.payload.data.auction_plot.bid_price_current));
                setCountDownCurrent(response.payload.data.auction_plot.count_down_current);
                setAuctionPlotLast(response.payload.data.auction_plot.auction_plot_last);
                setAvailableQty(parseFloat(response.payload.data.auction_plot.auction_plot_last.available_quantity));
                setCountBid(response.payload.data.auction_plot.bidders.count);

                //load by user
                setStatusUserPlot(response.payload.data.auction_plot.status_bid_user);
                setStatusUserAddress(response.payload.data.auction_plot.status_user_address);

                popupCloseModalPlaceBid();
                popupShowModalConfirmAuc();
                setIsLoading(false);
            }
        });

        event.preventDefault();
    };

    const subForfeitBidding = (event) => {
        setIsLoading(true);
        let plotId = params.plot_id;
        dispatch(
            forefeitBid({
                plot_id: plotId,
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                loadBidPriceLast();
                setTimeout(() => {
                    snackbarCtx.displayMsg(response.payload, "failure", true);
                    setIsLoading(false);
                }, 1000);

            } else {
                snackbarCtx.displayMsg(response.payload.message, "success", true);
                loadBidPriceLast();
                loadBidStatusUser();
                popupCloseModalForefitConf();
                popupShowModalConfirmAuc();
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        });

        event.preventDefault();
    };

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        loadPlot();
        loadStates();
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            loadAddress();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        const unsubscribe = onMessageListener().then((payload) => {
            // console.log(`${payload?.notification?.title}: ${payload?.notification?.body}`);
        });

        return () => {
            unsubscribe.catch((err) => console.log('failed: ', err));
        };
    }, []);

    useEffect(() => {

        if (isLoggedIn && plot != null) {
            if (plot.booking_type == "advanced") {
                loadBookingPlotByUser();
            }
            if (plot.booking_type == "auction") {
                loadBidPriceLast();
                loadBidStatusUser();
            }

        }
    }, [isLoggedIn, plot]);

    useEffect(() => {

        if (!isLoggedIn && plot != null) {
            if (plot.booking_type == "auction") {
                loadBidPriceLast();
            }

        }
    }, [isLoggedIn, plot]);


    //check self plot
    useEffect(() => {

        if (isLoggedIn && plot != null && currentUser != null) {
            if (plot.user_id == currentUser.id) {
                setIsSelfPlot(true);
            }

        }
    }, [isLoggedIn, plot, currentUser]);

    useEffect(() => {
        if (plot != null) {

            if (plot.deposit_type == 1) {
                setMinDepositAdvancedBooking(plot.min_deposit_amount);
            } else if (plot.deposit_type == 2) {
                let percent = parseFloat(plot.min_deposit_amount) / 100;
                let minDeposit = bookingQuantity * plot.selling_price * percent;
                setMinDepositAdvancedBooking(minDeposit.toFixed(2));
            }
        }

    }, [bookingQuantity, plot])

    useEffect(() => {
        if (availableQty != null && bidPriceYou != null) {
            setTotalBidPriceYou(bidPriceYou * availableQty);
        }

    }, [bidPriceYou, availableQty]);

    return (
        <Container className="margin-content mb-20">

            <Row className="mb-2 mt-2">
                <Col md={12} className="head-bar-nav">
                    <Link to={routesPaths.HOME} className="head-menu">
                        <span>Home</span>
                    </Link>
                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                    <Link to={routesPaths.CATALOG} className="head-menu">
                        <span>Farmers Produce</span>
                    </Link>
                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                    <span className="tag-current-bar">View Plot</span>
                </Col>

            </Row>
            <Row className="mb-2">
                <Col md={12} className="title-content">
                    <span>Plots Details</span>
                </Col>
            </Row>
            <Row>
                <Col md={7} className="title-content">
                    <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadPlot} disableEdit={true} />
                </Col>
                <Col md={5} className="title-content">
                    <div className="bg-plot-detail-content" style={{ minHeight: "547px" }}>
                        <span className="title-content">{(plot) ? plot.name : '-'}</span>

                        <Row className="mt-3">
                            <Col md={6}>
                                <p className="text-gray-sm">Crop Name</p>
                                <p className="text-dark-green-sm">{(plot) ? plot.crop.name : ''}</p>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={6}>
                                <p className="text-gray-sm">Stage of the crop</p>
                                <p className="text-dark-green-sm">{(plot) ? plot.crop_stage : ''}</p>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={6}>
                                <p className="text-gray-sm">Planting Start Date</p>
                                <p className="text-dark-green-sm">{(plot) ? plot.planting_start_date_formatted : ''}</p>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={6}>
                                <p className="text-gray-sm">Harvesting Start Date</p>
                                <p className="text-dark-green-sm">{(plot) ? plot.harvest_start_date_formatted : ''}</p>
                            </Col>
                            <Col md={6}>
                                <p className="text-gray-sm">Harvesting End Date</p>
                                <p className="text-dark-green-sm">{(plot) ? plot.harvest_end_date_formatted : ''}</p>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={6}>
                                <p className="text-gray-sm">Expected Harvest Quantity</p>
                                <p className="text-dark-green-sm">{(plot) ? plot.harvest_expected_quantity + ' ' + plot.unit : ''}</p>
                            </Col>
                        </Row>

                        {(plot) && (
                            <>
                                {(plot.booking_type != null) && (
                                    <>
                                        {/* {console.log("ayo dong")} */}
                                        {/* for advance */}
                                        {(plot) ? (plot.booking_type == 'advanced' && !isSelfPlot && bookingInProgress == false && plot.harvest_count_down != 'Harvest date has passed' && (currentUser.role_active == 'customer' || !isLoggedIn)) ?
                                            <>
                                                <Row className="mt-2">
                                                    <Col md={2}></Col>
                                                    <Col md={8}>
                                                        <center>
                                                            <button type="button" className="btn-border-green" onClick={() => {
                                                                if (isLoggedIn) {
                                                                    popupShowModalConfirmBook();
                                                                    setFormBooking(1);
                                                                } else {
                                                                    goLoginPage();
                                                                }
                                                            }}>
                                                                <label className="cursor-pointer text-sm">
                                                                    <b>{(isLoadingBooking) ? 'Processing, please wait..' : 'Book This Plot'}</b>
                                                                </label>
                                                            </button>
                                                        </center>
                                                    </Col>
                                                    <Col md={2}></Col>
                                                </Row>
                                            </>
                                            :
                                            ""
                                            :
                                            ""
                                        }

                                        {(plot.booking_type == 'advanced' && bookingInProgress == true) ?
                                            <>
                                                <Row className="mt-2">
                                                    <Col md={2}></Col>
                                                    <Col md={8}>
                                                        <center>
                                                            <button type="button" className="saveButton">
                                                                <label className="cursor-pointer text-sm">
                                                                    <b>Booking In Progress</b>
                                                                </label>
                                                            </button>
                                                        </center>
                                                    </Col>
                                                    <Col md={2}></Col>
                                                </Row>
                                            </>
                                            :
                                            ""
                                        }
                                        {/* for auction */}
                                        {(!isSelfPlot && plot.booking_type == 'auction' && (currentUser.role_active == 'customer' || !isLoggedIn)) ?
                                            <>
                                                <Row className="mt-2">
                                                    <Col md={2}></Col>
                                                    <Col md={8}>
                                                        <center>
                                                            {(countDownCurrent != null) && (
                                                                <>
                                                                    {(countDownCurrent.status_auc_bid == "PROGRESS") && (
                                                                        <>
                                                                            <div style={{ fontSize: 13, fontWeight: "normal" }}>
                                                                                Auction in progress
                                                                            </div>
                                                                            <button type="button" className="btn-border-green" onClick={() => {
                                                                                if (isLoggedIn) {
                                                                                    popupShowModalConfirmAuc();
                                                                                } else {
                                                                                    goLoginPage();
                                                                                }
                                                                            }}>
                                                                                <label className="cursor-pointer text-sm">
                                                                                    <b>Place a Bid</b>
                                                                                </label>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}

                                                        </center>
                                                    </Col>
                                                    <Col md={2}></Col>
                                                </Row>
                                            </>
                                            :
                                            ""
                                        }

                                        {/* for auction */}
                                        {(isSelfPlot && currentUser.role_active == 'customer') ?
                                            <>
                                                <Row className="mt-2">
                                                    <Col md={2}></Col>
                                                    <Col md={8}>
                                                        <center>
                                                            <div style={{ fontSize: 15, fontWeight: "normal", fontWeight: "bold" }}>
                                                                You won't be able to proceed with any action since this is your farm
                                                            </div>
                                                        </center>
                                                    </Col>
                                                    <Col md={2}></Col>
                                                </Row>
                                            </>
                                            :
                                            ""
                                        }

                                        {isLoggedIn && (
                                            <>
                                                {(currentUser.role_active == 'customer') && !isSelfPlot && (
                                                    <>
                                                        <Row className="mt-2">
                                                            <Col md={2}></Col>
                                                            <Col md={8}>
                                                                <center>
                                                                    <button type="button" className="btn-border-green"
                                                                        onClick={() => {
                                                                            if (window.innerWidth <= 500) {
                                                                                navigate(`/room-chat/live/${plot.user_id}/plot/${plot.id}/0`);
                                                                            } else {
                                                                                dispatch(updateActiveRoomChat());
                                                                                setShowFLoatChat(true);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <label className="cursor-pointer text-sm">
                                                                            <b>Chat with us</b>
                                                                        </label>
                                                                    </button>

                                                                </center>
                                                            </Col>
                                                            <Col md={2}></Col>
                                                        </Row>
                                                        {showFLoatChat && (
                                                            <>
                                                                <div className="chat-popup-float z-10">
                                                                    <div className="container-chat">
                                                                        <div className="flex flex-row" style={{ backgroundColor: "#77b06e", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                                                            <div className="basis-1/2 p-1">
                                                                                <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faUpRightAndDownLeftFromCenter} className={"text-gray-400"} onClick={() => {
                                                                                    navigate(`/room-chat/live/${plot.user_id}`);
                                                                                }} />
                                                                            </div>
                                                                            <div className="basis-1/2 text-right p-1">
                                                                                <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faCircleXmark} className={"text-gray-400"} onClick={() => {
                                                                                    dispatch(updateInActiveRoomChat());
                                                                                    setShowFLoatChat(false);
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                        <ChatRoomFloating id_catalog={0} to_id={plot.user_id} type_chat="plot" type_chat_id={plot.id} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}


                                                    </>
                                                )}

                                            </>
                                        )}




                                    </>
                                )}
                            </>
                        )}

                    </div>

                </Col>
            </Row>

            <Modal className="modal-large-custom" show={modalConfirmBook} onHide={popupCloseModalConfirmBook}>
                <Modal.Body>
                    <Row className="mt-4 mr-4 ml-4" hidden={(formBooking == 1) ? false : true}>
                        <Col md={6}>
                            <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadPlot} disableEdit={true} />
                            <Row className="mt-2">
                                <Col md={6}>
                                    <label>Harvesting Start Date:</label>
                                    <br />
                                    <label className="text-green">{(plot) ? plot.harvest_start_date_formatted : ''}</label>
                                </Col>
                                <Col md={6}>
                                    <label>Harvesting End Date:</label>
                                    <br />
                                    <label className="text-green">{(plot) ? plot.harvest_end_date_formatted : ''}</label>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={6}>
                                    <label>Expected Harvest Quantity:</label>
                                    <br />
                                    <label className="text-green">{(plot) ? plot.harvest_expected_quantity + ' ' + plot.unit : ''}</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <div className="title-content mb-2">
                                {(plot) ? plot.name : ''}
                            </div>
                            <label>Crop Name:</label>
                            <br />
                            <label className="text-dark-green"><b>{(plot) ? plot.crop.name : ''}</b></label>
                            <br />
                            <label>Farm Price:</label>
                            <br />
                            <label className="text-dark-green"><b>{(plot) ? 'RM ' + plot.selling_price + ' per ' + plot.selling_unit : ''}</b></label>
                            {/* <br />
                            <label>Available Quantity:</label>
                            <br />
                            <label className="text-dark-green"><b>{(plot) ? plot.available_quantity + ' ' + plot.available_quantity_unit : ''}</b></label> */}
                            <br />
                            <label>Available Quantity:</label>
                            <br />
                            <label className="text-red"><b>{(plot) ? parseFloat(plot.available_quantity) - parseFloat(plot.booked_quantity) + ' ' + plot.available_quantity_unit : ''}</b></label>
                            <br />
                            <label>Booking Quantity:</label>
                            <br />
                            {/* <form> */}
                            <Row className="mt-2">
                                <Col md={5} xs={7}>
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}>
                                            <div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => {
                                                let tempBookingQty = bookingQuantity - 1;
                                                let restQty = parseFloat(plot.available_quantity) - parseFloat(plot.booked_quantity);
                                                if (tempBookingQty > restQty) {
                                                    alert('Booking quantity must be less than rest quantity.');
                                                    setBookingQuantity(restQty);
                                                } else {
                                                    if (tempBookingQty < 0) {
                                                        setBookingQuantity(0)
                                                    } else {
                                                        setBookingQuantity(bookingQuantity - 1)
                                                    }
                                                }
                                            }}>
                                                -
                                            </div>
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="number"
                                            className="text-center text-green input-group-plot"
                                            min="0"
                                            placeholder=""
                                            value={bookingQuantity}
                                            onChange={({ target }) => {
                                                let restQty = parseFloat(plot.available_quantity) - parseFloat(plot.booked_quantity);
                                                if (target.value > restQty) {
                                                    alert('Booking quantity must be less than rest quantity.');
                                                    setBookingQuantity(restQty);
                                                } else {
                                                    if (target.value > plot.max_booking_quantity) {
                                                        alert('Maximum booking quantity is ' + plot.max_booking_quantity + ' ' + plot.max_booking_quantity_unit + ' per User');
                                                        setBookingQuantity(plot.max_booking_quantity);
                                                    } else {
                                                        setBookingQuantity(target.value);
                                                    }
                                                }
                                            }
                                            }
                                        />
                                        <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}>
                                            <div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => {
                                                let tempBookingQty = bookingQuantity + 1;
                                                let restQty = parseFloat(plot.available_quantity) - parseFloat(plot.booked_quantity);
                                                if (tempBookingQty > restQty) {
                                                    alert('Booking quantity must be less than rest quantity.');
                                                    setBookingQuantity(restQty);
                                                } else {
                                                    if (tempBookingQty > plot.max_booking_quantity) {
                                                        alert('Maximum booking quantity is ' + plot.max_booking_quantity + ' ' + plot.max_booking_quantity_unit + ' per User');
                                                        setBookingQuantity(plot.max_booking_quantity);
                                                    } else {
                                                        setBookingQuantity(bookingQuantity + 1)
                                                    }
                                                }
                                            }}>
                                                +
                                            </div>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={7} xs={5}>
                                    <label className="mt-2 text-dark-green-sm">{(plot) ? plot.available_quantity_unit : ''}</label>
                                </Col>
                            </Row>
                            <br />
                            {(isEnabledDeposit == 1 && minDepositAdvancedBooking > 0) ? <label className="text-red">Minimum Deposit Amount : RM {minDepositAdvancedBooking}</label> : ''}
                            <br />
                            <label className="text-green">* All Bookings are subjected to service charge</label>
                            <br />
                            <Row className="mt-2">
                                <Col md={8}>
                                    <center>
                                        <button type="submit" className="btn-border-green" hidden={(formBooking == 1) ? false : true} onClick={fillShippingAddress} style={{ minHeight: "50px" }}>
                                            <label className="cursor-pointer text-sm">
                                                <b>Continue to Shipping Address</b>
                                            </label>
                                        </button>
                                    </center>
                                </Col>
                            </Row>
                            {/* </form> */}
                        </Col>
                    </Row>
                    <Row className="mt-4 mr-4 ml-4" hidden={(formBooking == 2) ? false : true}>
                        <Col md={6}>
                            {(latitude && longitude) ?
                                <>
                                    <PickMapLocation
                                        curentLatMap={latitude}
                                        curentLngMap={longitude}
                                        curentLatMark={latitude}
                                        curentLngMark={longitude}
                                        saveLat={setLatitude}
                                        saveLng={setLongitude}
                                    />
                                </>
                                : ""}
                        </Col>
                        <Col md={6}>
                            <p>Shipping Address</p>
                            {(msgErrorInModalShipAddress != "") && (
                                <>
                                    <Row>
                                        <Col md={12}>
                                            <div style={{ color: "red", fontSize: 15, fontWeight: "bold" }} className="mt-2">
                                                {msgErrorInModalShipAddress}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Row>
                                <Col md={6}>
                                    <label className="text-dark-green-sm">Fullname <span style={{ color: "red" }}>*</span></label>
                                    <br />
                                    <input
                                        type="text"
                                        className="input-search"
                                        name="fullname"
                                        placeholder="Fullname"
                                        value={fullname}
                                        onChange={({ target }) => setFullname(target.value)}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label className="text-dark-green-sm">Email<span style={{ color: "red" }}>*</span></label>
                                    <br />
                                    <input
                                        type="email"
                                        className="input-search"
                                        name="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={({ target }) => setEmail(target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <label className="text-dark-green-sm">State<span style={{ color: "red" }}>*</span></label>
                                    <br />
                                    <select className="input-search" defaultValue={null} value={stateName} onChange={({ target }) => { setStateName(target.value); }}>
                                        <option key={-1} value="">
                                            Select State..
                                        </option>
                                        {
                                            statesList.map((state, key) => <option key={state.id} value={state.name}>{state.name}</option>)
                                        }
                                    </select>
                                </Col>
                                <Col md={6}>
                                    <label className="text-dark-green-sm">City<span style={{ color: "red" }}>*</span></label>
                                    <br />
                                    <input
                                        type="text"
                                        className="input-search"
                                        name="city"
                                        placeholder="City"
                                        value={city}
                                        onChange={({ target }) => setCity(target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <label className="text-dark-green-sm">Postcode<span style={{ color: "red" }}>*</span></label>
                                    <br />
                                    <input
                                        type="number"
                                        className="input-search"
                                        name="postcode"
                                        placeholder="Postcode"
                                        value={postCode}
                                        onChange={({ target }) => setPostCode(target.value)}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label className="text-dark-green-sm">Phone<span style={{ color: "red" }}>*</span></label>
                                    <br />
                                    <input
                                        type="number"
                                        className="input-search"
                                        name="phone"
                                        placeholder="Phone"
                                        value={phone}
                                        onChange={({ target }) => setPhone(target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <label className="text-dark-green-sm">Address<span style={{ color: "red" }}>*</span></label>
                                    <br />
                                    <textarea
                                        className="input-search"
                                        name="address"
                                        placeholder="Address"
                                        value={address}
                                        onChange={({ target }) => setAddress(target.value)}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row className="mt-2">
                                <Col md={12}>
                                    <center>
                                        <button type="submit" className="btn-border-green" hidden={(formBooking == 2) ? false : true} onClick={confirmBooking} style={{ minHeight: "50px" }}>
                                            <label className="cursor-pointer text-sm">
                                                <b>{(isLoadingBooking) ? 'Processing, please wait..' : 'Confirm Booking & Save Address'}</b>
                                            </label>
                                        </button>
                                    </center>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Col md={6}>
                        <button type="submit" className="btn-border-green" hidden={(formBooking == 1) ? false : true} onClick={fillShippingAddress}>
                            <label className="cursor-pointer text-sm">
                                <b>Continue to Shipping Address</b>
                            </label>
                        </button>
                        <button type="submit" className="btn-border-green" hidden={(formBooking == 2) ? false : true} onClick={confirmBooking}>
                            <label className="cursor-pointer text-sm">
                                <b>Confirm Booking</b>
                            </label>
                        </button>
                    </Col>
                </Modal.Footer> */}
                {snackbarCtx.isDisplayedOnOverlay && <Snackbar />}
            </Modal>

            <Modal className="modal-large-custom" show={modalConfirmAuc} onHide={popupCloseModalConfirmAuc}>
                <Modal.Body>
                    <Row className="mt-4 mr-4 ml-4">
                        <Col md={6}>
                            <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadPlot} disableEdit={true} />
                            <Row className="mt-2">
                                <Col md={6}>
                                    <label style={{ fontSize: 10 }}>Crop Name:</label>
                                    <br />
                                    <label className="text-green">{(plot) ? plot.crop.name : ''}</label>
                                </Col>
                                <Col md={6}>

                                </Col>
                            </Row>
                            <Row >
                                <Col md={4}>
                                    <label style={{ fontSize: 10 }}>Harvesting Start Date:</label>
                                    <br />
                                    <label className="text-green">{(plot) ? plot.harvest_start_date_formatted : ''}</label>
                                </Col>
                                <Col md={4}>
                                    <label style={{ fontSize: 10 }}>Harvesting End Date:</label>
                                    <br />
                                    <label className="text-green">{(plot) ? plot.harvest_end_date_formatted : ''}</label>
                                </Col>
                                <Col md={4}>
                                    <label style={{ fontSize: 10 }}>Expected Harvest Quantity:</label>
                                    <br />
                                    <label className="text-green">{(plot) ? plot.harvest_expected_quantity + ' ' + plot.unit : ''}</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            {(countDownCurrent != null) && (
                                <>
                                    <Row className="mt-2">
                                        <Col md={12}>
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>
                                                        <div style={{ fontSize: 25, fontWeight: "bold" }}>
                                                            Time left:
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div style={{ fontSize: 12 }}>
                                                            DAY
                                                        </div>
                                                        <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                            {countDownCurrent.days}
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        :
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div style={{ fontSize: 12 }}>
                                                            HOUR
                                                        </div>
                                                        <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                            {countDownCurrent.hours}
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        :
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div style={{ fontSize: 12 }}>
                                                            MIN
                                                        </div>
                                                        <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                            {countDownCurrent.minutes}
                                                        </div>
                                                    </td>

                                                </tr>
                                            </table>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Row className="mt-2">
                                <Col md={6}>
                                    <label style={{ fontSize: 10 }}>Start Date and Time: </label>
                                    <br />
                                    <label className="text-green">{(auctionPlotLast) ? auctionPlotLast.bidding_start_date : ''}</label>
                                </Col>
                                <Col md={6}>
                                    <label style={{ fontSize: 10 }}>End Date and Time: </label>
                                    <br />
                                    <label className="text-green">{(auctionPlotLast) ? auctionPlotLast.bidding_end_date : ''}</label>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={6}>
                                    <label style={{ fontSize: 10 }}>Available Quantity: </label>
                                    <br />
                                    <label className="text-green">{(auctionPlotLast) ? auctionPlotLast.available_quantity : ''} {(auctionPlotLast) ? auctionPlotLast.available_quantity_unit : ''}</label>
                                </Col>
                                <Col md={6}>

                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={12}>
                                    <label style={{ fontSize: 14, marginRight: 5, fontWeight: "bold", verticalAlign: "top" }}>Current Bid (MYR): </label>
                                    <label style={{ color: "#395B50", marginTop: "-5px" }}> <span style={{ fontSize: 25 }}> RM  {bidPriceCurrent}</span> <span style={{ fontSize: 10 }}> / {(auctionPlotLast) ? auctionPlotLast.start_bidding_price_unit : ''}</span></label>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col md={12}>
                                    <label style={{ fontSize: 14, marginRight: 5, fontWeight: "bold", verticalAlign: "top" }}>Total Price (MYR): </label>
                                    <label style={{ color: "#395B50", marginTop: "-5px" }}> <span style={{ fontSize: 25 }}> RM  {(auctionPlotLast) ? numberWithCommas(Number(bidPriceCurrent * auctionPlotLast.available_quantity).toFixed(2)) : ''}</span> <span style={{ fontSize: 10 }}> (Available Quantity x Current Bid)</span></label>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={6}>
                                    <label style={{ fontSize: 15 }}>Bid Status: </label>
                                    {(statusUserPlot == "NOT_BID") && (
                                        <>
                                            <div style={{ fontSize: 10 }}>You have not placed any bids. </div>
                                        </>
                                    )}
                                    {(statusUserPlot == "HIGH_BID") && (
                                        <>
                                            <div style={{ fontSize: 10 }}>You are currently the highest bidder. You are unable to place another bid unless you have been outbid by another bidder. </div>
                                        </>
                                    )}
                                    {(statusUserPlot == "LOST_BID") && (
                                        <>
                                            <div style={{ fontSize: 10 }}>You have been outbid.
                                                Place another bid if you wish to continue. </div>
                                        </>
                                    )}
                                </Col>
                                <Col md={6}>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            popupCloseModalConfirmAuc();
                                            popupShowModalViewBidders();
                                        }}
                                    >
                                        [<span className="text-green">{`${countBid} Bids`}</span>]
                                    </div>
                                    <div>
                                        <button disabled={(statusUserPlot == "HIGH_BID") ? true : false} type="button" className={clsx((statusUserPlot == "HIGH_BID") ? "btn-border-gray" : "btn-border-green")} onClick={() => {
                                            popupCloseModalConfirmAuc();
                                            if (statusUserAddress == "NOT_AVAILABLE") {
                                                popupShowModalAvailUserAddress();
                                            } else {
                                                popupShowModalPlaceBid();
                                            }
                                        }}>
                                            <label className="cursor-pointer text-sm">
                                                <b>Place Bid</b>
                                            </label>
                                        </button>
                                    </div>
                                    {(statusUserPlot == "HIGH_BID") && (
                                        <>
                                            <div className="mt-2">
                                                <button type="button" className={"btn-border-red"} onClick={() => {
                                                    popupShowModalForefitConf();
                                                }}>
                                                    <label className="cursor-pointer text-sm">
                                                        <b>Forfeit</b>
                                                    </label>
                                                </button>
                                            </div>
                                        </>
                                    )}

                                </Col>
                            </Row>



                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>

            <Modal className="modal-large-custom" show={modalPlaceBid} onHide={popupCloseModalPlaceBid}>
                <Modal.Body>
                    <Row className="mt-2 mr-4 ml-4">
                        <div>
                            <label className="cursor-pointer head-bar-nav" onClick={() => {
                                popupCloseModalPlaceBid();
                                popupShowModalConfirmAuc();
                            }}>
                                <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }}

                                />
                                {" Go Back"}
                            </label>
                        </div>
                        <div style={{ color: "#0E5934", fontSize: 25, fontWeight: "bold" }}>
                            Place your bid
                        </div>
                        {(msgErrorInModal != "") && (
                            <>
                                <div style={{ color: "red", fontSize: 15, fontWeight: "bold" }} className="mt-2">
                                    {msgErrorInModal}
                                </div>
                            </>
                        )}
                        {(countDownCurrent != null) && (
                            <>
                                <Row className="mt-2">
                                    <Col md={12}>
                                        <table style={{ width: "40%" }}>
                                            <tr>
                                                <td style={{ textAlign: "center" }}>
                                                    <div style={{ fontSize: 12 }}>
                                                        DAY
                                                    </div>
                                                    <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                        {countDownCurrent.days}
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                        :
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <div style={{ fontSize: 12 }}>
                                                        HOUR
                                                    </div>
                                                    <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                        {countDownCurrent.hours}
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                        :
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <div style={{ fontSize: 12 }}>
                                                        MIN
                                                    </div>
                                                    <div style={{ fontSize: 35, fontWeight: "bold" }}>
                                                        {countDownCurrent.minutes}
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>
                                                        left
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Row className="mt-2">
                            <Col md={12}>
                                <label style={{ fontSize: 20, marginRight: 5, fontWeight: "bold" }}>Current Bid (MYR): </label>
                                <label style={{ color: "#395B50" }}> <span style={{ fontSize: 25 }}> RM  {bidPriceCurrent}</span> <span style={{ fontSize: 10 }}> / {(auctionPlotLast) ? auctionPlotLast.start_bidding_price_unit : ''}</span></label>
                            </Col>
                        </Row>

                        {(auctionPlotLast) ? ((auctionPlotLast.is_allow_min_bidd_inc == 1) ? (
                            <>
                                <div>
                                    <label style={{ fontSize: 15, marginRight: 3 }}>Minimum Increment Bid (MYR) : </label>
                                    <label style={{ fontSize: 15, color: "#0E5934", fontWeight: "bold" }}>{(auctionPlotLast) ? auctionPlotLast.mini_bidding_price : ''}</label>
                                </div>
                            </>
                        ) : "") : ""}


                        <div>
                            <label style={{ fontSize: 20, marginRight: 3, fontWeight: "bold" }}>Your bid </label>
                        </div>
                        <div>
                            <Row style={{ alignItems: "center" }}>
                                <Col md={2}>
                                    MYR (RM):
                                </Col>
                                <Col md={3} className="ml-0 mr-0 pl-0 pr-0">
                                    <InputGroup>
                                        {(auctionPlotLast) ? ((auctionPlotLast.is_allow_min_bidd_inc == 1) ? (
                                            <>
                                                <InputGroup.Text id="basic-addon1"><div style={{ cursor: "pointer" }}
                                                    onClick={() => setBidPriceYou(bidPriceYou - parseFloat(auctionPlotLast.mini_bidding_price))}
                                                >-</div></InputGroup.Text>
                                            </>
                                        ) : "") : ""}

                                        <Form.Control

                                            type="number"
                                            min="0"
                                            style={{ borderRadius: "8px", border: "1px solid #00AF54" }}
                                            onChange={({ target }) => {
                                                setBidPriceYou(target.value);
                                            }}
                                            placeholder=""
                                            value={bidPriceYou}
                                            disabled={(auctionPlotLast) ? ((auctionPlotLast.is_allow_min_bidd_inc == 1) ? true : false) : false}
                                        />
                                        {(auctionPlotLast) ? ((auctionPlotLast.is_allow_min_bidd_inc == 1) ? (
                                            <>
                                                <InputGroup.Text id="basic-addon2"><div style={{ cursor: "pointer" }}
                                                    onClick={() => setBidPriceYou(bidPriceYou + parseFloat(auctionPlotLast.mini_bidding_price))}
                                                >+</div></InputGroup.Text>
                                            </>
                                        ) : "") : ""}

                                    </InputGroup>
                                </Col>
                                <Col md={2}>
                                    {/* <span style={{ fontSize: 10, verticalAlign: "bottom" }}>
                                        /{plot.available_quantity_unit}
                                    </span> */}
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-3">
                            <Row>
                                <Col md={8}>
                                    <table>
                                        <tr>
                                            <td>
                                                <div>
                                                    <label style={{ fontSize: 20, marginRight: 3, fontWeight: "bold" }}>Total Payment (MYR): </label>
                                                </div>
                                                <div style={{ fontSize: 11 }}>
                                                    (Available Quantity x Your Bid)
                                                </div>
                                            </td>
                                            <td>
                                                <label style={{ fontSize: 30, marginLeft: 3, fontWeight: "bold" }} className="text-green">{numberWithCommas(Number(totalbidPriceYou).toFixed(2))}</label>
                                            </td>
                                        </tr>
                                    </table>
                                </Col>
                                <Col md={4}>
                                    <button disabled={isLoading} type="button" style={{ height: 40 }} className="btn-border-green" onClick={() => {
                                        if (!isLoading) {
                                            submitBidAucPlot();
                                        }
                                    }}>
                                        <label className="cursor-pointer text-lg">
                                            {isLoading ? "Loading.." : "Bid"}
                                        </label>
                                    </button>
                                </Col>
                            </Row>

                        </div>

                    </Row>
                </Modal.Body>
                {snackbarCtx.isDisplayedOnOverlay && <Snackbar />}
            </Modal>
            {/* modal confirmation cancel advance booking */}
            <Modal className="modal-medium-custom" show={modalForefitConf} onHide={popupCloseModalForefitConf}>

                <Modal.Body>
                    <Row className="mt-4 mr-4 ml-4">
                        <Col md={12}>
                            <center>
                                <img src="/assets/images/plots/question.png" width={100} height={100} />
                            </center>
                            <br />
                            <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>Do you want to forfeit bidding? <br />
                            </p>
                            <p style={{ fontSize: "12px", textAlign: "center" }}>
                                By clicking yes, you are agreeing to give up your place as the highest bidder.</p>
                            <br />
                            <Row className="mt-4">
                                <Col md={6}>
                                    <center>
                                        <button
                                            type="button"
                                            className="btn-border-red"
                                            style={{ minHeight: "50px" }}
                                            onClick={() => {
                                                subForfeitBidding();
                                            }}
                                        >
                                            {isLoading ? "Loading.." : "Yes"}
                                        </button>
                                    </center>
                                </Col>
                                <Col md={6}>
                                    <center>
                                        <button type="button" onClick={popupCloseModalForefitConf} className="saveButton bg-red-500 hover:bg-red-600 hover:ring-red-600" style={{ minHeight: "50px" }}>
                                            {isLoading ? "Loading.." : "No"}
                                        </button>
                                    </center>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Modal.Body>
                {snackbarCtx.isDisplayedOnOverlay && <Snackbar />}
            </Modal>

            {/* modal available user address */}
            <Modal className="modal-medium-custom" show={modalAvailUserAddress} onHide={popupCloseModalAvailUserAddress}>
                <Modal.Body>
                    <Row className="mt-4 mr-4 ml-4">
                        <Col md={12}>
                            <center>
                                <img src="/assets/images/plots/question.png" width={100} height={100} />
                            </center>
                            <br />
                            <p style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>You have not completed the address<br />
                            </p>
                            <p style={{ fontSize: "12px", textAlign: "center" }}>
                                complete your address to be able to bid auction</p>
                            <br />
                            <Row className="mt-4">
                                <Col xs={6}>
                                    <center>
                                        <button
                                            type="button"
                                            className="btn-border-green"
                                            style={{ minHeight: "50px" }}
                                            onClick={() => {
                                                goEditAccount();
                                                // subForfeitBidding();
                                            }}
                                        >
                                            {isLoading ? "Loading.." : "Yes"}
                                        </button>
                                    </center>
                                </Col>
                                <Col xs={6}>
                                    <center>
                                        <button type="button" onClick={popupCloseModalAvailUserAddress} className="saveButton bg-red-500 hover:bg-red-600 hover:ring-red-600" style={{ minHeight: "50px" }}>
                                            {isLoading ? "Loading.." : "No"}
                                        </button>
                                    </center>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Modal.Body>
            </Modal>

            {/* modal view bidders */}
            <Modal className="modal-large-custom" show={modalViewBidders} onHide={popupCloseModalViewBidders}>
                <Modal.Body className="mt-2 mr-4 ml-4">
                    {isLoading ? "Loading......" : (
                        <>
                            <Row>
                                <div>
                                    <label className="cursor-pointer head-bar-nav" onClick={() => {
                                        popupCloseModalViewBidders();
                                        popupShowModalConfirmAuc();
                                    }}>
                                        <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }}

                                        />
                                        {" Go Back"}
                                    </label>
                                </div>
                                <div style={{ color: "#0E5934", fontSize: 25, fontWeight: "bold" }}>
                                    Bid History
                                </div>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadPlot} disableEdit={true} heightSty={"200px"} />
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        <div style={{ color: "#395B50", fontSize: 20, fontWeight: "bold" }}>
                                            {(plot != null) ? plot.name : "-"}
                                        </div>
                                        <div>
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td>
                                                        Bids:
                                                        <span className="text-green ml-3">
                                                            {(auctionPlotBidders != null) ? auctionPlotBidders.bid_count : "-"}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        Bidders:
                                                        <span className="text-green ml-3">
                                                            {(auctionPlotBidders != null) ? auctionPlotBidders.bidders_count : "-"}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {(auctionPlotBidders != null) && (
                                                            <>
                                                                <table>
                                                                    <tr>
                                                                        <td style={{ textAlign: "left" }}>

                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>

                                                                            <div style={{ fontSize: 6, fontWeight: "bold", color: "#395B50" }}>
                                                                                DAY
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>

                                                                            <div style={{ fontSize: 6, fontWeight: "bold", color: "#395B50" }}>
                                                                                HOUR
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>
                                                                            <div style={{ fontSize: 6, fontWeight: "bold", color: "#395B50" }}>
                                                                                MIN
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ textAlign: "left" }}>
                                                                            <div style={{ fontSize: 15 }} className="mr-3">
                                                                                Time left:
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>

                                                                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#395B50" }}>
                                                                                {auctionPlotBidders.count_down_current.days}
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>
                                                                            :
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>

                                                                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#395B50" }}>
                                                                                {auctionPlotBidders.count_down_current.hours}
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>
                                                                            :
                                                                        </td>
                                                                        <td style={{ textAlign: "center", color: "#395B50" }}>
                                                                            <div style={{ fontSize: 20, fontWeight: "bold", color: "#395B50" }}>
                                                                                {auctionPlotBidders.count_down_current.minutes}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </>
                                                        )}

                                                    </td>
                                                    <td>
                                                        Duration:
                                                        <span className="text-green ml-3">
                                                            {(auctionPlotBidders != null) ? `${auctionPlotBidders.count_down_current.days} Days` : "-"}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="mt-3">
                                            <table style={{ width: "100%" }}>
                                                <tr className="text-center" style={{ backgroundColor: "#D3D3D3" }}>
                                                    <th style={{ padding: "10px" }}>
                                                        Ranking
                                                    </th>
                                                    <th>
                                                        Bid Price (RM/UNIT)
                                                    </th>
                                                    <th>
                                                        Final Price (RM)
                                                    </th>
                                                    <th>
                                                        Bid Time (MYT)
                                                    </th>
                                                </tr>
                                                {auctionPlotBidders != null && (
                                                    <>
                                                        {auctionPlotBidders.data.map((listValue, index) => {
                                                            return (
                                                                <tr key={index} className="text-center" style={{ borderBottomWidth: "3px" }}>
                                                                    <td style={{ padding: "10px" }}>
                                                                        {index + 1}
                                                                    </td>
                                                                    <td>
                                                                        {listValue.bid_price}
                                                                    </td>
                                                                    <td>
                                                                        {listValue.total_bid_price}
                                                                    </td>
                                                                    <td>
                                                                        {listValue.bid_time}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </>
                                                )}

                                            </table>
                                        </div>
                                        <div style={{ color: "#939393", fontSize: 10 }} className="mt-2">
                                            <span>
                                                ** Ranking displayed is not the final result. Final winner will be notified and confirmed once deposit payment is made.
                                            </span>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}

                </Modal.Body>

            </Modal>
            <Footer />
        </Container>
    );
};

export default PlotsDetailForm;
