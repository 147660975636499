import clsx from "clsx";
import { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Text from "components/text/Text";
import { login, loginFB, loginGoogle } from "../../features/auth/loginSlice";
import SnackbarContext from "_helpers/snackbar-context";

import googleLogo from "images/Google.svg";
import facebookLogo from "images/Facebook.svg";
import buyyerPng from "images/login/buyer.png";
import farmerPng from "images/login/farmer.png";
import styles from "./Login.module.scss";
import { getAuthRememberMe, getCurrentUserInfo, setAuthRememberMe } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { initFBLoginAPI } from "support/support_function";
import { useGoogleLogin } from '@react-oauth/google';
import resources from '../../constants/translation'
import { routesPaths } from "constants/routes.constants";

const LoginForm = ({ from }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [accountType, setAccountType] = useState(0);
  const snackbarCtx = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const { currentUser, token, currentCompany, fcmKey } = useSelector((state) => state.auth);
  const [tokenFCM, setTokenFCM] = useState(null);

  // const reduxAuth = useSelector((state) => state.auth);
  const { _rememberMe } = useSelector((state) => state.auth);
  
  useEffect(() => {
    // console.log(fcmKey);
    setTokenFCM(fcmKey);
  }, [fcmKey]);

  const submitHandler = (event) => {
    dispatch(
      login({
        username: username,
        password: password,
        remember_me: rememberMe,
        fcm_token: tokenFCM
      })
    ).then(async function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        dispatch(setAuthRememberMe({
          username: (rememberMe === true) ? username : "",
          // password: (rememberMe === true) ? password  : "", // noted - we didn't save the password because this might be risky for the security as for remember me.
        }));
        if (from !== undefined) {
          window.location = from.pathname;
        } else {
          window.location = "/";
        }

        // window.location = "/account/edit";
      }
    });

    event.preventDefault();
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const loginWithFacebook = () => {
    window.FB.login(function (response) {
      if (response.status === 'connected') {
        dispatch(
          loginFB({
            token: response.authResponse.accessToken,
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure");
          } else {
            if (response?.payload?.data?.data?.user?.role_name !== null) {
              window.location = "/";
            } else {
              window.location = "/register/company";
            }
          }
        });
      } else {
        //user hit cancel button
        // console.log('User cancelled login or did not fully authorize.');
        snackbarCtx.displayMsg(resources['login.socialCancelButton'], "failure");

      }
    }, {
      scope: 'public_profile,email'
    });
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: tokenResponse => {
      dispatch(
        loginGoogle({
          token: tokenResponse.access_token,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          if (response?.payload?.data?.user?.role_name !== null) {
            window.location = "/";
          } else {
            window.location = "/register/company";
          }
        }
      });
    }
  });

  useEffect(() => {
    initFBLoginAPI();
  }, [])

  useEffect(() => {
    dispatch(getAuthRememberMe());
  }, []);

  useEffect(() => {
    setPassword(_rememberMe.password)
  }, [_rememberMe.password]);

  useEffect(() => {
    setUsername(_rememberMe.username)
  }, [_rememberMe.username]);

  //set account type
  const setStateAcountType = (value) => {
    setAccountType(value);
  };

  return (
    <div className="flex justify-center">
      <div>
        {/* <div className={clsx(styles.cardIconAccountTypeMobile, "flex", "justify-center", "mb-2")}>
          <div
            onClick={() => {
              setAccountType(0);
            }}
            className={clsx(accountType == 0 ? styles.iconActiveBuyFar : styles.iconNotActiveBuyFar, "cursor-pointer")}>
            <img className={clsx(accountType == 0 ? styles.iconImgActive : "", "w-10")} src={buyyerPng} alt="Buyyer" />
          </div>
          <div style={{ width: "10px" }}>

          </div>
          <div
            onClick={() => {
              setAccountType(1);
            }}
            className={clsx(accountType == 1 ? styles.iconActiveBuyFar : styles.iconNotActiveBuyFar, "cursor-pointer")}>
            <img className={clsx(accountType == 1 ? styles.iconImgActive : "", "w-10")} src={farmerPng} alt="Farmer" />
          </div>

        </div> */}
        <form data-testid="login" className={clsx("flex", "flex-col", "p-8", "space-y-6", styles.loginForm)} onSubmit={submitHandler}>
          <span className={clsx("self-center", "text-2xl", styles.titleLogin)}>
            <Text id="login" />
          </span>
          <div className="flex flex-col space-y-4">

            {/*<span>*/}

            {/*remember*/}
            {/*{_rememberMe.password}*/}
            {/*{_rememberMe.test}*/}
            {/*{_rememberMe.username}*/}
            {/*</span>*/}
            <input
              type="text"
              className={clsx("", styles.inputUserPass)}
              placeholder="Username"
              value={username}
              onChange={({ target }) => setUsername(target.value)}
            />
            <input
              type="password"
              className={clsx("", styles.inputUserPass)}
              placeholder="Password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <div style={{ marginTop: "1px" }}>
              <p className={clsx(styles.forgotPassword, "text-xs", "self-end", "cursor-pointer")}>
                {/* <Text id="login.forgotPassword" /> */}
                <Link to={routesPaths.FORGOT_PASSWORD} className={clsx(styles.menuWeb, "laptop:inline hover:text-green-500")}>
                  <Text id="login.forgotPassword" />
                </Link>
              </p>
              <div className={clsx(styles.rememberMe, "text-xs", "flex", "gap-x-2")}>
                <p>
                  <Text id="login.rememberMe" />?
                </p>
                <input
                  type="checkbox"
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                />

              </div>
            </div>
          </div>

          <p className="self-center" style={{ margin: "0px", padding: "10px" }}>
            <Text id="or" />
          </p>
          <div className="flex flex-col space-y-4" style={{ marginTop: "0px" }}>
            <button type="button" className={styles.borderedButton} onClick={() => loginWithGoogle()}>
              <p className="text-gray-400 text-sm" style={{ fontSize: "16px" }}>
                <Text id="login.signInWithGoogle" />
              </p>
              <img style={{ marginLeft: "5px" }} className="w-5" src={googleLogo} alt="Google logo" />
            </button>
            <button type="button" className={styles.borderedButton} onClick={() => loginWithFacebook()}>
              <p className="text-gray-400 text-sm" style={{ fontSize: "16px" }}>
                <Text id="login.signInWithFacebook" />
              </p>
              <img style={{ marginLeft: "5px" }} className="w-5" src={facebookLogo} alt="Facebook logo" />
            </button>
          </div>

          <div className={clsx(styles.dontHaveAccount)}>
            Don’t have an account? <Link
              to="/register/details"
              className={clsx("cursor-pointer")}
            >
              <u>Sign up here!</u>
            </Link>
          </div>

          <button className={clsx(styles.submit, "bg-green-500", "text-white", "hover:bg-green-600")} type="submit" style={{ marginTop: "0px" }}>
            <Text id="Login" />
          </button>


        </form>
      </div>

      {/* <div className={clsx(styles.cardIconAccountTypeWeb, "ml-5")}>
        <div
          onClick={() => {
            setAccountType(0);
          }}
          className={clsx(accountType == 0 ? styles.iconActiveBuyFar : styles.iconNotActiveBuyFar, "cursor-pointer")}>
          <img className={clsx(accountType == 0 ? styles.iconImgActive : "", "w-10")} src={buyyerPng} alt="Buyyer" />
        </div>
        <div
          onClick={() => {
            setAccountType(1);
          }}
          className={clsx(accountType == 1 ? styles.iconActiveBuyFar : styles.iconNotActiveBuyFar, "mt-3", "cursor-pointer")}>
          <img className={clsx(accountType == 1 ? styles.iconImgActive : "", "w-10")} src={farmerPng} alt="Farmer" />
        </div>

      </div> */}
    </div>

  );
};

export default LoginForm;
