// import React from "react";
import { useState, useEffect } from "react";

import "./photo-slider.scss";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {farmShow} from "../../../features/farm/farmSlice";
import {fileDelete} from "../../../features/file/fileSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { routesPaths } from "constants/routes.constants";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const PhotoSlider = ({photos, reloadData}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const reduxFile = useSelector((state) => state.file);


    const itemDelete = (photo) => {
        if(window.confirm("Are you sure you want to delete this Photo?" )) {
            // console.log(" deleted ",  photo.id)

            dispatch(
                fileDelete({
                    file_id: photo.id,
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                } else {
                    reloadData()
                }
            });
        }
        else {
            // console.log(" delete was cancelled")
        }
    }

    return (
        <div className="flex flex-col space-y-4 self-center w-11/12">
            { photos && photos.length > 0? (
                <AliceCarousel autoPlay autoPlayInterval="3000">
                    {photos.map((photo, index) => {
                        return (<div className={"flex flex-col relative"} key={index}>

                             <button className = "absolute top-2 right-2 fosuc:pointer-events-auto" onClick = {(e)=> {
                               e.preventDefault();
                               itemDelete(photo)
                              }}>
                                <div className={'bg-slate-800'}>
                                    <span className={'text-white mr-2'}>Delete</span>
                                    <FontAwesomeIcon icon={faTrashCan}  className={"text-cyan-400"} />
                                </div>
                            </button>

                               <button className = "absolute top-2 left-2 fosuc:pointer-events-auto" onClick = {(e)=> {
                                   e.preventDefault();

                                   navigate(`${routesPaths.FILES}/${photo.id}/edit`);
                               }}>
                                <div className={'bg-slate-800'}>
                                    <span className={'text-white mr-2'}>Edit</span>
                                    <FontAwesomeIcon icon={faPenToSquare} className={"text-rose-400"} />
                                </div>
                               </button>


                            <img src={photo.src} className="sliderimg" alt=""/>    
                            <div className= "absolute bottom-1 w-[100%] mb-[-3px] h-[50px] bg-black opacity-70" key={index}>
                                <p className = "text-white text-sm font-normal ml-[0.5rem] mt-[0.2rem]">{photo.date}</p>
                                <p className = "text-white text-sm font-normal ml-[0.5rem]">{photo.description_short}</p>
                            </div>                     
                        </div>)
                    })}
                </AliceCarousel>
            ) : null}                                    
        </div>
    );
};

export default PhotoSlider;
