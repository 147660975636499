import { Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Demand = ({ demand }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  let status = "";
  if (demand.status === 0) {
    status = "primary";
  } else if (demand.status === 1) {
    status = "warning";
  } else if (demand.status === 2) {
    status = "danger";
  } else {
    status = "success";
  }

  const tempInfo = {
    photo: "/assets/images/products/WheatIcon.svg",
    name: "Loading...",
    description: "Loading...",
    product_id: 1,
    farm_id: 1,
    grade_id: 1,
    frequency: "Loading...",
    quantity_number: "Loading...",
    quantity_target: "Loading...",
    unit: "Loading...",
    timeframe: "Loading...",
  };

  const editHandler = () => {
    navigate(`/demands/${demand.id}/edit`);
  };

  const detailsHandler = () => {
    navigate(`/demands/${demand.id}/show`);
  };

  return (
    <>
      <div data-testid="order-card" className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full" style={{ borderBottomWidth: "1px" }}>

        <div className="w-full flex flex-row p-2 gap-4 items-center ">
          <section className="basis-1/4 desktop:flex items-center gap-x-8 ">
            <img
              // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
              src={demand.photo_default}
              alt={demand.name}
              className="  orderLarge:inline min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] object-cover aspect-square rounded-full"
            />
            <div className="hidden lg:flex flex-col">
              <label className="text-2md font-semibold">Demand #{demand.id} </label>
              <label>{`${demand.quantity_required} ${demand.unit}`}/{demand.frequncy_title}</label>
              {/*<label>{`${orderDetail.quantity} ${$orderDeta.grade.price_unit_symbol} / ${info.frequency}`}</label>*/}
            </div>
          </section>
          <section className="lg:basis-1/4 w-full flex gap-4 items-center justify-center productSmall:flex-col">
            <div className="basis-1/2 flex flex-col laptop:gap-3 gap-1 items-center ">
              <label className="laptop:hidden text-2md font-semibold">Demand #{demand.id} </label>
              <label className="laptop:hidden">{`${demand.quantity_required} ${demand.unit}`}/{demand.frequncy_title}</label>
              <label className=" text-center ">
                {demand.frequency_label}
              </label>
              <label className="laptop:hidden"><Badge bg={status}>{demand.status_text}</Badge></label>
            </div>
          </section>

          <section className="lg:basis-1/4 flex gap-4 items-center justify-center">

            <div className="hidden orderSmall:flex gap-3 items-center">

              <label className="text-center w-[7rem]">
                <Badge bg={status}>{demand.status_text}</Badge>
                {/*{info.status ? info.status : "Loading..."}*/}
              </label>
            </div>
          </section>
          <section className="lg:basis-1/4 flex gap-2 items-center justify-end">
            <button className="hidden laptop:inline btn-border-green w-[120px]" onClick={() => editHandler()}>
              Edit Demand
            </button>
            <button className="hidden laptop:inline btn-border-green w-[100px]" onClick={() => detailsHandler()}>
              Details
            </button>
          </section>
        </div>
        <div className="laptop:hidden w-full flex flex-row p-2 gap-4 items-center mb-1">
          <button className="btn-border-green w-[120px]" onClick={() => editHandler()}>
            Edit Demand
          </button>
          <button className="btn-border-green w-[120px]" onClick={() => detailsHandler()}>
            Details
          </button>
        </div>


      </div>


      {/* <div
        data-testid="demand"
        className="bg-formDark border-buttonBorder w-full flex justify-between items-center p-4 gap-4 shadow rounded-lg"
      >
        <img
          src={demand.photo_default}
          alt="bananas"
          className="min-w-[10rem] min-h-[10rem] max-w-[10rem] max-h-[10rem] object-cover aspect-square hidden laptop:inline rounded-lg shadow"
        />
        <div className="w-full h-[10rem] flex flex-col justify-between">
          <section className="flex justify-between items-center gap-x-8">
            <label className="text-2xl">{`Demand #${demand.id} - ${demand.name}`}</label>

            <div className="flex justify-center items-center gap-x-2">
              <label>Status:</label>
              <label className="indentedText text-center">
                {demand.status_text}
              </label>
            </div>
          </section>

          <label>{`${demand.quantity_required} ${demand.unit}`} / {demand.frequncy_title} </label>

          <section className="w-full flex items-center justify-between">
            <div className="w-1/2 hidden laptop:flex gap-4">
              <button type="button" className="borderedButton w-fit" onClick={() => editHandler()}>
                Edit Demand
              </button>
              <button type="button" className="borderedButton w-fit" onClick={() => detailsHandler()}>
                Details
              </button>
            </div>
            <label className="text-end">{demand.frequency_label}</label>
          </section>
        </div>
      </div> */}
    </>
  );
};

export default Demand;
