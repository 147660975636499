import { useState, useEffect } from "react";
import { getCurrentUserInfo } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { routesPaths } from "constants/routes.constants";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const DemandMarketControls = ({ search, searchByHandler, inputHandler }) => {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { isLoggedIn, currentUser } = useSelector((state) => state.auth);
  // const { isLoggedIn } = useSelector((state) => state.auth);


  useEffect(() => {
    // getDemand();

    dispatch(getCurrentUserInfo());
    //
    // let params = url.set_current_query_string_filter(search)
    //
    // navigate(`?${params.toString()}`);
    //
    // console.log(`Search By: ${search.searchBy} | Filter: ${search.selectedFilter} | Input: ${search.input}, `);
  }, []);

  // staticPaths
  // DEMANDS

  return (
    <Row className="mb-3">
      <Col md={(isLoggedIn && currentUser.role_active == 'customer') ? 5 : 7} sm={6} className="mb-2">
        <div
          data-testid="market-demand-controls"
        >
          <input
            type="search"
            className="input-search w-full"
            placeholder="Search"
            value={search.input}
            onChange={({ target }) => inputHandler(target.value)}
          />

        </div>
      </Col>
      {isLoggedIn && currentUser.role_active == 'customer' && (
        <Col lg={5} sm={6} >

          <button
            onClick={() => navigate(routesPaths.DEMANDS_CREATE)}
            className="btn-home btn-browser-product" style={{ marginTop: "0px", height: "35px" }}>
            <FontAwesomeIcon icon={faPlus} className="mr-1" />
            New Demand
          </button>
        </Col>
      )}

    </Row>

  );
};

export default DemandMarketControls;
