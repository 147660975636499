import { useState, useEffect, useContext } from "react";
import AccountLayout from "pages/AccountLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { storeShow, storeUpdate } from "../../../features/store/storeSlice";
import SnackbarContext from "_helpers/snackbar-context";
import CropEdit from "../crops/CropEdit"
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { varietyShow, varietyStore } from "features/variety/varietySlice";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const StoreEdit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const snackbarCtx = useContext(SnackbarContext);
    const params = useParams();
    const { varieties } = useSelector((state) => state.variety);
    const [name, setName] = useState("");
    const [wait, setWait] = useState(false);
    
    const update = (event) => {
        event.preventDefault();
    };

    const loadStore = () => {
        let storeId = params.store_id;

        dispatch(storeShow({
            'store_id': storeId
        }
        )).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            } else {
                let store = response.payload.data.store;

                setName(store.name);
            }
        });
    }

    const updateStore = () => {
        let storeId = params.store_id;

        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            dispatch(storeUpdate({
                name: name,
                address: "address here",
                store_id: storeId
            })).then(function (response) {
                if (response.error && response.error.message == "Rejected") {
                    alert(response.payload);
                }
            });

        }, 1000))
    }

    const addCrop = () => {
        dispatch(varietyStore({
            name: '',
            crop_id: '',
        })).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure");
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success");
                loadVarieties();
            }
        });
    }

    const loadVarieties = () => {
        dispatch(varietyShow({}
        )).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            }
        });
    }

    useEffect(() => {
        loadStore();
        loadVarieties();
    }, []);

    return (
        <AccountLayout>
            <div className="w-full flex flex-col gap-4 p-4 laptop:p-6 wide:p-6">
                <div>
                    <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                        {" Go Back"}
                    </label>
                    <div className="title-content">
                        Crop & Stock Basic Information
                    </div>
                </div>

                <div className="flex flex-col rounded-lg shadow bg-card-white-new mb-5">
                    <form onSubmit={update}>
                        <div className="w-full">
                            <Row>
                                <Col md={12} className="mb-3">
                                    <div className="title-sidebar">
                                        Collection Center
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <input type="text"
                                        className="input-search mb-2"
                                        placeholder="Collection Center Name"
                                        value={name}
                                        onChange={({ target }) => {
                                            setName(target.value)
                                        }}

                                        onKeyUp={updateStore}
                                    />
                                </Col>
                                <Col md={6}>
                                    {/* <button type="button" className="btn-border-green">
                                        Manage Collection Centers
                                    </button> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="mb-3 mt-4">
                                    <div className="title-sidebar">
                                        Crops
                                    </div>
                                </Col>

                            </Row>
                            <div className="space-y-4">
                                {varieties && varieties.data.map((variety, varietyIndex) => {
                                    return (<CropEdit variety={variety} key={varietyIndex} varietyIndex={varietyIndex} reloadVariety={loadVarieties} />)
                                })}
                            </div>
                            <Row className="mt-4">
                                <Col md={12}>
                                    <button
                                        onClick={addCrop}
                                        className="btn-home btn-browser-product laptop:w-[12rem] wide:w-[12rem]"
                                        style={{ marginTop: "0px", height: "35px" }}>
                                        <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                        Add Crop
                                    </button>
                                </Col>
                            </Row>

                        </div>
                    </form>

                </div>
            </div>

        </AccountLayout>
    );
};

export default StoreEdit;
