import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  companyService from "./companyService";

export const companyIndex = createAsyncThunk(
    "companies/index",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await companyService.companyIndex(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyShow = createAsyncThunk(
    "companies/:id/show",
    async (args, thunkAPI) => {
        try {
            return await companyService.companyShow(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyUpdate = createAsyncThunk(
    "companies/companyUpdate",
    async (args, thunkAPI) => {
        try {
            return await companyService.companyUpdate(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

export const companyDelete = createAsyncThunk(
    "companies/:id/delete",
    async (args, thunkAPI) => {
        try {
            return await companyService.companyDelete(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    company: {},
    companies:  {
        data:[]
    },
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
    },
    extraReducers : {
        [companyIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.companies = payload.data.companies;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [companyShow.pending]:(state, {payload}) => {
            state.data = {};
            state.product = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.company = payload.data.company;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
        [companyUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },

        [companyDelete.pending]:(state, {payload}) => {
            state.data = {};
            state.user = {};

            state.loading=true;
            state.updating=true;
            state.isSuccess = false;
        },
        [companyDelete.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.loading=false;
            state.updating=false;
            state.isSuccess = true;
        },
        [companyDelete.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.updating = false;
            state.isSuccess = true;
        },
    }
})

export default companySlice;