import AccountLayout from "pages/AccountLayout";
import { useEffect, useState } from "react";
import AccountCompany from "./AccountCompany";
import AccountControls from "./AccountControls";
import AccountPersonal from "./AccountPersonal";
import AccountAddress from "./AccountAddress";
import { useParams } from "react-router-dom";
import AccountPackage from "./AccountPackage";
import AccountBankDetail from "./AccountBankDetail";
import clsx from "clsx";
import styles from "./Account.module.scss";
import { authGetUser, getCurrentUserInfo } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import AccountBill from "./AccountBill";

const AccountDetails = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState("Personal Details");
  const params = useParams();

  const pageHandler = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getCurrentUserInfo());
    dispatch(authGetUser());

    if (params.edit_type !== undefined) {
      if (params.edit_type === "Address") {
        setPage("Address");
      }
    }

  }, []);

  return (
    <AccountLayout>
      <div className={clsx(styles.footerMobile,"flex flex-col items-start gap-6 p-4")}>
        <h1 className="text-2xl font-bold" style={{ color: "#395B50" }}>Edit Account Details</h1>
        <AccountControls page={page} pageHandler={pageHandler} />
        <div className={clsx("w-full max-w flex flex-col items-start gap-4 p-4 rounded-lg", (page === "Package" || page === "Billing") ? "" : "bg-white shadow")}>
          <div className="row" style={{ width: "100%" }}>
            {page === "Personal Details" && <AccountPersonal />}
            {page === "Company Details" && <AccountCompany />}
            {page === "Address" && <AccountAddress />}
            {page === "Package" && <AccountPackage />}
            {page === "Bank" && <AccountBankDetail />}
            {page === "Billing" && <AccountBill />}
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default AccountDetails;
