import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Text from "components/text/Text";
import { loginFB, loginGoogle } from "../../features/auth/loginSlice";

import googleLogo from "images/Google.svg";
import facebookLogo from "images/Facebook.svg";
import FarmIcon from 'images/FarmIcon.svg';
import BuyerIcon from 'images/BuyerIcon.svg';

import { registerDetail } from "features/auth/registerSlice";
import SnackbarContext from "_helpers/snackbar-context";

import styles from './RegisterDetail.module.scss';
import clsx from "clsx";
import { initFBLoginAPI } from "support/support_function";
import { useGoogleLogin } from '@react-oauth/google';
import resources from '../../constants/translation'
import TermsOfUse from "components/home/TermsOfUse";

const RegisterDetails = () => {
  const [accept, setAccept] = useState(false);
  const [useEmail, setUseEmail] = useState(false);
  const [usePhoneNumber, setPhoneNumber] = useState(false);
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showTerms, setTermsModal] = useState(false);

  const dispatch = useDispatch();
  const register = useSelector((state) => state.register);
  const snackbarCtx = useContext(SnackbarContext);

  const navigate = useNavigate();

  const nextHandler = () => {
    setLoading(true);

    if (!accept) {
      snackbarCtx.displayMsg("Please accept our terms", "failure");
      return;
    }
    dispatch(
      registerDetail({
        username,
        phone_number: phone,
        email,
        password,
        password_confirmation: confirmPassword,
        // register_using_email: useEmail,
        register_using_phone: usePhoneNumber,
        accept,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        setLoading(false);
        snackbarCtx.displayMsg(response.payload, "failure");
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success");
        navigate("/register/company");
      }
    });
  };

  const loginWithFacebook = () => {
    setLoading(true);
    
    window.FB.login(function (response) {
      if (response.status === 'connected') {
        dispatch(
          loginFB({
            token: response.authResponse.accessToken,
          })
        ).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            setLoading(false);
            snackbarCtx.displayMsg(response.payload, "failure");
          } else {
            if (response?.payload?.data?.data?.user?.role_name !== null) {
              window.location = "/";
            } else {
              
              window.location = "/register/company";
            }
          }
        });
      } else {
        //user hit cancel button
        // console.log('User cancelled login or did not fully authorize.');
        snackbarCtx.displayMsg(resources['login.socialCancelButton'], "failure");

      }
    }, {
      scope: 'public_profile,email'
    });
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: tokenResponse => {
      setLoading(true);

      dispatch(
        loginGoogle({
          token: tokenResponse.access_token,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          setLoading(false);
          snackbarCtx.displayMsg(response.payload, "failure");
        } else {
          if (response?.payload?.data?.user?.role_name !== null) {
            window.location = "/";
          } else {
            window.location = "/register/company";
          }
        }
      });
    }
  });

  useEffect(() => {
    initFBLoginAPI();
  }, [])
  return (
    <div data-testid="register-detail">
      <form className={clsx(styles.box,"flex", "flex-col", "space-y-4")}>
        {/* User Input */}
        <div className={clsx("self-center", styles.headerLogin)}>
          <span>
            <Text id="registration.accountRegistration" />
          </span>
        </div>
        <div>
          <section className="self-center flex flex-col space-y-4">
            <input
              type="text"
              placeholder="Username"
              minLength={3}
              value={username}
              className={clsx("", styles.inputUserPass)}
              onChange={({ target }) => setUsername(target.value)}
            />
            <input
              type="email"
              className={clsx("", styles.inputUserPass)}
              placeholder="Email*"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={phone}
              className={clsx(!usePhoneNumber ? "bg-gray-400" : "", styles.inputUserPass)}
              disabled={!usePhoneNumber}
              onChange={(event) => {
                if (isNaN(Number(event.target.value))) {
                  return;
                } else {
                  setPhone(event.target.value);
                }
              }}
            // onChange={({ target }) => setPhone(target.value)}
            />
            <div className="flex gap-x-2">
              <input
                type="checkbox"
                value={usePhoneNumber}
                onClick={() => {
                  setPhoneNumber(!usePhoneNumber);
                }}
              />
              <p className="text-sm">
                <Text id="registration.registerUsingPhoneNumber" />
              </p>
            </div>
            <input
              type="password"
              placeholder="Password"
              minLength={7}
              value={password}
              className={clsx("", styles.inputUserPass)}
              onChange={({ target }) => setPassword(target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              minLength={7}
              value={confirmPassword}
              className={clsx("", styles.inputUserPass)}
              onChange={({ target }) => setConfirmPassword(target.value)}
            />
          </section>
        </div>


        <p className="self-center">
          <Text id="or" />
        </p>

        {/* Other Methods */}
        <div className="flex flex-col space-y-4" style={{ marginTop: "0px" }}>
          <button type="button" className={styles.borderedButton} onClick={() => loginWithGoogle()}>
            <p className="text-gray-400 text-sm" style={{ fontSize: "16px" }}>
              <Text id="login.registerInWithGoogle" />
            </p>
            <img style={{ marginLeft: "5px" }} className="w-5" src={googleLogo} alt="Google logo" />
          </button>
          <button type="button" className={styles.borderedButton} onClick={() => loginWithFacebook()}>
            <p className="text-gray-400 text-sm" style={{ fontSize: "16px" }}>
              <Text id="login.registerInWithFacebook" />
            </p>
            <img style={{ marginLeft: "5px" }} className="w-5" src={facebookLogo} alt="Facebook logo" />
          </button>
        </div>

        {/* Next */}
        <section className="self-center flex flex-col space-y-2">
          <div className="flex gap-x-2">
            <input
              type="checkbox"
              value={accept}
              onClick={() => {
                setAccept(!accept);
              }}
            />
            <p className="text-xs">
              By clicking you accept to our <u className="pointer-events-auto" onClick={() => setTermsModal(true)}>Terms & Conditions</u>
            </p>
          </div>
          <p className="text-xs">
            *Phone number is optional when registering using email.
          </p>

          <button
            type="button"
            className={clsx(styles.nextBtnDetail, loading ? "bg-gray-500 text-white" : "bg-green-500 hover:bg-green-600 text-white")}
            disabled={loading}
            onClick={nextHandler}
            lo
          >
            <Text id="next" />
          </button>
        </section>
      </form>
      {showTerms && (
        <div className="fixed z-10 top-0 left-0 bg-black/50 w-screen h-screen flex flex-col justify-center items-center">
          <section data-testid="popup-layout" className="absolute bg-white w-4/5 h-3/4 flex flex-col rounded-xl shadow pb-4">
            <div className="bg-white w-full h-full flex flex-col rounded-t-xl px-8 py-4 overflow-y-auto">
              <TermsOfUse />
            </div>
            <div className="w-full flex gap-4 justify-center mt-2">
              <button className="borderedButton w-2/5"  onClick={() => setTermsModal(false)}>
                Close
              </button>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default RegisterDetails;
