import axios from "axios";
import storage from "./support_storage";

const axiosPost = async (URL, data) => {
    let config = await storage.getConfig();

    return await axios.post(`${process.env.REACT_APP_BACKEND_API}${URL}`, data, config);
}

const axiosPatch = async (URL, data) => {
    let config = await storage.getConfig();

    return await axios.patch(`${process.env.REACT_APP_BACKEND_API}${URL}`, data, config);
}

const axiosGet = async (URL, params) => {
    let config = await storage.getConfig();

    let newUrl = `${process.env.REACT_APP_BACKEND_API}${URL}?${new URLSearchParams(params).toString()}`;

    return await axios.get(newUrl, config);
}

const axiosShow = async (URL, params) => {
    let config = await storage.getConfig();

    let newUrl = `${process.env.REACT_APP_BACKEND_API}${URL}`;

    return await axios.get(newUrl, config);
}

const axiosPut = async (URL, data) => {
    let config = await storage.getConfig();

    return await axios.put(`${process.env.REACT_APP_BACKEND_API}${URL}`, data, config);
}

const axiosDelete = async (URL) => {
    let config = await storage.getConfig();

    return await axios.delete(`${process.env.REACT_APP_BACKEND_API}${URL}`, config);
}

const axiosDownloadPDF = async (URL, params) => {
    let config = await storage.getConfig();
    let temp = JSON.parse(JSON.stringify(config));
    temp.responseType = 'blob';

    let newUrl = `${process.env.REACT_APP_BACKEND_API}${URL}?${new URLSearchParams(params).toString()}`;

    return await axios.get(newUrl, temp);
}

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status && `${process.env.REACT_APP_BACKEND_API}/users/login`.substring(0, 55) !== error.response.config.url.substring(0, 55)) {
        if (window.location.pathname !== '/login' && window.location.pathname !== '/') {
            alert("Unauthorized");
            storage.setUserClear();
            window.location = "/login";
        }
    } else {
        return Promise.reject(error);
    }
});

const support_api = {
    axiosPost,
    axiosPatch,
    axiosGet,
    axiosShow,
    axiosPut,
    axiosDelete,
    axiosDownloadPDF,
}

export default support_api;