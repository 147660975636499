import { useNavigate } from "react-router-dom";
import farmerProduce from "images/home/mobile_farmer_produce_icon.svg";
import home from "images/home/mobile_home_icon.svg";
import marketDemand from "images/home/mobile_market_demand_icon.svg";
import notification from "images/home/mobile_notification_icon.svg";
import mobile_profileIcon from "images/home/mobile_profile_icon.svg";
import { useSelector } from "react-redux";
import styles from "./footer.module.scss";
import clsx from "clsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faHouse, faShop, faEnvelope, faUser, faCartShopping, faBell, faRightToBracket, faFilePen } from '@fortawesome/free-solid-svg-icons';

const Footer = () =>{
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);
  // const isMobile = () => window.innerWidth <= 900;

return(
    <>
    {isLoggedIn ? (
    <footer className={clsx(styles.footerMobile,"fixed bottom-0 left-0 right-0 bg-white py-2  w-full")} style={{ zIndex: 400 }}>
      <div className="grid grid-cols-5">
        <div className="flex flex-col self-center text-center" style={{ fontSize: '8px' }} onClick={() =>  navigate(`/`)}>
            {/* <img src={home} className="mx-auto w-8 h-8"/> */}
            <FontAwesomeIcon className="mx-auto w-8 h-8" icon={faHouse} title="Home" />
            Home
          </div>
        <div className="flex flex-col self-center text-center" style={{ fontSize: '8px' }} onClick={() => navigate(`/products/catalogs`)}>
          {/* <img src={farmerProduce} className="mx-auto w-8 h-8" alt="Farmer's Produce" /> */}
          <FontAwesomeIcon className="mx-auto w-8 h-8" icon={faSeedling} />
          Farmer's Produce
        </div>
        <div className="flex flex-col self-center text-center" style={{ fontSize: '8px' }} onClick={() => navigate(`/demands/market`)}>
          {/* <img src={marketDemand} className="mx-auto w-8 h-8"/> */}
          <FontAwesomeIcon className="mx-auto w-8 h-8" icon={faShop} />
          Market Demand
        </div>
        <div className="flex flex-col self-center text-center" style={{ fontSize: '8px' }} onClick={() => navigate(`/notification/show`)}>
          {/* <img src={notification} className="mx-auto w-8 h-8"/> */}
          <FontAwesomeIcon className="mx-auto w-8 h-8" icon={faBell} />
          Notification
        </div>
        <div className="flex flex-col self-center text-center" style={{ fontSize: '8px' }} onClick={() => navigate(`/account/edit`)}>
          {/* <img src={mobile_profileIcon} className="mx-auto w-8 h-8"/> */}
          <FontAwesomeIcon className="mx-auto w-8 h-8" icon={faUser} />
          Profile
        </div>
      </div>
    </footer>
    ) : (<></>) }
    </>
);
}

export default Footer;