import { catalogDestroy } from "../../features/catalog/catalogSlice";
import {
  demandApplicationAccept,
  demandApplicationDecline,
} from "../../features/demand-application/demandApplicationSlice";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routesPaths } from "constants/routes.constants";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Row } from "react-bootstrap";

const DemandApplicantList = ({ userDemandApplications, setShowPopSuccesAct }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const snackbarCtx = useContext(SnackbarContext);

  const acceptHandler = (userDemandApplication) => {
    if (window.confirm(`Are you sure to accept farmer ${userDemandApplication.name} for this demand?`)) {
      dispatch(
        demandApplicationAccept({
          demand_application_id: userDemandApplication.demand_application.id,
        })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure");
          //alert(response.payload);
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success");
          // alert(response.payload.message);
          //
          // loadProduct()
          setShowPopSuccesAct(true);
          // navigate(routesPaths.DEMANDS);
        }
      });
    }
  };

  const demand_id = 1;
  const viewFarmHandler = (user) => {
    navigate(`/farms/view/farmer/${user.id}/widgets`);
    // navigate(`${routesPaths.DEMANDS}/${demand_id}/show/widgets`);
  };

  return (
    <>
      {userDemandApplications.data.map((userDemandApplication, index) => (
        <>
          <Row className="mb-3">
            <Col md={12}>
              <div className="flex flex-col rounded-lg shadow bg-card-white-new">
                <Row className="items-center">
                  <Col md={2} className="text-center">
                    <img
                      // src={orderDetail.product.photo ? info.photo : "/assets/images/products/WheatIcon.svg"}
                      src={userDemandApplication.photo_default.url}
                      alt={userDemandApplication.name}
                      style={{ margin: "auto", display: "block" }}
                      className="max-w-[9rem] max-h-[9rem] laptop:max-w-[18rem] laptop:max-h-[18rem] w-full h-full object-cover aspect-square rounded-full "
                    />

                  </Col>
                  <Col md={8} >
                    <div className="laptop:pl-10 flex flex-col">
                      <label className="text-2md font-semibold">{userDemandApplication.name} </label>

                      <label>
                        Location: {userDemandApplication?.company?.billing_address || 'Not Available'}
                      </label>

                    </div>
                  </Col>
                  <Col md={2}>
                    <section className="w-full flex flex-col gap-2 mt-1">
                      {userDemandApplication.demand_application.status_text === "pending" && (
                        <button style={{ borderRadius: "8px" }} className="saveButton" onClick={() => acceptHandler(userDemandApplication)}>
                          Accept
                        </button>
                      )}

                      {userDemandApplication.demand_application.status_text === "accepted" && (
                        <button style={{ borderRadius: "8px" }} className="saveButton" disabled={true}>
                          Accepted
                        </button>
                      )}

                      <button className="btn-border-green" onClick={() => viewFarmHandler(userDemandApplication)}>
                        View Farm
                      </button>
                    </section>
                  </Col>

                </Row>

              </div>
            </Col>

          </Row>
        </>
      ))}
    </>

  );
};

export default DemandApplicantList;
