import React, { useContext } from "react";
import ReactDOM from "react-dom";

import SnackbarContext from "_helpers/snackbar-context";

import styles from "./Snackbar.module.css";

const Snackbar = () => {
    const snackbarCtx = useContext(SnackbarContext);

    return (
        <div className={`${styles.snackbarContainer} ${styles[snackbarCtx.status]}`}>
            <div className={styles.snackbarLabel}>{snackbarCtx.msg}</div>
            <button className={styles.snackbarDismiss} onClick={()=>snackbarCtx.onClose}>
                &times;
            </button>
        </div>)
};

export default Snackbar;