import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { advanceBookingApprove, advanceBookingDetail, advanceBookingReject, advanceBookingCreateOrder, confirmOfflinePaymentDeposit, uploadPaymentReceipt } from "../../../features/advance-booking/advanceBookingSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import AccountLayout from "pages/AccountLayout";
import FarmImage from "../../../images/FarmImage.png";

import { Col, Modal, Row } from "react-bootstrap";
import { QRCodeSVG } from 'qrcode.react';
import * as statusConst from 'constants/advanceBooking.constants';

import styles from "../pay-deposit/AdvanceBookingDeposit.module.scss";
import clsx from "clsx";
// import { useSearchParams } from "react-router-dom";
// import {Route, Link, Routes, useParams} from 'react-router-dom';

const AdvanceBookingDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const snackbarCtx = useContext(SnackbarContext);
  const [bookingPlot, setBookingPlot] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const id = params.booking_id;
  const { currentUser } = useSelector((state) => state.auth);
  //modal payment proof
  const [showPaymentProofModal, setShowPaymentProofModal] = useState(false);
  const popupClosePaymentProofModal = () => setShowPaymentProofModal(false);
  const popupShowPaymentProofModal = () => setShowPaymentProofModal(true);

  // console.log("auth",reduxAuth);
  const loadDetail = () => {
    dispatch(
      advanceBookingDetail({
        id: id,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
      } else {
        let result = response.payload.data.booking_plot;
        setBookingPlot(result);
      }
    });
  };

  const denyBooking = () => {
    if (window.confirm("Are you sure want to deny this booking?")) {
      dispatch(advanceBookingReject({
        id: id,
      })).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          alert(response.payload);
        } else {
          let result = response.payload.data.booking_plot;
          snackbarCtx.displayMsg(response.payload.message, "success");
          // console.log(result);
          setBookingPlot(result);
        }
      });
    }
  }

  const acceptBooking = () => {
    if (window.confirm("Are you sure want to accept this booking?")) {
      dispatch(advanceBookingApprove({
        id: id,
      })).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          alert(response.payload);
        } else {
          let result = response.payload.data.booking_plot;
          snackbarCtx.displayMsg(response.payload.message, "success");
          // console.log(result);
          setBookingPlot(result);
        }
      });
    }
  }

  const depositHandler = () => {
    navigate(`/manage-advance-booking/deposit/${bookingPlot.id}`);
  };

  const changePaymentReceiptHandler = () => {
    // navigate(`/manage-advance-booking/deposit-offline/${bookingPlot.id}`);
    if (selectedFile == null) {
      alert("Please choose payment proof.");
    } else {
      if (window.confirm("Are you sure want to update payment proof for this booking?")) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        formData.append("id", params.booking_id);

        dispatch(uploadPaymentReceipt(formData)).then(function (response) {
          setIsLoading(false);
          popupClosePaymentProofModal();
          if (response.error && response.error.message === "Rejected") {
            alert(response.payload);
          } else {
            let result = response.payload.data.booking_plot;
            setBookingPlot(result);
            setIsLoading(false);
            snackbarCtx.displayMsg(response.payload.message, "success");
            navigate(`/manage-advance-booking/detail/${params.booking_id}`);
          }
        });
      }
    }
  };

  const createOrderHandler = () => {
    if (window.confirm("Are you sure want to create order from this booking?")) {
      dispatch(advanceBookingCreateOrder({
        id: id,
      })).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          alert(response.payload);
        } else {
          let result = response.payload.data.booking_plot;
          snackbarCtx.displayMsg(response.payload.message, "success");
          setBookingPlot(result);
        }
      });
    }
  };

  const confirmDepositHandler = () => {
    if (window.confirm("Are you sure want to confirm deposit from this booking?")) {
      dispatch(confirmOfflinePaymentDeposit({
        id: id,
      })).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          alert(response.payload);
        } else {
          let result = response.payload.data.booking_plot;
          snackbarCtx.displayMsg(response.payload.message, "success");
          setBookingPlot(result);
        }
      });
    }
  };

  const downloadPaymentReceiptHandler = () => {
    navigate(bookingPlot.payment_receipt);
  };

  const handleChangeFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  useEffect(() => {
    loadDetail();
  }, []);

  return (
    <AccountLayout>
      <div data-testid="order-details" className="flex flex-col items-start gap-6 p-4 mb-5">
        <h1 className="text-2xl font-bold" style={{ color: "#395B50" }}>Booking #{(bookingPlot) ? (bookingPlot) ? bookingPlot.booking_number : '' : ''}</h1>
        <div className="w-full max-w bg-white flex flex-col shadow rounded-lg p-4 space-y-4">
          <label className="font-medium" onClick={() => navigate(-1)} style={{ color: "#395B50" }}>
            {"< Go back to the list"}
          </label>
          <section className="relative flex flex-col justify-center items-center">
            <img
              src="/assets/images/QRIcon.svg"
              alt="QR icon"
              className={clsx(styles.qrCode)}
            />
            {/* Info */}
            <div className="flex max-w-[100rem] w-full">
              {/* Farmer Information bg-buttonText  text-white */}
              <div className={"bg-buttonText text-white w-full flex flex-col p-4 gap-2 rounded-l-lg shadow tablet:h-fit desktop:h-[12rem]"}>
                <label className="text-3xl">Farmer</label>
                <label className={clsx(styles.mobileText)}>{(bookingPlot) ? (bookingPlot?.plot?.farm) ? bookingPlot?.plot?.farm?.name : '' : ''}</label>
                <label className={clsx(styles.mobileText)}>{(bookingPlot) ? (bookingPlot?.plot?.farm) ? bookingPlot?.plot?.farm?.address : '' : ''}</label>
              </div>

              {/* Customer Information */}
              <div className={"bg-formSemiDark text-white w-full flex flex-col p-4 gap-2 rounded-r-lg shadow tablet:h-fit desktop:h-[12rem]"}>
                <label className="text-3xl">Buyer</label>
                <label className={clsx(styles.mobileText)}>{(bookingPlot) ? (bookingPlot.buyer.company) ? bookingPlot.buyer.company.name : '' : ''}</label>
                <label className={clsx(styles.mobileText)}>{(bookingPlot) ? (bookingPlot.buyer.company) ? bookingPlot.buyer.company.billing_address : '' : ''}</label>
              </div>
            </div>

            {/* Status */}
            <div className="flex max-w-[100rem] w-full">
              {/* Farmer Status */}
              <div className="w-full h-[12rem] flex flex-col p-4 gap-4 items-center mt-5">
                <label className="text-3xl text-center">Status</label>
                <img
                  src={(bookingPlot) ? (currentUser.role_active == 'farmer') ? statusConst.bookingPlotStatus[bookingPlot.status].icon_farmer : statusConst.bookingPlotStatus[bookingPlot.status].icon_buyer : ''}
                  alt="status icon"
                  className="w-[4rem] h-[4rem]"
                />
                <label className="text-lg text-gray-600 text-center">
                  {(bookingPlot) ? (currentUser.role_active == 'farmer') ? statusConst.bookingPlotStatus[bookingPlot.status].label_in_farmer_farmer : statusConst.bookingPlotStatus[bookingPlot.status].label_in_buyer_farmer : ''}
                </label>
              </div>
              {/* Customer Status */}
              <div className="w-full h-fit flex flex-col p-4 gap-4 items-center mt-5">
                <label className="text-3xl text-center">Status</label>
                <img
                  src={(bookingPlot) ? (currentUser.role_active == 'farmer') ? statusConst.bookingPlotStatus[bookingPlot.status].icon_farmer : statusConst.bookingPlotStatus[bookingPlot.status].icon_buyer : ''}
                  alt="status icon"
                  className="w-[4rem] h-[4rem]"
                />
                <label className="text-lg text-gray-600 text-center">
                  {(bookingPlot) ? (currentUser.role_active == 'farmer') ? statusConst.bookingPlotStatus[bookingPlot.status].label_in_farmer_buyer : statusConst.bookingPlotStatus[bookingPlot.status].label_in_buyer_buyer : ''}
                </label>
              </div>
            </div>
          </section>
          <br />
          <Row>
            <Col md={4}></Col>
            <Col md={5}>
              {(bookingPlot) ? (bookingPlot.plot.harvest_count_down_full == 'Harvest date has passed') ?
                <>
                  <h4 className="mt-2 text-red text-center"><b>{bookingPlot.plot.harvest_count_down_full}</b></h4>
                </>
                :
                <>
                  <center>
                    <div style={{ display: "inline", float: "left" }} className="mr-4 text-center">
                      <h4 className="mt-2"><b>Countdown to <br />Harvest</b></h4>
                    </div>
                    <div style={{ display: "inline", float: "left" }}>
                      <table>
                        <tr>
                          <td className="text-center">DAY</td>
                          <td className="pl-2 pr-2"></td>
                          <td className="text-center">HOUR</td>
                          <td className="pl-2 pr-2"></td>
                          <td className="text-center">MIN</td>
                        </tr>
                        <tr>
                          <td className="text-center text-green">{(bookingPlot) ? (bookingPlot.plot.harvest_count_down_full) ? bookingPlot.plot.harvest_count_down_full.days : '' : ''}</td>
                          <td className="pl-2 pr-2 text-green">:</td>
                          <td className="text-center text-green">{(bookingPlot) ? (bookingPlot.plot.harvest_count_down_full) ? bookingPlot.plot.harvest_count_down_full.hours : '' : ''}</td>
                          <td className="pl-2 pr-2 text-green">:</td>
                          <td className="text-center text-green">{(bookingPlot) ? (bookingPlot.plot.harvest_count_down_full) ? bookingPlot.plot.harvest_count_down_full.minutes : '' : ''}</td>
                        </tr>
                      </table>
                    </div>
                  </center>

                </>
                :
                ""
              }
            </Col>
            <Col md={3}></Col>
          </Row>
          <br />
          <br />
          <br />
          <h1 className="text-2xl">Booking Info</h1>
          <ol>
            <li className={"mt-2 gap-y-2"} key={0}>
              <div className="flex flex-row gap-x-3">
                <img
                  src={(bookingPlot) ? bookingPlot?.plot ? bookingPlot?.plot?.farm?.photo_default : "" : ""}
                  alt=""
                  className="  orderLarge:inline min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] object-cover aspect-square rounded-full"
                />
                <div className="flex flex-col">
                  <span><b>Plot Name:</b> {(bookingPlot) ? bookingPlot.plot.name : ''}</span>  <span><b>Quantity:</b> {(bookingPlot) ? bookingPlot.quantity + ' ' + bookingPlot.quantity_unit : ''}</span> <span><b>Price:</b> RM {(bookingPlot) ? bookingPlot.total_price : ''}</span>
                </div>
              </div>
            </li>
          </ol>
          <p className="text-red mt-2" hidden={(currentUser) ? (currentUser.role_active == 'farmer') ? false : true : true}><center>{(bookingPlot) ? statusConst.bookingPlotStatus[bookingPlot.status].status_farmer : ''}</center></p>
          <p className="text-red mt-2" hidden={(currentUser) ? (currentUser.role_active == 'customer') ? false : true : true}><center>{(bookingPlot) ? statusConst.bookingPlotStatus[bookingPlot.status].status_buyer : ''}</center></p>
          <Row hidden={(bookingPlot) ? (currentUser) ? (bookingPlot.status == 0 && currentUser.role_active == 'farmer') ? false : true : true : true}>
            <Col md={4}></Col>
            <Col md={2} xs={6}>
              <button type="button" className="btn-red" onClick={(event) => {
                event.preventDefault();
                denyBooking();
              }}>
                Deny
              </button>
            </Col>
            <Col lg={(bookingPlot?.deposit_amount > 0) ? 2 : 3} xs={6}>
              <button type="button" className="btn-green" onClick={(event) => {
                event.preventDefault();
                if (bookingPlot) {
                  if (bookingPlot.deposit_amount > 0) {
                    acceptBooking();
                  } else {
                    createOrderHandler();
                  }
                }
              }}>
                {(bookingPlot?.deposit_amount > 0) ? "Accept" : "Accept & Create Order"}
              </button>
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row className="flex items-center justify-center w-full" hidden={(bookingPlot) ? (currentUser) ? (bookingPlot.status == 1 && currentUser.role_active == 'customer') ? false : true : true : true}>
            {/* <Col md={5}></Col> */}
            <Col xs={8} md={3} lg={2}>
              <button type="button" className="btn-green" onClick={depositHandler}>
                Pay Deposit
              </button>
            </Col>
            {/* <Col md={5}></Col> */}
          </Row>
          <Row className="flex items-center justify-center w-full" hidden={(bookingPlot) ? (currentUser) ? (bookingPlot.status == 5 && currentUser.role_active == 'customer') ? false : true : true : true}>
            {/* <Col md={3}></Col> */}
            <Col xs={10} md={8} lg={2} className="flex items-center justify-center">
              <Row>
                <Col>
                  <button type="button" className="btn-green float-left mb-2">
                    <a href={bookingPlot?.payment_receipt} target="_blank" download>View Payment Proof</a>
                  </button>
                </Col>
                <Col>
                  <button type="button" className="btn-green float-right" onClick={popupShowPaymentProofModal} hidden={(bookingPlot) ? (currentUser) ? (bookingPlot.status == 5 && currentUser.role_active == 'customer') ? false : true : true : true}>
                    Edit Payment Proof
                  </button>
                </Col>
              </Row>
            </Col>
            {/* <Col md={3}></Col> */}
          </Row>
          <Row hidden={(bookingPlot) ? (bookingPlot.status == 5 && currentUser?.role_active == 'farmer') ? false : true : true}>
            <Col md={4}></Col>
            <Col md={4}>
              <center>
                <button type="button" className="btn-green">
                  <a href={bookingPlot?.payment_receipt} target="_blank" download>View Payment Proof</a>
                </button>
              </center>
            </Col>
            <Col md={4}></Col>
          </Row>
          <Row hidden={(bookingPlot) ? (currentUser) ? (bookingPlot.status == 3 && currentUser.role_active == 'farmer') ? false : true : true : true}>
            <Col md={5}></Col>
            <Col md={2}>
              <button type="button" className="btn-green" onClick={createOrderHandler}>
                Create Order
              </button>
            </Col>
            <Col md={5}></Col>
          </Row>
          <Row hidden={(bookingPlot) ? (currentUser) ? (bookingPlot.status == 5 && currentUser.role_active == 'farmer') ? false : true : true : true}>
            <Col md={4}></Col>
            <Col md={4}>
              <button type="button" className="btn-green" onClick={confirmDepositHandler}>
                Confirm Deposit
              </button>
            </Col>
            <Col md={4}></Col>
          </Row>
        </div>
      </div>

      <Modal className="modal-medium-custom" show={showPaymentProofModal} onHide={popupClosePaymentProofModal}>
        <Modal.Body>
          <h4 className="align-center">Edit Payment Proof</h4>
          <Row className="mt-4 mr-4 ml-4">
            <Col md={12}>
              {/* <form onSubmit={changePaymentReceiptHandler}> */}
              <Row>
                <Col md={12}>
                  <input
                    id="inputGroupFile"
                    // className={"hidden"}
                    className="form-control"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleChangeFile}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={12}>
                  <button type="button" className="btn-green laptop:w-5/5 wide:w-5/5" onClick={changePaymentReceiptHandler}>
                    {(isLoading) ? "Processing, please wait.." : "Update Payment Proof"}
                  </button>
                </Col>
              </Row>
              {/* </form> */}
            </Col>

          </Row>
        </Modal.Body>
      </Modal>
    </AccountLayout>
  );
};

export default AdvanceBookingDetail;
