import { routesPaths } from "constants/routes.constants";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import AccountLayout from "pages/AccountLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Modal, InputGroup, Form } from "react-bootstrap";
import { orderFarmerDeliveryDone, orderFarmerShow, bookingPlotDetail, confirmOrderQuantity, cancelOrder, printAwb, confirmOfflinePayment, orderFarmerInDelivery, orderFarmerDelivered } from "../../features/order-farmer/orderFarmerSlice";
import SnackbarContext from "_helpers/snackbar-context";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
import { orderApprove, orderReject } from "features/order/orderSlice";

const OrderFarmerTracking = () => {
    const [done, setDone] = useState(false);
    const [company, setCompany] = useState(null);
    const snackbarCtx = useContext(SnackbarContext);

    const [modalConfirmQuantity, setModalConfirmQuantity] = useState(false);
    const popupCloseModalConfirmQuantity = () => setModalConfirmQuantity(false);
    const popupShowModalConfirmQuantity = () => setModalConfirmQuantity(true);
    const [isLoadingConfirmQuantity, setIsLoadingConfirmQuantity] = useState(false);

    //modal confirm
    const [modalConfirm, setModalConfirm] = useState(false);
    const popupCloseModalConfirm = () => setModalConfirm(false);
    const popupShowModalConfirm = () => setModalConfirm(true);

    const [photos, setPhotos] = useState([]);
    const [bookingPlot, setBookingPlot] = useState(null);
    const [actualQuantity, setActualQuantity] = useState(0);
    const [showConfirmQtyButton, setShowConfirmQtyBtn] = useState(null);
    const [isConfirmByBuyer, setIsConfirmByBuyer] = useState(null);
    const [isConfirmByFarmer, setIsConfirmByFarmer] = useState(null);
    const [isFromBooking, setIsFromBooking] = useState(null);
    const [shippingId, setShippingId] = useState(null);
    const [orderIsPaid, setOrderIsPaid] = useState(false);
    const [orderFarmerStatusLogs, setOrderFarmerStatusLogs] = useState([]);
    const [order, setOrder] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [orderFarmerStatus, setOrderFarmerStatus] = useState(null);

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const reduxOrderFarmer = useSelector((state) => state.orderFarmer);

    const loadOrderFarmer = () => {
        dispatch(
            orderFarmerShow({
                order_farmer_id: params.order_farmer_id
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                let order_farmer = response.payload.data.order_farmer;
                let order_details = response.payload.data.order_details;
                setCompany(response.payload.data.order_farmer.farmer.companies[0]);
                setShowConfirmQtyBtn(order_farmer.order.is_confirm_qty_by_farmer);
                setIsConfirmByFarmer(order_farmer.order.is_confirm_qty_by_farmer);
                setIsConfirmByBuyer(order_farmer.order.is_confirm_qty_by_buyer);
                setActualQuantity(order_details.data[0].quantity);
                setSelectedDelivery((order_farmer.shipping_id) ? 'delivery_courier' : 'self_delivery');
                setIsFromBooking(order_farmer.order.is_from_booking);
                setShippingId(order_farmer.shipping_id);
                setOrderIsPaid((order_farmer.order.status === $STATUS_ORDER_PAID) ? true : false);
                setOrderFarmerStatus(order_farmer.status);
                setOrderFarmerStatusLogs((order_farmer.order_farmer_status_logs) ? order_farmer.order_farmer_status_logs.data : []);
                setPaymentType(order_farmer.order.payment_method);
                setOrder(order_farmer.order);
            }
        });
    }

    const startDelivery = () => {
        navigate(`/order-farmers/${params.order_farmer_id}/delivery`);
    }

    const inDelivery = () => {
        if (window.confirm("Are you sure want to update to in delivery?")) {
            dispatch(
                orderFarmerInDelivery({
                    order_farmer_id: params.order_farmer_id
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    let order_farmer = response.payload.data.order_farmer;
                    setOrderFarmerStatus(order_farmer.status);
                    setOrderFarmerStatusLogs((order_farmer.order_farmer_status_logs) ? order_farmer.order_farmer_status_logs.data : []);
                }
            });
        }
    }

    const delivered = () => {
        if (window.confirm("Are you sure want to update to delivered?")) {
            dispatch(
                orderFarmerDelivered({
                    order_farmer_id: params.order_farmer_id
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                    let order_farmer = response.payload.data.order_farmer;
                    setOrderFarmerStatus(order_farmer.status);
                    setOrderFarmerStatusLogs((order_farmer.order_farmer_status_logs) ? order_farmer.order_farmer_status_logs.data : []);
                }
            });
        }
    }

    const doneDelivery = () => {
        dispatch(
            orderFarmerDeliveryDone({
                order_farmer_id: params.order_farmer_id
            })
        ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                snackbarCtx.displayMsg(response.payload.message, "success")

                setDone(true);

                navigate(`/order-farmers`)

            }
        });
    }

    const loadBookingPlot = () => {
        dispatch(
            bookingPlotDetail({
                order_farmer_id: params.order_farmer_id,
            })
        ).then(function (response) {

            if (response.error && response.error.message == "Rejected") {
            } else {
                let bookingPlot = response.payload.data.booking_plot;
                if (bookingPlot) {
                    setBookingPlot(bookingPlot);
                    let tempPhoto = [];
                    tempPhoto.push({ src: bookingPlot.plot.farm.photo_default, url: bookingPlot.plot.farm.photo_default });
                    setPhotos(tempPhoto);
                }
            }
        });
    };

    const handleClickPrintAwb = () => {
        setIsLoadingConfirmQuantity(true);
        dispatch(
            printAwb({
                order_farmer_id: params.order_farmer_id
            })
        ).then(function (response) {
            setIsLoadingConfirmQuantity(false);
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure");
            } else {
                let result = response.payload.data;
                window.open(result.url, '_blank', 'noreferrer');
            }
        });
    }


    const handleClickConfirmOrderQuantity = () => {
        setIsLoadingConfirmQuantity(true);
        dispatch(
            confirmOrderQuantity({
                order_farmer_id: params.order_farmer_id,
                quantity: actualQuantity,
                is_confirm_qty_by_farmer: 1,
            })
        ).then(function (response) {
            setIsLoadingConfirmQuantity(false);
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                popupCloseModalConfirm();
                setShowConfirmQtyBtn(1);
                setIsConfirmByFarmer(1);
                snackbarCtx.displayMsg(response.payload.message, "success")
            }
        });
    }

    const handleClickCancelOrder = () => {
        setIsLoadingConfirmQuantity(true);
        dispatch(
            cancelOrder({
                order_farmer_id: params.order_farmer_id,
            })
        ).then(function (response) {
            setIsLoadingConfirmQuantity(false);
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                popupCloseModalConfirm();
                snackbarCtx.displayMsg(response.payload.message, "success")
                navigate('/order-farmers');
            }
        });
    }

    const handleCloseConfirm = () => {
        setModalConfirm(false);
    }

    const denyOrder = () => {
        if (window.confirm("Are you sure want to deny this order?")) {
            dispatch(orderReject({
                id: reduxOrderFarmer?.data?.data?.order_farmer?.order?.id,
            })).then(function (response) {
                if (response.error && response.error.message == "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure");
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    loadOrderFarmer();
                }
            });
        }
    }

    const acceptOrder = () => {
        if (window.confirm("Are you sure want to accept this order?")) {
            dispatch(orderApprove({
                id: reduxOrderFarmer?.data?.data?.order_farmer?.order?.id,
            })).then(function (response) {
                if (response.error && response.error.message == "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure");
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    loadOrderFarmer();
                }
            });
        }
    }

    const confirmPaymentProofHandler = () => {
        if (window.confirm("Are you sure want to confirm payment proof from this order?")) {
            dispatch(confirmOfflinePayment({
                order_farmer_id: params.order_farmer_id,
            })).then(function (response) {
                if (response.error && response.error.message == "Rejected") {
                } else {
                    let order_farmer = response.payload.data.order_farmer;
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    setOrderIsPaid((order_farmer.order.status === $STATUS_ORDER_PAID) ? true : false);
                    setPaymentType(order_farmer.order.payment_method);
                    setOrder(order_farmer.order);
                }
            });
        }
    }

    useEffect(() => {
        loadOrderFarmer();
        loadBookingPlot();
    }, []);

    const $STATUS_PENDING = 0;
    const $STATUS_DELIVERY_START = 1;
    const $STATUS_DELIVERY_CUSTOMER_ACCEPTED = 2;
    const $STATUS_DELIVERY_DONE = 3;
    const $STATUS_NEED_CONFIRM_QUANTITY = -1;
    const $STATUS_ORDER_CREATED = 9;
    const $STATUS_ORDER_CANCELLED = 5;
    const $STATUS_IN_DELIVERY = 6;
    const $STATUS_DELIVERED = 7;
    const $STATUS_PRINT_AWB = 8;
    const $STATUS_NORMAL_ORDER_DELIVERY_PENDING = 10;
    const $STATUS_ORDER_PAID = 3;

    return (
        <AccountLayout>
            <div data-testid="order-details" className="flex flex-col items-start gap-6 p-4 mb-5">
                <h1 className="text-2xl font-bold" style={{ color: "#395B50" }}>Order #{(reduxOrderFarmer.order_farmer) ? (reduxOrderFarmer.order_farmer.order) ? reduxOrderFarmer.order_farmer.order.order_number : reduxOrderFarmer.order_farmer.id : reduxOrderFarmer.order_farmer.id}</h1>
                <div className="w-full max-w bg-white flex flex-col shadow rounded-lg p-4 space-y-4">
                    <Link to={routesPaths.ORDER_FARMERS} className="font-medium" style={{ color: "#395B50" }}>
                        {"< Go back to the order list"}
                    </Link>
                    <section className="relative flex flex-col justify-center items-center">
                        <img
                            src="/assets/images/QRIcon.svg"
                            alt="QR icon"
                            className="absolute  bg-white border-[.1rem] border-black rounded-lg shadow -mt-5"
                        />
                        {/* Info */}
                        <div className="flex max-w-[100rem] w-full">


                            {/* Farmer Information bg-buttonText  text-white */}
                            {/*<div className="  w-full h-[12rem] flex flex-col p-4 gap-4 rounded-lg shadow">*/}
                            <div className={[(reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_START || reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_CUSTOMER_ACCEPTED || reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_DONE) ? "bg-buttonText text-white w-full flex flex-col p-4 gap-2 rounded-l-lg shadow tablet:h-fit desktop:h-[12rem]" : "bg-formSuperDark text-white w-full flex flex-col p-4 gap-2 rounded-l-lg shadow tablet:h-fit desktop:h-[12rem]"]}>
                                <label className="text-3xl">Farmer</label>
                                <label className="text-2xl">{reduxOrderFarmer.order_farmer.farmer && reduxOrderFarmer.order_farmer.farmer.name}</label>

                                {company && (<label className="text-2md">{reduxOrderFarmer?.order_farmer?.shipping_address && reduxOrderFarmer?.order_farmer?.shipping_address?.address + " " + reduxOrderFarmer?.order_farmer?.shipping_address?.postcode + " " + reduxOrderFarmer?.order_farmer?.shipping_address?.city + ", " + reduxOrderFarmer?.order_farmer?.shipping_address?.state}</label>)}
                            </div>

                            {/* Customer Information */}
                            <div className={[(reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_CUSTOMER_ACCEPTED || reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_DONE) ? "bg-formSemiDark text-white w-full flex flex-col p-4 gap-2 rounded-r-lg shadow tablet:h-fit desktop:h-[12rem]" : "bg-formExtraDark text-white w-full flex flex-col p-4 gap-2 rounded-r-lg shadow tablet:h-fit desktop:h-[12rem]"]}>

                                <label className="text-3xl">Buyer</label>
                                <label className="text-2xl">{reduxOrderFarmer.order_farmer.order && reduxOrderFarmer.order_farmer.order.customer.name}</label>
                                <label className="text-2md">{reduxOrderFarmer.order_farmer.order && reduxOrderFarmer.order_farmer.order.delivery_address_street + " " + reduxOrderFarmer.order_farmer.order.delivery_address_zipcode + " " + reduxOrderFarmer.order_farmer.order.delivery_address_city + ", " + reduxOrderFarmer.order_farmer.order.delivery_address_state}</label>
                            </div>
                        </div>



                        {/* Status */}
                        <div className="flex max-w-[100rem] w-full">
                            {/* Farmer Status */}
                            <div className="w-full h-[12rem] flex flex-col p-4 gap-4 items-center mt-4">
                                <label className="text-3xl text-center">Status</label>
                                <img
                                    src={
                                        reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_DONE ? "/assets/images/OrderCheck.svg" : "/assets/images/OrderClock.svg"
                                    }
                                    alt="status icon"
                                    className="w-[4rem] h-[4rem]"
                                />
                                <label className="text-lg text-gray-600">
                                    {
                                        (reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_START || reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_CUSTOMER_ACCEPTED || reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_DONE)
                                            // ? `Started: ${moment(info.farmer_date).format("h:mm, MM/DD/YYYY")}`
                                            ? `Started: ${reduxOrderFarmer.order_farmer.farmer_delivery_start_datetime}`
                                            : reduxOrderFarmer.order_farmer.status === $STATUS_ORDER_CANCELLED ? "Order cancelled" : "To be confirm"}
                                </label>
                            </div>
                            {/* Customer Status */}
                            <div className="w-full h-fit flex flex-col p-4 gap-4 items-center mt-4">
                                <label className="text-3xl text-center">Status</label>
                                <img
                                    src={
                                        reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_DONE
                                            ? "/assets/images/OrderCheck.svg"
                                            : "/assets/images/OrderClock.svg"
                                    }
                                    alt="status icon"
                                    className="w-[4rem] h-[4rem]"
                                />
                                <label className="text-lg text-gray-600">
                                    {
                                        reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_CUSTOMER_ACCEPTED || reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_DONE
                                            ? `Delivered: ${reduxOrderFarmer.order_farmer.customer_delivery_accept_datetime}`
                                            : reduxOrderFarmer.order_farmer.status === $STATUS_ORDER_CANCELLED ? "Order cancelled" : "To be accepted"}
                                </label>
                            </div>
                        </div>
                    </section>
                    <Row>
                        <Col md={6}>
                            <h1 className="text-2xl">Product List</h1>
                            <ol>
                                {reduxOrderFarmer.order_details && reduxOrderFarmer.order_details.data.map((orderDetail, index) => {
                                    if (orderDetail.product != null) {
                                        return (<li className={"mt-2 gap-y-2"} key={index}>
                                            <div className="flex flex-row gap-x-3">
                                                <img
                                                    src={orderDetail.product.photo_default}
                                                    alt=""
                                                    className="  orderLarge:inline min-w-[5rem] max-w-[5rem] min-h-[5rem] max-h-[5rem] object-cover aspect-square rounded-full"
                                                />
                                                <div className="flex flex-col">
                                                    <span><b>Name:</b> {orderDetail.product.name}</span>  <span><b>Price:</b> {orderDetail.price_unit} {orderDetail.price}</span> <span><b>Quantity:</b> {orderDetail.quantity}{" "}{orderDetail.grade.quantity_unit}</span>
                                                </div>
                                            </div>
                                        </li>)
                                    }
                                })}
                            </ol>
                        </Col>
                        <Col md={6}>
                            {orderFarmerStatusLogs.length > 0 && (
                                <>
                                    <h1 className="text-2xl">Tracking Delivery</h1>
                                    <div className="container mt-2">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12">
                                                <div id="tracking-pre"></div>
                                                <div id="tracking">
                                                    <div className="tracking-list">
                                                        {orderFarmerStatusLogs.length > 0 && orderFarmerStatusLogs.map((log, index) => {
                                                            return (
                                                                <>
                                                                    {(log.status != $STATUS_DELIVERY_DONE) &&
                                                                        <div className="tracking-item">
                                                                            <div className="tracking-icon status-intransit">
                                                                                <svg className="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                                                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                                                </svg>
                                                                            </div>
                                                                            <div className="tracking-date ml-4">
                                                                                {log.status === $STATUS_DELIVERY_START && <img src="/assets/images/delivery/start-delivery.png" className="img-responsive" alt="delivery start" />}
                                                                                {log.status === $STATUS_PRINT_AWB && <img src="/assets/images/delivery/waiting-for-pickup.png" className="img-responsive" alt="waiting for pickup" />}
                                                                                {log.status === $STATUS_IN_DELIVERY && <img src="/assets/images/delivery/in-delivery.png" className="img-responsive" alt="in delivery" />}
                                                                                {log.status === $STATUS_DELIVERED && <img src="/assets/images/delivery/delivered.png" className="img-responsive" alt="delivered" />}
                                                                                {log.status === $STATUS_DELIVERY_CUSTOMER_ACCEPTED && <img src="/assets/images/delivery/delivery-accepted.png" className="img-responsive" alt="delivery accepted" />}
                                                                                {log.status === $STATUS_DELIVERY_DONE && <img src="/assets/images/delivery/delivery-done.png" className="img-responsive" alt="delivery done" />}
                                                                            </div>
                                                                            <div className="tracking-content">{log.status_text.charAt(0).toUpperCase() + log.status_text.slice(1)}<span> {log.created_at_formatted}</span></div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row hidden={(order) ? (order.status == 4) ? false : true : true}>
                        <Col md={4}></Col>
                        <Col md={4}>
                            <button type="button" className="btn-green">
                                <a href={order?.payment_receipt} target="_blank" download>View Payment Proof</a>
                            </button>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row hidden={(order) ? (order.status == 4) ? false : true : true}>
                        <Col md={4}></Col>
                        <Col md={4}>
                            <button type="button" className="btn-green" onClick={confirmPaymentProofHandler}>
                                Confirm Payment Proof
                            </button>
                        </Col>
                        <Col md={4}></Col>
                    </Row>

                    {orderIsPaid === true && isFromBooking === 1 && reduxOrderFarmer.order_farmer.status === $STATUS_PENDING && isConfirmByFarmer === 1 && isConfirmByBuyer === 1 && <button className="saveButton self-center w-fit" onClick={startDelivery}>Start Delivery</button>}

                    {isFromBooking === 0 && reduxOrderFarmer.order_farmer.status === $STATUS_NORMAL_ORDER_DELIVERY_PENDING && <button className="saveButton self-center w-fit" onClick={startDelivery}>Start Delivery</button>}
                    {/* show button in delivery for self delivery */}
                    {orderFarmerStatus === $STATUS_DELIVERY_START && selectedDelivery === 'self_delivery' && <button className="saveButton self-center w-fit" onClick={inDelivery}>In Delivery</button>}
                    {/* show button delivered for self delivery */}
                    {orderFarmerStatus === $STATUS_IN_DELIVERY && selectedDelivery === 'self_delivery' && <button className="saveButton self-center w-fit" onClick={delivered}>Delivered</button>}

                    {reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_CUSTOMER_ACCEPTED && <button className="saveButton self-center w-fit" onClick={doneDelivery}> Done </button>}

                    {isFromBooking === 1 && reduxOrderFarmer.order_farmer.status === $STATUS_NEED_CONFIRM_QUANTITY && showConfirmQtyButton === 0 && <button className="saveButton self-center w-fit" onClick={popupShowModalConfirmQuantity}>Confirm Order Quantity</button>}
                    {reduxOrderFarmer.order_farmer.status === $STATUS_ORDER_CREATED && <div className="flex self-center">
                        <button type="button" className="btn-red mr-6 w-[150px] " onClick={(event) => {
                            event.preventDefault();
                            denyOrder();
                        }}>
                            Deny
                        </button>
                        <button type="button" className="btn-green ml-6 w-[150px] " onClick={(event) => {
                            event.preventDefault();
                            acceptOrder();
                        }}>
                            Accept
                        </button>
                    </div>}
                    <div className={"text-center"}>
                        <div className={"text-red"}>
                            {/* {reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_START && "Waiting for buyer to accept."} */}
                            {reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_START && shippingId && (
                                <Row>
                                    <Col md={5}></Col>
                                    <Col md={2}>
                                        <button type="button" className="btn-green" onClick={handleClickPrintAwb}>{(isLoadingConfirmQuantity) ? 'Print AWB' : 'Print AWB'}</button>
                                    </Col>
                                    <Col md={5}></Col>
                                </Row>
                            )}
                            {orderIsPaid === false && isFromBooking === 1 && reduxOrderFarmer.order_farmer.status != $STATUS_DELIVERY_START && reduxOrderFarmer.order_farmer.status != $STATUS_DELIVERY_DONE && isConfirmByFarmer === 1 && showConfirmQtyButton === 1 && paymentType === null && "Awaiting confirmation and full payment from buyer"}
                            {/* {isFromBooking === 0 && reduxOrderFarmer.order_farmer.status != $STATUS_DELIVERY_START && reduxOrderFarmer.order_farmer.status != $STATUS_DELIVERY_DONE && "Awaiting confirmation and full payment from buyer"} */}
                        </div>
                        <div className="text-dark-green">{reduxOrderFarmer.order_farmer.status === $STATUS_DELIVERY_DONE && "Order Completed"}</div>

                        {reduxOrderFarmer.updating && "Processing, please wait.."}
                    </div>
                </div>
                <Modal className="modal-confirmqty" show={modalConfirmQuantity} onHide={popupCloseModalConfirmQuantity}>
                    <Modal.Body>
                        <Row className="mt-4 mr-4 ml-4">
                            <Col md={8}>
                                <NewPhotoSlider name={""} address={""} photos={photos} reloadData={loadBookingPlot} disableEdit={true} />
                                <Row className="mt-2">
                                    <Col md={6}>
                                        <label className="text-gray-sm">Harvesting Start Date:</label>
                                        <br />
                                        <label className="text-green">{(bookingPlot) ? bookingPlot.plot.harvest_start_date_formatted : ''}</label>
                                    </Col>
                                    <Col md={6}>
                                        <label className="text-gray-sm">Harvesting End Date:</label>
                                        <br />
                                        <label className="text-green">{(bookingPlot) ? bookingPlot.plot.harvest_end_date_formatted : ''}</label>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md={6}>
                                        <label className="text-gray-sm">Expected Harvest Quantity:</label>
                                        <br />
                                        <label className="text-green">{(bookingPlot) ? bookingPlot.plot.harvest_expected_quantity + ' ' + bookingPlot.plot.unit : ''}</label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                <div className="title-content mb-2">
                                    {(bookingPlot) ? bookingPlot.plot.name : ''}
                                </div>
                                <Row>
                                    <Col md={8}>
                                        <label className="text-gray-sm">Crop Name:</label>
                                        <br />
                                        <label className="text-green">{(bookingPlot) ? bookingPlot.plot.crop.name : ''}</label>
                                        <br />
                                    </Col>
                                    <Col md={4}>
                                        <label className="text-gray-sm">Grade Name:</label>
                                        <br />
                                        <label className="text-green">{(bookingPlot) ? (bookingPlot.plot.grade) ? bookingPlot.plot.grade.name : '' : ''}</label>
                                        <br />
                                    </Col>
                                </Row>
                                <label className="text-gray-sm">Farm Price:</label>
                                <br />
                                <Row>
                                    <Col xs={8}>
                                        <input
                                            type="text"
                                            className="input-search text-green text-center"
                                            name="farm_price"
                                            value={(bookingPlot) ? 'RM ' + bookingPlot.plot.farm_price : ''}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <input
                                            type="text"
                                            className="input-search text-green text-center"
                                            name="farm_price"
                                            value={(bookingPlot) ? bookingPlot.plot.farm_unit : ''}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <label className="text-gray-sm">Selling Price:</label>
                                <br />
                                <Row>
                                    <Col xs={8}>
                                        <input
                                            type="text"
                                            className="input-search text-green text-center"
                                            name="farm_price"
                                            value={(bookingPlot) ? 'RM ' + bookingPlot.price : ''}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <input
                                            type="text"
                                            className="input-search text-green text-center"
                                            name="farm_price"
                                            value={(bookingPlot) ? bookingPlot.price_unit : ''}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <label className="text-gray-sm">Booking Quantity:</label>
                                <br />
                                <Row>
                                    <Col xs={8}>
                                        <input
                                            type="text"
                                            className="input-search text-green text-center"
                                            name="quantity"
                                            value={(bookingPlot) ? bookingPlot.quantity : ''}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <input
                                            type="text"
                                            className="input-search text-green text-center"
                                            name="quantity_unit"
                                            value={(bookingPlot) ? bookingPlot.quantity_unit : ''}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <label className="text-gray-sm">Actual Available Quantity:</label>
                                <br />
                                <Row className="mt-2">
                                    <Col xs={8}>
                                        <InputGroup>
                                            <InputGroup.Text id="basic-addon1" style={{ background: 'none', borderColor: '#00AF54' }}>
                                                <div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => {
                                                    let tempActualQty = actualQuantity - 1;
                                                    if (tempActualQty < 0) {
                                                        setActualQuantity(0)
                                                    } else {
                                                        setActualQuantity(actualQuantity - 1)
                                                    }
                                                }}>
                                                    -
                                                </div>
                                            </InputGroup.Text>
                                            <Form.Control
                                                className="text-center text-green input-group-plot"
                                                type="number"
                                                min="0"
                                                placeholder=""
                                                value={actualQuantity}
                                                onChange={({ target }) => {
                                                    setActualQuantity(target.value);
                                                }
                                                }
                                            />
                                            <InputGroup.Text id="basic-addon2" style={{ background: 'none', borderColor: '#00AF54' }}>
                                                <div style={{ cursor: "pointer", color: '#00AF54' }} onClick={() => {
                                                    let tempActualQty = actualQuantity + 1;
                                                    setActualQuantity(tempActualQty)
                                                }}>
                                                    +
                                                </div>
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <input
                                            type="text"
                                            className="input-search text-green text-center"
                                            name="quantity_unit"
                                            value={(bookingPlot) ? bookingPlot.quantity_unit : ''}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={12}>
                                        <button type="button" className="btn-border-green" onClick={() => {
                                            popupCloseModalConfirmQuantity();
                                            if (bookingPlot) {
                                                if (actualQuantity != bookingPlot.quantity) {
                                                    popupShowModalConfirm();
                                                } else {
                                                    handleClickConfirmOrderQuantity();
                                                }
                                            }
                                        }}>Confirm</button>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal className="modal-confirmation" show={modalConfirm} onHide={popupCloseModalConfirm}>
                    <Modal.Body>
                        <Row className="mt-4">
                            <Col md={12}>
                                <label className="text-center">
                                    Actual available quantity does not meet the requirement of order quantity.
                                    <br />
                                    Buyer will receive notification to confirm the order.
                                    <br />
                                    Do you want to proceed?
                                </label>
                                <br />
                                <br />
                                <Row>
                                    {/* <Col md={1}></Col> */}
                                    <Col md={4} xs={12}>
                                        <center>
                                            <button type="button" className="btn-red mt-2" onClick={handleClickCancelOrder}>{(isLoadingConfirmQuantity) ? 'Processing, please wait..' : 'Cancel Order'}</button>
                                        </center>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <center>
                                            <button type="button" className="btn-green mt-2" onClick={handleClickConfirmOrderQuantity}>{(isLoadingConfirmQuantity) ? 'Processing, please wait..' : 'Confirm Order'}</button>
                                        </center>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <center>
                                            <button type="button" className="btn text-red-500 border-red-500 mt-2" onClick={handleCloseConfirm}>{'Close '}</button>
                                        </center>
                                    </Col>
                                    {/* <Col md={1}></Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </AccountLayout>
    );
};

export default OrderFarmerTracking;
