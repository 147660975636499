import { useState, useEffect, useContext } from "react";
import { gradeUpdate, gradeDelete } from "../../../features/grade/gradeSlice";
import { useDispatch, useSelector } from "react-redux";
import SnackbarContext from "_helpers/snackbar-context";
import "./GradeEdit.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

// TODO - All select has a delay on setting state hook, so this must be fixed.
const GradeEdit = ({ grade, gradeIndex, reloadVariety }) => {
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(false);
    const [name, setName] = useState(grade.name);
    const [criteria, setCriteria] = useState(grade.criteria);
    const [price, setPrice] = useState(grade.price);
    const [priceUnit, setPriceUnit] = useState(grade.price_unit);
    const [quantity, setQuantity] = useState(grade.quantity);
    const [quantityUnit, setQuantityUnit] = useState(grade.quantity_unit);
    const [wait, setWait] = useState(false);
    const snackbarCtx = useContext(SnackbarContext)
    const { farm } = useSelector((state) => state.farm);

    const updateGrade = field => {
        let t = clearTimeout(wait);
        setWait(t);

        setWait(setTimeout(function () {
            dispatch(gradeUpdate({
                grade_id: grade.id,
                name: name,
                price: price,
                price_unit: priceUnit,
                quantity: quantity,
                quantity_unit: quantityUnit,
                criteria: criteria,
            })).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    let errorMessage = response.payload;

                    if (field === 'price' && (price === 0 && price === "0")) {
                        snackbarCtx.displayMsg('The price must be greater than 0.', "failure")
                    } else if (field === 'price' && price === "") {
                        snackbarCtx.displayMsg('The price field is required.', "failure")
                    } else if (errorMessage !== 'The price must be greater than 0.' && errorMessage !== 'The price field is required.') {
                        snackbarCtx.displayMsg(errorMessage, "failure")
                    }
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success")
                }
            });
        }, 1000))
    }

    const deleteGrade = () => {
        if (window.confirm("Are you sure you want to delete this grade?")) {
            dispatch(
                gradeDelete({
                    grade_id: grade.id,
                })
            ).then(function (response) {
                if (response.error && response.error.message === "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure")
                } else {
                    snackbarCtx.displayMsg(response.payload.message, "success");
                    reloadVariety();
                }
            });
        }
    }
    useEffect(() => {
        // update && updateGrade(null)
        return (() => {
            setUpdate(false)
        })
    }, [update]);

    return (
        <div className="flex flex-col space-y-4 self-center w-full mb-5">

            <div className="flex flex-row space-x-8 relative">
                <label className="title-grade-15-300 w-[60px]">Grade {gradeIndex + 1}</label>
                <FontAwesomeIcon onClick={(event) => {

                    event.preventDefault();
                    deleteGrade();

                }} icon={faTrashCan} className={"cursor-pointer title-grade-15-300 py-0.5 px-2 rounded absolute right-0 text-right"} />
            </div>

            <div className="grid grid-cols-2 gap-4 items-center">
                <div className="...">
                    <div className="row-start-2 row-span-2 mb-1">
                        <div className="grid grid-rows-2">
                            <div>
                                Grade Name
                            </div>
                            <div>
                                <input type="text"
                                    className="input-search"
                                    placeholder="Grade Name" value={name || ''}
                                    onChange={({ target }) => {
                                        setName(target.value)
                                    }}
                                // onKeyUp={updateGrade}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="...">
                    <div className="row-start-2 row-span-2 mb-1">
                        <div className="grid grid-rows-2">
                            <div>
                                Grade Criteria
                            </div>
                            <div>
                                <input className="input-search" type="text" placeholder="Grade Criteria" value={criteria}
                                    onChange={({ target }) => {
                                        setCriteria(target.value)
                                    }}
                                // onKeyUp={() => updateGrade('gradeCriteria')} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="...">
                    <div className="row-start-2 row-span-2 mb-1">
                        <div className="grid grid-rows-2">
                            <div>
                                Price per unit
                            </div>
                            <div>
                                <input type="number" placeholder="Price per unit"
                                    className="input-search"
                                    step="any"
                                    value={price}
                                    onChange={({ target }) => {
                                        setPrice(parseFloat(target.value))
                                    }}
                                // onKeyUp={() => updateGrade('price')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="...">
                    <div className="row-start-2 row-span-2 mb-1">
                        <div className="grid grid-rows-2">
                            <div>
                                Currency
                            </div>
                            <div>
                                <select className={"select-options input-search"} value={priceUnit || ""} onChange={({ target }) => { setPriceUnit(target.value); setUpdate(true); }}>
                                    <option value="">Select Currency</option>
                                    {/*<option value="usd">USD $</option>*/}
                                    {/*<option value="eur">EUR €</option>*/}
                                    {/*<option value="jpy">JPY ¥</option>*/}
                                    {/*<option value="gbp">GBP £</option>*/}
                                    {/*<option value="aud">AUD $</option>*/}
                                    {/*<option value="rmb">CNY 元</option>*/}
                                    {/*<option value="php">PHP ₱</option>*/}
                                    <option value="rm">MYR RM</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="...">
                    <div className="row-start-2 row-span-2 mb-1">
                        <div className="grid grid-rows-2">
                            <div>
                                Available Stock
                            </div>
                            <div>
                                <input type="text" className="input-search" placeholder="Available Stock" value={quantity} onChange={({ target }) => {
                                    setQuantity(target.value)
                                }}
                                // onKeyUp={() => updateGrade('stock')} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="...">
                    <div className="row-start-2 row-span-2 mb-1">
                        <div className="grid grid-rows-2">
                            <div>
                                Unit
                            </div>
                            <div>
                                <select className="input-search" value={quantityUnit || "DEFAULT"} onChange={({ target }) => {
                                    setQuantityUnit(target.value)
                                    setUpdate(true);
                                }}>
                                    <option value="DEFAULT" disabled>  Unit </option>
                                    <option value="kg">Kg</option>
                                    <option value="lbs">Lbs</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="...">
                    <div className="row-start-1 row-span-1 mb-1">
                        <div className="grid grid-rows-1">
                        </div>
                    </div>
                </div>
                <div className="...">
                    <div className="row-start-1 row-span-1 mb-1">
                        <div className="grid grid-rows-1">
                            <button className="saveButton" onClick={updateGrade}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GradeEdit;
