import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useContext, useState } from "react";
import SnackbarContext from "_helpers/snackbar-context";
import { demandApplicationDestroy } from "../../../features/demand-application/demandApplicationSlice";
import PopupConfirmation from "components/ui/popup/PopupConfirmation";

const ChatRoomDetail = ({ demand, loadDemandFarmerApplications }) => {
  const default_product = "/assets/images/products/WheatIcon.svg";
  const default_customer = "/assets/images/BlankPFP.png";
  const snackbarCtx = useContext(SnackbarContext);
  
  const [isDelete, setDelete] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const success = () => {
    dispatch(demandApplicationDestroy({
      demand_application_id: demand.application.id
    })).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")

        loadDemandFarmerApplications()
        setDelete(false);
      }
    })
  }
  return (
    <>
      {isDelete && <PopupConfirmation title="Are you sure want to delete this application?" successButtonTitle="Delete" cancelButtonTitle="Cancel" onSuccess={success} onCancel={setDelete} />}
      <div
        data-testid="application-card"
        className="hover:ring-2 hover:ring-formDark bg-white rounded-lg w-full" style={{ borderBottomWidth: "1px" }}
      >
        <div className=" w-full flex flex-row p-2 gap-4 items-center ">
          <section className="basis-1/4 desktop:flex items-center gap-x-8 ">
            <img
              src={demand.photo_default.src ? demand.photo_default.src : demand.photo}
              // src={demand.photo_default.src}
              alt="photo of product"
              className="  orderLarge:inline min-w-[8rem] max-w-[8rem] min-h-[8rem] max-h-[8rem] object-cover aspect-square rounded-full"
            />
            <div className="hidden lg:flex flex-col">
              <label className="text-2md font-semibold">{`${demand.name}`} </label>
              <label>{(demand.company != null) ? demand.company.name : "-"}</label>
            </div>
          </section>
          <section className="basis-2/4 flex gap-4 items-center ">
            <div className="hidden lg:flex flex-col ml-3">
              <div>
                {demand.last_chat.bodyParse.type == "TAG" && (
                  <>
                    {demand.last_chat.is_read == 0 ? (<>
                      <b>
                        {`(${demand.last_chat.bodyParse.title}) ${demand.last_chat.dataTag.name}`}
                      </b>
                    </>) : (<>
                      {`(${demand.last_chat.bodyParse.title}) ${demand.last_chat.dataTag.name}`}
                    </>)}
                  </>
                )}
                {demand.last_chat.bodyParse.type == "TEXT" && (
                  <>
                    {demand.last_chat.is_read == 0 ? (<>
                      <b>
                        {`${demand.last_chat.bodyParse.msg}`}
                      </b>
                    </>) : (<>
                      {`${demand.last_chat.bodyParse.msg}`}
                    </>)}
                  </>
                )}
              </div>
              <div className="mt-3">
                <>
                  {demand.last_chat.is_read == 0 ? (<>
                    <b>
                      {`${demand.last_chat.dateFormatedStr}`}
                    </b>
                  </>) : (<>
                    {`${demand.last_chat.dateFormatedStr}`}
                  </>)}
                </>
              </div>
            </div>
            <div className="lg:hidden lg:flex flex-col ml-3">
              <div>
                <label className="text-2md font-semibold">{`${demand.name}`} </label>
              </div>
              <div className="mt-3">
                <label>{(demand.company != null) ? demand.company.name : "-"}</label>
              </div>
            </div>
          </section>
          <section className="basis-1/4 flex gap-4 items-center justify-center hidden lg:table">
            <div>
              <span className="relative inline-block">
                <button
                  className="btn-border-green w-[150px] mr-auto ml-auto mb-3 mt-2 flex justify-center"
                  onClick={() => {
                    navigate(`/room-chat/live/${demand.id}`);
                  }}
                >View Message</button>
                {
                  demand.total_msg_unread > 0 ?
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full hidden tablet:inline">{demand.total_msg_unread}</span>
                    : null
                }
              </span>

            </div>
          </section>

        </div>
        <div className="lg:hidden justify-center">
          <div className="text-center">
            <span className="relative inline-block">
              <button
                className="btn-border-green w-[150px] mr-auto ml-auto mb-3 mt-2 flex justify-center"
                onClick={() => {
                  navigate(`/room-chat/live/${demand.id}`);
                }}
              >View Message</button>
              {
                demand.total_msg_unread > 0 ?
                  <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{demand.total_msg_unread}</span>
                  : null
              }
            </span>

          </div>

        </div>
      </div>
    </>
  );
};

export default ChatRoomDetail;
