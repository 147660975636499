import { useState, useEffect } from "react";

import AccountLayout from "pages/AccountLayout";
import ChatRoomDetail from "./ChatRoomDetail";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MessageEmpty from "../../ui/message/empty/MessageEmpty"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getUserIdAdmin, roomChatPage } from "features/room-chat/roomChatSlice";

const ChatRoomList = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const reduxDemand = useSelector((state) => state.demand);
  const { room_chat_count } = useSelector((state) => state.roomChat);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [demandList, setDemandList] = useState({
    data: []
  })
  const [itemsPerPage, setItemsPerPage] = useState(10); // Initial value, you can change this
  const [currentPage, setCurrentPage] = useState(1);
  const [idAdmin, setIdAdmin] = useState(null);
  const loadDemandFarmerApplications = (pageNumber, itemsPerPage) => {
    dispatch(
      roomChatPage({
        limit: itemsPerPage,
        page: pageNumber,
      })
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        // console.log(response.payload.message);
        //
        // let grades = response.payload.data.grades;
        //
        // console.log(" grades ", grades);
        //
        // setGradeList(grades);
        let demands = response.payload.data.user_room_chat;

        setDemandList(demands);
      }
    });
  }

  const loadCheckIdAdmin = () => {
    dispatch(
      getUserIdAdmin()
    ).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        let idAdmin = response.payload.data.id;
        setIdAdmin(idAdmin);
      }
    });
  }

  const handlePageChange = (newPageNumber, newItemsPerPage) => {
    setCurrentPage(newPageNumber);
    setItemsPerPage(newItemsPerPage); // Assuming you have an itemsPerPage state variable

    // Now you can call your data loading function with the updated values
    loadDemandFarmerApplications(newPageNumber, newItemsPerPage);
  };

  const handleChangePage = (event, newPageNumber) => {
    handlePageChange(newPageNumber, itemsPerPage); // Call the common function
  };

  const handleLimitPage = (newItemsPerPage) => {
    setCurrentPage(1); // Reset the current page to 1
    setItemsPerPage(newItemsPerPage); // Update the itemsPerPage state variable
    loadDemandFarmerApplications(1, newItemsPerPage);
  };

  useEffect(() => {
    loadDemandFarmerApplications()
  }, [room_chat_count]);

  useEffect(() => {
    loadCheckIdAdmin();
  }, []);

  // only run if the farmId was updated
  // useEffect(() => {
  //     if(farmId) {
  //         console.log("farm id was changed ", farmId);
  //         setGradeId("")
  //
  //         loadGrades()
  //     }
  // }, [farmId]);

  return (
    <AccountLayout>
      <div data-testid="application-list" className="flex flex-col items-start gap-6 p-4 mb-5">
        <div className="w-full">
          <div className="flex justify-between">
            <div>
              <h1 className="text-2xl font-bold" style={{ color: "#395B50" }}>Message</h1>
            </div>
            {((currentUser != null && idAdmin != null)) && (
              <>
                {(currentUser?.role_name != "super-admin") && (
                  <>
                    <div>
                      <button
                        className="btn-border-green w-[150px] mr-auto ml-auto flex justify-center"
                        onClick={() => {
                          navigate(`/room-chat/live/${idAdmin}`);
                        }}
                      >Chat with admin</button>
                    </div>
                  </>
                )}

              </>
            )}

          </div>
        </div>

        <div className="w-full max-w bg-white flex flex-col items-center shadow rounded-lg">
          <div className="p-4 w-full max-w">
            {/* <div className="hidden laptop:flex flex-row items-center justify-center pb-3" style={{ borderBottomWidth: "1px" }}>
              <div className="basis-1/4 flex items-center justify-center font-medium">Demands</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Details</div>
              <div className="basis-1/4 flex items-center justify-center font-medium">Status</div>
              <div className="basis-1/4 flex items-center justify-center font-medium"></div>
            </div> */}
            {demandList.data && demandList.data.map((demand, index) => (
              <ChatRoomDetail loadDemandFarmerApplications={loadDemandFarmerApplications} demand={demand} key={index} />
            ))}
          </div>

        </div>
        <div className="grid sm:grid-rows-2 md:grid-cols-2 w-full">
          <div className="flex items-center tablet:flex lg:justify-start justify-center w-full">
            <span>
              <select className="select-custom" onChange={(e) => handleLimitPage(e.target.value)}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={1000}>All</option>
              </select>
            </span>
            <span className="ml-2 text-sm">
              <label>entries per page</label>
            </span>
          </div>
          <div className="flex lg:justify-end justify-center">
            <Stack spacing={2} >
              <Pagination count={demandList.pagination?.total_pages} showFirstButton showLastButton onChange={handleChangePage} page={currentPage} />
            </Stack>
          </div>
        </div>
        <MessageEmpty loading={reduxDemand.loading} length={reduxDemand.demands.data.length} message={reduxDemand.data.message} />

      </div>
    </AccountLayout>
  );
};

export default ChatRoomList;
