import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { productsCheckQuantity } from "../../features/order/orderSlice";
import SnackbarContext from "_helpers/snackbar-context";
import md5 from 'md5';
import cartStyle from "./CartSidebar.module.scss";
import clsx from "clsx";

const CartSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [vcode, setVCode] = useState(null);

  const snackbarCtx = useContext(SnackbarContext);
  const params = useParams();
  const dispatch = useDispatch();
  const reduxOrderDetail = useSelector((state) => state.orderDetail);
  const reduxOrder = useSelector((state) => state.order);

  let buttonText = "";
  let path = "";
  let showButton = false;

  if (location.pathname === "/cart/products") {
    path = "/cart/delivery-payment";
    buttonText = "Pay";
    showButton = true;
  } else if (location.pathname === "/cart/delivery-payment") {
    path = "/cart/done";
    buttonText = "Finish the Order";
    showButton = false;
  }

  const validateOrderDetailQuantity = () => {
    dispatch(
      productsCheckQuantity({
        order_id: reduxOrderDetail.order.id
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
        //alert(response.payload);
      } else {
        snackbarCtx.displayMsg(response.payload.message, "success")
        // console.log(response.payload.message);

        navigate(path)
      }
    });
  }

  const payOrder = () => {
    if (window.confirm("Are you sure want to pay full amount for this order?")) {
      document.getElementById("razerForm").submit();
    }
  };

  useEffect(() => {
    setVCode(md5(reduxOrderDetail.order?.amount_total_overall_formatted + process.env.REACT_APP_RAZER_MERCHANT_ID + "ORDER_" + reduxOrderDetail.order?.order_number + process.env.REACT_APP_RAZER_VERIFY_KEY));
  }, []);

  return (
    <div data-testid="cart-sidebar" className="bg-white justify-self-center laptop:justify-self-end w-full desktop:col-span-2 desktop:col-start-7 flex flex-col h-fit p-3 gap-4 rounded-lg shadow">
      <section className="flex justify-between">
        <label className="font-semibold">Product Price:</label>
        <label className="font-semibold">
          {reduxOrderDetail.order?.price_unit} {reduxOrderDetail.order.amount_total_formatted}
          {/*{cart.currency} {cart.product_price.toFixed(2)}*/}
        </label>
      </section>
      {reduxOrderDetail?.order_detail?.product?.delivery_charge !== 0 && <section className="flex justify-between">
        <label className="font-semibold">+ Delivery:</label>
        <label className="font-semibold">
          {reduxOrderDetail.order?.price_unit}  {reduxOrderDetail?.order?.amount_delivery_formatted}

          {/*{cart.currency} {cart.delivery_fee.toFixed(2)}*/}
        </label>
      </section>}
      <hr className="bg-black text-black w-full h-[0.1rem]" />
      <section className="flex justify-between">
        <label className="font-semibold">Total:</label>
        <label className="font-semibold">
          {reduxOrderDetail.order?.price_unit}  {reduxOrderDetail.order?.amount_total_overall_formatted}

          {/*{cart.currency} {(cart.product_price + cart.delivery_fee).toFixed(2)}*/}
        </label>
      </section>

      {showButton &&
        // <button className="saveButton" onClick={() => validateOrderDetailQuantity(path)}>
        <button className={reduxOrderDetail.order_details.data.length > 0 ? "saveButton" : "btn-border-gray"} onClick={() => payOrder()} type="button" disabled={reduxOrderDetail.order_details.data.length === 0}>
          {/*{buttonText}*/}

          {reduxOrder.updating ? "Checking please wait" : buttonText}
        </button>
      }
      <form id="razerForm" action={process.env.REACT_APP_RAZER_PAYMENT_URL} method="POST">
        <input type="hidden" name="merchant_id" value={process.env.REACT_APP_RAZER_MERCHANT_ID} />
        <input type="hidden" name="amount" value={reduxOrderDetail.order?.amount_total_overall_formatted} />
        <input type="hidden" name="orderid" value={'ORDER_' + reduxOrderDetail.order?.order_number} />
        <input type="hidden" name="bill_name" value={reduxOrderDetail.order?.customer?.name} />
        <input type="hidden" name="bill_email" value={reduxOrderDetail.order?.customer?.email} />
        <input type="hidden" name="bill_mobile" value={reduxOrderDetail.order?.customer?.phone_number} />
        <input type="hidden" name="bill_desc" value={'ORDER_' + reduxOrderDetail.order?.order_number} />
        <input type="hidden" name="returnurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-normal-order-return"} />
        <input type="hidden" name="callbackurl" value={process.env.REACT_APP_BACKEND_API + "/razer/webhook/pay-normal-order-callback"} />
        <input type="hidden" name="vcode" value={vcode} />
        <input type="submit" className={clsx(cartStyle.hideVisibility)} />
      </form>
    </div>
  );
};

export default CartSidebar;
