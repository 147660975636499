
import { routesPaths } from "constants/routes.constants";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AccountLayout from "pages/AccountLayout";
import DemandAccept from "../demands/DemandAccept";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { farmShow } from "../../features/farm/farmSlice";
import { plotShow } from "../../features/plot/plotSlice";
import SnackbarContext from "_helpers/snackbar-context";
import PhotoModal from "components/ui/photo/modal/PhotoModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import CustomImageDemands from "components/demands/CustomImageDemands";
import PopupImageSlider from "components/catalogs/PopupImageSlider";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
const PlotShow = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [certificate, setCertificate] = useState("DEFAULT");
    const [accept, setAccept] = useState(false);
    const [isPhotoModalOpen, setPhotoModalOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState();
    const [isShowPopupImage, setIsShowPopupImage] = useState(false);
    const snackbarCtx = useContext(SnackbarContext);

    const acceptHandler = () => {
        setAccept(true);
    };

    const demand_id = 1;

    const dummyFarm = {
        data: {
            name: "",
            fields: 0,
            plots: 0,
            certificates: [{ name: "Food Standard 1" }, { name: "Food Standard 2" }, { name: "Food Standard 3" }],
            photos: [
                {
                    photo: "/assets/images/plots/PlotPhoto1.svg",
                    date: { day: 25, month: 5, year: 2022 },
                },
                {
                    photo: "/assets/images/plots/PlotPhoto2.svg",
                    date: { day: 25, month: 5, year: 2022 },
                },
                {
                    photo: "/assets/images/plots/PlotPhoto3.svg",
                    date: { day: 25, month: 5, year: 2022 },
                },
            ],
        },
    };

    const info = dummyFarm;
    const reduxPlot = useSelector((state) => state.plot);


    const loadPlot = () => {
        dispatch(plotShow({
            plot_id: params.plot_id
        })).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure")
                //alert(response.payload);
            } else {
                //console.log(response.payload.message);
                //snackbarCtx.displayMsg(response.payload.message, "success")
            }
        });
    }

    useEffect(() => {
        loadPlot()
        // if (farmId) {
        //     // console.log("farm id was changed ", farmId);
        //     // setGradeId("");
        //     //
        //     // loadGrades();
        // }
    }, []);

    const closePhotoModal = () => {
        setPhotoModalOpen(false);
    }


    return (
        <AccountLayout>
            {/* {(isShowPopupImage && reduxPlot.plot.photos) && <PopupImageSlider defaultPhoto={reduxPlot.plot.photo_default} photos={reduxPlot.plot.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />} */}
            {(isShowPopupImage && reduxPlot.plot.photos) && <NewPhotoSlider name={""} address={""} photos={reduxPlot.plot.photos} reloadData={loadPlot} />}

            <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6">
                <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                    {" Go Back"}
                </label>
                <div className="title-content mb-3">
                    Plot Details
                </div>
                <div className="flex flex-col rounded-lg shadow bg-card-white-new">
                    <Row>
                        <Col md={6}>
                            {reduxPlot.plot.photos && <NewPhotoSlider name={""} address={""} photos={reduxPlot.plot.photos.data} reloadData={loadPlot} disableEdit={true}/>}
                        </Col>
                        <Col md={1}>
                        </Col>
                        <Col md={5}>
                            <form className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <label>Plot Name:</label>
                                    <input
                                        type="text"
                                        className="input-search"
                                        placeholder="Plot Name"
                                        value={reduxPlot.plot.name}
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label>Crop Name:</label>
                                    <input
                                        type="text"
                                        className="input-search"
                                        placeholder="Crop Name"
                                        value={reduxPlot.plot.crop && reduxPlot.plot.crop.name}
                                        disabled={true}
                                    />
                                </div>

                                <div className="flex flex-col gap-2">
                                    <label>Planting Start Date:</label>
                                    <input
                                        className="input-search"
                                        type="date"
                                        placeholder="Planting Start Date"
                                        value={reduxPlot.plot.planting_start_date}
                                        disabled={true}
                                    />
                                </div>
                                <div className="flex gap-4">
                                    <div className="w-full min-w-[1rem] flex flex-col gap-2">
                                        <label>Harvesting Start Date:</label>
                                        <input
                                            className="input-search"
                                            type="date"
                                            placeholder="Harvesting Start Date"
                                            value={reduxPlot.plot.harvest_start_date}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="w-full min-w-[1rem] flex flex-col gap-2">
                                        <label>Harvesting End Date:</label>
                                        <input
                                            className="input-search"
                                            type="date"
                                            placeholder="Harvesting End Date"
                                            value={reduxPlot.plot.harvest_end_date}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <label>Expected Harvest Quantity:</label>
                                    <div className="flex gap-4">
                                        <input
                                            className="input-search"
                                            type="text"
                                            min="0"
                                            placeholder="Quantity"
                                            value={`${reduxPlot.plot.harvest_expected_quantity} ${reduxPlot.plot.unit}`}
                                            disabled={true}
                                        />

                                    </div>
                                </div>


                            </form>
                        </Col>
                    </Row>
                </div>
            </div>
        </AccountLayout>
        // <AccountLayout>
        //     {accept && <DemandAccept />}
        //     {/* Page Filler */}
        //     <div data-testid="demand-farm-show" className="w-full flex flex-col items-center gap-8">
        //         {/* Flex Column */}
        //         <div className="w-full max-w-[60rem] self-center flex flex-col gap-4">
        //             {/* Navigation */}
        //             <span className={'cursor-pointer'} onClick={() => navigate(-1) }> {"< Go Back "}</span>
        //             {/*<Link to={`${routesPaths.DEMANDS}/${demand_id}/show`}>{"< Go Back To Applicant List"}</Link>*/}
        //             <div className="flex justify-between">
        //                 {/* Title */}
        //                 <label className="text-3xl">
        //                     {/*Plot Show.. {params.plot_id}*/}

        //                     {/*{info.data.name ? info.data.name : "Farm Name"} farm id: {params.farm_id}*/}
        //                 </label>
        //                 {/*<button className="saveButton w-[10rem] h-fit" onClick={acceptHandler}>*/}
        //                     {/*Accept*/}
        //                 {/*</button>*/}
        //             </div>

        //             {/* Widgets */}
        //             <div className="bg-formDark flex flex-col p-4 gap-4 items-center rounded-lg shadow">
        //                 <div className="w-full flex flex-col gap-4 items-center demandFarm:flex-row">





        //                     {/* Farm */}
        //                     <section className="w-full flex justify-between gap-4">


        //                         <div className="flex flex-col gap-4">
        //                             <label className="text-2xl"> {reduxPlot.plot.name} Details</label>


        //                             <div className="flex gap-2 justify-between items-center">
        //                                 <label>Crop Name</label>
        //                                 <label className="ml-2 indentedText text-center w-[20rem]">{reduxPlot.plot.crop && reduxPlot.plot.crop.name}</label>
        //                             </div>

        //                             <div className="flex gap-2 justify-between items-center hidden">
        //                                 <label>Crop Season</label>
        //                                 <label className="ml-2 indentedText text-center w-[20rem]">{reduxPlot.plot.crop && reduxPlot.plot.crop.season}</label>
        //                             </div>

        //                             <div className="flex gap-2 justify-between items-center">
        //                                 <label>Start Planting</label>
        //                                 <label className="ml-2 indentedText text-center w-[20rem]">{reduxPlot.plot.planting_start_date}</label>
        //                             </div>


        //                             {/*<div className="flex gap-2 justify-between items-center">*/}
        //                                 {/*<label>Number of Plots</label>*/}
        //                                 {/*<label className="indentedText text-center w-[5rem]">{info.data.plots}</label>*/}
        //                             {/*</div>*/}
        //                         </div>
        //                         {/*<img src="/assets/images/FarmIconLarge.svg" alt="farm icon" className="self-center w-[9rem] h-[9rem]" />*/}
        //                     </section>

        //                     {/* Farm */}
        //                     <section className="w-full flex justify-between gap-4">

        //                         <div className="flex flex-col gap-4">
        //                             {/*<label className="text-2xl"> {reduxPlot.plot.name} Details</label>*/}

        //                             <div className="flex gap-2 justify-between items-center">
        //                                 <label>Start Of Harvest</label>
        //                                 <label className="ml-2 indentedText text-center w-[20rem]">{reduxPlot.plot.harvest_start_date}</label>
        //                             </div>

        //                             <div className="flex gap-2 justify-between items-center">
        //                                 <label>End Of Harvest</label>
        //                                 <label className="ml-2 indentedText text-center w-[20rem]">{reduxPlot.plot.harvest_end_date}</label>
        //                             </div>

        //                             <div className="flex gap-2 justify-between items-center">
        //                                 <label>Capacity:</label>
        //                                 <label className="ml-2 indentedText text-center w-[20rem]">{reduxPlot.plot.harvest_expected_quantity} {reduxPlot.plot.unit}</label>
        //                             </div>
        //                         </div>
        //                         {/*<img src="/assets/images/FarmIconLarge.svg" alt="farm icon" className="self-center w-[9rem] h-[9rem]" />*/}
        //                     </section>




        //                     {/*/!* Certification *!/*/}
        //                     {/*<section className="w-full flex justify-between gap-4">*/}



        //                         {/*<div className="w-full max-w-[19rem] flex flex-col gap-4">*/}
        //                             {/*<label className="text-2xl">Certification</label>*/}
        //                             {/*<div className="flex gap-2 justify-between items-center">*/}
        //                                 {/*<label>Number of Certificates</label>*/}
        //                                 {/*<label className="indentedText text-center w-[5rem]">{info.data.certificates.length}</label>*/}
        //                             {/*</div>*/}
        //                             {/*<select*/}
        //                                 {/*className="max-w-[19rem]"*/}
        //                                 {/*value={certificate}*/}
        //                                 {/*onChange={({ target }) => setCertificate(target.value)}*/}
        //                             {/*>*/}
        //                                 {/*<option value="DEFAULT" disabled>*/}
        //                                     {/*Choose Certificate*/}
        //                                 {/*</option>*/}
        //                                 {/*{info.data.certificates.map((certificate, index) => (*/}
        //                                     {/*<option value={certificate.name} key={index}>*/}
        //                                         {/*{certificate.name}*/}
        //                                     {/*</option>*/}
        //                                 {/*))}*/}
        //                             {/*</select>*/}
        //                         {/*</div>*/}




        //                         {/*<div className="w-[10rem] flex flex-col justify-around items-center">*/}
        //                             {/*<label className="flex justify-center items-center">*/}
        //                                 {/*<p className="cursor-pointer w-[5rem] text-sm">View Certificate</p>*/}
        //                                 {/*<img src="/assets/images/ViewIcon.svg" alt="download icon" className="w-[2rem] h-[2rem]" />*/}
        //                             {/*</label>*/}
        //                             {/*<label className="flex justify-center items-center">*/}
        //                                 {/*<p className="cursor-pointer w-[5rem] text-sm">Download Certificate</p>*/}
        //                                 {/*<img src="/assets/images/DownloadIcon.svg" alt="download icon" className="w-[2rem] h-[2rem]" />*/}
        //                             {/*</label>*/}
        //                         {/*</div>*/}
        //                     {/*</section>*/}



        //                 </div>

        //                 {/* Photos */}
        //                 <section className="bg-white w-full rounded-lg grid p-4 gap-4 justify-items-center grid-cols-1 productPlotWidgetSmall:grid-cols-2 productPlotWidgetLarge:grid-cols-3">
        //                     {reduxPlot.plot.photos && reduxPlot.plot.photos.data.map((photo, index) => (
        //                         <section className="bg-formLight flex flex-col w-full h-full rounded-lg shadow" key={index}>
        //                             <img src={photo.url} alt="plot" className="w-full h-[12rem] object-cover rounded-t-lg cursor-pointer"
        //                              onClick = {(e) => {
        //                                 setPhotoIndex(index);
        //                                 setPhotoModalOpen(true)
        //                                 }
        //                              }/>

        //                             <div className="flex flex-col p-2 gap-2">
        //                                 <label>
        //                                     {/*Photo taken on: {photo.date.day} / {photo.date.month} / {photo.date.year}*/}
        //                                     Photo taken on:  {photo.date_client_format}
        //                                 </label>
        //                                 <p>
        //                                     {photo.description}
        //                                 </p>
        //                             </div>                             
        //                         </section>
        //                     ))}
        //                       {isPhotoModalOpen && <PhotoModal closePhotoModal = {closePhotoModal} photoIndex = {photoIndex} imageSet = {reduxPlot.plot.photos }/>} 
        //                 </section>


        //                 {/*/!* Photos *!/*/}
        //                 {/*<section className="bg-white w-full rounded-lg grid p-4 gap-4 justify-items-center grid-cols-1 productPlotWidgetSmall:grid-cols-2 productPlotWidgetLarge:grid-cols-3">*/}
        //                     {/*{reduxPlot.plot.map((photo, index) => (*/}
        //                         {/*<section className="bg-formLight flex flex-col w-full h-full rounded-lg shadow" key={index}>*/}
        //                             {/*<img src={photo.photo} alt="plot photo" className="w-full h-[12rem] object-cover rounded-t-lg" />*/}
        //                             {/*<div className="flex flex-col p-2 gap-2">*/}
        //                                 {/*<label>*/}
        //                                     {/*Photo taken on: {photo.date.day} / {photo.date.month} / {photo.date.year}*/}
        //                                 {/*</label>*/}
        //                             {/*</div>*/}
        //                         {/*</section>*/}
        //                     {/*))}*/}
        //                 {/*</section>*/}
        //             </div>
        //         </div>
        //     </div>
        // </AccountLayout>
    );
};

export default PlotShow;
