import { useDispatch } from "react-redux";
import { login } from "../../features/auth/loginSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/logoutSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { useContext } from "react";
import { routesPaths } from "constants/routes.constants";

const ProfileMenuSuperAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbarCtx = useContext(SnackbarContext)
  
    const submitHandler = (event) => {
        dispatch(logout()).then(function (response) {
          if (response.error && response.error.message === "Rejected") {
            snackbarCtx.displayMsg(response.payload, "failure")
            //alert(response.payload);
          } else {
            // alert(response.payload.message);
            window.location = "/login";
            // navigate("/login");
          }
        });
    
        // navigate("/account");
    
        event.preventDefault();
      };
    return (
        <>
      <div
          
          className="unborderedButton"
          style={!window.location.pathname.includes(routesPaths.USER_DETAIL) ? {backgroundColor:"white", color:"black"} : {backgroundColor:"#00AF54" , color:"white"}}
          onClick={() => {
              navigate(routesPaths.USER_DETAIL_SHOW);
          }}
      >
          Manage User
      </div>
      <div
            
            className="unborderedButton"
            style={!window.location.pathname.includes(routesPaths.COMPANY_DETAIL) ? {backgroundColor:"white", color:"black"} : {backgroundColor:"#00AF54" , color:"white"}}
                onClick={() => {
                    navigate(routesPaths.COMPANY_DETAIL_SHOW);
                }}
        >
            Manage Company
      </div>
      <div
              
          className="unborderedButton"
          style={!window.location.pathname.includes(routesPaths.PACKAGE_DETAIL) ? {backgroundColor:"white", color:"black"} : {backgroundColor:"#00AF54" , color:"white"}}
          onClick={() => {
            navigate(routesPaths.PACKAGE_DETAIL_SHOW);
        }}
      >
          Manage Package
      </div>
      <div
              
          className="unborderedButton"
          style={!window.location.pathname.includes(routesPaths.SETTING_PAGE) ? {backgroundColor:"white", color:"black"} : {backgroundColor:"#00AF54" , color:"white"}}
          onClick={() => {
            navigate(routesPaths.SETTING_PAGE);
        }}
      >
          Setting
      </div>
        </>
    );
};

export default ProfileMenuSuperAdmin;
