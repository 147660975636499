import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import billingService from './billingService';

export const downloadBill = createAsyncThunk(
    "package/download-invoice/:id",
    async (args, thunkAPI) => {
        try {
            // alert("test");
            return await billingService.downloadBill(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)


const initialState = {
    data: {},
    billing: {},
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const billingSlice = createSlice({
    name: 'advanceBooking',
    initialState,
    reducers: {
    },
    extraReducers : {
        [downloadBill.pending]:(state, {payload}) => {
            // state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [downloadBill.fulfilled]:(state, {payload}) => {
            // state.data = payload;
            state.updating=false;
            state.loading=false;
            state.isSuccess = true;
        },
        [downloadBill.rejected]:(state, {payload}) => {
            // state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export default billingSlice;