import PopupLayoutConfirmation from "pages/PopupLayoutConfirmation";
import { useNavigate } from "react-router-dom";

const PopupPay = (props) => {
  const navigate = useNavigate();



  return (
    <PopupLayoutConfirmation>
      <label className="text-xl">{props.title}</label>
      <div className="w-full flex gap-4">
        <button className="btn-border-green w-[700px]" onClick={() => {
          props.onCancel(false);
        }}>
          {props.cancelButtonTitle}
        </button>
        <button className="saveButton w-[700px]"
          onClick={() => {
            props.onSuccess();
          }}
        >
          {props.successButtonTitle}
        </button>
      </div>
    </PopupLayoutConfirmation>
  );
};

export default PopupPay;
