import { routesPaths } from "constants/routes.constants";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ProductAdded from "./ProductAdded";
import { catalogShow } from "../../features/catalog/catalogSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { gradeShow } from "../../features/grade/gradeSlice";
import { orderDetailStore } from "../../features/order-detail/orderDetailSlice";
import { getCurrentUserInfo } from "../../features/auth/authSlice";
import SnackbarContext from "_helpers/snackbar-context";
import { Col, Container, Row } from "react-bootstrap";
import "./Catalog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCircleXmark, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import PopupImageSlider from "./PopupImageSlider";
import NewPhotoSlider from "components/files/NewSlider/NewPhotoSlider";
import Footer from "components/footer/footer";
import { updateActiveRoomChat, updateInActiveRoomChat } from "features/room-chat/roomChatSlice";
import ChatRoomFloating from "components/chat-room/ChatRoomFloating";

const CatalogShow = () => {
    const navigate = useNavigate();
    const [productAdded, setProductAdded] = useState(false);
    const snackbarCtx = useContext(SnackbarContext);
    const currentLocation = useLocation();

    const farmDetailsHandler = (event) => {
        event.preventDefault();
        navigate(`/farms/${reduxCatalog.farm.id}`)
    };

    const [quantity, setQuantity] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [grade, setGrade] = useState(0);
    const params = useParams();
    const dispatch = useDispatch();
    const reduxCatalog = useSelector((state) => state.catalog);
    const reduxGrade = useSelector((state) => state.grade);
    const { currentCompany } = useSelector((state) => state.auth);
    const reduxOrderDetail = useSelector((state) => state.orderDetail);
    const { isLoggedIn, currentUser } = useSelector((state) => state.auth);
    const [isShowPopupImage, setIsShowPopupImage] = useState(false);
    const [showFLoatChat, setShowFLoatChat] = useState(false);

    const [sellingPrice, setSellingPrice] = useState(0);

    const loadCatalog = () => {
        dispatch(
            catalogShow({
                catalog_id: params.catalog_id
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                alert(response.payload);
            }
        });
    }

    /**
     *
     * Todo: This could be changed later later for the backend request, in order to be reusable.
     *
     *
     */
    const setGradeSelected = (event) => {
        let activePackage = currentCompany?.company_packages?.data.find((companyPackage) =>
            companyPackage.status === 1
        )

        setGrade(parseInt(event.target.value));

        dispatch(
            gradeShow({
                grade_id: parseInt(event.target.value)
            })
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                snackbarCtx.displayMsg(response.payload, "failure");
            } else {
                if (response?.payload?.data?.grade?.selling_price === null) {
                    setSellingPrice((parseFloat(activePackage?.package?.service_charge) / 100 * response?.payload?.data?.grade?.price) + response?.payload?.data?.grade?.price)
                }

                if (quantity > 0) {
                    setTotalAmount(quantity * (reduxGrade.grade.selling_price || ((parseFloat(activePackage?.package?.service_charge) / 100 * response?.payload?.data?.grade?.price) + response?.payload?.data?.grade?.price)));
                }
            }
        });

        event.preventDefault();
    }

    const addToCartHandler = (event) => {
        if (!isLoggedIn) {
            navigate('/login', { state: { from: currentLocation } });
            return;
        }

        // todo - trigger backend adding to cart.
        dispatch(
            orderDetailStore(
                {
                    product_id: reduxCatalog.product.id,
                    quantity: quantity,
                    price: reduxGrade.grade.selling_price || sellingPrice,
                    grade_id: reduxGrade.grade.id,
                    amount_delivery: reduxCatalog.product.delivery_charge,
                    unit: reduxGrade.grade.quantity_unit
                }
            )
        ).then(function (response) {
            if (response.error && response.error.message == "Rejected") {
                //alert(response.payload);
                snackbarCtx.displayMsg(response.payload, "failure")
            } else {
                setProductAdded(true);
            }
        });

        event.preventDefault();

    };

    function incrementCount() {
        if (((quantity + 1) >= 0) && ((quantity + 1) <= reduxCatalog.product.quantity_number)) {
            setTotalAmount((quantity + 1) * (reduxGrade.grade.selling_price || sellingPrice));
            setQuantity(quantity + 1);
        }
    }
    function decrementCount() {
        if (((quantity - 1) >= 0) && ((quantity - 1) <= reduxCatalog.product.quantity_number)) {
            setTotalAmount((quantity - 1) * (reduxGrade.grade.selling_price || sellingPrice));
            setQuantity(quantity - 1);
        }
    }

    useEffect(() => {
        let activePackage = currentCompany?.company_packages?.data.find((companyPackage) =>
            companyPackage.status === 1
        )

        loadCatalog();
        dispatch(getCurrentUserInfo());

        setGrade(reduxCatalog?.product?.crop?.grades?.data[0]?.id)

        if (reduxCatalog?.product?.crop?.grades?.data.length > 0) {
            dispatch(
                gradeShow({
                    grade_id: parseInt(reduxCatalog?.product?.crop?.grades?.data[0]?.id)
                })
            ).then(function (response) {
                if (response.error && response.error.message == "Rejected") {
                    snackbarCtx.displayMsg(response.payload, "failure");
                } else {
                    if (response?.payload?.data?.grade?.selling_price === null) {
                        setSellingPrice((parseFloat(activePackage?.package?.service_charge) / 100 * response?.payload?.data?.grade?.price) + response?.payload?.data?.grade?.price)
                    }

                    setQuantity(1);

                    setTotalAmount(reduxGrade.grade.selling_price || ((parseFloat(activePackage?.package?.service_charge) / 100 * response?.payload?.data?.grade?.price) + response?.payload?.data?.grade?.price));
                }
            });
        }
    }, []);

    return (
        <Container className="margin-content mb-20">
            {productAdded && <ProductAdded />}
            {(isShowPopupImage && reduxCatalog.product.photos) && <PopupImageSlider defaultPhoto={reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />}
            <Row className="mb-2 mt-2">
                <Col md={12} className="head-bar-nav">
                    <Link to={routesPaths.HOME} className="head-menu">
                        <span>Home</span>
                    </Link>
                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                    <Link to={routesPaths.CATALOG} className="head-menu">
                        <span>Farmers Produce</span>
                    </Link>
                    <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: "13px", paddingLeft: "4px", paddingRight: "4px" }} />
                    <span className="tag-current-bar">{reduxCatalog.product.name} {reduxCatalog.product.scientific_name && <span>({reduxCatalog.product.scientific_name})</span>}</span>
                </Col>

            </Row>
            <Row className="mb-2">
                <Col md={12} className="title-content">
                    <span>Product Details</span>
                </Col>
            </Row>
            <Row>
                <Col md={7} className="title-content">
                    {/* {reduxCatalog.product.photos && <CustomImageProduct defaultPhoto={reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />} */}
                    {/* {reduxCatalog.product.photos && <CustomImageProduct defaultPhoto={reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />} */}
                    {reduxCatalog.product.photos && <NewPhotoSlider name={""} address={""} photos={reduxCatalog.product.photos.data} reloadData={loadCatalog} disableEdit={true} />}
                </Col>
                <Col md={5} className="title-content">
                    <div className="bg-form-content" style={{ minHeight: "547px" }}>
                        <form className="">
                            <span className="title-content">{reduxCatalog.product.name} {reduxCatalog.product.scientific_name && <span>({reduxCatalog.product.scientific_name})</span>}</span>

                            <div className="row mt-2">
                                <div className="col-md-7">
                                    <div className="title-farm">{reduxCatalog.farm && reduxCatalog.farm.name}</div>
                                    <div className="title-farm-sub-address">{reduxCatalog.farm && reduxCatalog.farm.address}</div>
                                </div>
                                <div className="col-md-5" style={{ alignSelf: "end" }}>
                                    <button className="borderedButton btn-farm-details" onClick={farmDetailsHandler}>Farm Details</button>
                                </div>

                            </div>
                            <div className="mt-4">
                                <div className="title-farm">Grade</div>
                                <div className="flex">
                                    {reduxCatalog.product.crop.grades && reduxCatalog.product.crop.grades.data.map((cropGrade, index) => (
                                        <button className={(grade == cropGrade.id) || index == 0 ? "borderedwhiteButton btn-catalog-grade-selected mx-1" : "borderedwhiteButton btn-catalog-grade mx-1"} onClick={setGradeSelected} value={cropGrade.id} key={index}>
                                            {cropGrade.name}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-4">
                                {reduxGrade.grade.selling_price || sellingPrice ?
                                    <>
                                        <div>
                                            <span className="price-sp-label mr-2">
                                                {reduxGrade?.grade?.price_unit_symbol}
                                            </span>
                                            <span className="price-sp-label">

                                                {parseFloat(reduxGrade.grade.selling_price) || parseFloat(sellingPrice)}
                                            </span>
                                            <span className="price-sp-label">
                                                /{reduxGrade?.grade?.quantity_unit_capital}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="total-sp-label mr-2">
                                                Total
                                            </span>
                                            <span className="total-sp-label mr-2">
                                                {reduxGrade?.grade?.price_unit_symbol}
                                            </span>
                                            <span className="total-sp-label">

                                                {totalAmount.toFixed(2) ? totalAmount.toFixed(2) : 0}
                                            </span>

                                        </div>


                                    </>
                                    : <span className="price-sp-label w-1/2" >Please choose grade</span>
                                }

                            </div>

                            <div className="mt-4">
                                <div className="title-farm">Quantity</div>
                                <Row>
                                    <Col md={5}>
                                        <div className="btn-quantity flex justify-between">
                                            <span onClick={decrementCount} className="cursor-pointer">-</span>
                                            <span>{quantity}</span>
                                            <span onClick={incrementCount} className="cursor-pointer">+</span>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <span className="avai-detail">Available: {reduxGrade.grade?.quantity || 0} {reduxGrade?.grade?.quantity_unit_capital}</span>
                                    </Col>
                                </Row>
                            </div>
                            <div className="flex items-center gap-6 mt-4">
                                <button className={quantity == 0 ? "btn-add-carts-disabled": "btn-add-carts"} onClick={addToCartHandler} disabled={quantity == 0}>
                                    {reduxOrderDetail.loading ? "Processing, please wait.." : "Add To Cart"}
                                </button>
                                {/* <button className="borderedButton btn-farm-details" onClick={futureOrderHandler}>
                                    Future Order
                                </button> */}
                                {isLoggedIn && (
                                    <>
                                        {(currentUser?.id != reduxCatalog.catalog.user_id) && (
                                            <>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    className="borderedButton btn-farm-details"
                                                    onClick={() => {
                                                        if (window.innerWidth <= 500) {
                                                            navigate(`/room-chat/live/${reduxCatalog.catalog.user_id}/product/${reduxCatalog.product.id}/${reduxCatalog.catalog.id}`);
                                                        } else {
                                                            dispatch(updateActiveRoomChat());
                                                            setShowFLoatChat(true);
                                                        }

                                                    }}
                                                >
                                                    Chat with us
                                                </div>

                                            </>
                                        )}
                                    </>
                                )}
                            </div>


                        </form>
                        {isLoggedIn && (
                            <>
                                {showFLoatChat && (
                                    <>
                                        <div className="chat-popup-float z-10">
                                            <div className="container-chat">
                                                <div className="flex flex-row" style={{ backgroundColor: "#77b06e", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                                    <div className="basis-1/2 p-1">
                                                        <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faUpRightAndDownLeftFromCenter} className={"text-gray-400"} onClick={() => {
                                                            navigate(`/room-chat/live/${reduxCatalog.catalog.user_id}`);
                                                        }} />
                                                    </div>
                                                    <div className="basis-1/2 text-right p-1">
                                                        <FontAwesomeIcon style={{ cursor: "pointer", color: "white" }} icon={faCircleXmark} className={"text-gray-400"} onClick={() => {
                                                            dispatch(updateInActiveRoomChat());
                                                            setShowFLoatChat(false);
                                                        }} />
                                                    </div>
                                                </div>
                                                <ChatRoomFloating id_catalog={reduxCatalog.catalog.id} to_id={reduxCatalog.catalog.user_id} type_chat="product" type_chat_id={reduxCatalog.product.id} />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </>
                        )}
                    </div>

                </Col>
            </Row>
            {/* <div className="self-center flex flex-col px-8 py-6 gap-6">
                <span className={'cursor-pointer'} onClick={() => navigate(-1)}> {"< Go Back "}</span>

                {productAdded && <ProductAdded />}
                <label className="self-center buyProductFull:self-start text-3xl">{reduxCatalog.product.name}</label>
                <div className="flex flex-col buyProductFull:flex-row gap-6">
                    <section className="flex flex-col gap-4">
                        {reduxCatalog.product.photos && <CustomImageProduct defaultPhoto={reduxCatalog.product.photo_default} photos={reduxCatalog.product.photos} />}
                    </section>



                </div>
            </div> */}
            <Footer />
        </Container>

    );
};

export default CatalogShow;

