import React, { useState, useEffect, useContext } from "react";

import PageLayout from "pages/AccountLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { categoryIndex } from "../../features/category/categorySlice";
import { demandDelete, demandShow, demandStore, demandUpdate } from "../../features/demand/demandSlice";
import { gradeIndex } from "../../features/grade/gradeSlice";
import { fileUpload } from "../../features/file/fileSlice";
import PhotoSlider from "../files/slider/PhotoSlider";
import SnackbarContext from "_helpers/snackbar-context";
import { cropGuest, cropShow } from "../../features/crop/cropSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import SliderDemand from "components/files/SliderDemand/SliderDemand";
import PopupImageSlider from "components/catalogs/PopupImageSlider";

const DemandEdit = () => {
  const params = useParams();
  const [photo, setPhoto] = useState("");
  // const [name, setName] = useState("Loading...");

  // Keeps track of grades in the UI
  const [grades, setGrades] = useState([{ name: "" }]);
  // Sets the selectable grades in the dropdown menu
  const [selectableGrades, setSelectableGrades] = useState([{ name: "Could Not Find Grades" }]);

  // const [quantity_required, setQuantityRequired] = useState(0);

  // Keeps track of the selected frequency
  // const [frequency, setFrequency] = useState("");
  // Sets the selectable frequencies in the dropdown menu
  // const [selectable_frequencies, setSelectableFrequencies] = useState([{ name: "Could Not Find Frequencies" }]);
  //
  // const [requirement_open_date, setRequirementOpenDate] = useState("");

  // Handler to point button to file input
  const hiddenPhotoInput = React.useRef(null);
  const uploadHandler = (event) => {
    hiddenPhotoInput.current.click();
  };

  const submitHandler = (event) => {
    event.preventDefault();
  };

  const gradeHandler = (newGrade, gradeIndex) => {
    setGrades(grades.map((grade, index) => (index === gradeIndex ? { name: newGrade } : grade)));
  };

  useEffect(() => {
  }, [grades]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [name, setName] = useState("");

  // Keeps track of the selected frequency
  const [frequency, setFrequency] = useState("");

  // Sets the selectable frequencies in the dropdown menu
  const [selectableFrequencies, setSelectableFrequencies] = useState([{ name: "once" }, { name: "weekly" }]);
  const [selectableFrequencyDays, setSelectableFrequencyDays] = useState([
    { name: "monday" },
    { name: "tuesday" },
    { name: "wednesday" },
    { name: "thursday" },
    { name: "friday" },
    { name: "saturday" },
    { name: "sunday" },
  ]);
  const [frequencyDay, setFrequencyDay] = useState("");
  const [isShowPopupImage, setIsShowPopupImage] = useState(false);
  const [requirementOpenDate, setRequirementOpenDate] = useState("");
  const [requirementCloseDate, setRequirementCloseDate] = useState("");
  const [deliveryOneOf, setDeliveryOneOf] = useState("");
  const [photosNew, setPhotosNew] = useState([]);

  const reduxCategory = useSelector((state) => state.category);
  const reduxGrade = useSelector((state) => state.grade);
  const reduxCrop = useSelector((state) => state.crop);

  const reduxDemand = useSelector((state) => state.demand);
  const [categoryId, setCategoryId] = useState("");
  const [quantityRequired, setQuantityRequired] = useState("");
  const [cropId, setCropId] = useState("");
  const [quantityUnit, setQuantityUnit] = useState("");

  const [gradeId, setGradeId] = useState("");
  const snackbarCtx = useContext(SnackbarContext);
  const remove = (event) => {


    if (window.confirm("Are you sure you want to delete this demand?")) {
      dispatch(demandDelete({
        demand_id: params.demand_id
      })
      ).then(function (response) {
        if (response.error && response.error.message === "Rejected") {
          snackbarCtx.displayMsg(response.payload, "failure")
          //alert(response.payload);
        } else {
          snackbarCtx.displayMsg(response.payload.message, "success")

          navigate(`/demands`);
        }
      });
    }


    event.preventDefault();
  }


  const save = (event) => {
    dispatch(
      demandUpdate({
        // customer_id
        demand_id: params.demand_id,
        name: name,
        grade_id: gradeId,
        crop_id: cropId,
        category_id: categoryId,
        quantity_required: quantityRequired,
        frequency: frequency,
        frequency_day: frequencyDay,
        delivery_one_off: deliveryOneOf,
        requirement_open_date: requirementOpenDate,
        requirement_close_date: requirementCloseDate,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        //alert(response.payload);
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        navigate(`/demands`);
      }
    });

    event.preventDefault();
  };

  const uploadImg = () => {
    inputElementPhoto.click();
  }

  const loadDemand = () => {
    dispatch(
      demandShow({
        demand_id: params.demand_id,
      })
    ).then(function (response) {
      let demand = response.payload.data.demand;

      setPhotos(demand.photos.data);
      setPhotosNew(demand.photos);
      setName(demand.name);
      setCategoryId(demand.category_id);
      setDeliveryOneOf(demand.delivery_one_off);
      setFrequency(demand.frequency);
      setGradeId(demand.grade_id);
      setQuantityRequired(demand.quantity_required);
      setRequirementCloseDate(demand.requirement_close_date);
      setRequirementOpenDate(demand.requirement_open_date);
      setFrequencyDay(demand.frequency_day);
      setQuantityUnit(demand.unit)

      loadCrop(demand.crop.id)
      setCropId(demand.crop.id)
    });
  };

  const loadCategories = () => {
    dispatch(
      categoryIndex({
        page: 1,
        limit: 100,
      })
    ).then(function (response) {
      // console.log(response.payload.message);
    });
  };

  const loadGrades = () => {
    dispatch(
      gradeIndex({
        page: 1,
        limit: 100,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        // alert(response.payload);
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        //console.log(response.payload.message);
      }
    });
  };

  const [photos, setPhotos] = useState([]);
  const [inputElementPhoto, setInputElementPhoto] = useState("");

  const uploadPhotos = (event) => {
    let demandId = params.demand_id;
    let length = event.target.files.length;
    for (let i = 0; i < length; i++) {
      let selectedFile = event.target.files[i];

      const formData = new FormData();

      formData.append("file", selectedFile, selectedFile.name);
      formData.append("store", "multiple");
      formData.append("model", "demand");
      formData.append("type", "photo");
      formData.append("model_id", demandId);

      dispatch(fileUpload(formData)).then(function (response) {
        if (response.error && response.error.message == "Rejected") {
          alert(response.payload);
        } else {
          let photos = response.payload.data.demand.photos.data;

          setPhotos(photos);
          setPhotosNew(response.payload.data.demand.photos);
        }
      });
    }
  };

  const loadCrop = (cropId) => {
    dispatch(cropShow({
      crop_id: cropId
    })).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        // snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });
  }

  const loadCrops = () => {
    dispatch(cropGuest({
      crop_id: cropId
    })).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure")
      } else {
        //  snackbarCtx.displayMsg(response.payload.message, "success")
      }
    });
  }




  useEffect(() => {

    loadCategories();
    loadCrops();
    // loadGrades();
    loadDemand();
  }, []);

  useEffect(() => {
    if (frequency !== "once") {
      setDeliveryOneOf("");
    } else {
      setFrequencyDay("");
    }
  }, [frequency]);

  return (
    <PageLayout>
      <div
        data-testid="demand-edit"
        className="flex flex-col items-start"
      >
        {(isShowPopupImage && photos) && <PopupImageSlider defaultPhoto={photos[0]} photos={photosNew} isShowPopupImage={isShowPopupImage} setIsShowPopupImage={setIsShowPopupImage} />}

        {/* Actual Component */}
        <div className="w-full flex flex-col p-4 laptop:p-6 wide:p-6 mb-5">
          <div className="head-bar-back">
            <section>
              <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
                {" Go Back"}
              </label>
            </section>
            <div className="mb-2">
              <label className="title-content">
                Edit Demand
              </label>
            </div>
          </div>

          <div className="flex flex-col rounded-lg shadow bg-card-white-new">
            <form>
              <Row>
                {/* image  */}
                <Col md={6}>
                  <section className="w-full  place-self-center laptop:self-start flex flex-col">
                    <SliderDemand photos={photos} reloadData={loadDemand} uploadImg={uploadImg} setIsShowPopupImage={setIsShowPopupImage} />
                    <input
                      className={"hidden"}
                      type="file"
                      accept="image/png, image/jpeg"
                      multiple
                      onChange={uploadPhotos}
                      ref={(input) => setInputElementPhoto(input)}
                    />

                    {/* <button type="button" className="saveButton" onClick={() => {
                      inputElementPhoto.click();
                    }}>
                      <label
                        className="cursor-pointer text-sm"
                      >
                        Upload Photos
                      </label>
                      <input
                        className={"hidden"}
                        type="file"
                        accept="image/png, image/jpeg"
                        multiple
                        onChange={uploadPhotos}
                        ref={(input) => setInputElementPhoto(input)}
                      />
                    </button> */}
                  </section>
                </Col>

                <Col md={6} className="padding-form-market">
                  <div className="self-center w-full flex flex-col gap-4">
                    <div className="w-full flex flex-col gap-4">
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Product Name
                        </Col>
                        <Col md={8}>
                          <input type="text" placeholder={name} value={name} onChange={({ target }) => setName(target.value)} className={" input-search"} />
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Categories
                        </Col>
                        <Col md={8}>
                          {
                            <select
                              className="input-search"
                              // defaultValue={""}
                              value={categoryId || ''}
                              onChange={({ target }) => setCategoryId(target.value)}
                            >
                              <option value="">Select Category</option>
                              {reduxCategory.categories.data.map((category, index) => {
                                return (
                                  <option value={category.id} key={index}>
                                    {category.name}
                                  </option>
                                );
                              })}
                            </select>
                          }
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Available Crops
                        </Col>
                        <Col md={8}>
                          {
                            <select
                              className="input-search"
                              // defaultValue={""}
                              value={cropId || ''}
                              onChange={({ target }) => {
                                setCropId(target.value);
                                loadCrop(target.value)


                                reduxCrop.crops?.data?.map((crop, index) => {
                                  if (crop.id == target.value) {
                                    setName(crop?.name)
                                  }
                                })

                              }}
                            >
                              <option value="" disabled={true}>Select Crop</option>
                              {reduxCrop.crops.data && reduxCrop.crops.data.map((crop, index) => {
                                return (
                                  <option value={crop.id} key={index}>
                                    {crop.name}
                                  </option>
                                );
                              })}
                            </select>
                          }
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Grades
                        </Col>
                        <Col md={8}>
                          <select
                            className="input-search"
                            // defaultValue={""}
                            value={gradeId || ''}
                            onChange={({ target }) => setGradeId(target.value)}
                          >
                            <option value="" disabled={true}>Select Grade</option>
                            {reduxCrop.crop.grades.data &&
                              reduxCrop.crop.grades.data.map((grade, index) => {
                                return (
                                  <option value={grade.id} key={index}>
                                    {grade.name}
                                  </option>
                                );
                              })}
                          </select>
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Amount Required
                        </Col>
                        <Col md={8}>
                          <input
                            className="input-search w-2/3"
                            type="number"
                            min={1}
                            placeholder="Amount Required"
                            value={quantityRequired}
                            onChange={({ target }) => setQuantityRequired(target.value)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Frequency
                        </Col>
                        <Col md={8}>
                          <select
                            className="input-search"
                            defaultValue={"DEFAULT"}
                            value={frequency || 'DEFAULT'}
                            onChange={({ target }) => setFrequency(target.value)}
                          >
                            <option value="DEFAULT" disabled>
                              Select Frequency
                            </option>
                            {selectableFrequencies.map((frequency, index) => (
                              <option value={frequency.name} key={index}>
                                {frequency.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                      {frequency === "weekly" && (
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Frequency Day
                          </Col>
                          <Col md={8}>
                            <select
                              className="input-search"
                              defaultValue={"DEFAULT"}
                              value={frequencyDay || 'DEFAULT'}
                              onChange={({ target }) => setFrequencyDay(target.value)}
                            >
                              <option value="DEFAULT" disabled>
                                Select Frequency
                              </option>
                              {selectableFrequencyDays.map((frequencyDay, index) => (
                                <option value={frequencyDay.name} key={index}>
                                  {frequencyDay.name}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                      )}
                      {frequency == "once" && (
                        <Row style={{ placeItems: "center" }}>
                          <Col md={4} className="head-bar-nav">
                            Delivery one off
                          </Col>
                          <Col md={8}>
                            <input
                              className="input-search"
                              type="date"
                              placeholder="Date Requirement Open"
                              value={deliveryOneOf}
                              onChange={({ target }) => setDeliveryOneOf(target.value)}
                            />
                          </Col>
                        </Row>
                      )}

                      <Row style={{ placeItems: "center" }}>
                        <Col md={4} className="head-bar-nav">
                          Demand Period
                        </Col>
                        <Col md={8}>
                          <Row style={{ placeItems: "center" }}>
                            <Col md={6} sm={6} xs={6}>
                              <input
                                className="input-search"
                                type="date"
                                placeholder="Date Requirement Open"
                                value={requirementOpenDate}
                                onChange={({ target }) => setRequirementOpenDate(target.value)}
                              />
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                              <input
                                className="input-search"
                                type="date"
                                placeholder="Date Requirement Close"
                                value={requirementCloseDate}
                                onChange={({ target }) => setRequirementCloseDate(target.value)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ placeItems: "center" }}>
                        <Col md={6} xs={5}>
                          <button type="submit" className="saveButton" onClick={save}>
                            {/*Post Demand*/}
                            {reduxDemand.updating ? "Processing, please wait.." : "Save"}
                          </button>


                        </Col>
                        <Col md={6}  xs={7}>
                          <button className=" saveButton bg-red-500 hover:bg-red-600 hover:ring-2 hover:ring-red-600" onClick={remove}  >
                            {reduxDemand.updating ? "Processing, please wait.." : "Delete Demand"}
                          </button>
                        </Col>
                      </Row>
                    </div>

                  </div>
                </Col>

              </Row>

            </form>
          </div>

        </div>
      </div>
    </PageLayout>
  );
};

export default DemandEdit;
