export const bookingPlotStatus = {
    0 : {
        'label' : 'New',
        'label_in_farmer_farmer' : 'To be confirmed',
        'label_in_farmer_buyer' : 'To be accepted',
        'label_in_buyer_farmer' : 'Booking in progress',
        'label_in_buyer_buyer' : 'Booking in progress',
        'status_farmer' : '',
        'status_buyer' : 'Awaiting farmers confirmation',
        'icon_farmer' : '/assets/images/booking/icon-time.png',
        'icon_buyer' : '/assets/images/booking/icon-time.png',
        'badge' : 'primary'
    },
    1 : {
        'label' : 'Awaiting Deposit',
        'label_in_farmer_farmer' : 'Awaiting Deposit',
        'label_in_farmer_buyer' : 'Deposit Required',
        'label_in_buyer_farmer' : 'Booking in progress',
        'label_in_buyer_buyer' : 'Booking in progress',
        'status_farmer' : 'Awaiting deposit from buyer',
        'status_buyer' : '',
        'icon_farmer' : '/assets/images/booking/icon-time.png',
        'icon_buyer' : '/assets/images/booking/icon-time.png',
        'badge' : 'warning'
    },
    2 : {
        'label' : 'Canceled',
        'label_in_farmer_farmer' : 'Booking Canceled',
        'label_in_farmer_buyer' : 'Booking Canceled',
        'label_in_buyer_farmer' : 'Booking Canceled',
        'label_in_buyer_buyer' : 'Booking Canceled',
        'status_farmer' : '',
        'status_buyer' : '',
        'icon_farmer' : '/assets/images/booking/icon-block.png',
        'icon_buyer' : '/assets/images/booking/icon-block.png',
        'badge' : 'danger'
    },
    3 : {
        'label' : 'Deposit Paid',
        'label_in_farmer_farmer' : 'Booking Confirmed',
        'label_in_farmer_buyer' : 'Deposit Paid',
        'label_in_buyer_farmer' : 'Booking in progress',
        'label_in_buyer_buyer' : 'Booking in progress',
        'status_farmer' : '',
        'status_buyer' : 'Awaiting full order confirmation from farmer',
        'icon_farmer' : '/assets/images/booking/icon-check.png',
        'icon_buyer' : '/assets/images/booking/icon-time.png',
        'badge' : 'superWarning'
    },
    4 : {
        'label' : 'Order Created',
        'label_in_farmer_farmer' : 'Order Created',
        'label_in_farmer_buyer' : 'Order Created',
        'label_in_buyer_farmer' : 'Order Created',
        'label_in_buyer_buyer' : 'Order Created',
        'status_farmer' : 'Order has been transferred to Incoming Order',
        'status_buyer' : 'Order has been transferred to Incoming Order',
        'icon_farmer' : '/assets/images/booking/icon-check.png',
        'icon_buyer' : '/assets/images/booking/icon-time.png',
        'badge' : 'success'
    },
    5 : {
        'label' : 'Awaiting Deposit Confirmation',
        'label_in_farmer_farmer' : 'Awaiting Deposit Confirmation',
        'label_in_farmer_buyer' : 'Deposit paid using offline payment',
        'label_in_buyer_farmer' : 'Booking in progress',
        'label_in_buyer_buyer' : 'Booking in progress',
        'status_farmer' : 'Payment for deposit received, waiting for your confirmation',
        'status_buyer' : 'Awaiting Deposit confirmation',
        'icon_farmer' : '/assets/images/booking/icon-check.png',
        'icon_buyer' : '/assets/images/booking/icon-time.png',
        'badge' : 'warning'
    },
    // 13 is a 'transition' status from 1 to 3. 
    // The payment has been made but the status is still pending on the payment gateway.
    13 : {
        'label' : 'Deposit Payment Processing',
        'badge' : 'extraWarning'
    },
};