import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


import AccountLayout from "pages/AccountLayout";
import DemandFarmControls from "./FarmViewControls";
import { routesPaths } from "constants/routes.constants";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userFarmerFarms } from "../../../features/user/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const FarmViewWidgets = () => {
  const navigate = useNavigate();

  const params = useParams();
  const dispatch = useDispatch();

  const dummyFarmList = {
    data: [
      { farm_id: 1, name: "", address: "", photo: "" },
      { farm_id: 2, name: "Green Farm", address: "21 Farm Street", photo: "/assets/images/plots/PlotPhoto1.svg" },
      { farm_id: 3, name: "Fresh Farm", address: "22 Farm Street", photo: "/assets/images/plots/PlotPhoto2.svg" },
      { farm_id: 4, name: "Healthy Farm", address: "23 Farm Street", photo: "/assets/images/plots/PlotPhoto3.svg" }
    ],
  }

  const farmList = dummyFarmList;

  const demand_id = 1;
  const viewHandler = (farm_id) => {
    // navigate(`${routesPaths.DEMANDS}/${demand_id}/show/widgets/${farm_id}/show`);
    navigate(`/farms/${farm_id}`);
  }

  const reduxUser = useSelector((state) => state.user);

  const loadFarmerFarms = () => {


    dispatch(userFarmerFarms({
      farmer_id: params.farmer_id
    })).then(function (response) {
      if (response.error && response.error.message == "Rejected") {
        alert(response.payload);
      } else {
        // console.log(response.payload.message);
      }
    });
  }

  useEffect(() => {
    loadFarmerFarms()
  }, []);


  return (
    <AccountLayout>
      <div data-testid="demand-farm-widgets" className="w-full flex flex-col gap-4 p-4 laptop:p-6 wide:p-6 mb-5">
        <div>
          <label className="cursor-pointer head-bar-nav" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "13px" }} />
            {" Go Back"}
          </label>
          <div className="title-content">
            Farm Details
          </div>
        </div>

        <div
          className="w-full grid place-items-center tablet:grid-cols-3 laptop:grid-cols-3 desktop:grid-cols-5 wide:grid-cols-6 gap-4">
          {reduxUser.farms.data && reduxUser.farms.data.map((farm, index) => {
            return (
              <button className="flex flex-col relative rounded-lg shadow w-full h-full" onClick={() => viewHandler(farm.id)}>
                <img src={farm.photo_default} alt="example farm" className="w-full h-full object-cover aspect-square rounded-lg" />

                <div
                  className="absolute w-full bottom-0 bg-black/30 h-1/3 flex flex-col items-start p-2 rounded-b-md box-img-card"
                  style={{ textAlign: "left" }}>
                  <label className="font-in-box-market cursor-pointer">{farm.name}</label>
                  <label className="font-in-box-sub-market cursor-pointer">{farm.address}</label>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </AccountLayout>
  );
};

export default FarmViewWidgets;
