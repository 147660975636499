import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { farmStore } from "../../features/farm/farmSlice";
import SnackbarContext from "_helpers/snackbar-context";

import AccountLayout from "pages/AccountLayout";
import PlotWidget from "./plots/PlotWidget";
import FarmControls from "./FarmControls";

import FarmImage from "../../images/FarmImage.png";
import GoogleLogo from "../../images/Google.svg";
import CheckCircle from "../../images/CheckCircle.svg";
import { Col, Row } from "react-bootstrap";
import iconHome from "images/iconHome.png";
import iconMsg from "images/iconMsg.png";
import iconLoca from "images/iconLoca.png";
import { Circle, FeatureGroup, LayerGroup, LayersControl, MapContainer, Marker, Popup, Rectangle, TileLayer, useMap, useMapEvents } from "react-leaflet";
import { useCallback } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";

import clsx from "clsx";
import styles from "../account/Account.module.scss";

const FarmCreate = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [certificate, setCertificate] = useState("");
  const [photo, setPhoto] = useState(FarmImage);
  const [plots, setPlots] = useState([]);
  const centerLocation = {
    lat: 1.966773,
    lng: 102.881449,
  }

  const [location, setLocation] = useState("");
  const [latitude, setLatitude] = useState(centerLocation.lat);
  const [longitude, setLongitude] = useState(centerLocation.lng);

  const dispatch = useDispatch();
  const snackbarCtx = useContext(SnackbarContext);

  const { farm, updating } = useSelector((state) => state.farm);

  const manageHandler = (farmID) => {
    navigate(`/account/farms/edit-plots/${farmID}`);
  };

  const save = (event) => {
    dispatch(
      farmStore({
        name: name,
        address: address,
        location: location,
        latitude: latitude,
        longitude: longitude,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        snackbarCtx.displayMsg(response.payload, "failure");
        //alert(response.payload);
      } else {
        //console.log(response.payload.message);
        snackbarCtx.displayMsg(response.payload.message, "success");
        let farm = response.payload.data.farm;
        navigate(`/farms/${farm.id}/edit`);
      }
    });

    event.preventDefault();
  };

  const SearchField = () => {
    const provider = new OpenStreetMapProvider();
    // @ts-ignore
    const searchControl = new GeoSearchControl({
      provider: provider,
      showMarker: false,
    });

    const map = useMap();
    useEffect(() => {
      map.addControl(searchControl);
      return () => map.removeControl(searchControl);
    }, []);

    return null;
  };

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        // setState your coords here
        // coords exist in "e.latlng.lat" and "e.latlng.lng"
        setLatitude(e.latlng.lat.toFixed(6));
        setLongitude(e.latlng.lng.toFixed(6));
      },
    });
  }


  const DraggableMarker = ({ centerLocationPick }) => {
    const [draggable, setDraggable] = useState(true);
    const [position, setPosition] = useState(centerLocationPick);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            var locati = null;
            locati = marker.getLatLng();
            setLatitude(locati.lat.toFixed(6));
            setLongitude(locati.lng.toFixed(6));
            setPosition({ lat: locati.lat.toFixed(6), lng: locati.lng.toFixed(6) });

          }
        },
      }),
      [],
    );

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90}>
          <span>
            {position.lat}, {position.lng}
          </span>
        </Popup>
      </Marker>
    );
  }

  return (
    <AccountLayout>
      <div className="w-full flex flex-col gap-4 p-4 laptop:p-6 wide:p-6 mb-5">
        <div className="title-content">
          Create Farm
        </div>
        <FarmControls />
        <Row>
          <Col md={8} >
            <div className="w-full h-full" style={{ border: "1px solid black", borderRadius: "8px" }}>
              <MapContainer center={[centerLocation.lat, centerLocation.lng]} zoom={13} scrollWheelZoom={false}>
                <SearchField />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker centerLocationPick={{
                  lat: latitude,
                  lng: longitude,
                }} />
                <MapEvents />
                <LayersControl position="topright">
                  <LayersControl.Overlay name="Satellite">
                    <TileLayer
                      url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                      maxZoom={20}
                      subdomains={['mt1', 'mt2', 'mt3']}
                    />
                  </LayersControl.Overlay>
                </LayersControl>
              </MapContainer>
            </div>
          </Col>
          <Col md={4}>
            <div className="w-full bg-content-new-white self-center flex flex-col p-6 rounded-lg shadow mt-3">
              {/* <h1 className="text-2xl">Create Farm</h1> */}
              <form onSubmit={save}>
                {/*This is create..*/}
                <section className="w-full flex flex-col space-y-4">
                  {/* <div className="flex flex-col">
                    <h1 className="text-xl  self-center">{name}</h1>
                    <h1 className="text-sm  self-center">{address}</h1>
                  </div> */}
                  {/*<button type="button" className="flex gap-2">*/}
                  {/*<img src={GoogleLogo} alt="Google logo" />*/}
                  {/*<label className="cursor-pointer">Add Farm Geo Location</label>*/}
                  {/*<div className="bg-green-500 text-white flex justify-center items-center rounded-full w-5 h-5">+</div>*/}
                  {/*</button>*/}
                  <img src={photo} alt="Photo of this farm" className="rounded-lg shadow" />
                  <div className="flex justify-between space-x-2" style={{ alignItems: "center" }}>
                    <div style={{ width: "20%" }}>
                      <img src={iconHome} alt="" />
                    </div>
                    <input className="input-search " type="text" placeholder="Farm Name" value={name} onChange={({ target }) => setName(target.value)} />
                  </div>
                  <div className="flex justify-between space-x-2" style={{ alignItems: "center" }}>
                    <div style={{ width: "20%" }}>
                      <img src={iconMsg} alt="" />
                    </div>
                    <input
                      type="text"
                      className="input-search"
                      placeholder="Farm Address"
                      value={address}
                      onChange={({ target }) => setAddress(target.value)}
                    />
                  </div>
                  <div className="flex justify-between space-x-2" style={{ alignItems: "center" }}>
                    <div style={{ width: "20%" }}>
                      <img src={iconLoca} alt="" />
                    </div>
                    <input
                      type="text"
                      className="input-search"
                      value={`${latitude}, ${longitude}`}
                      placeholder="Farm Location"
                      disabled={true}
                    />
                  </div>
                  <div className="flex justify-between space-x-4">
                    {/* <div className="w-1/2 flex flex-col gap-2">
                      <label>New Name:</label>
                      <input type="text" placeholder={name} value={name} onChange={({ target }) => setName(target.value)} />
                    </div>
                    <div className="w-1/2 flex flex-col gap-2">
                      <label>New Address:</label>
                      <input
                        type="text"
                        placeholder={address}
                        value={address}
                        onChange={({ target }) => setAddress(target.value)}
                      />
                    </div> */}
                    {/*<div className="flex flex-col gap-2 hidden">*/}
                    {/*<label>Certificate:</label>*/}
                    {/*<label className="bg-white flex px-3 py-2 rounded-lg shadow gap-2">*/}
                    {/*Certificate_1.pdf*/}
                    {/*<img src={CheckCircle} alt="check mark" />*/}
                    {/*</label>*/}
                    {/*</div>*/}
                    {/*<div className="flex space-x-4 hidden">*/}
                    {/*<button type="button" className="saveButton">*/}
                    {/*Upload Certificate*/}
                    {/*</button>*/}
                    {/*<button type="button" className="saveButton">*/}
                    {/*Upload Photos*/}
                    {/*</button>*/}
                    {/*</div>*/}
                    {/*<button type="submit" className="saveButton">*/}
                    {/*/!*Save*!/*/}
                    {/*{updating ? "Processing, please wait.." : "Save"}*/}
                    {/*</button>*/}

                    {/*<section className="w-full flex flex-col space-y-4">*/}
                    {/*<div className="flex flex-col">*/}
                    {/*<h1 className="text-xl  self-center">Farm Plots</h1>*/}
                    {/*<h1 className="text-sm  self-center">4 Plots</h1>*/}
                    {/*</div>*/}

                    {/*<button type="button" className="saveButton" onClick={() => manageHandler(farmID)}>*/}
                    {/*Manage Farm Plots*/}
                    {/*</button>*/}
                    {/*<div className="grid grid-cols-2 gap-6">*/}
                    {/*<PlotWidget />*/}
                    {/*<PlotWidget />*/}
                    {/*<PlotWidget />*/}
                    {/*<PlotWidget />*/}
                    {/*</div>*/}
                    {/*</section>*/}
                  </div>
                  {/*<div className="flex flex-col gap-2 hidden">*/}
                  {/*<label>Certificate:</label>*/}
                  {/*<label className="bg-white flex px-3 py-2 rounded-lg shadow gap-2">*/}
                  {/*Certificate_1.pdf*/}
                  {/*<img src={CheckCircle} alt="check mark" />*/}
                  {/*</label>*/}
                  {/*</div>*/}
                  {/*<div className="flex space-x-4 hidden">*/}
                  {/*<button type="button" className="saveButton">*/}
                  {/*Upload Certificate*/}
                  {/*</button>*/}
                  {/*<button type="button" className="saveButton">*/}
                  {/*Upload Photos*/}
                  {/*</button>*/}
                  {/*</div>*/}
                  <button type="submit" className="saveButton btn-add-carts">
                    Save
                  </button>
                </section>
                {/*<section className="w-full flex flex-col space-y-4">*/}
                {/*<div className="flex flex-col">*/}
                {/*<h1 className="text-xl  self-center">Farm Plots</h1>*/}
                {/*<h1 className="text-sm  self-center">4 Plots</h1>*/}
                {/*</div>*/}

                {/*<button type="button" className="saveButton" onClick={() => manageHandler(farmID)}>*/}
                {/*Manage Farm Plots*/}
                {/*</button>*/}
                {/*<div className="grid grid-cols-2 gap-6">*/}
                {/*<PlotWidget />*/}
                {/*<PlotWidget />*/}
                {/*<PlotWidget />*/}
                {/*<PlotWidget />*/}
                {/*</div>*/}
                {/*</section>*/}
              </form>
            </div>
          </Col>
        </Row>

      </div>
    </AccountLayout>
  );
};

export default FarmCreate;
